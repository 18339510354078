import gql from "graphql-tag";

export const SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY = gql`
  query superIntendentPavingCalendarDatas($where: SuperIntendentPavingCalendarDatasInput!){
    superIntendentPavingCalendarDatas(where: $where) {
			correctItems {
				date
				jobNumber
				tonnage
				pmpe
				shift
				tph
				rtsSupport
				bookTruckVendor
				grinder4ft
				grinder6ft
				grinder7ft
				grinder12ft
				extraWork
				uts
				superIntendent
				plant
				loadOutTime
				pavingForeman
				pavingSuperIntendent
				typeOfTrucks
				nrOfTrucks
				mixSubmital
				broker
				material
				mixSubmital
				mixDesignApproval
				locationIndex
				jobName
				crewMakeOp
				crewMakeLab
				jobMap
				oilTruk
				resourcesOp {
					resourceID
    			resourceType
   				resourceQTY
				 	resourceDescription
				}
				resourcesLab {
					resourceID
    			resourceType
   				resourceQTY
					resourceDescription
				}
			  addedDate
				pavingTimeStamp
				truckingTimeStamp
			},
			incompleteItems {
				date
				jobNumber
				jobName
				pmpe
				locationIndex
				areaManager
			}
		}
  }
`;