import React, { ReactElement } from "react";
import EventEmitter from "eventemitter3";
import { isElement } from "react-dom/test-utils";
import { EmitterEvents } from "../emitter";
import { v4 } from "uuid";

interface ElementModal<P> {
  id?: string;
  element: ReactElement<P>;
}

interface ComponentModal<P> {
  id?: string;
  component: React.ComponentClass<P> | React.FC<P>;
  props?: P;
}

export type Modal<P = any> = ElementModal<P> | ComponentModal<P>;

export function isElementModal(modal: any): modal is ElementModal<any> {
  return (
    isElement((modal as ElementModal<any>).element)
    && (modal as ComponentModal<any>).component == null
    && (modal as ComponentModal<any>).props == null
  );
}

export function isComponentModal(modal: any): modal is ComponentModal<any> {
  return (
    (modal as ComponentModal<any>).component != null
    && (modal as ComponentModal<any>).props != null
    && (modal as ElementModal<any>).element == null
  );
}

export type ModalContextValue = {
  emitter: EventEmitter<string | symbol, any> | undefined;
  openModal: <P>(modal: Modal<P>) => void;
  getModalHandlers: <P>(modal: Modal<P>) => {
    openModal: () => void;
    closeModal: () => void;
  }
  openTopModal: <P>(modal: Modal<P>) => void;
  getTopModalHandlers: <P>(modal: Modal<P>) => {
    openTopModal: () => void;
    closeTopModal: () => void;
  },
  closeAllModals: () => void;
}

const openModalCreator = (emitter: EventEmitter<string | symbol, any>) =>
  (modal: Modal) => { emitter?.emit?.(EmitterEvents.MODAL, { ...modal, id: v4() }) };

const openModalHandler = (emitter: EventEmitter<string | symbol, any>, id: string, modal: Modal) =>
  () => { emitter?.emit?.(EmitterEvents.MODAL, { ...modal, id }) };

const closeModalHandler = (emitter: EventEmitter<string | symbol, any>, id: string) =>
  () => { emitter?.emit?.(EmitterEvents.CLOSE_MODAL, { id }) };

const getModalHandlersCreator = (emitter: EventEmitter<string | symbol, any>) => {
  const id = v4();
  return (modal: Modal) => ({
    openModal: openModalHandler(emitter, id, modal),
    closeModal: closeModalHandler(emitter, id),
  });
}

const openTopModalCreator = (emitter: EventEmitter<string | symbol, any>) =>
  (modal: Modal) => { emitter?.emit?.(EmitterEvents.TOP_MODAL, { ...modal, id: v4() }) };

const openTopModalHandler = (emitter: EventEmitter<string | symbol, any>, id: string, modal: Modal) =>
  () => { emitter?.emit?.(EmitterEvents.TOP_MODAL, { ...modal, id }) };

const closeTopModalHandler = (emitter: EventEmitter<string | symbol, any>, id: string) =>
  () => { emitter?.emit?.(EmitterEvents.CLOSE_TOP_MODAL, { id }) };

const getTopModalHandlersCreator = (emitter: EventEmitter<string | symbol, any>) => {
  const id = v4();
  return (modal: Modal) => ({
    openTopModal: openTopModalHandler(emitter, id, modal),
    closeTopModal: closeTopModalHandler(emitter, id),
  });
}

const closeAllModals = (emitter: EventEmitter<string | symbol, any>) =>
  () => {
    emitter.emit(EmitterEvents.CLOSE_ALL_MODALS);
  }

export const createModalContextValue = (emitter: EventEmitter<string | symbol, any>): ModalContextValue => {
  return ({
    emitter,
    openModal: openModalCreator(emitter),
    getModalHandlers: getModalHandlersCreator(emitter),
    openTopModal: openTopModalCreator(emitter),
    getTopModalHandlers: getTopModalHandlersCreator(emitter),
    closeAllModals: closeAllModals(emitter),
  });
}

export const ModalContext = React.createContext<ModalContextValue | null>(null);
