import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { settings } from "../config/settings";
import { EnhancedStore } from '@reduxjs/toolkit';
import { removeToken } from '../redux/appSlice';
import { removeTokenFromCookie } from '../auth/removeTokenFromCookie';
import { getTokenFromCookie, getIdTokenFromCookie } from '../auth/getTokenFromCookie';

export const createClient = (store: EnhancedStore) => {
  // Create connection link
  const httpLink = new HttpLink({
    uri: settings?.graphqlUri,
  });

  // get token for singed requests
  const middlewareLink = setContext(() => {
    const token = getTokenFromCookie();
    const idToken = getIdTokenFromCookie();
    const authorizationHeader = token ? `Bearer ${token}` : null;
    const cognitoHeader = idToken ? `Bearer ${idToken}` : null;

    return {
      headers: {
        Authorization: authorizationHeader,
        Cognito: cognitoHeader,
      },
    };
  });

  // catch errors
  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
    if (
      (graphQLErrors
        ?.map?.(err => err?.extensions?.code)
        ?.filter?.(code => code === "UNAUTHENTICATED")
        ?.length ?? 0) > 0
    ) {
      store.dispatch(removeToken());
      removeTokenFromCookie();
    }
  });

  // set cache
  const cache = new InMemoryCache();

  // return new client
  return new ApolloClient({
    link: ApolloLink.from([middlewareLink, errorLink, httpLink]),
    cache,
    connectToDevTools: true,
  });
}
