export interface IState {
  nrOfTrucks: number,
  typeOfTrucks: string,
  tonnage: number,
  pavingSuper: string,
  pavingForeman: string,
  truckCo: string,
  loadOutTime: string,
  tph: number,
  material: string,
  plant: string,
  mixSubmital: string,
  mixDesignApproval: string,
  rtsSupport: string,
  bookTruckVendor: string,
  uts: string,
  extrawork: string,
  oilTruk: string,
  grinder4ft: number,
  grinder6ft: number,
  grinder7ft: number,
  grinder12ft: number,
  updateJobInfoTable: boolean,
  updateMasterCrewSchedulePavingTable: boolean,
  updateMasterCrewScheduleTable: boolean,
}


export const booleanType = [
  {
    value: "Y",
    key: "Yes"
  },

  {
    value: "N",
    key: "No"
  }
]