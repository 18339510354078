import gql from "graphql-tag";

export const MASTER_CREW_SCHEDULE_AND_PAVING_QUERY = gql`
  query masterCrewScheduleAndPaving($where: StartEndDateInput!) {
    masterCrewScheduleAndPaving(where: $where) {
			trucking {
				broker
				material
    		loadSite
				type
			},
			paving {
				material
				plant
			}
		}
	}
`;