import { ReactElement, useRef } from "react";
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop, XYCoord } from "react-dnd";
import { ItemTypesEquip } from "./ItemTypes";

export interface ChangeEvent {
  dragSource: DraggableEquipItem;
  dropTarget?: DraggableEquipItem;
}

export interface DraggableFileProps {
  index: number;
  hasSubcategoryDescription: boolean;
  subcategoryId: string;
  subcategoryDescription: string;
  accept: ItemTypesEquip;
  children(ref: React.RefObject<any>, isDragging: boolean): ReactElement | null;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  noPreview?: boolean;
}

export interface DraggableEquipItem {
  index: number;
  subcategoryId: string;
  subcategoryDescription: string;
  hasSubcategoryDescription: boolean;
  type: ItemTypesEquip;
  noPreview?: boolean;
}

export const DraggableItem: React.FC<DraggableFileProps> = ({ index, hasSubcategoryDescription, accept, children, moveCard, subcategoryDescription, subcategoryId, noPreview }) => {
  const ref = useRef<HTMLDivElement>(null);
  const equipItem: DraggableEquipItem = { index, hasSubcategoryDescription, type: accept, subcategoryDescription, subcategoryId, noPreview };

  const [{ isDragging }, drag] = useDrag({
    item: equipItem,

    canDrag(monitor: DragSourceMonitor) {
      return hasSubcategoryDescription
    },

    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag()
    }),
  })

  const [, drop] = useDrop({
    accept,
    hover(item: DraggableEquipItem, monitor: DropTargetMonitor) {
      if (!ref.current) { return }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) { return }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current!.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  drag(drop(ref));

  return typeof children === "function"
    ? children(ref, isDragging)
    : null;
}