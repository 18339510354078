import React, { FC, useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import moment from 'moment';

interface IDateSelect {
  superIntendent: string;
}

const DateSelect: FC<IDateSelect> = (props) => {
  const [firstDay, setFirstDay] = useState<number>();
  const [lastDay, setLastDay] = useState<number>();

  const days = useSelector((state: RootState) => state.app.schedule.locations);

  useEffect(() => {
    if (days == null || Object.keys(days).length === 0) return;

    const _days = Object.values(days[1]?.days);
    setFirstDay(_days[0]);
    setLastDay(_days[_days.length - 1])
  }, [days])

  return (
    <div>
      <div className="Main_row">
        <div className="label">Begin Date:</div>
        <label>
          <select className="Dropdown field inactive" disabled>
            <option>
              {moment(firstDay).utc().format('LL')}
            </option>
          </select>
        </label>
      </div>
      <div className="Main_row">
        <div className="label">End Date:</div>
        <label>
          <select className="Dropdown field inactive" disabled>
            <option>
              {moment(lastDay).utc().format('LL')}
            </option>
          </select>
        </label>
      </div>
      <div className="Main_row">
        <div className="label">Superintendent</div>
        <label>
          <input className="Dropdown field inactive noFocus" value={props.superIntendent} readOnly />
        </label>
      </div>
    </div>
  )
}

export default DateSelect;