import { FC, useContext, useEffect } from "react";
import { loadingContext, IErrorEventArgument, IEmitterEventArgument } from "../LoadingContext/LoadingContext";

interface IFullScreenErrorIndicatorProps extends Partial<IErrorEventArgument & IEmitterEventArgument> { }

export const FullScreenErrorIndicator: FC<IFullScreenErrorIndicatorProps> = props => {

  const context = useContext(loadingContext);

  useEffect(() => {
    if (context == null) return;

    const { emitErrorEvent, emitDismissErrorEvent } = context.getEmitters(props.origin || "FullScreenErrorIndicator");

    const { onDismiss, onRetry, message } = props;

    emitErrorEvent({
      onDismiss,
      onRetry,
      message,
    });

    return () => {
      // just in case, if <FullScreenErrorIndicator /> component is unmounted, 
      // we should just dismiss the error
      emitDismissErrorEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    null
  )

}
