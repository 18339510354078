import gql from "graphql-tag";

export const MATERIAL_DATAS_QUERY = gql`
  query materialDatas {
    materialDatas {
      uID
      value
      key
      operationType
      type
	  }
  }
`;