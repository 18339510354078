import React, { FC } from "react";
import { Maybe, SuperIntendentPavingCalendarDatas } from "../../../graphql/schema-types";
import "./Row.css";

interface ISheetRow {
  item?: Maybe<SuperIntendentPavingCalendarDatas>;
}
export const Row: FC<ISheetRow> = (props) => {
  const { item } = props;

  return (
    <div className="Row_Sheet" style={{ display: "flex", alignItems: "center" }} >
      <div className="Row_Item Small">{item?.jobNumber as number}</div>
      <div className="Row_Item Large NoWrap">{item?.jobName as string}</div>
      <div className="Row_Item Medium NoWrap">{item?.pmpe}</div>
      <div className="Row_Item Small">{item?.shift as string}</div>
      <div className="Row_Item Large NoWrap">{item?.pavingSuperIntendent as string}</div>
      <div className="Row_Item Medium">{item?.pavingForeman as string}</div>
      <div className="Row_Item Small">{item?.tonnage as number}</div>
      <div className="Row_Item Small">{item?.tph as number}</div>
      <div className="Row_Item Large NoWrap">{item?.material as string}</div>
      <div className="Row_Item Large NoWrap">{item?.mixSubmital as string}</div>
      <div className="Row_Item Medium NoWrap">{item?.plant as string}</div>
      <div className="Row_Item Medium">{item?.loadOutTime}</div>
      <div className="Row_Item Small">{item?.mixDesignApproval as string}</div>
      <div className="Row_Item Medium NoWrap">{item?.rtsSupport as string}</div>
      <div className="Row_Item Small">{item?.bookTruckVendor as string}</div>
      <div className="Row_Item Large">{item?.broker as string}</div>
      <div className="Row_Item Medium">{item?.typeOfTrucks as string}</div>
      <div className="Row_Item Small">{item?.nrOfTrucks as number}</div>
      <div className="Row_Item Small">{item?.uts as string}</div>
      <div className="Row_Item Small">{item?.extraWork as string}</div>
      <div className="Row_Item Small">{item?.crewMakeOp as number}</div>
      <div className="Row_Item Small">{item?.crewMakeLab as number}</div>
      <div className="Row_Item Medium grinder">
        <div style={{ padding: "2px" }}>{item?.grinder4ft as number}</div>
        <div style={{ padding: "2px" }}>{item?.grinder6ft as number}</div>
        <div style={{ padding: "2px" }}>{item?.grinder7ft as number}</div>
        <div style={{ padding: "2px" }}>{item?.grinder12ft as number}</div>
      </div>
    </div>
  );
}