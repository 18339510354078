import React, { FC } from "react";
import { Button } from "../../Form/Button";
import "./QtyError.css";
import { CloseButton } from "../../Form/CloseButton";

interface QtyErrorInterface {
  onClose?(): void;
}

export const QtyError: FC<QtyErrorInterface> = props => {

  return (
    <div>
      <div className="QtyError Add_Crew">
        <div className="New_Crew_Title">
          <div>Qty Error</div>
          <CloseButton onClick={() => props.onClose?.()} />
        </div>
        <div className="ErrorMessage">
          <div className="ErrorSign">
            <div className="Arrow1"></div>
            <div className="Arrow2"></div>
          </div>
          <div className="ErrorText">
            Missing QTY
          </div>
        </div>
        <div className="ErrorActionHolder">
          <Button
            onClick={() => props.onClose?.()}
            title="OK"
            type="button"
            className="Button"
          />
        </div>
      </div>
    </div>
  );
}
