/* eslint-disable no-loop-func */
import React, { FC, memo, useContext, useState, useRef } from 'react';
import DateSelect from './DateSelect'
import logo from '../Logo.png';
import { CrewManagement } from './Crew/CrewManagement/CrewManagement';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { SAVE } from '../graphql/mutations/SAVE';
import { RootState } from '../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { SaveInput, JobInfoFullInput, DeleteJobInfoInput, AddMasterCrewSchedulesInput, AddMasterCrewSchedulesPavingInput, DeleteEquipmentsInput, Maybe, SuperIntendentPavingCalendar } from '../graphql/schema-types';
import to from 'await-to-js';
import { SaveSuccesful } from './Warnings & errors/SaveSuccesful/SaveSuccesful';
import { SaveError } from './Warnings & errors/SaveError/SaveError';
import { NothingToSave } from './Warnings & errors/Nothing to save/NothingToSave';
import { ModalContext } from './Modal/ModalContext/ModalContext';
import { FullScreenLoadingIndicator } from './Modal/LoadingIndicator/FullScreenLoadingIndicator';
import { QtyError } from './Warnings & errors/QtyError/QtyError';
import { ADD_MASTER_CREW_SCHEDULES } from '../graphql/mutations/ADD_MASTER_CREW_SCHEDULES';
import { ADD_MASTER_CREW_SCHEDULES_PAVING } from '../graphql/mutations/ADD_MASTER_CREW_SCHEDULES_PAVING';
import PavingCalendar from './Paving/LongRangeCalendar/LongRangeCalendar';
import { Button } from './Button';
import { cleanModifiedDayByLocation } from '../redux/appSlice';
import { GeneralWarningModal } from './Warnings & errors/GeneralWarningModal/GeneralWarningModal';
import { DELETE_EQUIPMENTS } from "../graphql/mutations/DELETE_EQUIPMENTS";
import { SUPER_INTENDENT_PAVING_CALENDAR_QUERY } from '../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_QUERY';
import { LockedDaysWhileWorking } from './Warnings & errors/LockedDaysWhileWorking/LockedDaysWhileWorking';
import { AddResources } from './AddResources/AddResources';

const Header: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [save] = useMutation(SAVE);
  const [addMasterCrewSchedules] = useMutation(ADD_MASTER_CREW_SCHEDULES);
  const [addMasterCrewSchedulesPaving] = useMutation(ADD_MASTER_CREW_SCHEDULES_PAVING);
  const [deleteEquipment] = useMutation(DELETE_EQUIPMENTS);

  const jobNumber = useSelector((state: RootState) => state.app.start.jobNumber);
  const locations = useSelector((state: RootState) => state.app.schedule.locations);
  const modifiedLocationDays = useSelector((state: RootState) => state.app.modifiedDayByLocation);
  const modifiedLocation = useSelector((state: RootState) => state.app.modifiedLocation);
  const modifiedLocationInfo = useSelector((state: RootState) => state.app.modifiedLocationInfo);
  const jobName = useSelector((state: RootState) => state.app.jobNumberInformations?.jobName);
  const areaManager = useSelector((state: RootState) => state.app.jobNumberInformations?.areaManager) as string;
  const pmpe = useSelector((state: RootState) => state.app.jobNumberInformations?.projectManager) as string;
  const superIntendent = useSelector((state: RootState) => state.app.jobNumberInformations?.superIntendentName);
  const modifiedDaysByLocationInfo = useSelector((state: RootState) => state.app.modifiedDayByLocation);
  const loadedLockedDays = useSelector((state: RootState) => state.app.lockedDays);

  const modal = useContext(ModalContext);
  const dispatch = useDispatch();

  const refSaveButton = useRef<HTMLButtonElement>(null);

  const client = useApolloClient();


  const showErrorModal = () => {
    modal?.openModal?.({
      component: SaveError,
      props: {
        onClose: () => { }
      },
    });
  }

  const showSuccessfulModal = () => {
    modal?.openModal?.({
      element: <SaveSuccesful />
    });
  }

  const showNothingToSaveModal = () => {
    modal?.openModal?.({
      element: <NothingToSave />
    });
  }

  const showMissingQTYModal = () => {
    modal?.openModal?.({
      element: <QtyError />
    })
  }

  const showLockedDaysWhileWorkingModal = (days: number[]) => {
    modal?.openModal?.({
      element: <LockedDaysWhileWorking days={days} />
    });
  }

  const onSubmit = async () => {
    setIsLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let qty: boolean = false;
    const modifiedDayByLocation: DeleteJobInfoInput[] = [];
    const modifiedDayByLocationPaving: DeleteJobInfoInput[] = [];
    const modifiedDayByLocationTrucking: DeleteJobInfoInput[] = [];
    const modifiedDayForDeleteEquip: DeleteEquipmentsInput[] = [];
    const jobToSubmit: JobInfoFullInput[] = [];
    const truckingResources: AddMasterCrewSchedulesInput[] = [];
    const pavingResources: AddMasterCrewSchedulesPavingInput[] = [];
    let _lockedDays: Maybe<number>[] = [];
    let count = 0;
    let daysLocked: number[] = [];

    let _modifiedLocationDays = Object.keys(modifiedLocationDays);
    for (let j = 0; j < _modifiedLocationDays.length; j++) {
      let md = _modifiedLocationDays[j];
      const key = parseInt(md);
      const days = modifiedLocationDays[key];
      let _days = Object.keys(days);

      for (let i = 0; i < _days.length; i++) {

        let d = _days[i];
        const [, responseLocked] = await to(
          client.query({
            query: SUPER_INTENDENT_PAVING_CALENDAR_QUERY, variables: {
              where: {
                startDate: Number(d),
                endDate: Number(d),
              }
            }
          })
        )

        responseLocked?.data.superIntendentPavingCalendar.forEach((item: Maybe<SuperIntendentPavingCalendar>) => {
          if (item?.jobNumbersBlocked?.includes(jobNumber as number) || item?.status === true) _lockedDays.push(item.date)
        })

        if (_lockedDays.includes(Number(d))) {
          count++;
          if (!loadedLockedDays.includes(Number(d))) {
            daysLocked.push(Number(d));
          }
        }

        modifiedDayByLocation.push({
          jobNumber: parseInt(`${jobNumber}`),
          locIndex: key,
          day: parseInt(d)
        })

        const operationType = days[parseInt(d)];
        Object.keys(operationType).forEach(ot => {

          if (operationType[parseInt(ot)] === "clearDay") {
            if ((key === 1 && locations[2]?.daysByUnix?.[parseInt(d)]?.paving?.pavingResources?.length === 0)
              || (key === 2 && locations[1]?.daysByUnix?.[parseInt(d)]?.paving?.pavingResources?.length === 0)) {
              modifiedDayForDeleteEquip.push({
                date: parseInt(d),
                jobNumber: parseInt(`${jobNumber}`)
              })
            }
          }

          if (operationType[parseInt(ot)] === "paving" || operationType[parseInt(ot)] === "clearDay") {
            modifiedDayByLocationPaving.push({
              jobNumber: parseInt(`${jobNumber}`),
              locIndex: key,
              day: parseInt(d)
            })

            const pvResources = locations[key].daysByUnix[parseInt(d)].paving.pavingResources;

            pvResources.forEach(pav => {
              pavingResources.push({
                date: parseInt(d),
                jobNumber: pav?.jobNumber,
                locationIndex: key,
                material: pav?.material,
                notes: pav?.notes,
                qty: pav?.qty,
                searchGSI_JobNumber: pav?.searchGSI_JobNumber,
                shift: pav?.shift,
                mixSubmital: pav?.mixSubmital,
                tonnage: pav?.tonnage,
                oilTruck: pav?.oilTruck,
                plant: pav?.plant,
                resourceDescription: pav?.resourceDescription,
                resourceID: pav?.resourceID,
                resourceType: pav?.resourceType,
                bookTruckVendor: pav?.bookTruckVendor,
                rtsSupport: pav?.rtsSupport,
                tph: pav?.tph,
                extraWork: pav?.extraWork,
                grinder12ft: pav?.grinder12ft,
                grinder4ft: pav?.grinder4ft,
                grinder6ft: pav?.grinder6ft,
                grinder7ft: pav?.grinder7ft,
                uts: pav?.uts,
                mixDesignApproval: pav?.mixDesignApproval,
                addedFromPaving: pav?.addedFromPaving,
                updated: pav?.updated,
                timeStamp: pav?.timeStamp,
                loadOutTime: pav?.loadOutTime,
                addedDate: pav?.addedDate
              })
            })
          }

          if (operationType[parseInt(ot)] === "trucking" || operationType[parseInt(ot)] === "clearDay") {
            modifiedDayByLocationTrucking.push({
              jobNumber: parseInt(`${jobNumber}`),
              locIndex: key,
              day: parseInt(d)
            })

            const trResources = locations[key].daysByUnix[parseInt(d)].trucking.truckingResources;

            trResources.forEach(tr => {
              truckingResources.push({
                broker: tr?.broker,
                date: parseInt(d),
                jobNumber: tr?.jobNumber,
                loadSite: tr?.loadSite,
                locationIndex: key,
                material: tr?.material,
                notes: tr?.notes,
                qty: tr?.qty,
                searchGSI_JobNumber: tr?.searchGSI_JobNumber,
                tableauGSI_Tableau: "tableau",
                shift: tr?.shift,
                type: tr?.type,
                operationType: tr?.operationType,
                addedFromPaving: tr?.addedFromPaving,
                updated: tr?.updated,
                timeStamp: tr?.timeStamp
              })
            })
          }
        })


        const jobInfoLabor = locations[key].daysByUnix[parseInt(d)].labor.jobInfos
        const jobInfoEquipment = locations[key].daysByUnix[parseInt(d)].equip.jobInfos
        Object.keys(jobInfoLabor).forEach(labor => {
          const laborKey = parseInt(labor);
          if (jobInfoLabor[laborKey]?.resourceQTY == null || jobInfoLabor[laborKey]?.resourceQTY === 0) {
            qty = true
            return;
          }
          jobToSubmit.push({
            jobNumber_Date: parseInt(`${jobNumber}${parseInt(d)}`) / 1000,
            searchGSI_JobNumber: jobNumber as number,
            tableauGSI_Tableau: "tableau",
            resourceName: jobInfoLabor[laborKey]?.resourceName,
            resourceQTY: jobInfoLabor[laborKey]?.resourceQTY,
            resourceID: jobInfoLabor[laborKey]?.resourceID,
            resourceType: jobInfoLabor[laborKey]?.resourceType,
            date: parseInt(d),
            additionalResourcesComments: locations[key].daysByUnix[parseInt(d)].additionalResourceComments.length === 0 ? " " : locations[key].daysByUnix[parseInt(d)].additionalResourceComments,
            areaManager: areaManager != null && areaManager?.length === 0 ? " " : areaManager,
            description: locations[key].daysByUnix[parseInt(d)].description.length === 0 ? " " : locations[key].daysByUnix[parseInt(d)].description,
            jobName: jobName != null && jobName?.length === 0 ? " " : jobName,
            locationIndex: key,
            pmpe: pmpe != null && pmpe.length === 0 ? " " : pmpe,
            superIntendent: superIntendent != null && superIntendent?.length === 0 ? " " : superIntendent as string,
            pavingForeman: jobInfoLabor[laborKey]?.pavingForeman ?? " ",
            pavingSuperIntendent: jobInfoLabor[laborKey]?.pavingSuperIntendent ?? " ",
            jobMap: jobInfoLabor[laborKey]?.jobMap ?? " "
          }
          )
          if (jobInfoLabor[laborKey]?.resourceID === "PAV") {
            jobToSubmit.push({
              jobNumber_Date: parseInt(`${jobNumber}${parseInt(d)}`) / 1000,
              searchGSI_JobNumber: jobNumber as number,
              tableauGSI_Tableau: "tableau",
              resourceName: "AC Paving Crew",
              resourceQTY: 1,
              resourceID: "ACP",
              resourceType: "Labor",
              date: parseInt(d),
              additionalResourcesComments: " ",
              areaManager: areaManager != null && areaManager?.length === 0 ? " " : areaManager,
              description: " ",
              foreman: " ",
              jobName: jobName != null && jobName?.length === 0 ? " " : jobName,
              locationIndex: key,
              pmpe: pmpe != null && pmpe.length === 0 ? " " : pmpe,
              superIntendent: superIntendent != null && superIntendent?.length === 0 ? " " : superIntendent as string,
              pavingForeman: " ",
              pavingSuperIntendent: " "
            })

          }
        }) // labor

        Object.keys(jobInfoEquipment).forEach(equip => {
          const equipKey = parseInt(equip);

          // If you added an equipment resource, with 0 as qty, then you cannot save
          // If the resourceID === TRK, then you can, because if the user selects inside the TRK, a resource with material "No Material", then you can add 0 as qty
          if ((jobInfoEquipment[equipKey]?.resourceQTY == null || jobInfoEquipment[equipKey]?.resourceQTY === 0) && jobInfoEquipment[equipKey]?.resourceName !== "Trucking") {
            qty = true
            return;
          }
          jobToSubmit.push({
            jobNumber_Date: parseInt(`${jobNumber}${parseInt(d)}`) / 1000,
            searchGSI_JobNumber: jobNumber as number,
            tableauGSI_Tableau: "tableau",
            resourceName: jobInfoEquipment[equipKey]?.resourceName,
            resourceQTY: jobInfoEquipment[equipKey]?.resourceQTY,
            resourceID: jobInfoEquipment[equipKey]?.resourceID,
            resourceType: jobInfoEquipment[equipKey]?.resourceType,
            date: parseInt(d),
            additionalResourcesComments: locations[key].daysByUnix[parseInt(d)].additionalResourceComments.length === 0 ? " " : locations[key].daysByUnix[parseInt(d)].additionalResourceComments,
            areaManager: areaManager != null && areaManager?.length === 0 ? " " : areaManager,
            description: locations[key].daysByUnix[parseInt(d)].description.length === 0 ? " " : locations[key].daysByUnix[parseInt(d)].description,
            jobName: jobName != null && jobName?.length === 0 ? " " : jobName,
            locationIndex: key,
            pmpe: pmpe != null && pmpe.length === 0 ? " " : pmpe,
            superIntendent: superIntendent != null && superIntendent?.length === 0 ? " " : superIntendent as string,
            pavingForeman: jobInfoEquipment[equipKey]?.pavingForeman ?? " ",
            pavingSuperIntendent: jobInfoEquipment[equipKey]?.pavingSuperIntendent ?? " ",
            jobMap: jobInfoEquipment[equipKey]?.jobMap ?? " "
          })
        }) // equipment
      }
    };
    if (qty) {
      showMissingQTYModal();
      setIsLoading(false);
      return
    }
    const [error, response] = await to(save({
      variables: {
        data: {
          locations: Object.keys(modifiedLocation).map(key => {
            const intKey = parseInt(key);
            return ({
              name: locations[intKey].name,
              jobNumber,
              activityDescription: locations[intKey].activityDescription,
              locationIndex: intKey
            })
          }),
          jobInfos: ({
            deleteJobInfo: Object.keys(modifiedDayByLocation).map(day => {
              const intKey = parseInt(day);
              return ({
                jobNumber: modifiedDayByLocation[intKey].jobNumber,
                day: modifiedDayByLocation[intKey].day,
                locIndex: modifiedDayByLocation[intKey].locIndex
              })
            }),
            putJobInfo: Object.keys(jobToSubmit).map(loc => {
              const intKey = parseInt(loc);
              return ({
                jobNumber_Date: jobToSubmit[intKey].jobNumber_Date,
                searchGSI_JobNumber: jobToSubmit[intKey].searchGSI_JobNumber,
                tableauGSI_Tableau: jobToSubmit[intKey].tableauGSI_Tableau,
                resourceName: jobToSubmit[intKey].resourceName,
                resourceQTY: jobToSubmit[intKey].resourceQTY,
                resourceID: jobToSubmit[intKey].resourceID,
                resourceType: jobToSubmit[intKey].resourceType,
                date: jobToSubmit[intKey].date,
                additionalResourcesComments: jobToSubmit[intKey].additionalResourcesComments,
                areaManager: jobToSubmit[intKey].areaManager,
                description: jobToSubmit[intKey].description,
                foreman: jobToSubmit[intKey].foreman,
                jobName: jobToSubmit[intKey].jobName,
                locationIndex: jobToSubmit[intKey].locationIndex,
                pmpe: jobToSubmit[intKey].pmpe,
                superIntendent: jobToSubmit[intKey].superIntendent,
                pavingForeman: jobToSubmit[intKey].pavingForeman,
                pavingSuperIntendent: jobToSubmit[intKey].pavingSuperIntendent,
                jobMap: jobToSubmit[intKey].jobMap
              })
            })
          })
          // ,job infos to add
        } as SaveInput
      }
    }))
    //}  // end of save function

    let addMasterCrewSchedulesError, addMasterCrewSchedulesResponse;
    if (Object.keys(modifiedDayByLocationTrucking).length > 0) {
      [addMasterCrewSchedulesError, addMasterCrewSchedulesResponse] = await to(addMasterCrewSchedules({
        variables: {
          data: {
            deleteTruckingResources: Object.keys(modifiedDayByLocationTrucking).map(day => {
              const intKey = parseInt(day);
              return ({
                jobNumber: modifiedDayByLocationTrucking[intKey].jobNumber,
                date: modifiedDayByLocationTrucking[intKey].day,
                locIndex: modifiedDayByLocationTrucking[intKey].locIndex
              })
            }),
            addTruckingResources: truckingResources
          }
        }
      }));
    }

    let addMasterCrewSchedulesPavingError, addMasterCrewSchedulesPavingResponse;

    if (Object.keys(modifiedDayByLocationPaving).length > 0) {
      [addMasterCrewSchedulesPavingError, addMasterCrewSchedulesPavingResponse] = await to(addMasterCrewSchedulesPaving({
        variables: {
          data: {
            deletePavingResources: Object.keys(modifiedDayByLocationPaving).map(day => {
              const intKey = parseInt(day);
              return ({
                jobNumber: modifiedDayByLocationPaving[intKey].jobNumber,
                date: modifiedDayByLocationPaving[intKey].day,
                locIndex: modifiedDayByLocationPaving[intKey].locIndex
              })
            }),
            addPavingResources: pavingResources
          }
        }
      }));
    }

    let deleteEquipError, deleteEquipResponse;
    if (Object.keys(modifiedDayForDeleteEquip).length > 0) {
      [deleteEquipError, deleteEquipResponse] = await to(deleteEquipment({
        variables: {
          where: modifiedDayForDeleteEquip
        }
      }))
    }

    setIsLoading(false);

    if (daysLocked.length > 0) {
      showLockedDaysWhileWorkingModal(daysLocked);
    }

    if (modifiedLocation.length === 0 && modifiedDayByLocation.length === 0 && jobToSubmit.length === 0 && count === 0) {
      showNothingToSaveModal();
      return
    }

    if (response || addMasterCrewSchedulesResponse || addMasterCrewSchedulesPavingResponse || deleteEquipResponse) {
      showSuccessfulModal(); // set state for modal
      dispatch(cleanModifiedDayByLocation()); //empty modify day by location after save
      return
    }

    if (error || addMasterCrewSchedulesError || addMasterCrewSchedulesPavingError || deleteEquipError) {
      showErrorModal();
      return
    }

    if (refSaveButton.current) {
      refSaveButton.current.setAttribute("disabled", "disabled");
    }
  }

  const showCrewModal = () => {
    modal?.openModal({
      element: <CrewManagement />
    });
  }

  const showPavingCalendar = () => {
    let hasPavingUnsaved: boolean = false;

    const modifiedDaysInfo = Object.values(modifiedDaysByLocationInfo).reduce((p, n) => ({ ...p, ...n }), {});

    for (const date in modifiedDaysInfo) {
      if (Object.values(modifiedDaysInfo[parseInt(date)] ?? {})?.flat()?.includes("paving") ||
        Object.values(modifiedDaysInfo[parseInt(date)] ?? {})?.flat()?.includes("trucking")
        || Object.values(modifiedDaysInfo[parseInt(date)] ?? {})?.flat()?.includes("clearDay")
        || Object.values(modifiedDaysInfo[parseInt(date)] ?? {})?.flat()?.includes("true")) {
        hasPavingUnsaved = true;
        break;
      }
    }

    if (hasPavingUnsaved === true) {
      modal?.openModal({
        element: <GeneralWarningModal
          message="You have paving and trucking resources unsaved. Please click on Save Changes button and the try again!"
          title="Unsaved Paving Resources"
          yesNoButtons={false}
        />
      })
    }
    else {
      modal?.openModal({
        element: <PavingCalendar />
      })
    }
  }

  const isSaveButtonDisabled = () => {
    if (Object.keys(modifiedLocationDays).length === 0 && Object.keys(modifiedLocationInfo).length === 0) {
      return true;
    }
    return false;
  }

  // it opens an url outside the app, for the tableau viewer
  const openUrl = () => {
    const url = "http://skynet-elb-1907289844.us-west-2.elb.amazonaws.com/#/views/PavingResourceSchedule_16149850944500/CompletePavingSchedule?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link";
    window.open(url);
  }

  const openResourcesModal = () => {
    modal?.openModal({
      element: <AddResources />
    })
  }

  return (
    <>
      {isLoading && <FullScreenLoadingIndicator />}

      <div className="Header">
        <div className="col1">
          <div className="Header_Mega_Menu">
            <button onClick={showCrewModal} className="button button-crew"><i className="fas fa-users"></i> Crew Mgmt</button>
            <Button onClick={showPavingCalendar} className="button button-calendar"><i className="fas fa-hard-hat"></i> Long Range Calendar</Button>
            <button onClick={() => openUrl()} className="button button-reports-pav">
              <i className="fa fa-calendar"></i> Paving Reports
            </button>
            <button onClick={() => openResourcesModal()} className="button button-resources">
              <i className="fa fa-boxes"></i>
            </button>
            <button onClick={() => onSubmit()} className="button button-save" disabled={isSaveButtonDisabled()} ref={refSaveButton}>
              <i className="fa fa-save"></i> Save Changes
            </button>
          </div>
          <div className="Main_box">
            <DateSelect superIntendent={superIntendent === "" || superIntendent == null || superIntendent === " " ? "Missing" : superIntendent} />
          </div>
        </div>
        <div className="col2">
          Construction <br /> Labor And Equipment Schedule
        </div>
        <div className="col3">
          <img src={logo} alt="logo" />
          <div className="version">License #22</div>
        </div>
      </div>
    </>
  );
}

export default memo(Header);
