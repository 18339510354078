
import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import "./FullDayPreviewPage.css";
import { SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY } from "../../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY";
import { Query, ResourcesInput, SheetRowDayPreviewInput } from "../../graphql/schema-types";
import { SheetHeader } from "../SuperIntendentPavingModule/FullDayPreview/SheetHeader/SheetHeader";
import moment from "moment";
import { SheetRow } from "../SuperIntendentPavingModule/FullDayPreview/SheetRow/SheetRow";
import { FullScreenLoadingIndicator } from "../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { createPavingModule, updateItemsFromPavingModule } from "../../redux/appSlice";
import { useDispatch, useSelector, useStore } from "react-redux";
import { RootState } from "../../redux/store";
import { ExpandableSheet } from "../SuperIntendentPavingModule/FullDayPreview/ExpandableSheet/ExpandableSheet";
import { SAVE_FULL_DAY_PREVIEW } from "../../graphql/mutations/SAVE_FULL_DAY_PREVIEW";
import { ScrollSync } from 'scroll-sync-react';
import { ScrollSyncNode } from 'scroll-sync-react';
import to from "await-to-js";
import { ModalContext } from "../Modal/ModalContext/ModalContext";
import { GeneralWarningModal } from "../Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import { SleepMode } from "../Warnings & errors/SleepMode/SleepMode";
import { PAVING_FOREMANS_QUERY } from "../../graphql/queries/PAVING_FOREMANS_QUERY";
import { filteredItems, IState } from "../../utils/filteredItems";

interface IFullDayPreviewPageProps { }

export const FullDayPreviewPage: FC<IFullDayPreviewPageProps> = (props) => {
  const isGeneral = useSelector((state: RootState) => state.app?.pavingModuleUser.isInGeneralPavingSupers);
  const truckingDatas = useSelector((state: RootState) => state.app?.truckingDatas);
  const fullDayPreviewItemsToUpdate = useSelector((state: RootState) => state.app?.fullDayPreview);
  const pavingModuleUserName = useSelector((state: RootState) => state.app?.pavingModuleUser.name);
  const daysPavingModule = useSelector((state: RootState) => state.app.pavingModule);

  const [state, setState] = useState<IState>(filteredItems([], [], []));
  const [call, setCall] = useState(false);
  const [queryCounter, setQueryCounter] = useState(0);
  const [sleepMode, setSleepMode] = useState(false);

  const dispatch = useDispatch();
  const store = useStore<RootState>();
  const modal = useContext(ModalContext);
  const ref = useRef<any>(null);

  const _daysPavingModule = Object.keys(daysPavingModule);

  // we calculate the first day we generate from the current week + 2 week in the past
  const _firstDay = moment(parseInt(_daysPavingModule[0])).utc().subtract(14, "days").format("YYYY-MM-DD");
  const _currentDay = moment().utc().format("MM-DD-YY");

  // generate 6 and half months from the _firstDay
  const generatedDays = createPavingModule(194, _firstDay);
  const _lastDay = Object.keys(generatedDays)[Object.keys(generatedDays).length - 1];

  const { data: pavingForemansList } = useQuery<Pick<Query, "pavingForemans">>(PAVING_FOREMANS_QUERY);

  const { data, loading, refetch } = useQuery<Pick<Query, "superIntendentPavingCalendarDatas">>(SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY, {
    variables: { where: { startDate: parseInt(_daysPavingModule[0]), endDate: parseInt(_lastDay), pavingSuperName: pavingModuleUserName == null ? "" : pavingModuleUserName } },
    skip: _daysPavingModule[0] == null || _daysPavingModule[_daysPavingModule.length - 1] == null || pavingModuleUserName == null || _lastDay == null,
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  const [saveFullDayPreview, { loading: loadingSave }] = useMutation(SAVE_FULL_DAY_PREVIEW);

  const pavingForemans: string[] | undefined = pavingForemansList?.pavingForemans
    .map(foreman => foreman?.username as string);

  // remove duplicates from array
  const filteredPavForemans: string[] | undefined = pavingForemans?.filter((foreman, index) => pavingForemans?.indexOf(foreman) === index);
  filteredPavForemans != null && filteredPavForemans.push("Unassgined");

  useEffect(() => {
    if (data == null || data.superIntendentPavingCalendarDatas == null) return;

    setState(
      {
        ...state,
        filteredItems: filteredItems(Object.keys(generatedDays), filteredPavForemans as string[], data.superIntendentPavingCalendarDatas.correctItems).filteredItems,
        dayItemsLength: filteredItems(Object.keys(generatedDays), filteredPavForemans as string[], data.superIntendentPavingCalendarDatas.correctItems).dayItemsLength
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data?.superIntendentPavingCalendarDatas])

  const MINUTE_MS = 5000;

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('Verify if new items were added in the last 5 seconds');
      if (sleepMode === false) {
        setCall(true);
      }

    }, MINUTE_MS);

    return () => {
      clearInterval(interval);
    } // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call])

  useEffect(() => {
    if (call === false) return;
    if (queryCounter === 120) // after 10 minutes
    {
      setSleepMode(true);
      setCall(false);
      modal?.openModal({
        element:
          <SleepMode
            onClose={() => { setSleepMode(false); setQueryCounter(0); }}
            onCancel={() => { setSleepMode(false); setQueryCounter(0); }} />
      })
      return;
    }
    else {
      refetch();
      setCall(false);
      setQueryCounter(queryCounter + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call])

  const onSave = async () => {
    const state = store.getState();

    let isStateReadyToSave = true;

    for (let i = 0; i < state.app.fullDayPreview.length; i++) {
      const currentItem = state.app.fullDayPreview[i];
      if (currentItem.broker == null) {
        if (currentItem.tonnage == null) {
          isStateReadyToSave = false;
          break;
        }
      }
      if (currentItem.broker != null) {
        if (currentItem.nrOfTrucks == null || currentItem.tonnage == null) {
          isStateReadyToSave = false;
          break;
        }
      }
    }

    if (isStateReadyToSave === false) {
      modal?.openModal({
        element: <GeneralWarningModal
          message="One or more required fields are empty. (Check entry for tonnage or number of trucks)"
          title="Required Value"
          yesNoButtons={false}
        />
      })
      return;
    }

    let _fullDayPreviewItemsToUpdate: SheetRowDayPreviewInput[] = [];
    let _reduxFullDayPreviewItemsToUpdate = fullDayPreviewItemsToUpdate;

    _reduxFullDayPreviewItemsToUpdate.forEach(fd => {
      let resOp: ResourcesInput[] = [];
      let resLab: ResourcesInput[] = [];
      fd.resourcesLab.forEach(r => {
        resLab.push({
          resourceDescription: r.resourceDescription,
          resourceID: r.resourceID,
          resourceQTY: r.resourceQTY,
          resourceType: r.resourceType
        });
      });

      fd.resourcesOp.forEach(r => {
        resOp.push({
          resourceDescription: r.resourceDescription,
          resourceID: r.resourceID,
          resourceQTY: r.resourceQTY,
          resourceType: r.resourceType
        });
      });

      _fullDayPreviewItemsToUpdate.push({
        locIndex: fd.locatinIndex,
        nrOfTrucks: fd.nrOfTrucks,
        pavingSuper: fd.superIntendent,
        date: fd.date,
        jobNumber: fd.jobNumber,
        broker: fd.broker,
        shift: fd.shift,
        tonnage: fd.tonnage,
        material: fd.material as string,
        plant: fd.plant as string,
        loadOutTime: fd.loadOutTime as string,
        typeOfTrucks: fd.typeOfTrucks,
        updateJobInfoTable: fd.updateJobInfoTable,
        updateMasterCrewSchedulePavingTable: fd.updateMasterCrewSchedulePavingTable,
        updateMasterCrewScheduleTable: fd.updateMasterCrewScheduleTable,
        areaManager: fd.areaManager as string,
        jobMap: fd.jobMap,
        jobName: fd.jobName,
        pavingForeman: fd.pavingForeman,
        pavingSuperIntendent: fd.pavingSuperIntendent,
        pmpe: fd.pmpe,
        superIntendent: fd.superIntendent,
        resourcesOp: resOp,
        resourcesLab: resLab,
        bookTruckVendor: fd.bookTruckVendor,
        extraWork: fd.extrawork,
        grinder12ft: fd.grinder12ft,
        grinder4ft: fd.grinder4ft,
        grinder6ft: fd.grinder6ft,
        grinder7ft: fd.grinder7ft,
        mixDesignApproval: fd.mixDesignApproval,
        mixSubmital: fd.mixSubmital,
        oilTruck: fd.oilTruk,
        rtsSupport: fd.rtsSupport,
        tph: fd.tph,
        uts: fd.uts
      });
      dispatch(updateItemsFromPavingModule(fd));
    })

    if (_fullDayPreviewItemsToUpdate.length > 0) {
      await to(
        saveFullDayPreview({
          variables: {
            where: _fullDayPreviewItemsToUpdate
          },
        })
      );
    }
  }

  const onScrollLeft = () => {
    const container = document.getElementById('rightSide');
    sideScroll(container, 'left', 25, 100, 10);
  };

  const onScrollRight = () => {
    const container = document.getElementById('rightSide');
    sideScroll(container, 'right', 25, 100, 10);

  };

  const onScrollUp = () => {
    const container = document.getElementById('secondaryScroll');
    sideScroll(container, 'up', 25, 100, 10);
  };

  const onScrollDown = () => {
    const container = document.getElementById('secondaryScroll');
    sideScroll(container, 'down', 25, 100, 10);

  };


  const sideScroll = (element: any, direction: string, speed: number, distance: number, step: number) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === 'left') {
        element.scrollLeft -= step;
      } else if (direction === 'right') {
        element.scrollLeft += step;
      } else if (direction === 'up') {
        element.scrollTop -= step;
      } else if (direction === 'down') {
        element.scrollTop += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  return (
    <>
      <div className="Full_Day_Preview">
        {(loading || loadingSave) && <FullScreenLoadingIndicator />}
        <div className="Modal_Bar">
          <div className="Scroll_Horizontally_Buttons">
            <button onClick={() => onScrollLeft()} onMouseEnter={() => onScrollLeft()} title="Scroll to left" id="slideBack">
              <i className="fa fa-chevron-left"></i>
            </button>
            <button onClick={() => onScrollRight()} onMouseEnter={() => onScrollRight()} title="Scroll to right" id="slide">
              <i className="fa fa-chevron-right"></i>
            </button>
          </div>
        </div>
        <ScrollSync>
          <div className="Modal_Body">
            <ScrollSyncNode scroll="syncer-only">
              <div className="Days_Left_Header" ref={ref} id="secondaryScroll">
                {
                  Object.keys(generatedDays)?.map((day, index) => (
                    <div className="date" key={index}
                      style={{
                        height: state?.dayItemsLength?.[day]?.itemsLength == null || state.dayItemsLength?.[day]?.itemsLength === 0 ? "30px" : state?.dayItemsLength?.[day]?.itemsLength * 30,
                        backgroundColor: moment(parseInt(day)).utc().days() === 6 || moment(parseInt(day)).utc().days() === 0 ? "rgba(245, 123, 32, 0.2)" : "",
                        color: _currentDay === moment(parseInt(day)).utc().format("MM-DD-YY") ? "#f57b20" : ""
                      }}
                    >{moment(parseInt(day)).utc().format("MM-DD-YY")}</div>
                  ))
                }
              </div>
            </ScrollSyncNode>
            <div className="Wrap_Right_Side" id="rightSide">
              {
                filteredPavForemans && filteredPavForemans?.map((pavForemanName, i) => (
                  <ExpandableSheet key={i}>

                    <div className={"SuperIntendent_Sheet"} key={i + pavForemanName}>
                      <div className="Wrap_Header">
                        <div className="SuperIndendent_Name">{(pavForemanName == null || pavForemanName === " ") ? "Unassigned" : pavForemanName + " "}
                        </div>
                        <div className="Wrap_SheetHeader">
                          <SheetHeader />
                        </div>
                      </div>
                      <ScrollSyncNode scroll="synced-only" >
                        <div className="FullBody" ref={ref} >
                          <div className="Body">
                            {
                              Object.keys(generatedDays)?.map(day => (
                                <div className="Body_Row"
                                  key={day}
                                  data-day={moment(parseInt(day)).utc().format("DD-MMMM")}
                                  style={{
                                    height: state?.dayItemsLength?.[day]?.itemsLength == null || state.dayItemsLength?.[day]?.itemsLength === 0 ? "30px" : state?.dayItemsLength?.[day]?.itemsLength * 30,
                                    backgroundColor: moment(parseInt(day)).utc().days() === 6 || moment(parseInt(day)).utc().days() === 0 ? "rgba(245, 123, 32, 0.2)" : ""
                                  }}
                                >
                                  {
                                    state?.filteredItems?.[day]?.[pavForemanName]?.map((superIntendentPavingCalendarData, index) => (
                                      <div className="Sheet_Row" key={index}>
                                        <SheetRow item={superIntendentPavingCalendarData} isSupersSelectDisabled={!isGeneral} truckingDatas={truckingDatas} pavingForemans={pavingForemans} />
                                      </div>
                                    ))
                                  }
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </ScrollSyncNode>
                    </div>
                  </ExpandableSheet>
                ))
              }
            </div>
            <div className="Scroll_Vertically_Buttons">
              <button onClick={() => onScrollUp()} onMouseEnter={() => onScrollUp()} title="Scroll up" id="slideUp">
                <i className="fa fa-chevron-up"></i>
              </button>
              <button onClick={() => onScrollDown()} onMouseEnter={() => onScrollDown()} title="Scroll down" id="slideDown">
                <i className="fa fa-chevron-down"></i>
              </button>
            </div>
          </div>
        </ScrollSync>
      </div>
      <div className="Button_Container">
        <button onClick={() => onSave()} disabled={loadingSave}>Save</button>
      </div>
    </>
  );
}