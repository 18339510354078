import React from "react";
import { useHistory } from "react-router-dom";
import "./PavingPlanner.css";
import { SelectAmPm } from "../SelectAmPm/SelectAmPm";
import { SideBar } from "../SuperIntendentPavingModule/SideBar/SideBar";
import { InitPavingModule } from "../InitPavingModule/InitPavingModule";

// this is the main page for the Paving Planner(AM) module
export const PavingPlanner = () => {
  const history = useHistory();

  const onCalendar = () => {
    history.push({
      pathname: '/calendar',
      state: {
        from: "/calendar"
      }
    })
  }

  const onFullDayPreview = () => {
    history.push({
      pathname: '/daypreview',
      state: {
        from: "/daypreview"
      }
    })
  }

  const onHistoryPage = () => {
    history.push({
      pathname: '/history',
      state: {
        from: "/history"
      }
    })
  }

  return (
    <div className="Paving_Planner">
      <div className="Wrap">
        <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
        <div className="Button_Container">
          <div className="top">
            <button onClick={() => onCalendar()}>Calendar</button>
            <button onClick={() => onFullDayPreview()}>Day Preview</button>
          </div>
          <button onClick={() => onHistoryPage()}>History</button>
          <SelectAmPm />
        </div>
      </div>
    </div>
  )
}