import React, { FC, useEffect, useContext } from "react";
import "./EditCrew.css";
import { useQuery } from "@apollo/react-hooks";
import { RESOURCE_CODES_QUERY } from "../../../graphql/queries/RESOURCE_CODES_QUERY";
import { Query, Maybe, CustomCrewResourceComposition } from "../../../graphql/schema-types";
import { TextInput } from "../../TextInput";
import { CheckBox } from "../../Form/CheckBox";
import { useImmer } from "use-immer";
import { toMap } from "../../../utils/toMap";
import { useMutation } from '@apollo/react-hooks';
import { ADD_UPDATE_CREW_RESOURCE_MUTATION } from "../../../graphql/mutations/ADD_UPDATE_CREW_RESOURCE_MUTATION";
import { Button } from "../../Form/Button";
import { ModalContext } from "../../Modal/ModalContext/ModalContext";
import { toMultiMap } from "../../../utils/toMultiMap";
import { GeneralWarningModal } from "../../Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import { CloseButton } from "../../Form/CloseButton";
import { FullScreenLoadingIndicator } from "../../Modal/LoadingIndicator/FullScreenLoadingIndicator";

interface EditCrewInterface {
  onClose?(): void;
  crewCode: string;
  crewName: string;
  crewResoures: Maybe<CustomCrewResourceComposition>[]
  jobNumber: number | "";
  refetch?(): void;
}

type State = {
  resources: Maybe<CustomCrewResourceComposition>[];
  crewResourcesByResourceId: { [id: string]: Maybe<CustomCrewResourceComposition> };
  crewResourcesByResourceType: ResourceCodesByResourceType;
};

type ResourceCodesByResourceType = { [type: string]: Maybe<CustomCrewResourceComposition>[] };

export const EditCrew: FC<EditCrewInterface> = (props) => {
  const [state, setState] = useImmer<State>({
    resources: [],
    crewResourcesByResourceId: {},
    crewResourcesByResourceType: {}
  });

  const { data, loading } = useQuery<Pick<Query, "resourceCodes">>(RESOURCE_CODES_QUERY);
  const [addCrewResources] = useMutation(ADD_UPDATE_CREW_RESOURCE_MUTATION, { onCompleted: props?.refetch, fetchPolicy: "no-cache" });

  const modal = useContext(ModalContext);

  useEffect(() => {
    window.onpopstate = (e: any) => {
      props?.onClose?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data == null || data.resourceCodes == null) return;
    if (state.resources.length > 0) return;
    if (props.crewResoures == null) return;

    const crewResourcesByRecourceId = toMap(props.crewResoures, cr => cr?.resourceID ?? "");

    setState(draft => {
      draft.crewResourcesByResourceId = crewResourcesByRecourceId;
      const res = data.resourceCodes
        .filter(rc => rc?.ResourceID !== "TRK" && rc?.ResourceID !== "PAV" && rc?.ResourceCode !== "MISCELLANEOUS CODES")
        .map(rc => (
          {
            id: crewResourcesByRecourceId[rc?.ResourceID!]?.id ?? null,
            crewCode: props.crewCode,
            dateAdded: crewResourcesByRecourceId[rc?.ResourceID!]?.dateAdded,
            JobNumber: crewResourcesByRecourceId[rc?.ResourceID!]?.JobNumber ?? props.jobNumber,
            resourceID: rc?.ResourceID,
            resourceDescription: rc?.ResourceDescription,
            resourceType: rc?.ResourceType,
            QTYNeeded: crewResourcesByRecourceId[rc?.ResourceID!] != null ? crewResourcesByRecourceId[rc?.ResourceID!]?.QTYNeeded : 0,

          } as CustomCrewResourceComposition
        ));

      const crewResourcesByResourceType = toMultiMap(res, r => r.resourceType ?? "");

      Object.keys(crewResourcesByResourceType).forEach(key => {
        crewResourcesByResourceType[key].sort((a, b) => a?.resourceDescription! < b?.resourceDescription! ? -1 : 1)
      })

      draft.crewResourcesByResourceType = crewResourcesByResourceType;
      draft.resources.push(...crewResourcesByResourceType["Labor"], ...crewResourcesByResourceType["Equipment"]);
    })
  }, [data, props.crewCode, props.crewResoures, props.jobNumber, setState, state.resources.length])

  const onChangeQty = (index: number, e: any) => {
    let value: "" | number = parseInt(e.target.value);
    value = isNaN(value) ? "" : value;
    setState(draft => {
      draft.resources[index]!.QTYNeeded = value as number;
    })
  }

  const onCheckboxChange = (index: number, checked: boolean) => {
    setState(draft => {
      if (checked === true) {
        draft.resources[index]!.QTYNeeded = 1;
      }

      if (checked === false) {
        draft.resources[index]!.QTYNeeded = 0;
      }
    })
  }

  const onSubmit = () => {
    const _resourcesToUpdate = state.resources
      .filter(r => {
        const crbr = state.crewResourcesByResourceId[r?.resourceID ?? ""];
        return crbr != null && crbr.QTYNeeded !== r?.QTYNeeded && r?.QTYNeeded !== 0;
      })

    const _resourcesToDelete = state.resources
      .filter(r => state.crewResourcesByResourceId[r?.resourceID ?? ""] != null && r?.QTYNeeded === 0)
      .map(r => r?.id);

    const _resourcesToAdd = state.resources
      .filter(r => {
        return state.crewResourcesByResourceId[r?.resourceID ?? ""] == null && r?.QTYNeeded! > 0;
      });

    addCrewResources({ variables: { where: { deletedResources: _resourcesToDelete, updatedResources: _resourcesToUpdate, addedResources: _resourcesToAdd } } });
    props.onClose?.();
  }

  const showCancelAssignmentModal = () => {
    modal?.openModal?.({
      element:
        <GeneralWarningModal
          message="All edits here will be lost. Are you sure you want to cancel?"
          title="Cancel Assignment"
          yesNoButtons={true}
          onConfirm={() => props.onClose?.()}
        />
    })
  }

  return (
    <>
      {loading && <FullScreenLoadingIndicator />}
      <div className="Equipment_Crew_Main">
        <div className="New_Crew_Title">
          <div className="Wrap">
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="graniterock" />
            <div className="Title">Resource for crew: {props.crewName}</div>
          </div>
          <CloseButton onClick={showCancelAssignmentModal} />
        </div>
        <div className="Equipment_Resources_Table">
          <div className="Equipment_Table_Header">
            <div>Crew Name</div>
            <div>Qty Needed</div>
            <div>Select</div>
          </div>
          {
            state?.resources && state?.resources
              ?.map((rc: Maybe<CustomCrewResourceComposition>, index: number) => (
                <div className="Resource_Line" key={index}>
                  <div className="Resource_Name">
                    {rc?.resourceDescription as string}
                  </div>
                  <div className="Resource_Qu">
                    <TextInput
                      onChange={(e) => { onChangeQty(index, e) }}
                      value={state.resources?.[index]?.QTYNeeded?.toString() ?? ""}
                      type="text"
                      placeholder={state.resources?.[index]?.QTYNeeded?.toString() ?? "0"}
                    />
                  </div>
                  <div className="Resource_Check">
                    <CheckBox
                      onChange={(e) => { onCheckboxChange(index, e.target.checked) }}
                      checked={state.resources?.[index]?.QTYNeeded as number > 0}
                    />
                  </div>
                </div>
              ))
          }
        </div>
        <div className="Resources_Actions_Holder">
          <button className="Button" onClick={showCancelAssignmentModal}>Cancel</button>
          <Button
            onClick={() => onSubmit()}
            title="Save"
            className="Button_Save"
          />
        </div>
      </div>
    </>
  );
}