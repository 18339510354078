import React from 'react';

interface ITextInput {
	id?: string;
	type: string;
	placeholder?: string;
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	className?: string;
	disabled?: boolean;
	maxLength?: number;
	min?: string;
	style?: React.CSSProperties | undefined;
}

export const TextInput: React.FC<ITextInput> = ({ id, type, value, placeholder, onChange, className, onBlur, disabled, maxLength, min, style }) => {
	return (
		<input
			id={id}
			value={value}
			type={type}
			onChange={e => onChange(e)}
			placeholder={placeholder}
			className={className}
			onBlur={e => onBlur?.(e)}
			disabled={disabled}
			maxLength={maxLength}
			min={min}
			style={style}
		/>
	);
};