import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useImmer } from "use-immer";
import { start, addJobNumberInformation, reset } from "../redux/appSlice";
import { useLazyQuery } from "@apollo/react-hooks";
import { Query } from "../graphql/schema-types";
import { JOB_NUMBER_INFO_QUERY } from "../graphql/queries/JOB_NUMBER_INFO_QUERY";
import { FullScreenLoadingIndicator } from "./Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { FullScreenErrorIndicator } from "./Modal/ErrorIndicator/FullScreenErrorIndicator";
import { settings } from "../config/settings";
import { SelectDateStart } from "./SelectDateStart";
import { SelectAmPm } from "./SelectAmPm/SelectAmPm";

const isNumber = (num: number | "") => {
  if (typeof num !== "number") return false;
  if (isNaN(num)) return false;
  return true;
}

const isValidJobNumber = (jobNumber: number | "") => {
  if (!isNumber(jobNumber)) return false;
  return true;
}

const isValidDays = (days: number | "") => {
  if (!isNumber(days)) return false;
  if (days < 9 || days > 90) return false;
  return true;
}

const isValidDate = (date: string) => {
  if (!moment(date, "YYYY-MM-DD").isValid()) return false;
  return true;
}

const getLocalState = () => {
  let rv = {};
  try {
    rv = JSON.parse(localStorage.getItem("startState") ?? "{}")
  }
  catch (e) {

  }
  return rv;
}

export const Start: FC = (props) => {

  const history = useHistory();

  const [state, setState] = useImmer({
    jobNumber: "" as number | "",
    beginDate: "" as string,
    endDate: "" as string,
    days: "" as number | "",
    validJobNumber: null as boolean | null,
    validBeginDate: null as boolean | null,
    validEndDate: null as boolean | null,
    validDays: null as boolean | null,
  });

  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const localState: Partial<typeof state> = getLocalState();
    setState(() => ({
      jobNumber: localState.jobNumber ?? "",
      beginDate: localState.beginDate ?? selectedDates[0],
      endDate: localState.endDate ?? selectedDates[1],
      days: localState.days ?? "",
      validJobNumber: (localState.jobNumber != null && localState.jobNumber !== "") ? true : null,
      validBeginDate: ((localState.beginDate != null && localState.beginDate !== "") || (selectedDates != null && selectedDates?.[0] !== "")) ? true : null,
      validEndDate: ((localState.endDate != null && localState.endDate !== "") || (selectedDates != null && selectedDates?.[1] !== "")) ? true : null,
      validDays: (localState.days != null && localState.days !== "") ? true : null,
    }));

    setSelectedDates([localState.beginDate as string, localState.endDate as string]);
  }, []); // eslint-disable-line

  const [getJobNumberInfo, {
    loading: jobNumberInfoLoading
    , error: jobNumberInfoError
    , data: jobNumberInfoData
  }] = useLazyQuery<Pick<Query, "jobNumberInformation">>(JOB_NUMBER_INFO_QUERY, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (jobNumberInfoData == null || jobNumberInfoData.jobNumberInformation == null) {
      return;
    }
    dispatch(addJobNumberInformation({ jobNumberInformations: jobNumberInfoData.jobNumberInformation }))
    dispatch(start({ ...state }));
    localStorage.setItem("startState", JSON.stringify(state));
    history.push("/schedule");
  }, [dispatch, history, jobNumberInfoData, state]);

  const onJobNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    const inputValue = isNaN(value) ? "" : value;
    setState(draft => {
      draft.jobNumber = inputValue;
      draft.validJobNumber = isValidJobNumber(inputValue);
    });
  }

  const isValidForm = () => {
    const { validBeginDate, validDays, validJobNumber, validEndDate } = state;
    return validBeginDate && validEndDate && validDays && validJobNumber;
  }

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isValidForm()) return;

    getJobNumberInfo({
      variables: { jobNumber: state.jobNumber },
    });
  }

  const onLogout = () => {
    history.push({
      pathname: '/logout',
      state: {
        from: "/logout"
      }
    })
  }

  const onDateSelected = (dates: string[]) => {
    setSelectedDates(dates);

    const _firstDay = moment(dates?.[0]);
    const _lastDay = moment(dates?.[1]);
    const daysLength = Math.abs(moment.duration(_firstDay.diff(_lastDay)).asDays()) + 1;

    setState(draft => {
      draft.beginDate = dates?.[0];
      draft.endDate = dates?.[1];
      draft.days = Math.round(daysLength);
      draft.validDays = isValidDays(Math.round(daysLength));
      draft.validEndDate = isValidDate(dates[1]);
      draft.validBeginDate = isValidDate(dates[0]);
    });
  }

  return (
    <div className="First_select" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/gr-com_construction-dispatch_background.png'})` }}>

      {jobNumberInfoLoading && <FullScreenLoadingIndicator />}
      {jobNumberInfoError != null && <FullScreenErrorIndicator />}
      <div className="wrap_holder">
        <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
        <div className="Select_holder">
          <div className="Main_row">
            <div className="label">Job number:</div>
            <label>
              <input type="number" value={state.jobNumber ?? ""} onChange={onJobNumberChange} id="job_number" />
              {state.validJobNumber === false
                ? <div className="error">Not a valid job number!</div>
                : null}
            </label>
          </div>
          <div className="Main_row">
            <label>
              <div className="label_date" style={{
                fontSize: "1rem",
                fontFamily: "Arial",
                cursor: "pointer"
              }}>
                <SelectDateStart
                  currentDates={selectedDates}
                  onPickerChange={dates => onDateSelected(dates)}
                />
              </div>
              {state.validBeginDate === false
                ? <div className="error">Not a valid date!</div>
                : null}
            </label>
          </div>
          {
            jobNumberInfoData?.jobNumberInformation === null
              ? <div className="error">Not a valid job number</div>
              : null
          }
          {
            state.days < 9
              ? <div className="error">The number of days should be minimum 9</div>
              : null
          }
          <div style={{ display: "flex", paddingTop: "10px" }}>
            <a
              href={settings.logOutUri}
              target="_blank" rel="noopener noreferrer"
            >
              <button className="logout" onClick={onLogout} id="logout_button">Logout</button>
            </a>
            <span style={{ flex: 1 }} />
            <button className="submit" disabled={!isValidForm()} onClick={onSubmit} id="login_button">Submit</button>  
          </div>
          <SelectAmPm />
        </div>
        

      </div>
    </div>
  );
}