import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SAVE_JOB_MAP } from "../../../graphql/mutations/SAVE_JOB_MAP";
import { Maybe, SuperIntendentPavingCalendarDatas } from "../../../graphql/schema-types";
import useContextMenu from "../../../hooks/useContextMenu";
import { RootState } from "../../../redux/store";
import { CloseButton } from "../../Form/CloseButton";
import { updateJobMapToPavingModule } from "../../../redux/appSlice";
import "./JobMap.css"
import { useMutation } from "@apollo/react-hooks";

interface IJobMapProps {
  onClose?(): void;
  item: Maybe<SuperIntendentPavingCalendarDatas>;
}

export const JobMap: FC<IJobMapProps> = (props) => {

  let isDayDisabled = useSelector((state: RootState) => state.app?.pavingModule[props.item?.date].isDayBlocked);
  let isJobDisabled = useSelector((state: RootState) => state.app?.pavingModule[props.item?.date].jobNumbersBlocked.includes(props.item?.jobNumber as number));
  let isGeneral = useSelector((state: RootState) => state.app?.pavingModuleUser.isInGeneralPavingSupers);
  const endDate = useSelector((state: RootState) => Object.keys(state.app.pavingModule)[Object.keys(state.app.pavingModule).length - 1])

  const dispatch = useDispatch();

  const [saveJobMap] = useMutation(SAVE_JOB_MAP);

  const outerRef = useRef(null);
  const { xPos, yPos } = useContextMenu(outerRef);

  const [link, setLink] = useState(props.item?.jobMap ?? " ");
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.removeAttribute('style')
    }
  }, [])

  const isButtonDisabled = () => {
    if (isDayDisabled === false) {
      if (isJobDisabled === false) {
        if (isGeneral === true) {
          if (props.item?.shift as string !== null) {
            return false;
          }
          if (props.item?.shift as string !== "") {
            return false;
          }
        }
      }
    }
    return true;
  }

  const pasteLink = () => {
    setShowMenu(false)

    navigator.clipboard.readText()
      .then(text => {
        setLink(text);
      })
      .catch(err => {
        console.error('Failed to read clipboard contents: ', err);
      });
  }

  const clear = () => {
    setLink("");
    setShowMenu(false)
  }

  const saveJM = () => {

    dispatch(updateJobMapToPavingModule({ startDate: props.item?.date, jobNumber: props.item?.jobNumber as number, jobMap: link as string }));

    saveJobMap({
      variables: {
        where: {
          jobNumber: props.item?.jobNumber as number,
          startDate: props.item?.date as number,
          endDate: parseInt(endDate),
          jobMap: link
        }
      }
    });

    props?.onClose?.();
  }

  return (
    <div className="Job_Map">
      <div className="JobMap_Header">
        <div className="Date_Wrap">
          <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
          <div className="Title">Job Map</div>
        </div>
        <CloseButton onClick={props.onClose} />
      </div>
      <div className="middle-content">
        <div className="Wrap_Inputs">
          <a className="link" href={link as string} target="_blank" rel="noopener noreferrer">{link}</a>
          <input
            className="Link_Input"
            type="text"
            value={link as string}
            maxLength={250}
            placeholder="Link"
            onChange={(e) => { setLink(e.target.value) }}
            onClick={() => setShowMenu(false)}
            onContextMenu={() => setShowMenu(true)}
          />
          <div ref={outerRef} style={{ top: yPos, left: xPos }}>
            {showMenu === true
              ? <ul className="Menu_Calendar">
                <> <div className="Menu_Icons"><i className="fa fa-clipboard"></i><button onClick={() => { pasteLink() }}>Paste</button></div></>
                <> <div className="Menu_Icons"><i className="fa fa-eraser"></i><button onClick={() => { clear() }}>Clear</button></div></>
              </ul>
              : <></>
            }
          </div>
        </div>
        <div className="Buttons_Wrap">
          <button className="Save" onClick={() => saveJM()} disabled={isButtonDisabled()} >OK</button>
          <button className="Cancel" onClick={() => props.onClose?.()} >Cancel</button>
        </div>
      </div>
    </div>
  )
}