import React, { FC, useEffect, useState, useContext } from "react";
import "./TruckingManagement.css";
import moment from "moment";
import { useImmer } from "use-immer";
import { useQuery } from "@apollo/react-hooks";
import { CheckBox } from "../../Form/CheckBox";
import { MasterCrewSchedule, MasterCrewSchedulePaving, Maybe, Query, SuperIntendentPavingCalendar } from "../../../graphql/schema-types";
import { TRUCKING_DATAS_QUERY } from "../../../graphql/queries/TRUCKING_DATAS_QUERY";
import { TextInput } from "../../TextInput";
import { useDispatch, useSelector } from "react-redux";
import { addTruckingResourcesToDay, updateShiftToPavingResources } from "../../../redux/appSlice";
import { RootState } from "../../../redux/store";
import { CloseButton } from "../../Form/CloseButton";
import { ModalContext } from "../../Modal/ModalContext/ModalContext";
import { TruckingResourcesNotAllocated } from "../../Warnings & errors/ApplyTruckingResources/TruckingResourcesNotAllocated";
import { EditTruckingResource } from "../EditTruckingResource/EditTruckingResource";
import { FullScreenLoadingIndicator } from "../../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { FullScreenErrorIndicator } from "../../Modal/ErrorIndicator/FullScreenErrorIndicator";
import { GeneralWarningModal } from "../../Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import { SUPER_INTENDENT_PAVING_CALENDAR_QUERY } from "../../../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_QUERY";

interface ITruckingManagementProps {
  unix: number;
  locationIndex: number
  jobNumber: number;
  onApply(value: string | number): void;
  onClose?(): void;
  shift?: string;
  material?: string;
  loadSite?: string;
  canAddPavingResources: boolean;
  fromPavingManagement: boolean;
}
type State = {
  truckingResources: Maybe<MasterCrewSchedule>[];
  qtySum: number;
  addButtonDisabled: boolean;
  // pavResourceDisabled: boolean;
};

const getInitialTruckingState = (): MasterCrewSchedule & { initial: boolean } => {
  return ({
    initial: true,
    broker: "",
    loadSite: "",
    material: "",
    notes: "",
    shift: "",
    type: "",
    qty: null,
    operationType: "",
    addedFromPaving: false,
    updated: true
  })
}

export const TruckingManagement: FC<ITruckingManagementProps> = props => {
  const [state, setState] = useImmer<State>({
    truckingResources: [],
    qtySum: 0,
    addButtonDisabled: true,
    // pavResourceDisabled: props.canAddPavingResources
  });
  const [truckingStateReset, setTruckingStateReset] = useState<boolean>(true);
  const [truckingState, setTruckingState] = useImmer<MasterCrewSchedule & { initial: boolean }>(getInitialTruckingState());
  const [checkedResources, setCheckedResources] = useState<{ [key: number]: boolean }>({});

  const truckingResourcesReduxState = useSelector((state: RootState) => state.app.schedule.locations?.[props.locationIndex]?.daysByUnix?.[props.unix]?.trucking?.truckingResources);
  const pavinvResourcesReduxState = useSelector((state: RootState) => state.app.schedule.locations?.[props.locationIndex]?.daysByUnix?.[props.unix]?.paving?.pavingResources);

  const { data: trucking, loading, error } = useQuery<Pick<Query, "truckingDatas">>(TRUCKING_DATAS_QUERY);

  const materials = useSelector((state: RootState) => state.app.materialDatas);
  const jobNumber = useSelector((state: RootState) => state.app.start.jobNumber);
  const pavingResurcesLocationForPaving = useSelector((state: RootState) => state.app.schedule.locations?.[props.locationIndex].daysByUnix?.[props.unix]?.paving?.pavingResources);

  const { data: lockedDaysData, refetch: refetchLockedDays } = useQuery<Pick<Query, "superIntendentPavingCalendar">>(SUPER_INTENDENT_PAVING_CALENDAR_QUERY, {
    variables: {
      where: {
        startDate: (moment.utc(moment().startOf('month').format("YYYY-MM-DD")).unix()) * 1000,
        endDate: (moment.utc(moment().startOf('month').add(1, 'months').format("YYYY-MM-DD")).unix()) * 1000,
      }
    },
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.removeAttribute('style')
    }
  }, [])

  useEffect(() => {
    window.onpopstate = (e: any) => {
      props?.onClose?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.material != null && props.shift != null && props.loadSite != null) {
      setTruckingState(draft => {
        draft.loadSite = props.loadSite;
        draft.material = (props.fromPavingManagement && !props.canAddPavingResources) ? "" : props.material;
        draft.shift = props.shift;
      })
      setTruckingStateReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (truckingStateReset === false) return;

    if (props.material != null && props.shift != null && props.loadSite != null) {
      setTruckingState(draft => {
        draft.loadSite = props.loadSite;
        draft.material = (props.fromPavingManagement && !props.canAddPavingResources) ? "" : props.material;
        draft.shift = props.shift;
      })
      setTruckingStateReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckingStateReset])

  useEffect(() => {
    if (state.truckingResources.length > 0) return;

    if (truckingResourcesReduxState.length > 0) {
      setState(draft => {
        draft.truckingResources = [];
        draft.truckingResources.push(...truckingResourcesReduxState);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckingResourcesReduxState])

  const dispatch = useDispatch();
  const modal = useContext(ModalContext);

  useEffect(() => {
    if (state == null || state.truckingResources == null) return;
    setState(draft => {
      draft.qtySum = state.truckingResources
        .map(tr => tr?.qty)
        .reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number;
    })
  }, [state, setState])

  useEffect(() => {
    if (truckingState.broker === "" ||
      truckingState.loadSite === "" ||
      truckingState.material === "" ||
      truckingState.qty == null ||
      ((props.material === "No Material" && props.loadSite !== "None – Job Prep") && truckingState.qty === 0) ||
      ((props.material !== "No Material" && props.loadSite === "None – Job Prep") && truckingState.qty === 0) ||
      ((props.material !== "No Material" && props.loadSite !== "None – Job Prep") && truckingState.qty === 0) ||
      truckingState.qty.toString() === "" ||
      truckingState.shift === "" ||
      truckingState.type === "") {
      setState(draft => {
        draft.addButtonDisabled = true;
      })
    }
    else {
      setState(draft => {
        draft.addButtonDisabled = false;
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckingState, setState]);

  useEffect(() => {
    if (truckingState.material === "") return;
    const operationTypeForSelectedMaterial = materials?.filter(material => material?.value === truckingState.material)

    setTruckingState(draft => {
      draft.operationType = operationTypeForSelectedMaterial?.[0]?.operationType as string;
    });
    setTruckingStateReset(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materials, truckingState?.material])

  const _brokerData = trucking?.truckingDatas
    .filter(td => td?.type === "Broker")
    .sort((a, b) => a?.value! < b?.value! ? -1 : 1);

  if (_brokerData !== null && _brokerData !== undefined) {
    _brokerData.push({
      key: "Other",
      type: "Broker",
      value: "Other (log in notes)"
    })
  }

  let _materialData = materials != null && materials.length > 0 ? materials : [];
  if (_materialData !== null && _materialData !== undefined && _materialData.length > 0) {
    _materialData = [..._materialData, {
      key: "Other",
      operationType: " ",
      value: "Other (log in notes)",
    }]
  }

  const _trukData = trucking?.truckingDatas
    .filter(td => td?.type === "TruckType")
    .sort((a, b) => (a?.value! < b?.value!) && a?.value! !== "Other (log in notes)" ? -1 : 1);

  if (_trukData !== null && _trukData !== undefined) {
    _trukData.push({
      key: "Other",
      type: "Broker",
      value: "Other (log in notes)"
    })
  }

  const _loadSiteData = trucking?.truckingDatas
    .filter(td => td?.type === "Plant")
    .sort((a, b) => (a?.value! < b?.value!) && a?.value! !== "Other (log in notes)" ? -1 : 1);

  if (_loadSiteData !== null && _loadSiteData !== undefined) {
    _loadSiteData.push({
      key: "Other",
      type: "Broker",
      value: "Other (log in notes)"
    })
  }

  const onCheckboxChange = (index: number, isChecked: boolean) => {
    const _checkedResources = { ...checkedResources };

    if (isChecked === false) {
      delete _checkedResources[index as number]
      setCheckedResources(_checkedResources);
      return;
    }

    setCheckedResources({ ...checkedResources, [index]: isChecked })
  }

  const onOpenDelete = () => {
    let canDelete = true;

    Object.keys(checkedResources).forEach(cr => {
      if (state.truckingResources[parseInt(cr)]?.operationType === "paving") {
        canDelete = false;
      }
    });

    if (canDelete === true) {
      modal?.openModal?.({
        element: <GeneralWarningModal
          message="Are you sure you want to delete the selected resource(s)?"
          title="Delete trucking resource"
          onConfirm={() => onDelete()}
          onCancel={() => { }}
          yesNoButtons={true}
        />
      });
    } else {
      modal?.openModal?.({
        element: <GeneralWarningModal
          message="You can't delete a trucking resource with operation type paving, because you have a Paving Crew defined. You can edit it."
          title="Delete trucking resource with operation type paving"
          onConfirm={() => { }}
          yesNoButtons={false}
        />
      });
    }
  }

  const onDelete = () => {
    setState(draft => {
      Object.keys(checkedResources).forEach(cr => {
        delete draft.truckingResources[parseInt(cr)];
      });
      draft.truckingResources = draft.truckingResources.filter(tr => tr != null);
    })
    setCheckedResources(() => ({}));
  }

  // const showNoPavingModal = () => {
  //   modal?.openModal?.({
  //     element: <GeneralWarningModal
  //       message="YOU NEED TO DEFINE A PAVING CREW FIRST AND THEN ADD TRUCKING WITH OPERATION TYPE PAVING"
  //       title="TRUCKING ERROR: THERE IS NO PAVING ACTIVITY FOR TODAY"
  //       yesNoButtons={false}
  //     />
  //   });
  // }

  const onAdd = () => {
    // if ((state.pavResourceDisabled || !props.canAddPavingResources) && truckingState?.operationType === "paving") {
    // if (state.pavResourceDisabled) {
    //   showNoPavingModal();
    //   return;
    // }

    setState(draft => {
      draft.truckingResources.push({
        jobNumber: props.jobNumber,
        searchGSI_JobNumber: props.jobNumber,
        broker: truckingState.broker,
        material: truckingState.material,
        qty: truckingState.qty,
        type: truckingState.type,
        loadSite: truckingState.loadSite,
        notes: truckingState.notes,
        date: props.unix,
        shift: truckingState.shift,
        locationIndex: props.locationIndex,
        operationType: truckingState.operationType,
        addedFromPaving: truckingState.addedFromPaving,
        updated: true,
        timeStamp: truckingState.timeStamp
      })
    })

    setTruckingState(() => getInitialTruckingState())
    setTruckingStateReset(true);
  }

  // const reinitializeState = () => {
  //   if (props.material != null && props.shift != null && props.loadSite != null) {
  //     setTruckingState(draft => {
  //       draft.loadSite = props.loadSite;
  //       draft.material = (props.fromPavingManagement && !props.canAddPavingResources) ? "" : props.material;
  //       draft.shift = props.shift;
  //     })
  //     setTruckingStateReset(false);
  //   }
  // }

  const onOpenApply = () => {
    if (pavingResurcesLocationForPaving.length > 0 && state?.truckingResources.filter(t => t?.operationType === "paving")?.length === 0) return;
    modal?.openModal?.({
      element: <GeneralWarningModal
        message="Are you sure you want to add this resource(s) to schedule?"
        title="Add trucking resource(s) to schedule"
        onConfirm={() => onApply()}
        onCancel={() => { }}
        yesNoButtons={true}
      />
    });
  }

  const onApply = () => {
    props.onApply(state.qtySum);
    let truckingRes: Maybe<MasterCrewSchedule>[] = [];
    state.truckingResources.forEach(tr => {
      if (tr != null) {
        truckingRes.push({
          ...tr,
          updated: true,
        }
        )
      }
    })

    dispatch(addTruckingResourcesToDay({ locationIndex: props.locationIndex, unix: props.unix, truckingResources: truckingRes }));

    let pavTrkRes = truckingRes.filter(tr => tr?.operationType === "paving")[0];
    let pavingRes: Maybe<MasterCrewSchedulePaving>[] = [];
    if (pavinvResourcesReduxState[0]?.shift !== pavTrkRes?.shift) {
      let loadOutTime: string = "";
      pavinvResourcesReduxState.forEach(pav => {
        if (pavTrkRes?.shift === "N" && pav?.loadOutTime?.includes("AM")) {
          loadOutTime = pav?.loadOutTime?.replace("AM", "PM");

          if (pav?.loadOutTime === "00:00 AM") {
            loadOutTime = "12:00 PM";
          }
          if (pav?.loadOutTime === "00:30 AM") {
            loadOutTime = "12:30 PM";
          }
        }

        if (pavTrkRes?.shift === "D" && pav?.loadOutTime?.includes("PM")) {
          loadOutTime = pav?.loadOutTime?.replace("PM", "AM");

          if (pav?.loadOutTime === "12:00 PM") {
            loadOutTime = "00:00 AM";
          }
          if (pav?.loadOutTime === "12:30 PM") {
            loadOutTime = "00:30 AM";
          }
        }

        pavingRes.push({
          ...pav,
          shift: pavTrkRes?.shift,
          loadOutTime
        })
      });
      dispatch(updateShiftToPavingResources({ locationIndex: props.locationIndex, unix: props.unix, pavingResources: pavingRes }))
    }
    props.onClose?.();
  }

  const onBrokerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const _eValue = e.target.value;

    setTruckingState(draft => {
      draft.broker = _eValue;
    })
    setTruckingStateReset(false);
  }

  const onTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const _eValue = e.target.value;

    setTruckingState(draft => {
      draft.type = _eValue;
    })
    setTruckingStateReset(false);
  }

  const onMaterialChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const _eValue = e.target.value;
    setTruckingState(draft => {
      draft.material = _eValue;
    })
    setTruckingStateReset(false);
  }

  const onLoadSiteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const _eValue = e.target.value;
    setTruckingState(draft => {
      draft.loadSite = _eValue;
    })
    setTruckingStateReset(false);
  }

  const onQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let qty: number | "" = parseInt(e.target.value);
    qty = isNaN(qty) ? "" : qty;

    // if the material is No Material & the plant(it's called loadSite in TruckingManagement) is None – Job Prep than you an add 0 as qty
    if (props?.material !== "No Material" && props?.loadSite !== "None – Job Prep") {
      if (qty < 0) { return }
    }

    if (qty.toString().length > 5) {
      return;
    }

    setTruckingState(draft => {
      draft.qty = qty as number;
    })
    setTruckingStateReset(false);
  }

  const onNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const _eValue = e.target.value.length > 0 ? e.target.value : " ";;

    if (_eValue.length > 250) { return }

    setTruckingState(draft => {
      draft.notes = _eValue
    })
    setTruckingStateReset(false);
  }

  const onShiftChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const _eValue = e.target.value;

    setTruckingState(draft => {
      draft.shift = _eValue;
    })
    setTruckingStateReset(false);
  }

  const shift = [
    {
      key: 1,
      value: "N"
    },
    {
      key: 2,
      value: "D"
    }
  ];


  const shiftSpecial = [
    {
      key: 1,
      value: "N 2"
    },
    {
      key: 2,
      value: "D 2"
    }
  ];

  const openTruckingResources = () => {
    modal?.openModal({
      element: <TruckingResourcesNotAllocated
        message="Exit without changes?"
        title="Trucking resources not allocated"
        yesNoButtons={true}
        onConfirm={() => props.onClose?.()}
      />
    });
  }

  const areTruckingResourcesUnsaved = () => {
    if (state.truckingResources.length > truckingResourcesReduxState.length || state.truckingResources.length < truckingResourcesReduxState.length) {
      return true
    }
    return false
  }

  const onEditTrResource = (resource: MasterCrewSchedule, index: number) => {
    if (state.truckingResources == null || state.truckingResources[index] == null) { return }

    setState(draft => {
      if (draft.truckingResources == null || draft.truckingResources[index] == null) { return }

      draft.truckingResources[index]!.broker = resource.broker;
      draft.truckingResources[index]!.loadSite = resource.loadSite;
      draft.truckingResources[index]!.material = resource.material;
      draft.truckingResources[index]!.notes = resource.notes;
      draft.truckingResources[index]!.qty = resource.qty;
      draft.truckingResources[index]!.shift = resource.shift;
      draft.truckingResources[index]!.type = resource.type;
      draft.truckingResources[index]!.operationType = resource.operationType == null ? " " : resource.operationType
      draft.truckingResources[index]!.addedFromPaving = false;
    })
  }

  const openEditTruckingResource = (resourceToEdit: Maybe<MasterCrewSchedule>, resourceToEditIndex: number) => {
    modal?.openModal({
      element: <EditTruckingResource
        brokerData={_brokerData}
        loadSiteData={_loadSiteData}
        materialData={materials}
        truckData={_trukData}
        truckingResource={resourceToEdit}
        truckingResourceIndex={resourceToEditIndex}
        onEditResource={onEditTrResource}
        fromPavingManagement={props.fromPavingManagement}
        unix={props.unix}
      />
    });
  }

  const checkForLockedDays = () => {
    let _lockedDays: Maybe<number>[] = [];
    lockedDaysData?.superIntendentPavingCalendar.forEach((item: Maybe<SuperIntendentPavingCalendar>) => {
      if (item?.jobNumbersBlocked?.includes(jobNumber as number) || item?.status === true) _lockedDays.push(item.date)
    })
    return _lockedDays;
  }

  useEffect(() => {
    refetchLockedDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, setState, onEditTrResource, onShiftChange, onNotesChange, onQtyChange, onLoadSiteChange, onMaterialChange,
    onTypeChange, onBrokerChange, onCheckboxChange])

  return (
    <>
      {loading && <FullScreenLoadingIndicator />}
      {error != null && <FullScreenErrorIndicator />}
      <div className="Manage_Trucking_Main_Screen">
        <div className="Trucking_Header">
          <div className="Date_Wrap">
            <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
            <div className="Title">{moment(props.unix).utc().format('LL')}</div>
          </div>
          <CloseButton onClick={areTruckingResourcesUnsaved() === true ? openTruckingResources : props.onClose} />
        </div>
        <div className="middle-content">
          <div className="Wrap_Inputs_Side">
            <div className="Trucking_Title">
              Trucking for job number {props.jobNumber}
            </div>
            <div className="Wrap_Row">
              <div className="left_side">
                <div className="Row">
                  <div className="Wrap_Select">
                    <select className="dropdown" value={truckingState.broker as string} onChange={onBrokerChange} style={{ outline: 0 }}>
                      <option value="" disabled data-default>
                        Broker
                      </option>
                      {
                        _brokerData?.map((bd, index) => (
                          <option value={bd?.value as string} key={index}>
                            {bd?.value}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="Row">
                  <div className="Combined">
                    <div className="Wrap_Select">
                      <select className="dropdown" value={truckingState.type as string} onChange={onTypeChange} style={{ outline: 0 }}>
                        <option value="" disabled>
                          Type
                        </option>
                        {
                          _trukData?.map((td, index) => (
                            <option value={td?.value as string} key={index}>
                              {td?.value}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                    <TextInput
                      type="text"
                      value={truckingState.qty?.toString?.() ?? ""}
                      onChange={(e) => onQtyChange(e)}
                      className="input-container"
                      placeholder="Qty"
                    />
                  </div>
                </div>
                <div className="Row">
                  <div className="Wrap_Select">
                  <select
                    className="dropdown"
                    value={truckingState.material as string}
                    onChange={onMaterialChange}
                    style={{ outline: 0 }}
                  >
                    <option value="" disabled>
                      Material:
                    </option>
                    {props.fromPavingManagement === false
                      ? _materialData?.map((md, index) => (
                      md?.operationType !== "paving" && (
                    <option value={md?.value as string} key={index}>
                      {md?.key !== md?.value ? md?.key as string + " - " + md?.value as string : md?.value as string}
                    </option>
                    )
                    ))
                    : _materialData?.filter((lr) => lr?.operationType === "paving")?.map((md, index) => (
                        <option value={md?.value as string} key={index}>
                          {md?.key !== md?.value ? md?.key as string + " - " + md?.value as string : md?.value as string}
                        </option>
                      ))}
                  </select>
                  </div>
                </div>
                <div className="Row">
                  <div className="Wrap_Select">
                    <select className="dropdown" value={truckingState.loadSite as string} onChange={onLoadSiteChange} style={{ outline: 0 }}>
                      <option value="" disabled>
                        Load Site:
                      </option>
                      {
                        _loadSiteData?.map((lsd, index) => (
                          <option value={lsd?.value as string} key={index}>
                            {lsd?.value}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="Row">
                  <div className="Wrap_Select">
                    <select className="dropdown" value={truckingState.shift as string} onChange={(e) => onShiftChange(e)} style={{ outline: 0 }} disabled={props.fromPavingManagement}>
                      <option value="" disabled>
                        Shift:
                      </option>
                      {
                        props.locationIndex != null && props.locationIndex > 2
                          ? shiftSpecial.map((s, index) => (
                            <option value={s.value} key={index}>
                              {s.value}
                            </option>
                          ))
                          : shift.map((s, index) => (
                            <option value={s.value} key={index}>
                              {s.value}
                            </option>
                          ))
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="right_side">
                <div className="Row">
                  <textarea
                    value={truckingState.notes as string}
                    onChange={(e) => onNotesChange(e)}
                    placeholder="Notes"
                    cols={30}
                    rows={30}
                  />
                </div>
              </div>
            </div>

          </div>
          <div className="Action_Holder">
            <button
              className="Add_Truck_Resources Button"
              onClick={() => onAdd()}
              disabled={state.addButtonDisabled || ((checkForLockedDays().includes(props.unix)) && truckingState.operationType === "paving")}
              title={(checkForLockedDays().includes(props.unix)) ? "Locked day" : ""}
            >
              Add to trucking table
            </button>
          </div>
          <div className="Trucking_Before_Table">
            <div className="Trucking_Table_Title">Trucking Table</div>
            <div className="buttons">
              <button className="Delete_Resources Button" onClick={() => onOpenDelete()} disabled={Object.keys(checkedResources).length === 0}>Delete</button>
              <button className="Add_Resources Button" onClick={() => { onOpenApply() }}>Update values to schedule</button>
            </div>
          </div>
          <div className="Trucking_Resources">
            <div className="Trucking_Table_Header">
              <div className="header_item_small"></div>
              <div className="header_item_small">Edit</div>
              <div className="header_item">
                Broker
              </div>
              <div className="header_item">
                Type
              </div>
              <div className="header_item">
                QTY
              </div>
              <div className="header_item">
                Mat
              </div>
              <div className="header_item">
                Load Site
              </div>
              <div className="header_item">
                Shift
              </div>
              <div className="header_item">
                Notes
              </div>
            </div>
            <div className="Trucking_Table">
              {
                state.truckingResources.map((tr, index) =>
                  tr != null
                    ? (<div className="Trucking_Resource_Line" key={index}>
                      <div className="header_item_small">
                        <CheckBox
                          onChange={(e) => { onCheckboxChange(index, e.target.checked) }}
                          checked={checkedResources[index] || false}
                          disabled={(checkForLockedDays().includes(props.unix)) && tr?.operationType === "paving" ? true : false}
                        />
                      </div>
                      <button
                        className="header_item_small"
                        onClick={() => openEditTruckingResource(tr, index)}
                        disabled={(checkForLockedDays().includes(props.unix)) && tr?.operationType === "paving" ? true : false}
                      >
                        Edit
                      </button>
                      <div className="header_item">
                        {tr?.broker}
                      </div>
                      <div className="header_item">
                        {tr?.type}
                      </div>
                      <div className="header_item">
                        {tr?.qty}
                      </div>
                      <div className="header_item">
                        {tr?.material}
                      </div>
                      <div className="header_item">
                        {tr?.loadSite}
                      </div>
                      <div className="header_item">
                        {tr?.shift}
                      </div>
                      <div className="header_item">
                        {tr?.notes}
                      </div>
                    </div>)
                    : null
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}