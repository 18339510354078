import React, { FC } from "react";
import './App.css';
import { Provider } from 'react-redux';
import { store } from "../redux/store";
import { Router } from "./Router";
import { ApolloProvider } from '@apollo/react-hooks';
import { createClient } from "../graphql/client";
import { emitter } from "./Modal/emitter";
import { createModalContextValue, ModalContext } from "./Modal/ModalContext/ModalContext";
import { loadingContext, loadingContextValue } from "./Modal/LoadingContext/LoadingContext";

const client = createClient(store);

const modalContextValue = createModalContextValue(emitter)

export const App: FC = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <ModalContext.Provider value={modalContextValue}>
            <loadingContext.Provider value={loadingContextValue}>
              <Router />
            </loadingContext.Provider>
          </ModalContext.Provider>
        </ApolloProvider>
      </Provider>
    </React.StrictMode>
  );
}