import gql from "graphql-tag";

export const EQUIPMENT_RESOURCES_QUERY = gql`
  query equipmentResources {
    equipmentResources {
      eqID
      eqName
      eqSubcategoryDescription
      eqSubcategoryID
	  }
  }
`;