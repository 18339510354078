import { removeTokenFromCookie, removeIdTokenFromCookie, removeRefreshTokenFromCookie } from "../../auth/removeTokenFromCookie";

export const logoutMiddleware = ({ dispatch, getState }: any) => (next: any) => (action: any) => {
  if (action.type === "app/logout") {
    removeTokenFromCookie();
    removeIdTokenFromCookie();
    removeRefreshTokenFromCookie();
  }
  return next(action);
}
