import React, { FC } from "react";
import "./SuperIndendentPavingModuleHeader.css"
interface ISuperIndendentPavingModuleHeaderProps {

}

export const SuperIndendentPavingModuleHeader: FC<ISuperIndendentPavingModuleHeaderProps> = () => {

  return (
    <div className="Super_Pav_Calendar_Header">
      <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
    </div>
  )
}