import React, { FC } from "react";
import { Button } from "../../Form/Button";
import "./SaveError.css";
import { CloseButton } from "../../Form/CloseButton";

interface SaveSuccesfulInterface {
  onClose?(): void;
}

export const SaveError: FC<SaveSuccesfulInterface> = props => {

  return (
    <div>
      <div className="SaveSuccesful Add_Crew">
        <div className="New_Crew_Title">
          <div className="Wrap">
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="graniterock" />
            <div className="Title">Resource Schedule</div>
          </div>
          <CloseButton onClick={props.onClose} />
        </div>
        <div className="ErrorMessage">
          <div className="InfoSign">
            <div className="Arrow1"></div>
            <div className="Arrow2"></div>
          </div>
          <div className="ErrorText">
            Save was unsuccesfull!
          </div>
        </div>
        <div className="ErrorActionHolder">
          <Button
            onClick={() => props.onClose?.()}
            title="OK"
            type="button"
            className="Button"
          />
        </div>
      </div>
    </div>
  );
}
