/**
 * Converts an array to a key-value pair map
 * @param collection an array of type T
 * @param keyPredicate a function that returns the key of the map entry
 * @returns { [key: string]: T } object
 */
export function toMultiMap<T>(collection: T[], keyPredicate: (item: T, index: number) => number): { [key: number]: [T] }
export function toMultiMap<T>(collection: T[], keyPredicate: (item: T, index: number) => string): { [key: string]: [T] }
/**
 * Converts an array to a key-value pair map
 * @param collection an array of type T
 * @param keyPredicate a function that returns the key of the map entry
 * @param valuePredicate an optional function that returns the value of the map entry
 * @returns { [key: string]: K } object
 */
export function toMultiMap<T, K>(collection: T[], keyPredicate: (item: T, index: number) => number, valuePredicate: (item: T, index: number) => K): { [key: number]: [K] }
export function toMultiMap<T, K>(collection: T[], keyPredicate: (item: T, index: number) => string, valuePredicate: (item: T, index: number) => K): { [key: string]: [K] }
export function toMultiMap<T, K>(collection: T[], keyPredicate: (item: T, index: number) => string | number, valuePredicate?: (item: T, index: number) => K): { [key: string]: [T | K]; [key: number]: [T | K] } {

    const map: { [key: string]: [T | K] } = {};

    collection.forEach((item, index) => {
        const key = keyPredicate(item, index);
        if(!Array.isArray(map[key])) {
            map[keyPredicate(item, index)] = [] as any;
        }
        map[key].push(typeof valuePredicate === "function" ? valuePredicate(item, index) : item);
    });
    
    return map;
}
