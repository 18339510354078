import React, { FC } from "react";
import { IErrorEventsByType, IErrorEventArgument } from "../LoadingContext/LoadingContext";
import { Button } from "../../Form/Button";
import { Dialog } from "../Dialog/Dialog";

interface IErrorIndicatorProps {
  onDismissAllErrors?(): void;
  events?: IErrorEventsByType;
}

const getStrippedMessage = (message: string) => {
  if (typeof message !== "string") return message;
  return `${message}`.replace("GraphQL error:", "").trim();
}

const getErrorMessage = (error?: IErrorEventArgument["message"]): React.ReactNode => {
  if (typeof error === "string") {
    return <>{getStrippedMessage(error)}</>;
  }
  if (error instanceof Error) {
    return <>{getStrippedMessage(error.message)}</>;
  }
  if (React.isValidElement(error)) {
    return <>{error}</>;
  }
  return <>An error occurred!</>;
}

export const ErrorIndicator: FC<IErrorIndicatorProps> = props => {
  const onDismissAllErrors = () => {
    typeof props.onDismissAllErrors === "function" && props.onDismissAllErrors();
    Object
      .values(props.events || [])
      .forEach(event => typeof event.onDismiss === "function" && event.onDismiss());
  }

  return (
    <Dialog>
      {Object
        .values(props.events || [])
        .map((event, i) => {
          return (
            <div
              key={i}
              style={{
                padding: "0em 1em 1em 1em",
                textAlign: "center",
                fontSize: "1rem"
              }}
            >
              {getErrorMessage(event.message)}
            </div>
          )
        })}
      <Button
        onClick={onDismissAllErrors}
        className="dark"
        style={{ margin: "auto", display: "block", fontSize: "1rem", padding: "0.5em 1em" }}
      >
        Dismiss
      </Button>
    </Dialog>
  )
}
