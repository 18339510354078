import React, { FC, useState } from "react";
import "./ExpandableSheet.css"

interface IExpandableSheetProps {
  key: number,
  children: any
}

export const ExpandableSheet: FC<IExpandableSheetProps> = (props) => {

  const [expanded, setExpanded] = useState(false);
  const onChange = () => {
    if (expanded === true) setExpanded(false);
    else if (expanded === false) setExpanded(true);
  }

  return (
    <div
      className={"ExpandableSheet"}
      style={{
        width: expanded === false ? "405px" : "auto"
      }}
    >
      <button
        className={expanded === false ? 'fas fa-plus' : "fa fa-minus"}
        onClick={() => onChange()}>
      </button>

      {props.children}

    </div>
  )
}