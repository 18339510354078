import { TextInput } from "./TextInput"
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/store";
import React, { FC, useState, useEffect, memo } from "react";
import { addDescriptionToDay } from "../redux/appSlice";

interface IDayDescriptionProps {
	locationIndex: number;
	unix: number;
}

const DayDescription: FC<IDayDescriptionProps> = (props) => {
	const [description, setDescription] = useState<string>("");

	const dayDescription = useSelector((state: RootState) => state.app.schedule.locations?.[props.locationIndex].daysByUnix?.[props.unix].description);

	useEffect(() => {
		if (description === dayDescription) return;
		setDescription(dayDescription);
	}, [dayDescription, description])

	const dispatch = useDispatch();

	const onDayDescriptionChange = (value: string) => {
		setDescription(value);

		dispatch(addDescriptionToDay({
			locationIndex: props.locationIndex,
			unix: props.unix,
			description: value,
		}))
	}

	return (
		<TextInput
			onChange={(e) => onDayDescriptionChange(e.target.value)}
			value={description}
			type="text"
		/>
	);

};

export default memo(DayDescription);