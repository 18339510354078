import gql from "graphql-tag";

export const RESOURCE_CODES_QUERY = gql`
  query resourceCodes {
    resourceCodes {
		id
		Limit
		ResourceCode
		ResourceDescription
		ResourceID
		ResourceType
	}
  }
`;