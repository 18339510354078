/**
 * Converts an array to a key-value pair map
 * @param collection an array of type T
 * @param keyPredicate a function that returns the key of the map entry
 * @returns { [key: string]: T } object
 * @example
 *      toMap([12, 14, 23, 31], item => item.toString()); // -> { "12": 12, "14": 14, "23": 23, "31": 31 }
 *      toMap([{id: 24, other: 55}, {id: 14, other: 45}], item => item.id.toString()); // -> { "14": { id: 14, other: 45 }, "24": { id: 24, other: 55 } }
 */
export function toMap<T>(collection: T[], keyPredicate: (item: T, index: number) => number): { [key: number]: T }
export function toMap<T>(collection: T[], keyPredicate: (item: T, index: number) => string): { [key: string]: T }
/**
 * Converts an array to a key-value pair map
 * @param collection an array of type T
 * @param keyPredicate a function that returns the key of the map entry
 * @param valuePredicate an optional function that returns the value of the map entry
 * @returns { [key: string]: K } object
 * @example
 *      toMap([12, 14, 23, 31], item => item.toString(), item => item); // -> { "12": 12, "14": 14, "23": 23, "31": 31 }
 *      toMap([12, 14, 23, 31], item => item.toString(), () => true); // -> { "12": true, "14": true, "23": true, "31": true }
 *      toMap([{id: 24, other: 55}, {id: 14, other: 45}], item => item.id.toString(), () => true); // -> { "14": true, "24": true }
 */
export function toMap<T, K>(collection: T[], keyPredicate: (item: T, index: number) => number, valuePredicate: (item: T, index: number) => K): { [key: number]: K }
export function toMap<T, K>(collection: T[], keyPredicate: (item: T, index: number) => string, valuePredicate: (item: T, index: number) => K): { [key: string]: K }
export function toMap<T, K>(collection: T[], keyPredicate: (item: T, index: number) => string | number, valuePredicate?: (item: T, index: number) => K): { [key: string]: T | K;[key: number]: T | K } {
    const map: { [key: string]: T | K } = {};
    collection.forEach((item, index) => {
        map[keyPredicate(item, index)] = typeof valuePredicate === "function" ? valuePredicate(item, index) : item;
    });
    return map;
}
