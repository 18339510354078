import gql from "graphql-tag";

export const TRUCKING_DATAS_QUERY = gql`
  query truckingDatas {
    truckingDatas {
      uID
      value
      key
      type
	  }
  }
`;