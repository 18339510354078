import { FC, useRef, useEffect, memo, ReactNode } from "react";
import ReactDOM from "react-dom";
import { useMounted } from "../../hooks/useMounted";

type ModalPortalProps = {
  selector: string;
  children?: ReactNode
}

const Portal: FC<ModalPortalProps> = props => {
  const modalRootRef = useRef<HTMLDivElement>(null);

  const isMounted = useMounted();

  useEffect(() => {
    // @ts-ignore
    modalRootRef.current = document.querySelector(props.selector);
  }, [props.selector]);

  if (!isMounted) {
    return null;
  }

  if (modalRootRef.current == null) {
    console.log(`could not query element ${props.selector}`)
    return null;
  }

  return ReactDOM.createPortal(
    props.children,
    modalRootRef.current
  );
}

export default memo(Portal);
