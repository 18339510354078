import "../../../Paving/PavingManagement.css";
import { FC, useEffect, memo } from "react";
import React from "react";
import { Maybe, Query, Resource, ResourceCode, SuperIntendentPavingCalendarDatas } from "../../../../graphql/schema-types";
import { useImmer } from "use-immer";
import { CheckBox } from "../../../Form/CheckBox";
import { TextInput } from "../../../TextInput";
import { CloseButton } from "../../../Form/CloseButton";
import { toMultiMap } from "../../../../utils/toMultiMap";
import { useQuery } from "@apollo/react-hooks";
import { RESOURCE_CODES_QUERY } from "../../../../graphql/queries/RESOURCE_CODES_QUERY";
import { toMap } from "../../../../utils/toMap";
import { useDispatch, useSelector } from "react-redux";
import { saveChangesToItemFromFullDayPreview } from "../../../../redux/appSlice";
import { RootState } from "../../../../redux/store";
interface ICrewCompositionProps {
  item?: Maybe<SuperIntendentPavingCalendarDatas>;
  itemState: any;
  updateJobInfoTable: boolean,
  updateMasterCrewSchedulePavingTable: boolean,
  updateMasterCrewScheduleTable: boolean,
  type: string;
  resources: Maybe<Resource>[];
  otherResources: Maybe<Resource>[];
  onClose?(): void;
}

type State = {
  resources: Maybe<Resource>[];
  qtySum: number;
};

const CrewComposition: FC<ICrewCompositionProps> = props => {

  const [state, setState] = useImmer<State>({
    resources: [],
    qtySum: 0
  });

  const { data } = useQuery<Pick<Query, "resourceCodes">>(RESOURCE_CODES_QUERY);

  const fullDayPreviewItem = useSelector((state: RootState) => state.app.fullDayPreview).filter(f => f.date === props?.item?.date)[0];

  const dispatch = useDispatch();

  useEffect(() => {
    if (state.resources.length > 0) return;
    if (data == null || data.resourceCodes == null) return;

    const masterCrewRecourceId = toMap(props?.resources, cr => cr?.resourceID ?? "");

    setState(draft => {

      const resourcesByResourceType = toMultiMap(data.resourceCodes.filter(r => r?.ResourceID !== "ACP"), r => r?.ResourceType ?? "");

      const resourcesByResourceCode = toMultiMap(data.resourceCodes.filter(r => r?.ResourceID !== "ACP"), r => r?.ResourceCode ?? "");

      Object.keys(resourcesByResourceType).forEach(key => {
        resourcesByResourceType[key].sort((a, b) => a?.ResourceDescription! < b?.ResourceDescription! ? -1 : 1)
      })
      let resourcesCodes: Maybe<ResourceCode>[] = [];
      resourcesCodes.push(...resourcesByResourceType["Labor"], ...resourcesByResourceCode["Paving Equipment"], ...resourcesByResourceCode["Asphalt Compactors"]);

      draft.resources = resourcesCodes
        .filter(rc => rc?.ResourceID !== "TRK" && rc?.ResourceID !== "PAV" && rc?.ResourceDescription?.includes(props.type))
        .map(rc => (
          {
            resourceID: rc?.ResourceID,
            resourceType: rc?.ResourceType,
            resourceDescription: rc?.ResourceDescription,
            resourceQTY: masterCrewRecourceId[rc?.ResourceID!] != null ? masterCrewRecourceId[rc?.ResourceID!]?.resourceQTY : 0,
          } as Resource
        ));
    })

  }, [data, setState, state.resources.length, props, props.resources])

  useEffect(() => {
    if (state == null || state.resources == null) return;
    setState(draft => {
      draft.qtySum = state.resources
        .map(tr => tr?.resourceQTY)
        .reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number;
    })
  }, [state, setState])

  const onCheckboxChange = (index: number, checked: boolean) => {
    setState(draft => {
      if (checked === true) {
        draft.resources[index]!.resourceQTY = 1;
        draft.qtySum = state.resources
          .map(tr => tr?.resourceQTY)
          .reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number;
      }

      if (checked === false) {
        draft.resources[index]!.resourceQTY = 0;
        draft.qtySum = state.resources
          .map(tr => tr?.resourceQTY)
          .reduce((previousValue, currentValue) => previousValue! as number - currentValue! as number, 0) as number;
      }
    })
  }

  const onChangeQty = (index: number, e: any) => {
    let value: "" | number = parseInt(e.target.value);
    value = isNaN(value) ? "" : value;

    if (value < 0) { return }

    if (value.toString().length > 5) {
      return;
    }

    setState(draft => {
      draft.resources[index]!.resourceQTY = value as number;
    })
  }

  const onSubmit = () => {
    if (props.type === "Labor") {
      dispatch(saveChangesToItemFromFullDayPreview({
        broker: props.item?.broker as string,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        nrOfTrucks: props.item?.nrOfTrucks,
        superIntendent: props.item?.superIntendent as string,
        tonnage: props.item?.tonnage,
        typeOfTrucks: props.item?.typeOfTrucks as string,
        shift: props.item?.shift as string,
        updateJobInfoTable: props?.updateJobInfoTable,
        updateMasterCrewSchedulePavingTable: true,
        updateMasterCrewScheduleTable: props.updateMasterCrewScheduleTable,
        material: props.item?.material as string,
        plant: props.item?.plant as string,
        loadOutTime: props.item?.loadOutTime as string,
        areaManager: props.item?.areaManager as string,
        jobMap: props.item?.jobMap as string,
        jobName: props.item?.jobName as string,
        pavingForeman: props.item?.pavingForeman as string,
        pavingSuper: props.item?.pavingSuperIntendent as string,
        pavingSuperIntendent: props.item?.superIntendent as string,
        pmpe: props.item?.pmpe as string,
        crewMakeLab: state.qtySum as number,
        crewMakeOp: fullDayPreviewItem?.crewMakeOp != null ? fullDayPreviewItem?.crewMakeOp : props.item?.crewMakeOp as number,
        resourcesLab: state.resources as [Resource],
        resourcesOp: (fullDayPreviewItem?.resourcesOp != null && fullDayPreviewItem?.resourcesOp?.[0].resourceType != null) ? fullDayPreviewItem?.resourcesOp : props.otherResources as [Resource],
        tph: props.item?.tph as number,
        uts: props.item?.uts as string,
        extrawork: props.item?.extraWork as string,
        oilTruk: props.item?.oilTruk as string,
        bookTruckVendor: props.item?.bookTruckVendor as string,
        grinder4ft: props.item?.grinder4ft as number,
        grinder6ft: props.item?.grinder6ft as number,
        grinder7ft: props.item?.grinder7ft as number,
        grinder12ft: props.item?.grinder12ft as number,
        mixDesignApproval: props.item?.mixDesignApproval as string,
        rtsSupport: props.item?.rtsSupport as string,
        mixSubmital: props.item?.mixSubmital as string,
      }));
    }
    if (props.type === "Operator") {
      dispatch(saveChangesToItemFromFullDayPreview({
        broker: props.item?.broker as string,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        nrOfTrucks: props.item?.nrOfTrucks,
        superIntendent: props.item?.superIntendent as string,
        tonnage: props.item?.tonnage,
        typeOfTrucks: props.item?.typeOfTrucks as string,
        shift: props.item?.shift as string,
        updateJobInfoTable: props?.updateJobInfoTable,
        updateMasterCrewSchedulePavingTable: true,
        updateMasterCrewScheduleTable: props.updateMasterCrewScheduleTable,
        material: props.item?.material as string,
        plant: props.item?.plant as string,
        loadOutTime: props.item?.loadOutTime as string,
        areaManager: props.item?.areaManager as string,
        jobMap: props.item?.jobMap as string,
        jobName: props.item?.jobName as string,
        pavingForeman: props.item?.pavingForeman as string,
        pavingSuper: props.item?.pavingSuperIntendent as string,
        pavingSuperIntendent: props.item?.superIntendent as string,
        pmpe: props.item?.pmpe as string,
        crewMakeLab: fullDayPreviewItem?.crewMakeLab != null ? fullDayPreviewItem?.crewMakeLab : props.item?.crewMakeLab as number,
        crewMakeOp: state.qtySum as number,
        resourcesLab: (fullDayPreviewItem?.resourcesLab != null && fullDayPreviewItem?.resourcesLab?.[0].resourceType != null) ? fullDayPreviewItem?.resourcesLab : props.otherResources as [Resource],
        resourcesOp: state.resources as [Resource],
        tph: props.item?.tph as number,
        uts: props.item?.uts as string,
        extrawork: props.item?.extraWork as string,
        oilTruk: props.item?.oilTruk as string,
        bookTruckVendor: props.item?.bookTruckVendor as string,
        grinder4ft: props.item?.grinder4ft as number,
        grinder6ft: props.item?.grinder6ft as number,
        grinder7ft: props.item?.grinder7ft as number,
        grinder12ft: props.item?.grinder12ft as number,
        mixDesignApproval: props.item?.mixDesignApproval as string,
        rtsSupport: props.item?.rtsSupport as string,
        mixSubmital: props.item?.mixSubmital as string,
      }));
    }
  }

  return (
    <>
      <div className="Manage_Paving_Main_Screen" style={{ height: "auto" }}>
        <div className="Paving_Header">
          <div className="Date_Wrap">
            <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
          </div>
          <CloseButton onClick={props.onClose} />
        </div>
        <div className="middle-content">
          <div className="Wrap_Inputs_Side">

            <div className="Equipment_Paving_Second">
              <div className="right_side">
                <div className="Paving_Before_Table">
                  <div className="Paving_Table_Title">CREW COMPOSITION</div>
                  <div className="Row">
                    <div className="WrapCheckBox">
                    </div>
                  </div>
                </div>
                <div className="Equipment_Paving_Main">
                  <div className="Equipment_Resources_Table">
                    <div className="Equipment_Table_Header">
                      <div>Select</div>
                      <div>Resource Name</div>
                      <div>Qty</div>
                    </div>
                    {state.resources?.map((rc: Maybe<Resource>, index: number) => (
                      <div className="Resource_Line" key={index} >
                        <div className="Resource_Check">
                          <CheckBox
                            onChange={(e) => { onCheckboxChange(index, e.target.checked) }}
                            checked={state.resources?.[index]?.resourceQTY as number > 0}
                          />
                        </div>
                        <div className="Paving_Resource_Name">
                          {rc?.resourceDescription as string}
                        </div>
                        <div className="Resource_Qu">
                          <TextInput
                            onChange={(e) => { onChangeQty(index, e) }}
                            value={rc?.resourceQTY?.toString() ?? ""}
                            type="text"
                            placeholder={state.resources?.[index]?.resourceQTY?.toString() ?? "0"}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Actions_Holder_Paving">
          <div className="buttons" style={{ textAlign: "center", paddingTop: "10px" }}>
            <button
              onClick={() => onSubmit()}
              className="Button Add_Resources"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(CrewComposition)