import React, { FC, useState } from "react";


interface IButtonProps {
  onClick(): void,
  className?: string,
  disabled?: boolean,
  type?: "button" | "submit" | "reset" | undefined,
  tooltip?: string,
  onMouseOver?(e: React.MouseEvent<HTMLButtonElement>): void,
  onMouseLeave?(e: React.MouseEvent<HTMLButtonElement>): void,
  children: any,
}

export const Button: FC<IButtonProps> = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseOver = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props?.tooltip) {
      setShowTooltip(true);
    }
    typeof props.onMouseOver == 'function' && props.onMouseOver(e);
  }

  const handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowTooltip(false);
    typeof props.onMouseLeave == 'function' && props.onMouseLeave(e)
  }

  return (
    <button
      className={props.className}
      type={props.type}
      onClick={props.onClick}
      onMouseLeave={handleMouseLeave as any}
      onMouseOver={handleMouseOver as any}
      disabled={props.disabled}
      title={props.tooltip as string}
    >
      {props.children}
      {showTooltip
        ? <div style={{
          position: "absolute",
          display: "block",
          padding: "5px 10px"
        }}>{props.tooltip}</div>
        : null
      }
    </button>
  )
}