import React, { FC, useContext, useEffect, useState } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";
import { Maybe, PavingForeman, Query } from "../../../graphql/schema-types";
import { PAVING_FOREMANS_QUERY } from "../../../graphql/queries/PAVING_FOREMANS_QUERY";
import "./TeamManagement.css"
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { FullScreenLoadingIndicator } from "../../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { FullScreenErrorIndicator } from "../../Modal/ErrorIndicator/FullScreenErrorIndicator";
import { CloseButton } from "../../Form/CloseButton";
import { ADD_PAVING_FOREMAN } from "../../../graphql/mutations/ADD_PAVING_FOREMAN";
import to from "await-to-js";
import { GeneralWarningModal } from "../../Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import { ModalContext } from "../../Modal/ModalContext/ModalContext";
import { DELETE_PAVING_FOREMAN } from "../../../graphql/mutations/DELETE_PAVING_FOREMAN";
import { JOB_INFOS_BY_DELETED_FOREMAN } from "../../../graphql/queries/JOB_INFOS_BY_DELETED_FOREMAN";
import moment from "moment";
import { ChangeUser } from "./ChangeUser/ChangeUser";

interface ITeamManagement {
  onClose?(): void;
}

// This component loads the modal from button "Crew Management" from Paving Planner side
export const TeamManagement: FC<ITeamManagement> = (props) => {
  const [state, setState] = useState<string>("");
  const [buttonStatus, setButtonStatus] = useState<boolean>(true);
  const { loading, error, data, refetch } = useQuery<Pick<Query, "pavingForemans">>(PAVING_FOREMANS_QUERY);

  let isGeneral = useSelector((state: RootState) => state.app?.pavingModuleUser.isInGeneralPavingSupers);
  const [addPavingForeman] = useMutation(ADD_PAVING_FOREMAN, { onCompleted: refetch });
  const [deletePavingForeman] = useMutation(DELETE_PAVING_FOREMAN, { onCompleted: refetch });

  const modal = useContext(ModalContext);
  const client = useApolloClient();

  const pavingForemanNames = data?.pavingForemans.map(f => f?.username);

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.removeAttribute('style')
    }
  }, [])

  useEffect(() => {
    if (state === "" || state.trim() === "" || state.trim().length < 3 || pavingForemanNames?.includes(state)) {
      setButtonStatus(true)
    }
    else {
      setButtonStatus(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, setState])

  const formatInput = (e: string) => {
    const value = e;
    setState(value.trim());
  }

  const onSaveUser = async () => {
    const [error, response] = await to(
      addPavingForeman({
        variables: {
          where: { username: state }
        }
      })
    )

    if (error != null) {
      modal?.openModal?.({
        element: (
          <GeneralWarningModal
            message={error.message}
            title="Error"
            yesNoButtons={false}
          />
        ),
      });
    }

    if (response) {
      setState("");
    }
  }

  const onDelete = async (foreman: Maybe<PavingForeman>) => {
    const _currentDay = moment.utc().unix() * 1000;

    const [, responseJobInfos] = await to(
      client.query({
        query: JOB_INFOS_BY_DELETED_FOREMAN, variables: { where: { foremanName: foreman?.username, startDate: _currentDay } }
      })
    )

    // check if there are paving resources added into DB where the selected foreman to delete is setted
    // if the length > 0, then we need to change the foreman with another for all paving resources
    // otherwise you can't delete the foreman
    if (responseJobInfos?.data.jobInfosByDeletedForeman.length > 0) {
      modal?.openModal?.({
        element: (
          <ChangeUser
            pavingForemans={data?.pavingForemans}
            userToDelete={foreman?.username as string}
            userIDToDelete={foreman?.uID as string}
            jobInfosByDeletedUser={responseJobInfos?.data.jobInfosByDeletedForeman}
            refetch={() => refetch()}
          />
        ),
      });
    }
    else {
      const [error, response] = await to(
        deletePavingForeman({
          variables: { where: { foremanName: foreman?.username as string, uID: foreman?.uID as string, jobInfosByDeletedUser: [] } }
        })
      )

      if (error != null) {
        modal?.openModal?.({
          element: (
            <GeneralWarningModal
              message={error.message}
              title="Error"
              yesNoButtons={false}
            />
          ),
        });
      }

      if (response) {
        refetch();
      }
    }
  }

  return (
    <div className="Team_Management">
      {loading && <FullScreenLoadingIndicator />}
      {error != null && <FullScreenErrorIndicator />}
      <div className="Team_Management_Header">
        <div className="Date_Wrap">
          <img src={process.env.PUBLIC_URL + '/logo.png'} alt="graniterock" />
          <div className="Title">Crew Management</div>
        </div>
        <CloseButton onClick={props.onClose} />
      </div>
      <div className="Add_Team_User">
        <div className="Wrap_Inputs">
          <input
            type="text"
            value={state}
            placeholder="Name"
            onChange={(e) => {
              const value = e.target.value;
              setState(value);
            }}
            onBlur={(e) => formatInput(e.target.value)}
          />
        </div>
        <button onClick={() => { onSaveUser() }} className="Save_Button" disabled={buttonStatus}>Add Team</button>
      </div>
      <div className="Table">
        <div className="Table_Header">
          <div className="Header_Item">Name</div>
          <div className="Header_Item">Actions</div>
        </div>
        <div className="Table_Content">
          {
            data?.pavingForemans?.map((foreman, index) => (
              <div className="Row" key={index}>
                <div className="Row_Item">{foreman?.username}</div>
                <div className="Row_Item">
                  {
                    <button className="Delete_User_Button" onClick={() => onDelete(foreman)} disabled={!isGeneral}>Delete user</button>
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div >
  )
}