import React, { FC } from "react";

export const Nowhere: FC = props => {
  return (
    <>
      <div className="First_select" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/gr-com_construction-dispatch_background.png'})` }}>
        <div style={{ textAlign: "center"}}>
          <div style={{ fontSize: "40px", color: "#073f2d", fontWeight: "bold" }}>404</div>
          <div style={{ fontSize: "14px", color: "#073f2d", fontWeight: "bold" }}>Page not found</div>
        </div>
      </div>
    </>
  );
}