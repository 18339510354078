import React, { FC } from "react";
// import { Button } from "../../Form/Button";
import { CloseButton } from "../../Form/CloseButton";
import "./DeallocateAllResourcesFromDay.css";

interface DeallocateAllResourcesFromDayInterface {
	onConfirm(): void;
	onClose?(): void;
}

export const DeallocateAllResourcesFromDay: FC<DeallocateAllResourcesFromDayInterface> = (props) => {
	return (
		<div>
			<div className="DeallocateAllResourcesFromDay">
				<div className="Deallocate_Resources_Title">
					<div className="Wrap">
						<img src={process.env.PUBLIC_URL + '/logo.png'} alt="graniterock" />
						<div className="Title">Empty Day</div>
					</div>
					<CloseButton onClick={() => props.onClose?.()} />
				</div>
				<div className="ErrorMessage">
					<i className="fas fa-exclamation-circle"></i>
					<div className="ErrorText">
						You have to delete all the resources added to this day to allocate the crew resources <br />
					</div>
				</div>
			</div>
		</div>
	);
}