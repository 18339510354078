import React, { FC, useEffect } from "react";
import { useImmer } from "use-immer";
import { useQuery } from "@apollo/react-hooks";
import { toMap } from "../../utils/toMap";
import { DayFromCalendar } from "./DayFromCalendar/DayFromCalendar";
import Preview from "./Preview";
import { Query, SuperIntendentPavingCalendar, Maybe } from "../../graphql/schema-types";
import { SUPER_INTENDENT_PAVING_CALENDAR_QUERY } from "../../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_QUERY";
import { FullScreenLoadingIndicator } from "../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { FullScreenErrorIndicator } from "../Modal/ErrorIndicator/FullScreenErrorIndicator";
import "./Calendar.css";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { addBlockedJobNumbersAndForemansToPavingModule, addDayStatusToPavingModule } from "../../redux/appSlice";

interface ICalendarProps {
  generatedDays: string[];
}

interface CalendarState {
  days: Maybe<SuperIntendentPavingCalendar>[];
}

export const Calendar: FC<ICalendarProps> = (props) => {

  const [calendarData, setCalendarData] = useImmer<CalendarState>({
    days: []
  });

  const { data, loading, error } = useQuery<Pick<Query, "superIntendentPavingCalendar">>(SUPER_INTENDENT_PAVING_CALENDAR_QUERY, {
    variables: { where: { startDate: parseInt(props.generatedDays[0]), endDate: parseInt(props.generatedDays[props.generatedDays.length - 1]) } },
    skip: props.generatedDays.length === 0
  });

  const dispatch = useDispatch();

  const items = useSelector((state: RootState) => state.app?.pavingModule);

  useEffect(() => {
    if (props.generatedDays.length === 0 || data?.superIntendentPavingCalendar == null || calendarData.days.length > 0) return;

    const dataByDate = toMap(data.superIntendentPavingCalendar, d => d?.date ?? "");

    setCalendarData(draft => {
      const res = props.generatedDays
        .map(gd => (
          {
            date: gd,
            pk: dataByDate[parseInt(gd)] != null ? dataByDate[parseInt(gd)]?.pk : "calendar",
            status: dataByDate[parseInt(gd)] != null ? dataByDate[parseInt(gd)]?.status : false,
          } as SuperIntendentPavingCalendar
        ));
      draft.days = res;
    });

  }, [calendarData.days.length, data, props.generatedDays, setCalendarData]);

  useEffect(() => {
    if (data == null || data.superIntendentPavingCalendar == null) return;
    dispatch(addBlockedJobNumbersAndForemansToPavingModule({ pavingCalendarItems: data.superIntendentPavingCalendar }));
    dispatch(addDayStatusToPavingModule({ superIntendentPavingCalendarItems: data.superIntendentPavingCalendar }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      {loading && <FullScreenLoadingIndicator />}
      {error != null && <FullScreenErrorIndicator />}
      <div className="week">
        <Preview />
      </div>
      <div className="calendar_paving_wrap">
        <div className="calendar_paving_header">
          <div className="header_item">SUN</div>
          <div className="header_item">MON</div>
          <div className="header_item">TUE</div>
          <div className="header_item">WED</div>
          <div className="header_item">THU</div>
          <div className="header_item">FRI</div>
          <div className="header_item">SAT</div>
        </div>
        <div className="calendar_paving" >
          {
            calendarData.days.map((day, index) => (
              <DayFromCalendar
                key={day?.date ?? index}
                calendarDay={day}
                items={items[day?.date].items}
                lastDay={props.generatedDays[props.generatedDays.length - 1]}
              />
            ))
          }
        </div>
      </div>
    </>
  )
}