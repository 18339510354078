import React, { FC, useEffect, useState } from "react"
import { GestureResponderEvent, TouchableHighlight, View } from "react-native"

export const useTouchableMenu = () => {

  const [state, setState] = useState({
    pageX: 0,
    pageY: 0,
    visible: false,
  });

  const onLongPress = (event: GestureResponderEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const { pageX, pageY } = event.nativeEvent;
    setState(state => ({
      ...state,
      pageX,
      pageY,
      visible: true,
    }))
  }

  const onContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.nativeEvent.preventDefault();
    const { pageX, pageY } = event;
    setState(state => ({
      ...state,
      pageX,
      pageY,
      visible: true,
    }))
  }

  const onClick = () => {
    setState(state => ({
      ...state,
      visible: false,
    }))
  }

  useEffect(() => {
    document.addEventListener("click", onClick);
    return (() => {
      document.removeEventListener("click", onClick);
    })
  }, [])

  return ([
    <TouchableMenu onLongPress={onLongPress} onContextMenu={onContextMenu} />,
    state,
  ] as [JSX.Element, typeof state])
}

type TouchableMenuProps = {
  onLongPress: (event: GestureResponderEvent) => void;
  onContextMenu: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const TouchableMenu: FC<TouchableMenuProps> = props => {

  return (
    <div onContextMenu={props.onContextMenu}>
      <TouchableHighlight onLongPress={props.onLongPress} >
        <View>
          {props.children}
        </View>
      </TouchableHighlight>
    </div>
  )
}