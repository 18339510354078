import { useMutation } from "@apollo/react-hooks";
import to from "await-to-js";
import React, { FC, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { DELETE_PAVING_FOREMAN } from "../../../../graphql/mutations/DELETE_PAVING_FOREMAN";
import { ChangeUserJobInfo, JobInfo, Maybe, PavingForeman } from "../../../../graphql/schema-types";
import { updateForemanForItemsFromCalendar } from "../../../../redux/appSlice";
import { CloseButton } from "../../../Form/CloseButton";
import { ModalContext } from "../../../Modal/ModalContext/ModalContext";
import { GeneralWarningModal } from "../../../Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import "./ChangeUser.css";

interface IChangeUser {
  pavingForemans: Maybe<PavingForeman>[] | undefined
  userToDelete: string;
  userIDToDelete: string;
  onClose?(): void;
  jobInfosByDeletedUser: JobInfo[];
  refetch?(): void;
}

export const ChangeUser: FC<IChangeUser> = (props) => {

  const [state, setState] = useState<string>("");
  const modal = useContext(ModalContext);

  const [deletePavingForeman] = useMutation(DELETE_PAVING_FOREMAN, { onCompleted: props.refetch });

  const dispatch = useDispatch();

  const onChangeUserApply = () => {
    modal?.openModal?.({
      element: <GeneralWarningModal
        message={
          `Are you sure you want to replace ${props.userToDelete} with ${state} ?`
        }
        title="Disable user"
        onConfirm={() => onSave()}
        onCancel={() => { }}
        yesNoButtons={true}
      />
    });
  }

  const onSave = async () => {
    if (state == null) return;

    const jobInfosItemsToUpdate: Maybe<ChangeUserJobInfo>[] = [];
    props.jobInfosByDeletedUser.forEach((jobInfo: JobInfo) => {
      delete jobInfo["__typename"];
      jobInfosItemsToUpdate.push({
        ...jobInfo,
        pavingForeman: state as string,
      });
    });

    dispatch(updateForemanForItemsFromCalendar({
      foremanNameForUpdate: state,
      foremanToDelete: props.userToDelete
    }))
    const [error, response] = await to(deletePavingForeman({
      variables: { where: { foremanName: state as string, uID: props.userIDToDelete as string, jobInfosByDeletedUser: jobInfosItemsToUpdate } }
    }))

    if (response) {
      props.onClose?.();
    }

    if (error != null) {
      modal?.openModal?.({
        element: (
          <GeneralWarningModal
            message={error.message}
            title="Error"
            yesNoButtons={false}
          />
        ),
      });
    }
  }

  return (
    <div className="Change_User">
      <div className="Change_User_Header">
        <div className="Date_Wrap">
          <img src={process.env.PUBLIC_URL + '/logo.png'} alt="graniterock" />
          <div className="Title">Delete User {props.userToDelete}</div>
        </div>
        <CloseButton onClick={props.onClose} />
      </div>
      <div className="Change_User_Content">
        <div className="Wrap_Select">
          <select className="dropdown" value={state} onChange={(e) => { setState(e.target.value) }} style={{ outline: 0 }}>
            <option value="" disabled>
              Foremans:
            </option>
            {
              props.pavingForemans != null && props.pavingForemans.map((au, index) => (
                <option value={au?.username as string} key={index} disabled={au?.username === props.userToDelete}>
                  {au?.username}
                </option>
              ))
            }
          </select>
        </div>
        <button
          onClick={() => { onChangeUserApply() }}
          className="Change_User_Button "
          disabled={state == null}
          title={state == null ? "You have to select a user from dropdown" : "Change User"}>
          Change User
        </button>
      </div>
    </div>
  )
}