import React, { FC, useEffect, useState, useContext } from "react";
import "./ResourceAssignment.css";
import { EditCrew } from "../EditCrew/EditCrew";
import { useQuery } from "@apollo/react-hooks";
import { CREW_RESOURCES_QUERY } from "../../../graphql/queries/CREWS_RESOURCES_QUERY";
import { CustomCrewResourceComposition, CustomCrew, Maybe } from "../../../graphql/schema-types";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import { addResourcesToDay, addDescriptionToDay } from "../../../redux/appSlice";
import { ApplyCrew } from "../../Warnings & errors/ApplyCrew/ApplyCrew";
import moment from "moment";
import { DeallocateAllResourcesFromDay } from "../../Warnings & errors/DeallocateAllResourcesFromDay/DeallocateAllResourcesFromDay";
import { ModalContext } from "../../Modal/ModalContext/ModalContext";
import { CloseButton } from "../../Form/CloseButton";
import { FullScreenLoadingIndicator } from "../../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { FullScreenErrorIndicator } from "../../Modal/ErrorIndicator/FullScreenErrorIndicator";
import { GeneralWarningModal } from "../../Warnings & errors/GeneralWarningModal/GeneralWarningModal";

interface ResourceAssignmentInterface {
  onClose?(): void;
  crew?: Maybe<CustomCrew>
  locationIndex: number;
  unix: number;
}

export const ResourceAssignment: FC<ResourceAssignmentInterface> = props => {
  const [laborResources, setLaborResources] = useState<Maybe<CustomCrewResourceComposition>[]>();
  const [equipmentResources, setEquipmentResources] = useState<Maybe<CustomCrewResourceComposition>[]>();
  const selectedResources = useSelector((state: RootState) => state.app.schedule.locations?.[props.locationIndex]?.daysByUnix?.[props.unix]?.selectedResources)

  const jobNumber = useSelector((state: RootState) => state.app.start.jobNumber);
  const day = useSelector((state: RootState) => state.app.schedule?.locations?.[props.locationIndex]?.daysByUnix?.[props.unix])

  const { data, loading, error, refetch } = useQuery(CREW_RESOURCES_QUERY, {
    variables: { crewCode: props?.crew?.crewCode },
    skip: props?.crew?.crewCode == null || props?.crew?.crewCode === "",
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.removeAttribute('style')
    }
  }, [])

  useEffect(() => {
    window.onpopstate = (e: any) => {
      props?.onClose?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data == null || data?.crewResources == null) return;

    const _laborResources = data?.crewResources
      .filter((cr: CustomCrewResourceComposition) => cr.resourceType === "Labor" && cr.JobNumber === jobNumber);

    const _equipmentResources = data?.crewResources
      .filter((cr: CustomCrewResourceComposition) => cr.resourceType === "Equipment" && cr.JobNumber === jobNumber);

    setLaborResources(_laborResources);
    setEquipmentResources(_equipmentResources);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const dispatch = useDispatch();

  const modal = useContext(ModalContext);

  const openApplyCrewModal = () => {
    modal?.openModal({
      element: <ApplyCrew
        onConfirm={() => onApplyResources()}
        crewCode={props.crew?.crewCode as string}
        crewName={props.crew?.crewDescription as string}
        assignDate={moment(props.unix).utc().format("LL")}
      />
    });
  }

  const openDeallocateAllResourcesFromDayModal = () => {
    modal?.openModal({
      element: <DeallocateAllResourcesFromDay onConfirm={() => { }} />
    });
  }

  const openMoreThan10ResoucesModal = () => {

    if (laborResources?.length! === 10 && !Object.keys(selectedResources).includes("PAV") && (props.locationIndex === 1 || props.locationIndex === 2)) {
      modal?.openModal({
        element: <GeneralWarningModal
          onConfirm={() => { }}
          title="Error on adding a new crew"
          message="You have more than 9 labor resources added on this crew and the Paving resource is not selected yet . Please try to edit and than apply"
          yesNoButtons={false}
        />
      });
    }
    else if (equipmentResources?.length! === 10 && !Object.keys(selectedResources).includes("TRK") && (props.locationIndex === 1 || props.locationIndex === 2)) {
      modal?.openModal({
        element: <GeneralWarningModal
          onConfirm={() => { }}
          title="Error on adding a new crew"
          message="You have more than 9 equipment resources added on this crew and the Trucking resource is not selected yet . Please try to edit and than apply"
          yesNoButtons={false}
        />
      });
    }
    else {
      modal?.openModal({
        element: <GeneralWarningModal
          onConfirm={() => { }}
          title="Error on adding a new crew"
          message="You have more than 20 resources added on this crew. Please try to edit and than apply"
          yesNoButtons={false}
        />
      });
    }
  }

  const isDayCleared = () => {
    if (day?.labor.jobInfos.length === 0 && day?.equip.jobInfos.length === 0) {
      return true;
    }
    if (day?.labor.jobInfos.length === 1) {
      if (Object.values(day?.labor.jobInfos).length === 0) {
        return true;
      }
    }

    if (day?.equip.jobInfos.length === 1) {
      if (Object.values(day?.equip.jobInfos).length === 0) {
        return true;
      }
    }
    return false;
  }

  const areMoreResources = () => {
    return equipmentResources?.length! > 10 || laborResources?.length! > 10 ||
      (laborResources?.length! === 10 && !Object.keys(selectedResources).includes("PAV") && (props.locationIndex === 1 || props.locationIndex === 2)) ||
      ((equipmentResources?.length! === 10 && !Object.keys(selectedResources).includes("TRK") && (props.locationIndex === 1 || props.locationIndex === 2)))
  }

  const onApplyResources = () => {
    if (!isDayCleared()) { openDeallocateAllResourcesFromDayModal(); return; }
    if (areMoreResources() === true) { openMoreThan10ResoucesModal(); return; }
    dispatch(addResourcesToDay({
      locationIndex: props.locationIndex,
      unix: props.unix,
      resources: data?.crewResources?.filter((c: CustomCrewResourceComposition) => c.JobNumber === jobNumber)
    }));

    props.onClose?.();

    dispatch(addDescriptionToDay({
      locationIndex: props.locationIndex,
      unix: props.unix,
      description: props.crew?.crewDescription! as string,
    }));
  }

  const onPressApplyButton = () => {
    if (!isDayCleared()) {
      openDeallocateAllResourcesFromDayModal();
    }
    else {
      openApplyCrewModal()
    }
  }

  const showResourceCrewModal = () => {
    modal?.openModal?.({
      element:
        <EditCrew
          crewCode={props?.crew?.crewCode! as string}
          crewName={props.crew?.crewDescription as string}
          crewResoures={data?.crewResources?.filter((c: CustomCrewResourceComposition) => c.JobNumber === jobNumber)}
          jobNumber={jobNumber}
          refetch={() => refetch()}
        />
    })
  }

  let sortedCurrentCrewResources = [...(data?.crewResources ?? [])]
    .filter((c: CustomCrewResourceComposition) => c.JobNumber === jobNumber)
    .sort((a, b) => a.resourceType! < b.resourceType! ? 1 : -1)
    .sort((n, p) => n.resourceID! > p.resourceID! ? 1 : -1);

  return (
    <>
      {loading && <FullScreenLoadingIndicator />}
      {error != null && <FullScreenErrorIndicator />}
      <div className="Resource_Assignment_Main_Screen">
        <div className="Crew_Header">
          <div className="Wrap">
            <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
            <div className="Title">Crew Management</div>
          </div>
          <CloseButton onClick={props.onClose} />
        </div>
        <div className="Resource_Assignment_Header">
          <div className="Crew_Name_Assignment">
            <div>
              Resource For {props?.crew?.crewCode} - {props.crew?.crewDescription}
            </div>
          </div>
          <div className="Equipment_Limit_Assignment">
            <div className="Labor_Limit">
              <div className="Labor_Limit_Title">
                Labour Resources Allocated:
              </div>
              <div className="Labor_Limit_Value">
                {laborResources?.length}/10
              </div>
            </div>
            <div className="Equipment_Limit">
              <div className="Equipment_Limit_Title">
                Equipment Resources Allocated:
              </div>
              <div className="Equipment_Limit_Value">
                {equipmentResources?.length}/10
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
        <div className="Resources_Table">
          <div className="Equipment_Table_Header">
            <div>Resource Code</div>
            <div>Resource Description</div>
            <div>QTY Needed</div>
          </div>
          {
            sortedCurrentCrewResources != null && sortedCurrentCrewResources.map((cr: CustomCrewResourceComposition, index: number) => (
              <div className="Resource_Line" key={index}>
                <div className="Resource_ID">
                  {cr.resourceID}
                </div>
                <div className="Resource_Name">
                  {cr.resourceDescription}
                </div>
                <div className="Resource_Qu">
                  {cr.QTYNeeded}
                </div>
              </div>
            ))
          }
        </div>
        <div className="Resources_Actions_Holder">
          <button className="Button" onClick={props.onClose}>Cancel</button>
          <button className="Button_Save" onClick={showResourceCrewModal}>Edit Resources</button>
          <button className="Button_Save" onClick={() => onPressApplyButton()}>Apply</button>
        </div>
      </div>
    </>
  );
}