import { EventEmitter } from "eventemitter3";

export enum EmitterEvents {
  MODAL = "MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",
  TOP_MODAL = "TOP_MODAL",
  CLOSE_TOP_MODAL = "CLOSE_TOP_MODAL",
  CLOSE_ALL_MODALS = "CLOSE_ALL_MODALS",
}

export const emitter = new EventEmitter();
