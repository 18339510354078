import React, { FC, CSSProperties } from 'react';

interface IButton {
	className?: string;
	type?: "button" | "submit" | "reset" | undefined;
	onClick?(): void;
	title?: string;
	children?: any;
	disabled?: boolean;
	style?: CSSProperties;
}

export const Button: FC<IButton> = ({ className, type, onClick, disabled, title, children, style }) => {
	return (
		<button
			type={type}
			className={className}
			disabled={disabled}
			onClick={onClick}
			style={style}
		>
			{title}
			{children}
		</button>
	);
};