const _settings = {
  production: {
    sendOTPUri: "https://gue9f4xrqe.execute-api.us-west-2.amazonaws.com/prod/sendOTP",
    verifyOTPUri: "https://gue9f4xrqe.execute-api.us-west-2.amazonaws.com/prod/verifyOTP",
    selfUri: "https://gue9f4xrqe.execute-api.us-west-2.amazonaws.com/prod/self",
    graphqlUri: "https://atqbkls9k2.execute-api.us-west-2.amazonaws.com/prod/graphql",
    weatherGridPoints: "https://api.weather.gov/points/",
    authenticateUri: "https://atqbkls9k2.execute-api.us-west-2.amazonaws.com/prod/authenticate",
    tokenRefreshUri: "https://atqbkls9k2.execute-api.us-west-2.amazonaws.com/prod/refreshToken",
    redirectUri: "https://myresourceschedule.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=315526t3a57ue09nukn4p17htj&redirect_uri=https://www.myresourceschedule.com/",
    logOutUri: "https://myresourceschedule.auth.us-west-2.amazoncognito.com/logout?response_type=code&client_id=315526t3a57ue09nukn4p17htj&redirect_uri=https://www.myresourceschedule.com/",
  },
  preproduction: {
    sendOTPUri: "https://gue9f4xrqe.execute-api.us-west-2.amazonaws.com/prod/sendOTP",
    verifyOTPUri: "https://gue9f4xrqe.execute-api.us-west-2.amazonaws.com/prod/verifyOTP",
    selfUri: "https://gue9f4xrqe.execute-api.us-west-2.amazonaws.com/prod/self",
    graphqlUri: "https://cv0xic1d52.execute-api.us-west-2.amazonaws.com/preprod/graphql",
    weatherGridPoints: "https://api.weather.gov/points/",
    authenticateUri: "https://cv0xic1d52.execute-api.us-west-2.amazonaws.com/preprod/authenticate",
    tokenRefreshUri: "https://cv0xic1d52.execute-api.us-west-2.amazonaws.com/preprod/refreshToken",
    redirectUri: "https://preproddevresourceschedule.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=erci05b5al8phg00j6e560trl&redirect_uri=https://preprod.devresourceschedule.com/",
    logOutUri: "https://preproddevresourceschedule.auth.us-west-2.amazoncognito.com/logout?response_type=code&client_id=erci05b5al8phg00j6e560trl&redirect_uri=https://preprod.devresourceschedule.com/",
  },
  development: {
    sendOTPUri: "https://003szlvwpb.execute-api.us-west-2.amazonaws.com/dev/sendOTP",
    verifyOTPUri: "https://003szlvwpb.execute-api.us-west-2.amazonaws.com/dev/verifyOTP",
    selfUri: "https://003szlvwpb.execute-api.us-west-2.amazonaws.com/dev/self",
    graphqlUri: "https://oedh84g9kf.execute-api.us-west-2.amazonaws.com/dev/graphql",
    weatherGridPoints: "https://api.weather.gov/points/",
    authenticateUri: "https://oedh84g9kf.execute-api.us-west-2.amazonaws.com/dev/authenticate",
    tokenRefreshUri: "https://oedh84g9kf.execute-api.us-west-2.amazonaws.com/dev/refreshToken",
    redirectUri: "https://devresourceschedule.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=6dj0rree6e2crvvv1m7tqa2e4k&redirect_uri=https://www.devresourceschedule.com/",
    logOutUri: "https://devresourceschedule.auth.us-west-2.amazoncognito.com/logout?response_type=code&client_id=6dj0rree6e2crvvv1m7tqa2e4k&redirect_uri=https://www.devresourceschedule.com/",
    //logOutUri: "https://devresourceschedule.auth.us-west-2.amazoncognito.com/logout?client_id=6dj0rree6e2crvvv1m7tqa2e4k&logout_uri=com.ResourceSchedule///logout",
  },
  localhost: {
    sendOTPUri: "https://003szlvwpb.execute-api.us-west-2.amazonaws.com/dev/sendOTP",
    verifyOTPUri: "https://003szlvwpb.execute-api.us-west-2.amazonaws.com/dev/verifyOTP",
    selfUri: "https://003szlvwpb.execute-api.us-west-2.amazonaws.com/dev/self",
    graphqlUri: "http://localhost:3000/dev/graphql",
    weatherGridPoints: "https://api.weather.gov/points/",
    authenticateUri: "http://localhost:3000/dev/authenticate",
    tokenRefreshUri: "http://localhost:3000/dev/refreshToken",
    redirectUri: "https://localhostrs.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=1lc4i5gbtcjchof8qcjeoqiup1&redirect_uri=http://localhost:7777/",
    logOutUri: "https://localhostrs.auth.us-west-2.amazoncognito.com/logout?response_type=code&client_id=1lc4i5gbtcjchof8qcjeoqiup1&logout_uri=http://localhost:7777/",

  },
};

export const stage = process.env.REACT_APP_STAGE ?? process.env.NODE_ENV ?? "development"
export const settings = _settings[stage as "production" | "development" | "localhost"];
