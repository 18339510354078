import React, { FC, useRef, useEffect, useState, memo, useContext, CSSProperties } from 'react';
import { useImmer } from 'use-immer';
import { Maybe, ResourceCode, CustomCrew, JobInfo, Query, SuperIntendentPavingCalendar } from '../graphql/schema-types';
// @ts-ignore
import { useDispatch, useSelector } from 'react-redux';
import { JobChange, JobQTY, RemoveJob, removeNonPavingTruckingResourcesToDay, removePavingResourcesToDay, removePavingTruckingResourcesToDay, removeTruckingResourcesToDay } from '../redux/appSlice';
import { ResourceAssignment } from "../components/Crew/ResourceAssignment/ResourceAssignment";
import { RootState } from '../redux/store';
import { ModalContext } from './Modal/ModalContext/ModalContext';
import { TruckingManagement } from './Trucking/TruckingManagement/TruckingManagement';
import PavingManagement from './Paving/PavingManagement';
import { GeneralWarningModal } from './Warnings & errors/GeneralWarningModal/GeneralWarningModal';
import { useQuery } from '@apollo/react-hooks';
import { SUPER_INTENDENT_PAVING_CALENDAR_QUERY } from '../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_QUERY';
interface Props {
  resourceCodes: Maybe<ResourceCode>[];
  crews?: Maybe<CustomCrew>[];
  resourceIdIndexes?: { [resourceId: string]: number };
  indexPos: number | null;
  locationIndex: number;
  unix: number;
  resourceType: "labor" | "equipment";
  onReloadLockedDays(): void;
}

interface Option {
  value: string;
  rc: Maybe<ResourceCode>;
  crew: Maybe<CustomCrew>;
  qty: "" | number;
}

const getInitialState = (): Option => {
  return ({
    value: "",
    rc: null,
    crew: null,
    qty: ""
  })
}

const labelStyle: CSSProperties = {
  overflow: "hidden"
  , flex: 1
  , whiteSpace: "nowrap"
  , margin: "3px 5px"
  , textOverflow: "ellipsis"
  , fontSize: "14px"
}

const JobSelect: FC<Props & { jobInfo: Maybe<JobInfo> }> = props => {
  const input = useRef<HTMLInputElement>(null);
  const { indexPos, locationIndex, unix, resourceType, jobInfo } = props
  const [state, setState] = useImmer<Option>(getInitialState());

  const jobNumber = useSelector((state: RootState) => state.app.start.jobNumber);
  const selectedResources = useSelector((state: RootState) => state.app.schedule.locations?.[props.locationIndex]?.daysByUnix?.[props.unix]?.selectedResources)
  const laborResources = useSelector((state: RootState) => state.app.schedule.locations?.[props.locationIndex]?.daysByUnix?.[props.unix]?.labor.jobInfos.filter(lr => lr?.resourceID !== "ACP"))
  const equipResources = useSelector((state: RootState) => state.app.schedule.locations?.[props.locationIndex]?.daysByUnix?.[props.unix]?.equip.jobInfos)
  const pavingResources = useSelector((state: RootState) => state.app.schedule.locations?.[props.locationIndex]?.daysByUnix?.[props.unix]?.paving.pavingResources)
  const truckingResources = useSelector((state: RootState) => state.app.schedule.locations?.[props.locationIndex]?.daysByUnix?.[props.unix]?.trucking.truckingResources);
  const _daysGenerated = useSelector((state: RootState) => state.app.schedule.locations[1].days);

  const { data: lockedDaysData, refetch: refetchLockedDays } = useQuery<Pick<Query, "superIntendentPavingCalendar">>(SUPER_INTENDENT_PAVING_CALENDAR_QUERY, {
    variables: {
      where: {
        // @ts-ignore
        startDate: _daysGenerated.at(0),
        // @ts-ignore
        endDate: _daysGenerated.at(-1)
      }
    },
    skip: _daysGenerated == null || _daysGenerated?.length === 0,
    fetchPolicy: "no-cache"
  });

  let _lockedDays: Maybe<number>[] = [];
  lockedDaysData?.superIntendentPavingCalendar.forEach(item => {
    if (item?.jobNumbersBlocked?.includes(jobNumber as number) || item?.status === true) _lockedDays.push(item.date)
  })

  const updatedPav = pavingResources?.[0]?.updated;
  const updatedTrk = truckingResources?.[0]?.updated;

  const modal = useContext(ModalContext);

  useEffect(() => {
    setState(draft => {
      const index = props.resourceIdIndexes?.[jobInfo?.resourceID ?? ""] ?? -1;
      draft.value = index !== -1 ? `r/${index}` : "";
      draft.rc = props.resourceCodes?.[index!];
      if (jobInfo?.resourceID !== "ACP") {
        draft.qty = jobInfo?.resourceQTY! ?? "";
      }
    });
  }, [jobInfo, props.resourceCodes, props.resourceIdIndexes, setState]);

  const dispatch = useDispatch();

  const onPreventChangeTrk = async (eventValue: string[]) => {

    refetchLockedDays();
    let _lockedDays: Maybe<number>[] = checkForLockedDays();

    if (_lockedDays.includes(unix)) {
      modal?.openModal?.({
        element: <GeneralWarningModal
          message="The day is locked. You can not change the paving resources"
          title="TRUCKING"
          yesNoButtons={false}
          onCancel={() => { }}
        />
      });
    }
    if (pavingResources.length > 0) {
      modal?.openModal?.({
        element: <GeneralWarningModal
          message="Only the resources without operation type paving will be removed,
          because you have a Paving Crew Defined. Are you sure you want to continue?"
          title="TRUCKING"
          yesNoButtons={true}
          onConfirm={() => {
            if (eventValue?.[0] === "r") {
              if (truckingResources.filter(tr => tr?.operationType === "paving").length === 0) {
                onResourceChange(parseInt(eventValue?.[1]) ?? -1);
                dispatch(removeTruckingResourcesToDay({ locationIndex, unix }))
              }
              if (truckingResources.filter(tr => tr?.operationType === "paving").length > 0) {
                dispatch(removeNonPavingTruckingResourcesToDay({ locationIndex, unix }))
              }
              return;
            }
          }}
          onCancel={() => { }}
        />
      });
    }
    else {
      modal?.openModal?.({
        element: <GeneralWarningModal
          message="Are you sure you want to change
          the trucking resource? If you continue you will loose all your trucking entries!"
          title="TRUCKING"
          yesNoButtons={true}
          onConfirm={() => {
            if (eventValue?.[0] === "r") {
              onResourceChange(parseInt(eventValue?.[1]) ?? -1);
              dispatch(removeTruckingResourcesToDay({ locationIndex, unix }))
              return;
            }
          }}
          onCancel={() => { }}
        />
      });
    }
  }

  const onPreventChangePav = async (eventValue: string[]) => {

    let _lockedDays: Maybe<number>[] = checkForLockedDays();

    if (_lockedDays.includes(unix)) {
      modal?.openModal?.({
        element: <GeneralWarningModal
          message="The day is locked. You can not change the paving resources"
          title="PAVING"
          yesNoButtons={false}
          onCancel={() => { }}
        />
      });
    }
    if (truckingResources.length > 0) {
      modal?.openModal?.({
        element: <GeneralWarningModal
          onConfirm={() => {
            if (!_lockedDays.includes(unix)) {
              if (eventValue?.[0] === "r") {
                onResourceChange(parseInt(eventValue?.[1]) ?? -1);
                dispatch(removePavingResourcesToDay({ locationIndex, unix }))
                if (truckingResources.filter(tr => tr?.operationType === "paving").length > 0) {
                  dispatch(removePavingTruckingResourcesToDay({ locationIndex, unix }))
                }
                return;
              }
            }
          }}
          message="Are you sure you want to change
          the paving resource? If you continue you will loose all your paving
          entries and the trucking resources with operation type paving will be removed!"
          title="PAVING"
          yesNoButtons={true}
          onCancel={() => { }}
        />
      });
    }
    else {
      modal?.openModal?.({
        element: <GeneralWarningModal
          onConfirm={() => {
            if (eventValue?.[0] === "r") {
              onResourceChange(parseInt(eventValue?.[1]) ?? -1);
              dispatch(removePavingResourcesToDay({ locationIndex, unix }))
              return;
            }
          }}
          message="Are you sure you want to change
          the paving resource? If you continue you will loose all your paving entries!"
          title="PAVING"
          yesNoButtons={true}
          onCancel={() => { }}
        />
      });
    }
  }

  const onResourceChange = (index: number) => {
    if (index === -1) {
      setState(draft => getInitialState());
      dispatch(RemoveJob({ locationIndex, unix, indexPos, resourceType: props.resourceType, resourceID: jobInfo?.resourceID as string }));
      return;
    }
    const rc = props.resourceCodes?.[index];
    if (rc != null) {
      dispatch(RemoveJob({ locationIndex, unix, indexPos, resourceType: props.resourceType, resourceID: jobInfo?.resourceID as string }));
      dispatch(JobChange({ locationIndex, unix, indexPos, rc }));
    }
    setState(draft => {
      draft.value = `r/${index}`;
      draft.rc = rc;
      draft.qty = "";
    });
    input?.current?.focus();
  }

  const onCrewChange = (index: number) => {
    const crew = props.crews?.[index];

    setState(draft => {
      draft.crew = crew!;
    });

    showResourceAssigmentModal(crew);
  }

  const onJobChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const _eventValue = event.target.value.split("/");

    if (state.rc?.ResourceID === "TRK" && truckingResources.length > 0) {
      onPreventChangeTrk(_eventValue);
      return;
    }

    if (state.rc?.ResourceID === "PAV" && pavingResources.length > 0) {
      onPreventChangePav(_eventValue);
      return;
    }

    if (_eventValue?.[0] === "r") {
      onResourceChange(parseInt(_eventValue?.[1]) ?? -1);
      return;
    }

    if (_eventValue?.[0] === "c") {
      onCrewChange(parseInt(_eventValue?.[1]) ?? -1);
      return;
    }
  }

  const onQtyChange = (value: string) => {
    if (state.rc == null) return;
    let qty: Option["qty"] = parseInt(value);
    let qtyLenght = qty.toString().length;

    qty = isNaN(qty) ? "" : qty;
    if (qty < 0 || qtyLenght > 5 || qty === 0) { return }

    setState(draft => { draft.qty = qty; })
    dispatch(JobQTY({ locationIndex, unix, indexPos, qty, resourceType }));
  }

  const showResourceAssigmentModal = (crew: CustomCrew | null | undefined) => {
    modal?.openModal?.({
      element:
        <ResourceAssignment
          crew={crew}
          locationIndex={locationIndex}
          unix={unix}
        />
    })
  }

  const setShowTruckingAssigment = () => {
    modal?.openModal?.({
      element: <TruckingManagement
        unix={unix}
        locationIndex={locationIndex}
        jobNumber={jobNumber as number}
        canAddPavingResources={(pavingResources.length === 0 || truckingResources.filter(lr => lr?.operationType === "paving").length > 0) ? false : true}
        fromPavingManagement={false}
        onApply={(value: string | number) => {
          if (value === 0) {
            dispatch(RemoveJob({ locationIndex, unix, indexPos, resourceType: props.resourceType, resourceID: jobInfo?.resourceID as string }));
          }
          onQtyChange(value as string);
        }}
      />
    })
  }

  const setShowPavingAssigment = () => {
    modal?.openModal?.({
      element: <PavingManagement
        indexPosition={indexPos}
        unix={unix}
        locationIndex={locationIndex}
        jobNumber={jobNumber as number}
        pavingResources={pavingResources}
        onApply={(value: string | number) => { onQtyChange(value as string) }}
      />
    })
  }

  const isDisabled = (rc: Maybe<ResourceCode>) => {
    let _lockedDaysFromRefetch = checkForLockedDays();
    if ((selectedResources[rc?.ResourceID as string] != null && selectedResources[rc?.ResourceID as string] === true) || (locationIndex > 4 && rc?.ResourceID === "PAV") ||
      (locationIndex < 3 && Object.keys(laborResources).length === 9 && (!Object.keys(selectedResources).includes("PAV")) && rc?.ResourceID !== "PAV" && rc?.ResourceType as string === "Labor") ||
      (locationIndex < 3 && Object.keys(equipResources).length === 9 && !Object.keys(selectedResources).includes("TRK") && rc?.ResourceID !== "TRK" && rc?.ResourceType as string === "Equipment") ||
      ((_lockedDaysFromRefetch.includes(unix)) && (rc?.ResourceID === "PAV"))) {
      return true;
    }
    else return false;
  }

  const checkForLockedDays = () => {
    let _lockedDays: Maybe<number>[] = [];
    lockedDaysData?.superIntendentPavingCalendar.forEach((item: Maybe<SuperIntendentPavingCalendar>) => {
      if (item?.jobNumbersBlocked?.includes(jobNumber as number) || item?.status === true) _lockedDays.push(item.date)
    });
    return _lockedDays;
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", flex: 1, }}>
        <select className="Dropdown" value={state.value} onChange={onJobChange} style={{ outline: 0 }} onClick={() => refetchLockedDays()}>
          <option value={"r/-1"} />

          {props.resourceCodes?.map((rc, index) => (
            <option key={`r/${index}`} value={`r/${index}`} disabled={isDisabled(rc)}>
              {rc?.ResourceID} -- {rc?.ResourceDescription}
            </option>
          ))}

          {props.resourceType === "labor" ? <option value="--Crews--" disabled>--Custom Crews--</option> : null}

          {props.resourceType === "labor" && props.crews?.map((crew, index) => (
            <option key={`c/${index}`} value={`c/${index}`} >
              {crew?.crewCode} -- {crew?.crewDescription}
            </option>
          ))}
        </select>

        {state.rc == null
          ? <div style={labelStyle} />
          : null}

        {state.rc != null && state.rc.ResourceID !== "TRK" && state.rc.ResourceID !== "PAV"
          ? <div style={labelStyle} title={state.rc?.ResourceDescription ?? ""}>
            {state.rc?.ResourceDescription}
          </div>
          : null}

        {state.rc != null && state.rc.ResourceID === "TRK"
          ? <div style={{ ...labelStyle, color: (updatedTrk != null && updatedTrk === false) ? "red" : "blue", cursor: "pointer" }} onClick={() => setShowTruckingAssigment()} title={state.rc.ResourceCode?.toUpperCase() ?? ""}>{state.rc.ResourceCode?.toUpperCase()}</div>
          : null}

        {state.rc != null && state.rc.ResourceID === "PAV"
          ? <div style={{ ...labelStyle, color: (updatedPav != null && updatedPav === false) ? "red" : "blue", cursor: "pointer" }} onClick={() => setShowPavingAssigment()} title={state.rc.ResourceCode?.toUpperCase() ?? ""}>{state.rc.ResourceCode?.toUpperCase()}</div>
          : null}

        <input
          ref={input}
          className="Quantity"
          type="number"
          value={state.qty}
          onChange={e => onQtyChange(e.target.value)}
          style={{
            borderColor: state.rc != null && (state.qty === "" || (state.qty === 0 && state.rc.ResourceID !== "TRK")) ? 'red' : 'black',
            outline: 0,
          }}
          readOnly={(state?.rc?.ResourceID === "TRK" || state?.rc?.ResourceID === "PAV") ? true : false}
        />
      </div>
    </>
  );
};

const MemoJobSelect = memo(JobSelect);

const EmptyJobSelectElement = <MemoJobSelect
  indexPos={-1}
  locationIndex={-1}
  jobInfo={null}
  resourceCodes={[]}
  resourceType={"equipment"}
  unix={0}
  onReloadLockedDays={() => { }}
/>

const LasyJobSelect: FC<Props> = props => {

  const [isActivated, setIsActivated] = useState(false);

  const jobInfo = useSelector((state: RootState) =>
    state.app.schedule?.locations?.[props.locationIndex]?.daysByUnix?.[props.unix]
      ?.[props.resourceType === "equipment" ? "equip" : "labor"]?.jobInfos?.[props?.indexPos ?? -1]);

  const activate = () => {
    if (isActivated) return;
    setIsActivated(true);
  }

  return (
    <div
      onClick={activate}
      onMouseOver={activate}
    >
      {
        jobInfo != null || isActivated
          ? <MemoJobSelect {...props} jobInfo={jobInfo} />
          : EmptyJobSelectElement
      }
    </div>
  );

}

export default memo(LasyJobSelect);