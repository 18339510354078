import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";
import to from "await-to-js";
import React, { FC, useContext, useEffect, useState } from "react"
import { ADD_PAVING_SUPER } from "../../../graphql/mutations/ADD_PAVING_SUPER";
import { CHANGE_USER_MUTATION } from "../../../graphql/mutations/CHANGE_USER_MUTATION";
import { JOB_INFOS_BY_DISABLED_USER } from "../../../graphql/queries/JOB_INFOS_BY_DISABLED_USER";
import { PAVING_SUPERS_QUERY } from "../../../graphql/queries/PAVING_SUPERS_QUERY";
import { Query } from "../../../graphql/schema-types";
import { CloseButton } from "../../Form/CloseButton";
import { FullScreenErrorIndicator } from "../../Modal/ErrorIndicator/FullScreenErrorIndicator";
import { FullScreenLoadingIndicator } from "../../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { ModalContext } from "../../Modal/ModalContext/ModalContext";
import { GeneralWarningModal } from "../../Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import { DisableUser } from "./DisableUser/DisableUser";
import "./UsersManagement.css";

interface IUsersManagementProps {
  onClose?(): void;
}

export const UsersManagement: FC<IUsersManagementProps> = (props) => {
  const modal = useContext(ModalContext);

  const [user, setUser] = useState({
    email: "",
    name: ""
  });

  const mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-]/;

  const { data, loading, error, refetch } = useQuery<Pick<Query, "pavingSupers">>(PAVING_SUPERS_QUERY);

  const [addPavingSuper] = useMutation(ADD_PAVING_SUPER, { onCompleted: refetch });

  const [changeUser] = useMutation(CHANGE_USER_MUTATION, { onCompleted: refetch });

  const client = useApolloClient();

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.removeAttribute('style')
    }
  }, [])

  const pavingSuperEmail = data != null ? data?.pavingSupers.map(ps => ps?.email) : [];

  const onAddUser = async () => {
    if (user.name !== "" && user.email !== "" && pavingSuperEmail?.includes(user.email as string) === false && mailFormat.test(user.email)) {
      const [errorAddPavingSuper,] = await to(
        addPavingSuper({
          variables: {
            where: {
              email: user.email,
              name: user.name
            }
          }
        })
      )
      if (errorAddPavingSuper != null) {
        modal?.openModal?.({
          element: (
            <GeneralWarningModal
              message={errorAddPavingSuper?.toString()}
              title="Error"
              yesNoButtons={false}
            />
          ),
        });
      }
    }
    else if (pavingSuperEmail?.includes(user.email as string) === true) {
      modal?.openModal?.({
        element: (
          <GeneralWarningModal
            message={
              `A user can be added only once.`
            }
            title="This user is already in the group."
            yesNoButtons={false}
          />
        ),
      });
    }
    else {
      modal?.openModal?.({
        element: (
          <GeneralWarningModal
            message={
              `Please provide a valid email.`
            }
            title="Invalid email"
            yesNoButtons={false}
          />
        ),
      });
    }
  }

  const onDisableUser = async (currentUser: string, currentUserEmail: string) => {
    const [, response] = await to(
      client.query({
        query: JOB_INFOS_BY_DISABLED_USER, variables: { disabledUserName: currentUser }
      })
    )
    if (response?.data.jobInfosByDisabledUser.length > 0) {
      modal?.openModal?.({
        element: (
          <DisableUser
            activeUsers={data?.pavingSupers.filter(ps => ps?.disabled === false)}
            userNameWeWantToDisable={currentUser}
            userEmailWeWantToDisable={currentUserEmail}
            refetch={() => refetch()}
            jobInfosByDisabledUser={response?.data.jobInfosByDisabledUser}
          />
        ),
      });
    }
    else {
      await to(changeUser({ variables: { where: { disabledUser: currentUser, disabledUserEmail: currentUserEmail, updateUser: "", disabled: true, jobInfosByDisabledUser: [] } } }));
    }
  }

  const onEnableUser = async (currentUser: string, currentUserEmail: string) => {
    if (currentUser == null || currentUser === "" || currentUserEmail == null || currentUserEmail === "") return;
    await to(changeUser({ variables: { where: { disabledUser: currentUser, disabledUserEmail: currentUserEmail, updateUser: "", disabled: false, jobInfosByDisabledUser: [] } } }));
  }

  const onChangeEmail = (email: string) => {
    const _name = email.split("@")[0].toUpperCase();
    const name = _name.slice(0, 1) + " " + _name.slice(1);
    setUser({ email, name })
  }


  return (
    <div className="Users_Management">
      {loading && <FullScreenLoadingIndicator />}
      {error != null && <FullScreenErrorIndicator />}
      <div className="Users_Management_Header">
        <div className="Date_Wrap">
          <img src={process.env.PUBLIC_URL + '/logo.png'} alt="graniterock" />
          <div className="Title">Users Management</div>
        </div>
        <CloseButton onClick={props.onClose} />
      </div>
      <div className="Add_Super">
        <div className="Wrap_Inputs">
          <input
            type="text"
            value={user.email}
            placeholder="Email"
            onChange={(e) => { onChangeEmail(e.target.value) }}
          />
        </div>
        <button onClick={() => { onAddUser() }} className="Add_Super_Button">
          Add Super
        </button>
      </div>
      <div className="Table">
        <div className="Table_Header">
          <div className="Header_Item">Name</div>
          <div className="Header_Item">Email</div>
          <div className="Header_Item">Actions</div>
        </div>
        <div className="Table_Content">
          {
            data?.pavingSupers.map((ps, index) => (
              <div className="Row" key={index}>
                <div className="Row_Item">{ps?.name}</div>
                <div className="Row_Item">{ps?.email}</div>
                <div className="Row_Item">
                  {
                    ps?.disabled === true
                      ?
                      <button className="Disable_User_Button" onClick={() => { onEnableUser(ps?.name as string, ps?.email as string) }}>enable user</button>
                      :
                      <button className="Disable_User_Button" onClick={() => { onDisableUser(ps?.name as string, ps?.email as string) }}>disable user</button>
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}