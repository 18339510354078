import gql from "graphql-tag";

export const ITEMS_IN_PAVING_CALENDAR_QUERY = gql`
  query itemsInPavingCalendar($date: Date!) {
    itemsInPavingCalendar(date: $date) {
      uID
      date
      jobNumber
      jobName
      manager
      tonnage
      plant
      nrOfTrucks
      typeOfTrucks
      broker
      material
      operationType
      shift
      locationIndex
    }
  }
`;