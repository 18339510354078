import gql from "graphql-tag";

export const JOB_INFOS_BY_DISABLED_USER = gql`
  query jobInfosByDisabledUser($disabledUserName: String!){
    jobInfosByDisabledUser(disabledUserName: $disabledUserName) {
      uID
      jobNumber_Date
      searchGSI_JobNumber
      tableauGSI_Tableau
      date  
      areaManager
      jobName
      pmpe
      superIntendent
      foreman
      resourceName
      resourceID
      resourceType
      resourceQTY  
      description
      additionalResourcesComments
      locationIndex  
      pavingForeman
      pavingSuperIntendent
      jobMap
    }
  }
`;
