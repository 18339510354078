import { Maybe, SuperIntendentPavingCalendarDatas } from "../graphql/schema-types";

export interface ISuperIntendentPavingCalendarDatasByDateAndSuperIntendentName {
  [date: string]: {
    [foreman: string]: Maybe<SuperIntendentPavingCalendarDatas>[];
  }
}

export interface IDayItemsLength {
  [date: string]: { itemsLength: number }
}

export interface IState {
  filteredItems: ISuperIntendentPavingCalendarDatasByDateAndSuperIntendentName
  dayItemsLength: IDayItemsLength
}

export const filteredItems = (days: string[], pavingForemans: string[], items: Maybe<SuperIntendentPavingCalendarDatas>[]): IState => {
  const mappedItems: ISuperIntendentPavingCalendarDatasByDateAndSuperIntendentName = {};
  const dayItemsLength: IDayItemsLength = {}

  const init = (day: string, foreman: string) => {
    if (mappedItems[day] == null) {
      mappedItems[day] = {};
    }

    if (mappedItems[day][foreman] == null) {
      mappedItems[day][foreman] = [];
    }
  }

  const initDayItemsLength = (day: string) => {
    if (dayItemsLength[day] == null) {
      dayItemsLength[day] = {
        itemsLength: 0
      };
    }
  }

  days?.forEach(day => {
    const itemsFromCurrentDay = items.filter(item => item != null && item.date === parseInt(day));

    pavingForemans?.forEach(foreman => {
      const itemsFromForeman = itemsFromCurrentDay.filter(item => item?.pavingForeman === foreman);

      itemsFromForeman.forEach(item => {
        init(day, foreman);

        mappedItems[day][foreman].push(item);
      });

      if (itemsFromForeman.length > 0) {
        initDayItemsLength(day);
        if (dayItemsLength[day].itemsLength < itemsFromForeman.length) {
          dayItemsLength[day].itemsLength = itemsFromForeman.length;
        }
      }
    });

    const itemsUnassigned = itemsFromCurrentDay.filter(item => item?.pavingForeman == null || item.pavingForeman === " ");

    if (itemsUnassigned.length > 0) {
      initDayItemsLength(day);
      if (dayItemsLength[day].itemsLength < itemsUnassigned.length) {
        dayItemsLength[day].itemsLength = itemsUnassigned.length;
      }
    }

    itemsUnassigned?.forEach(item => {
      init(day, "Unassgined");

      mappedItems[day]["Unassgined"].push(item);
    });

  });
  return {
    filteredItems: mappedItems,
    dayItemsLength
  };
}