import gql from "graphql-tag";

export const DATES_PAVING_CALENDAR_QUERY_JN = gql`
  query datesJNInPavingCalendar($data: PavingCalendarQueryInput!) {
    datesJNInPavingCalendar(data: $data) {
      uID
      date
      jobNumber
      jobName
      manager
      tonnage
      plant
      nrOfTrucks
      typeOfTrucks
      broker
      material
      operationType
      constant
      shift
      locationIndex
    }
  }
`;