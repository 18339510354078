import React from "react";
import { loadingContext, ILoadingEventsByType, IEmitterEventArgument, EmitterEvent } from "../LoadingContext/LoadingContext";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { Backdrop } from "../Backdrop/Backdrop";
import { ErrorIndicator } from "../ErrorIndicator/ErrorIndicator";
import css from "./LoadingHub.module.css";
import Portal from "../Portal";

interface ILoadingHubProps { }

export class LoadingHub extends React.PureComponent<ILoadingHubProps> {
  static contextType = loadingContext;
  context: React.ContextType<typeof loadingContext> | null | undefined;

  events: ILoadingEventsByType = {};

  // could not use setState because of its asynchronous nature
  setEvents = (events: ILoadingEventsByType) => {
    this.events = events;
    this.forceUpdate();
  }

  onLoading = (arg: IEmitterEventArgument) => {
    this.setEvents({
      ...this.events,
      [arg.id]: arg
    });
  }

  onCompleted = (arg: IEmitterEventArgument) => {
    const events = { ...this.events };
    delete events[arg.id];
    this.setEvents(events);
  }

  onError = (arg: IEmitterEventArgument) => {
    this.setEvents({
      ...this.events,
      [arg.id]: arg
    });
  }

  dismissAllErrors = () => {
    const events = { ...this.events };
    Object.keys(events).forEach(key => {
      if (events[key].event !== EmitterEvent.ERROR) {
        return;
      }
      delete events[key];
    });
    this.setEvents(events);
  }

  onDismissError = this.onCompleted;

  componentDidMount() {
    if (this.context == null) return;
    this.context.emitter.on(EmitterEvent.LOADING, this.onLoading);
    this.context.emitter.on(EmitterEvent.COMPLETED, this.onCompleted);
    this.context.emitter.on(EmitterEvent.ERROR, this.onError);
    this.context.emitter.on(EmitterEvent.DISMISS_ERROR, this.onDismissError);
  }

  componentWillUnmount() {
    if (this.context == null) return;
    this.context.emitter.off(EmitterEvent.LOADING, this.onLoading);
    this.context.emitter.off(EmitterEvent.COMPLETED, this.onCompleted);
    this.context.emitter.off(EmitterEvent.ERROR, this.onError);
    this.context.emitter.off(EmitterEvent.DISMISS_ERROR, this.onDismissError);
  }

  getEventsByType() {
    let loadingEvents: ILoadingEventsByType = {};
    let errorEvents: ILoadingEventsByType = {};
    Object
      .keys(this.events)
      .forEach(key => {
        if (this.events[key].event === EmitterEvent.LOADING) {
          loadingEvents[key] = this.events[key];
        }
        if (this.events[key].event === EmitterEvent.ERROR) {
          errorEvents[key] = this.events[key];
        }
      });
    return ({
      loadingEvents,
      errorEvents,
    });
  }

  render() {
    const { loadingEvents, errorEvents } = this.getEventsByType();

    const isLoadingIndicatorVisible = Object.keys(loadingEvents).length > 0;
    const isErrorIndicatorVisible = Object.keys(errorEvents).length > 0;

    return (
      <Portal selector="#loading-root">
        {isErrorIndicatorVisible || isLoadingIndicatorVisible
          ? <Backdrop className={`${css.center} ${css.backdrop}`}>
            {isLoadingIndicatorVisible
              ? <LoadingIndicator />
              : isErrorIndicatorVisible
                ? <ErrorIndicator
                  onDismissAllErrors={this.dismissAllErrors}
                  events={errorEvents}
                />
                : null}
          </Backdrop>
          : null}
      </Portal>
    );
  }
}
