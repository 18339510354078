import React, { FC, CSSProperties } from "react";
import css from "./Backdrop.module.css";

type Props = {
  style?: CSSProperties;
  className?: string;
}

export const Backdrop: FC<Props> = props => {
  return (
    <div className={`${css.container} ${props.className}`} style={props.style}>
      {props.children}
    </div>
  );
}
