import { FC, useEffect } from "react";
import React from "react";
import { logout } from "../../redux/appSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom"
import { settings } from "../../config/settings";


export const LogOut: FC = props => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout());
  }, [dispatch])

  const onSubmit = () => {
    // eslint-disable-next-line no-restricted-globals
    location.href = settings.redirectUri;
  }

  return (
    <div className="First_select" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/gr-com_construction-dispatch_background.png'})` }}>


      <div className="wrap_holder">
        <div className="Select_holder">
          <Link to="/" className="submit log_out" onClick={onSubmit}>Login</Link>
        </div>
      </div>
    </div>

  );
}