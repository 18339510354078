import React, { FC, useEffect, memo, useContext, useState } from 'react';
import Header from './Header';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../redux/store';
import { useHistory, Prompt } from 'react-router-dom';
import ScheduleTable from './ScheduleTable';
import { init, addCustomCrews, getWeatherDataAsync } from '../redux/appSlice';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Maybe, Query, WeatherContentData, WeatherData } from '../graphql/schema-types';
import { JOB_INFO_QUERY } from '../graphql/queries/JOB_INFO_QUERY';
import { RESOURCE_CODES_QUERY } from '../graphql/queries/RESOURCE_CODES_QUERY';
import { CREWS_QUERY } from '../graphql/queries/CREWS_QUERY';
import { ScrollSync } from 'scroll-sync-react';
import moment from 'moment';
import { JOB_INFO_LOCATIONS_QUERY } from '../graphql/queries/JOB_INFO_LOCATIONS_QUERY';
import { FullScreenLoadingIndicator } from './Modal/LoadingIndicator/FullScreenLoadingIndicator';
import { FullScreenErrorIndicator } from './Modal/ErrorIndicator/FullScreenErrorIndicator';
import { ModalContext } from './Modal/ModalContext/ModalContext';
import LocationSelect from './Warnings & errors/Locations/LocationSelect';
import { MASTER_CREW_SCHEDULES_PAVING_QUERY } from '../graphql/queries/MASTER_CREW_SCHEDULES_PAVING_QUERY';
import { MASTER_CREW_SCHEDULES_QUERY } from '../graphql/queries/MASTER_CREW_SCHEDULES_QUERY';
import { MATERIAL_DATAS_QUERY } from '../graphql/queries/MATERIAL_DATAS_QUERY';
import { SUPER_INTENDENT_PAVING_CALENDAR_QUERY } from '../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_QUERY';
import { WEATHER_QUERY } from '../graphql/queries/WEATHER_QUERY';
import { WEATHER_CONTENT_QUERY } from '../graphql/queries/WEATHER_CONTENT_QUERY';
import { ADD_WEATHER } from '../graphql/mutations/ADD_WEATHER';
import to from 'await-to-js';
import { AddLocation } from './Warnings & errors/Locations/AddLocation/AddLocation';

interface INotificationIndicatorProps {
  onClose(): void;
}

const getEndDate = (days: number | "", beginDate: string) => {
  return (
    typeof days === "number"
      ? days === 0
        ? moment.utc(beginDate, "YYYY-MM-DD").valueOf()
        : moment.utc(beginDate, "YYYY-MM-DD").add(days - 1, 'days').valueOf()
      : moment.utc(beginDate, "YYYY-MM-DD").valueOf()
  );
}

export const Schedule: FC<INotificationIndicatorProps> = props => {
  const [didInit, setDidInit] = useState<boolean>(false);

  const isDirty = useSelector((state: RootState) => Object.keys(state.app.modifiedDayByLocation).length > 0);
  const isDirtyLocationInfo = useSelector((state: RootState) => Object.keys(state.app.modifiedLocationInfo).length > 0);

  const {
    beginDate, jobNumber, days,
    validBeginDate, validDays, validJobNumber,
  } = useSelector((state: RootState) => state.app.start);

  const locations = useSelector((state: RootState) => Object.keys(state.app.schedule.locations));
  const jobNumberInformation = useSelector((state: RootState) => state.app.jobNumberInformations);


  const dispatch = useDispatch();
  const modal = useContext(ModalContext);


  const { data: weatherData, loading: weatherDataLoading } = useQuery<Pick<Query, "weatherValues">>(WEATHER_QUERY, { fetchPolicy: "no-cache" });
  const { data: weatherContentsData, loading: weatherContentsDataLoading } = useQuery<Pick<Query, "weatherContent">>(WEATHER_CONTENT_QUERY, { fetchPolicy: "no-cache" });

  const { loading: jobInfoLocationsLoading, error: jobInfoLocationsError, data: jobInfoLocationsData } = useQuery<Pick<Query, "jobInfoLocations">>(JOB_INFO_LOCATIONS_QUERY, {
    variables: {
      where: {
        jobNumber
      }
    },
    skip: jobNumber == null || jobNumber === "",
    fetchPolicy: "no-cache"
  });

  const { loading: jobInfoLoading, error: jobInfoError, data: jobInfoData } = useQuery<Pick<Query, "jobInfo">>(JOB_INFO_QUERY, {
    variables: {
      where: {
        jobNumber,
        startDate: (moment.utc(beginDate, "YYYY-MM-DD").unix()) * 1000,
        endDate: getEndDate(days, beginDate)
      }
    },
    skip: jobNumber == null || jobNumber === "" || beginDate == null || beginDate === "" || days == null,
    fetchPolicy: "no-cache"
  });

  const { loading: resourceCodesLoading, error: resourceCodesError, data: resourceCodesData } = useQuery<Pick<Query, "resourceCodes">>(RESOURCE_CODES_QUERY, { fetchPolicy: "no-cache" });

  const { loading: customCrewsLoading, error: customCrewsError, data: customCrewsData } = useQuery<Pick<Query, "crews">>(CREWS_QUERY, {
    variables: { jobNumber },
    skip: jobNumber == null || jobNumber === "",
    fetchPolicy: "no-cache"
  });

  const { loading: pavingCrewLoading, error: pavingCrewError, data: pavingCrewData } = useQuery<Pick<Query, "masterCrewSchedulesPaving">>(MASTER_CREW_SCHEDULES_PAVING_QUERY, {
    variables: {
      where: {
        jobNumber,
        startDate: (moment.utc(beginDate, "YYYY-MM-DD").unix()) * 1000,
        endDate: getEndDate(days, beginDate)
      }
    },
    skip: jobNumber == null || jobNumber === "" || beginDate == null || beginDate === "" || days == null,
    fetchPolicy: "no-cache"
  });

  const { loading: truckingCrewLoading, error: truckingCrewError, data: truckingCrewData } = useQuery<Pick<Query, "masterCrewSchedules">>(MASTER_CREW_SCHEDULES_QUERY, {
    variables: {
      where: {
        jobNumber,
        startDate: (moment.utc(beginDate, "YYYY-MM-DD").unix()) * 1000,
        endDate: getEndDate(days, beginDate)
      }
    },
    skip: jobNumber == null || jobNumber === "" || beginDate == null || beginDate === "" || days == null,
    fetchPolicy: "no-cache"
  });

  const { loading: lockedDaysLoading, error: lockedDaysError, data: lockedDaysData } = useQuery<Pick<Query, "superIntendentPavingCalendar">>(SUPER_INTENDENT_PAVING_CALENDAR_QUERY, {
    variables: {
      where: {
        startDate: (moment.utc(moment().startOf('month').format("YYYY-MM-DD")).unix()) * 1000,
        endDate: (moment.utc(moment().startOf('month').add(6, 'months').format("YYYY-MM-DD")).unix()) * 1000,
      }
    },
    fetchPolicy: "no-cache"
  });

  const { data: materials, loading: materialLoading, error: materialErrors } = useQuery<Pick<Query, "materialDatas">>(MATERIAL_DATAS_QUERY, { fetchPolicy: "no-cache" });

  const [addWeather] = useMutation(ADD_WEATHER);

  useEffect(() => {
    const callWeatherMutation = async () => {
      if (jobNumberInformation?.coordinateLat != null && jobNumberInformation?.coordinateLong != null && jobNumber !== "") {
        await to(addWeather(
          {
            variables:
            {
              where:
              {
                lat: jobNumberInformation?.coordinateLat,
                long: jobNumberInformation?.coordinateLong,
                jobNumber
              }
            }
          }
        ));
      }
    }

    callWeatherMutation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobNumberInformation])

  useEffect(() => {
    if (!didInit) return;
    if (customCrewsData == null || customCrewsData.crews == null) return;

    dispatch(addCustomCrews({ customCrews: customCrewsData.crews }))
  }, [customCrewsData, didInit, dispatch])

  useEffect(() => {
    if (didInit) return;

    if (jobInfoData == null || jobInfoData.jobInfo == null) return;
    if (resourceCodesData == null || resourceCodesData.resourceCodes == null) return;
    if (jobInfoLocationsData == null || jobInfoLocationsData.jobInfoLocations == null) return;
    if (pavingCrewData == null || pavingCrewData.masterCrewSchedulesPaving == null) return;
    if (truckingCrewData == null || truckingCrewData.masterCrewSchedules == null) return;
    if (materials == null || materials.materialDatas == null) return;
    if (lockedDaysData == null || lockedDaysData.superIntendentPavingCalendar == null) return;

    let _lockedDays: Maybe<number>[] = [];

    lockedDaysData?.superIntendentPavingCalendar.forEach(item => {
      if (item?.jobNumbersBlocked?.includes(jobNumber as number) || item?.status === true) _lockedDays.push(item.date)
    })

    dispatch(init({
      jobInfos: jobInfoData.jobInfo.filter(j => j?.resourceID !== "ACP"),
      resourceCodes: resourceCodesData.resourceCodes.filter(r => r?.ResourceID !== "ACP"),
      jobInfoLocationsData: jobInfoLocationsData.jobInfoLocations,
      pavingCrewData: pavingCrewData.masterCrewSchedulesPaving,
      truckingCrewData: truckingCrewData.masterCrewSchedules,
      materialDatas: materials.materialDatas,
      lockedDays: _lockedDays as Maybe<number>[]
    }));

    setDidInit(true);
    if (jobNumberInformation?.coordinateLat !== '0' && jobNumberInformation?.coordinateLong !== '0' && jobNumber !== "") {
      dispatch(getWeatherDataAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobInfoData, resourceCodesData, jobInfoLocationsData, pavingCrewData, dispatch, truckingCrewData, didInit, materials, lockedDaysData]);

  const history = useHistory();

  const isValidForm = () => {
    return validBeginDate && validDays && validJobNumber;
  }

  useEffect(() => {
    if (!isValidForm()) {
      history.push("/start");
      return;
    }
  }, [beginDate, jobNumber, days]); // eslint-disable-line

  useEffect(() => {
    const handler = (e: BeforeUnloadEvent) => {
      const confirmationMessage = "Are you sure you want to leave this page??";

      if (!isDirty && !isDirtyLocationInfo) return true;

      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }

    window.addEventListener("beforeunload", handler);
    return () => {
      window.removeEventListener("beforeunload", handler);
    }
  }, [isDirty, isDirtyLocationInfo])

  if (!isValidForm()) return null;

  const openNotificationModal = () => {

    modal?.openModal({
      element: <AddLocation />
    });
  }

  if (jobInfoLoading || resourceCodesLoading || customCrewsLoading || jobInfoLocationsLoading || pavingCrewLoading || truckingCrewLoading || materialLoading || lockedDaysLoading || weatherDataLoading || weatherContentsDataLoading) {
    return <FullScreenLoadingIndicator />;
  }

  if ((jobInfoError != null || resourceCodesError != null || customCrewsError != null || jobInfoLocationsError != null || pavingCrewError != null || truckingCrewError != null || materialErrors != null || lockedDaysError != null)) {
    return <FullScreenErrorIndicator />;
  }

  const isDataAdded = () => {
    if (isDirty) {
      return true;
    }
    if (isDirtyLocationInfo) {
      return true;
    }
    return false;
  }

  return (
    <div className="App" >
      <Prompt
        when={isDataAdded()}
        message={location => `Are you sure you want to leave this page?`}
      />
      <Header />
      <button className="button button-location" style={{ marginLeft: '10px', marginBottom: '5px' }} onClick={() => openNotificationModal()}><i className="fas fa-plus"></i> Create New Location</button>
      <LocationSelect
      />
      <ScrollSync>
        <div>
          {locations.map((loc, index) => <ScheduleTable locationIndex={index + 1} key={index} weatherContent={weatherContentsData?.weatherContent as WeatherContentData} weatherValues={weatherData?.weatherValues as WeatherData[]} />)}
        </div>
      </ScrollSync>
    </div>
  );
};

export default memo(Schedule);
