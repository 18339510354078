import "./EquipmentListManagement.css";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { EQUIPMENT_SUBCATEGORIES_QUERY } from "../../../graphql/queries/EQUIPMENT_SUBCATEGORIES";
import { EQUIPMENT_SUBCATEGORIES_FULL_LIST } from "../../../graphql/queries/EQUIPMENT_SUBCATEGORIES_FULL_LIST";
import { Maybe, Query, RemoteEquipment } from "../../../graphql/schema-types";
import { toMap } from "../../../utils/toMap";
import { CheckBox } from "../../Form/CheckBox";
import { CloseButton } from "../../Form/CloseButton";
import { FullScreenErrorIndicator } from "../../Modal/ErrorIndicator/FullScreenErrorIndicator";
import { FullScreenLoadingIndicator } from "../../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useImmer } from "use-immer";
import { ModalContext } from "../../Modal/ModalContext/ModalContext";
import { GeneralWarningModal } from "../../Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import { CHANGE_CONTENT_FROM_FILE } from "../../../graphql/mutations/CHANGE_CONTENT_FROM_FILE";
import to from "await-to-js";
import { DraggableItem } from "./DraggableItem";
import { ItemTypesEquip } from "./ItemTypes";
import update from 'immutability-helper';
import { REFRESH_EQUIPMENT_RESOURCES } from "../../../graphql/mutations/REFRESH_EQUIPMENT_RESOURCES";
interface IEquipmentListManagement {
  onClose?(): void;
}

type IState = {
  subCategoryId: string,
  subCategoryDescription: string,
  assignByIndividual: boolean | null,
  isRed: boolean | null
}

export const EquipmentListManagement: FC<IEquipmentListManagement> = (props) => {
  const [state, setState] = useImmer<IState[]>([]);
  const [newSubcategoryId, setNewSubcategoryId] = useState<string>("");
  const [remoteEquipById, setRemoteEquipById] = useState<{ [key: string]: Maybe<RemoteEquipment> }>({});

  const { data, loading, error } = useQuery<Pick<Query, "equipmentSubcategories">>(EQUIPMENT_SUBCATEGORIES_QUERY, { fetchPolicy: "no-cache" });
  const { data: equipmentFullList, loading: equipmentFullListLoading, error: equipmentFullListError } = useQuery<Pick<Query, "equipmentSubcategoriesFullList">>(EQUIPMENT_SUBCATEGORIES_FULL_LIST, { fetchPolicy: "no-cache" });

  const [changeContentFromFile, { loading: loadingChangeContentFromFile }] = useMutation(CHANGE_CONTENT_FROM_FILE);
  const [refreshEquipmentResources, { loading: loadingRefreshEquipmentResources }] = useMutation(REFRESH_EQUIPMENT_RESOURCES);

  const remoteEquip = useSelector((state: RootState) => state.app.remoteEquipmentList);
  const modal = useContext(ModalContext);

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.removeAttribute('style')
    }
  }, [])

  useEffect(() => {
    window.onpopstate = (e: any) => {
      props?.onClose?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const res = toMap(remoteEquip ?? [], e => e?.id ?? "");
    setRemoteEquipById(res);
  }, [remoteEquip])

  useEffect(() => {
    if (equipmentFullList == null) return;
    if (data == null) return;
    
    const equipList = equipmentFullList.equipmentSubcategoriesFullList.split(",");
    const equipmentsForCheckbox = data?.equipmentSubcategories?.equipmentsForCheckbox?.split(",")

    // the list of subcategory id's came from backend as a list in a string
    // after every subcategory id we put comma to separate them
    // if the string doesn't include one comma, it means that the list is empty

    if (equipList?.length > 0) {
      setState(draft => {
        equipList.forEach((e) => {
          if(remoteEquipById?.[e]?.description !== undefined){
          draft.push({
            assignByIndividual: equipmentsForCheckbox?.includes(e) ? true : false,
            subCategoryDescription: remoteEquipById?.[e]?.description as string,
            subCategoryId: e,
            isRed: remoteEquipById?.[e] == null && remoteEquipById?.[e]?.description === "" ? true : false
          })
        }
      })
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, equipmentFullList, remoteEquipById]);

  useEffect(() => {
    const subCategoryIdsFromState = state.map(s => s.subCategoryId);
    if (subCategoryIdsFromState?.includes(newSubcategoryId)) {

      modal?.openModal?.({
        element: <GeneralWarningModal
          message="The subcategory is already added to list. Please check again"
          title="Equipment List Management"
          yesNoButtons={false}
          onCancel={() => { }}
        />
      });
      setNewSubcategoryId("");
    }

    if (newSubcategoryId !== "" && !subCategoryIdsFromState?.includes(newSubcategoryId)) {
      const itemDescription: string | undefined = remoteEquipById?.[newSubcategoryId]?.description?.toString();

      const item: IState = {
        assignByIndividual: false,
        subCategoryDescription: (itemDescription != null || itemDescription !== "") ? itemDescription as string : "",
        subCategoryId: newSubcategoryId,
        isRed: itemDescription == null || itemDescription === "" ? true : false
      }

      setState(draft => {
        draft.push(item);
      })
      setNewSubcategoryId("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSubcategoryId, setNewSubcategoryId, remoteEquipById])

  const onAssignByIndividualChange = (index: number, checked: boolean) => {
    setState(draft => {
      if (draft?.[index] != null) {
        draft[index].assignByIndividual = checked;
      }
    })
  }

  const onDeleteCheck = (index: number, subcategoryId: string) => {
    modal?.openModal?.({
      element: <GeneralWarningModal
        message={"Are you sure you want to delete the item with subcategory" + subcategoryId}
        title="Equipment List Management"
        yesNoButtons={true}
        onConfirm={() => onDeleteChange(index)}
        onCancel={() => { }}
      />
    })
  }

  const onDeleteChange = (index: number) => {
    setState(draft => {
      if (draft?.[index] != null) {
        draft.splice(index, 1);
      }
    })
  }

  const onBlurEvent = (value: string) => {
    const trimmedValue = value.trim();
    if (trimmedValue != null && trimmedValue !== " " && trimmedValue.length > 1) {
      setNewSubcategoryId(trimmedValue.toUpperCase())
    }
  }

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    const dragCard = state?.[dragIndex];

    setState(draft => {
      return update(draft, {
        $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const isDisabled = useCallback(() => {
    const hasRedItems = state.filter(s => s.isRed === true);

    if (hasRedItems.length > 0) {
      return true
    }
    else {
      return false;
    }
  }, [state])

  const onSubmit = async () => {
    const contentForFullList = state
      .filter((item, index) => state.indexOf(item) === index)
      .map((item) => item.subCategoryId)
      .join(',');
    const contentForCheckBoxList = state
      .filter((item, index) => {
        return item.assignByIndividual === true && state.indexOf(item) === index
      })
      .map(i => i.subCategoryId)
      .join(',');
    const contentForInputList = state
      .filter((item, index) => {
        return item.assignByIndividual === false && state.indexOf(item) === index
      })
      .map(i => i.subCategoryId)
      .join(',');

    const [error, response] = await to(changeContentFromFile({
      variables: {
        where:
          [
            {
              content: contentForFullList,
              fileName: 'fullList.txt'
            },
            {
              content: contentForCheckBoxList,
              fileName: 'checkboxList.txt'
            },
            {
              content: contentForInputList,
              fileName: 'inputList.txt'
            },
          ]
      }
    }));

    if (error != null && error.message != null) {
      modal?.openModal?.({
        element: <GeneralWarningModal
          message="There was a problem saving the modifications, please try again."
          title="Equipment List Management"
          yesNoButtons={false}
        />
      })
    }

    if (response && error == null) {
      modal?.openModal?.({
        element: <GeneralWarningModal
          message="The list was updated with success. In a few seconds the page will refresh."
          title="Equipment List Management"
          yesNoButtons={false}
        />
      })
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }

  const onRefreshEquipCheck = () => {
    modal?.openModal?.({
      element: <GeneralWarningModal
        message="Are you sure you want to refresh the equipments from database?"
        title="Refresh Equipment List"
        yesNoButtons={true}
        onConfirm={() => onRefreshEquip()}
        onCancel={() => { }}
      />
    })
  }

  const onRefreshEquip = async () => {

    const [error, response] = await to(refreshEquipmentResources());

    if (response && error == null) {
      modal?.openModal?.({
        element: <GeneralWarningModal
          message="The list was updated with success. In a few seconds the page will refresh."
          title="Equipment List Management"
          yesNoButtons={false}
        />
      })
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }

  return (
    <>
      {(loading || equipmentFullListLoading || loadingChangeContentFromFile || loadingRefreshEquipmentResources) && <FullScreenLoadingIndicator />}
      {(error != null || equipmentFullListError != null) && <FullScreenErrorIndicator />}
      <div className="Equipment_List_Management">
        <div className="Equipment_List_Management_Header">
          <div className="Date_Wrap">
            <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
          </div>
          <CloseButton onClick={() => props.onClose?.()} />
        </div>
        <div className="middle-content">
          <div className="List_Header">
            <div className="header_item">Sort Order</div>
            <div className="header_item">Subcat ID</div>
            <div className="header_item">Subcat Description</div>
            <div className="header_item">Assign by individual equipment ID</div>
            <div className="header_item">Delete</div>
          </div>
          <div className="List_Table">
            <>
              {
                state.length > 0 && state?.map((s, index) => (
                  <DraggableItem
                    noPreview
                    key={`${s.subCategoryId}-${index}`}
                    index={index}
                    hasSubcategoryDescription={s.subCategoryDescription !== "" ? true : false}
                    moveCard={moveCard}
                    accept={ItemTypesEquip.EQUIPMENT}
                    subcategoryDescription={s.subCategoryDescription}
                    subcategoryId={s.subCategoryId}
                  >
                    {(ref, isDragging) => {
                      const opacity = isDragging ? 0 : 1;
                      return (
                        <div className="Row_List" ref={ref} style={{ opacity, cursor: "move" }}>
                          <div className="header_item">{index + 1 as number}</div>
                          <div className="header_item" style={{ "color": s.isRed === true ? "red" : "#747474" }}>
                            {s?.subCategoryId as string}
                          </div>
                          <div className="header_item">{s?.subCategoryDescription as string}</div>
                          <CheckBox
                            className="header_item"
                            key={index + "individual"}
                            name={"individual" + index}
                            onChange={(e) => { onAssignByIndividualChange(index, e.target.checked) }}
                            checked={state?.[index]?.assignByIndividual as boolean || false}
                            disabled={false}
                          />
                          <div className="header_item">
                            <button
                              className="Delete_Button"
                              onClick={() => onDeleteCheck(index, s.subCategoryId)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      )
                    }}
                  </DraggableItem>
                ))
              }
            </>
          </div>
          <button
            className="Button Refresh_Resources"
            onClick={() => onRefreshEquipCheck()}
            disabled={state.length === 0 ? true : false}>Refresh equipments
          </button>
          <AddNewSubcategory onBlur={onBlurEvent} />
        </div>
        <div className="Actions_Holder_Equipment_List_Management">
          <div className="buttons">
            <button className="Button_Cancel" onClick={() => props?.onClose?.()}>Cancel</button>
            <button
              onClick={() => onSubmit()}
              className="Button Add_Resources"
              disabled={isDisabled()}
            >Save</button>
          </div>
        </div>
      </div>
    </>
  );
}

interface IAddNewSubcategory {
  onBlur(value: string): void;
}

const AddNewSubcategory: FC<IAddNewSubcategory> = (props) => {
  const onBlur: React.InputHTMLAttributes<HTMLInputElement>["onBlur"] = (event) => {
    props.onBlur(event.target.value);
    event.target.value = "";
  }

  return (
    <div className="Row_List New_Line">
      <input
        className="header_item"
        onBlur={onBlur}
        placeholder="Add new"
        title="After you fill the input with the subcategory you want, please click outside so we can add it to the list."
      />
    </div>
  )
}