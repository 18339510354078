import gql from "graphql-tag";

export const MASTER_CREW_SCHEDULES_PAVING_QUERY = gql`
  query masterCrewSchedulesPaving($where: MasterCrewSchedulesPavingQueryInput!) {
    masterCrewSchedulesPaving(where: $where) {
			uID
			jobNumber
			locationIndex
			searchGSI_JobNumber
			date
			material
			mixSubmital
			plant
			tonnage
			oilTruck
			shift
			notes
			resourceID
			resourceDescription
			resourceType
			qty
			tph
			rtsSupport
			bookTruckVendor
			grinder4ft
			grinder6ft
			grinder7ft
			grinder12ft
			extraWork
			uts
			mixDesignApproval
			addedFromPaving
			updated
			loadOutTime
			addedDate
			timeStamp
		}
	}
`;