import gql from "graphql-tag";

export const JOB_NUMBER_INFO_QUERY = gql`
  query jobNumberInformation($jobNumber: Int!){
    jobNumberInformation(jobNumber: $jobNumber) {
		id
		jobNumber
		jobName
		coordinateLat
		coordinateLong
		address
		areaManager
		projectManager
		superIntendentName
		superIntendentCode
	}
  }
`;