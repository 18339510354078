import React, { useRef, ReactElement } from "react";
import { useDrop, DropTargetMonitor } from "react-dnd";
import { DayItem } from "../DayFromCalendar/DayFromCalendar";
import { ItemTypes } from "./ItemTypes";


export interface ChangeEvent {
  dragSource: DraggableEntry;
  dropTarget?: DroppableEntry;
}

export interface DroppableItemProps {
  id: any;
  children(ref: React.RefObject<any>, isOver: boolean, canDrop: boolean): ReactElement | null;
  onChange(event: ChangeEvent): void;
  unixDate: number;
  itemsFromDay: DayItem[];
  canDropElement: boolean;
}

interface DroppableEntry {
  id: any;
  unixDate: number;
}

interface DraggableEntry {
  id: any;
  unixDate: number;
  jobNumber?: number;
  locationIndex?: number;
  pavingForeman?: string;
  type: ItemTypes;
  itemsFromDay: DayItem[];
  canDropElement: boolean;
  nrOfItemsWithSameJobNumber?: number;
  shift?: string;
}

export const DroppableItem: React.FC<DroppableItemProps> = ({ id, children, onChange, unixDate, itemsFromDay, canDropElement }) => {
  const item: DraggableEntry = { id, unixDate, type: ItemTypes.CALENDAR_DAY, itemsFromDay, canDropElement };

  const ref = useRef<any>(null);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.CALENDAR_ITEM,
    canDrop: (dragObject: DraggableEntry /* DragObjectWithType */, monitor: DropTargetMonitor) => {
      if (item.id === dragObject.id) return false;

      if (canDropElement === true) {
        if (dragObject.shift as string !== "D") {
          if (dragObject.shift as string !== "N") {
            if (dragObject.shift as string !== "D 2") {
              if (dragObject.shift as string !== "N 2") {
                return false;
              }
            }
          }
          else {
            return true;
          }
        }
      }
      if (canDropElement === false) return false;

      return true;
    },

    drop: (dragObject: DraggableEntry /* DragObjectWithType */, monitor: DropTargetMonitor) => {
      onChange?.({ dragSource: dragObject, dropTarget: item });
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  drop(ref);

  return typeof children === "function"
    ? children(ref, isOver, canDrop)
    : null;
};