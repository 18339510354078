
import { useEffect, useCallback, useState } from "react";


const useContextMenu = (outerRef: React.MutableRefObject<any>) => {
    const [xPos, setXPos] = useState("0px");
    const [yPos, setYPos] = useState("0px");
    const [menu, showMenu] = useState(false);

    const handleContextMenu = useCallback(
        event => {
            event.preventDefault();
            if (outerRef && outerRef.current.contains(event.target)) {
                setXPos(`${event.pageX}px`);
                setYPos(`${event.pageY}px`);
                showMenu(true);
            } else {
                showMenu(false);
            }
        },
        [showMenu, outerRef, setXPos, setYPos]
    );

    useEffect(() => {
        const handleClick = () => {
            showMenu(false);
        };
        document.addEventListener("click", handleClick);
        document.addEventListener("contextmenu", handleContextMenu);
        return () => {
            document.removeEventListener("click", handleClick);
            document.removeEventListener("contextmenu", handleContextMenu);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { xPos, yPos, menu };
};

export default useContextMenu;