import gql from "graphql-tag";

export const EQUIPMENT_MANAGEMENT_DATAS_QUERY = gql`
  query equipmentManagementDatas($where: EquipmentManagementQueryInput!) {
    equipmentManagementDatas(where: $where) {
      uID
      date
      constant
      jobNumber
      equipmentResources {
        eqID
        eqName
        eqSubcategoryDescription
        eqSubcategoryID
        jobNumber
        jobName
        locIndex
      }
      equipmentSubcategoryData {
        eqSubcategoryDescription
        eqSubcategoryID
        jobNumber
        jobName
        locIndex
        qty
      }
    }
  }
`;
