import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useImmer } from "use-immer";
import { SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY } from "../../../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY";
import { Maybe, Query, SuperIntendentPavingCalendarDatas } from "../../../graphql/schema-types";
import { RootState } from "../../../redux/store";
import { CloseButton } from "../../Form/CloseButton";
import { FullScreenLoadingIndicator } from "../../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import "./AuditTrail.css";

interface IAuditTrail {
  onClose?(): void;
}

export const AuditTrail: FC<IAuditTrail> = props => {
  const [pavingItems, setPavingItems] = useImmer<Maybe<SuperIntendentPavingCalendarDatas>[] | undefined>([]);

  const items = useSelector((state: RootState) => state.app?.pavingModule);
  const daysFromState: string[] = Object.keys(items);
  const pavingModuleUser = useSelector((state: RootState) => state.app?.pavingModuleUser);

  const { data, loading } = useQuery<Pick<Query, "superIntendentPavingCalendarDatas">>(SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY, {
    variables: { where: { startDate: parseInt(daysFromState[0]), endDate: parseInt(daysFromState[daysFromState.length - 1]), pavingSuperName: pavingModuleUser.name } },
    skip: Object.keys(items).length === 0,
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  useEffect(() => {
    const _newPavAdded = data?.superIntendentPavingCalendarDatas.correctItems
      .filter(pav => pav?.addedDate != null || moment(pav?.pavingTimeStamp).format("YYYY.MM.DD") <= moment().utc().format("YYYY.MM.DD"))
      .sort((a, b) => moment(a?.addedDate).format("YYYY.MM.DD") > moment(b?.addedDate).format("YYYY.MM.DD") ? -1 : 1)
      .slice(0, 49);

    const _dayNewPavingAdded = _newPavAdded?.filter(pav => pav?.shift === "D");
    const _nightNewPavingAdded = _newPavAdded?.filter(pav => pav?.shift === "N");

    const _newPavingAdded: Maybe<SuperIntendentPavingCalendarDatas>[] | undefined = [];
    if (_dayNewPavingAdded != null && _nightNewPavingAdded != null) {
      _newPavingAdded.push(
        ..._dayNewPavingAdded as Maybe<SuperIntendentPavingCalendarDatas>[],
        ..._nightNewPavingAdded as Maybe<SuperIntendentPavingCalendarDatas>[]
      );
    }

    const pavingItemsWithAddedDate = _newPavingAdded?.filter(pav => pav?.addedDate != null);
    const pavingItemsWithoutAddedDate = _newPavingAdded?.filter(pav => pav?.addedDate == null);

    setPavingItems(draft => {
      if (pavingItemsWithAddedDate != null && pavingItemsWithoutAddedDate != null) {
        draft?.push(...pavingItemsWithAddedDate, ...pavingItemsWithoutAddedDate);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.superIntendentPavingCalendarDatas.correctItems])

  return (
    <>
      {(loading) && <FullScreenLoadingIndicator />}
      <div className="Audit_Trail">
        <div className="Audit_Trail_Header">
          <div className="Date_Wrap">
            <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
          </div>
          <CloseButton onClick={() => props.onClose?.()} />
        </div>
        <div className="middle-content">
          <div className="Paving_List_Header">
            <div className="header_item">Project Manager</div>
            <div className="header_item">Paving Date</div>
            <div className="header_item">Shift</div>
            <div className="header_item">Added Date</div>
            <div className="header_item">Edited Date</div>
            <div className="header_item">Job Number & Name</div>
          </div>
          <div className="Paving_List">
            {
              pavingItems?.map((pav, i) => (
                <div className="Paving_Item" key={i}>
                  <div className="item">{pav?.pmpe}</div>
                  <div className="item">{moment(pav?.date).format("MM-DD-YYYY")}</div>
                  <div className="item">{pav?.shift}</div>
                  <div className="item">{pav?.addedDate != null ? moment(pav?.addedDate).format("MM-DD-YYYY") : "-"}</div>
                  <div className="item">{moment(pav?.pavingTimeStamp).format("MM-DD-YYYY")}</div>
                  <div className="item">{pav?.jobNumber} - {pav?.jobName}</div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </>
  )
}