import { FC, useEffect } from "react";
import { refreshAuthToken } from '../redux/appSlice';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/store";
import axios from "axios";
import to from "await-to-js";
import { settings } from "../config/settings";

interface IRefreshTokenProps { }

export const RefreshToken: FC<IRefreshTokenProps> = () => {
  const refreshToken = useSelector((state: RootState) => state.app.auth.refresh_token);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getAlerts() {
      if (refreshToken == null) { return }
      const [error, response] = await to(
        axios.post(
          settings?.tokenRefreshUri,
          {
            refreshToken: refreshToken
          }
        )
      )

      if (error != null) return;
      dispatch(refreshAuthToken({ id_token: response?.data.id_token }))
    }
    getAlerts()
    const interval = setInterval(() => getAlerts(), 1000 * 60 * 50)
    return () => {
      clearInterval(interval);
    }
  }, [dispatch, refreshToken])

  return (null)
}