import { FC, useEffect, memo } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { locationInfoChange } from "../redux/appSlice";
import { RootState } from "../redux/store";
import { useImmer } from "use-immer";


interface ILeftHeaderSideProps {
    jobNumber: number | undefined | "";
    jobName: string;
    PMPE?: string;
    superIntendent?: string;
    locationIndex: number;
}

interface IState {
    activityDescription: string,
    location: string
}

const getInitialState = (): IState => {
    return ({
        activityDescription: "",
        location: ""
    })
}

const LeftHeader: FC<ILeftHeaderSideProps> = (props) => {
    const { locationIndex } = props;
    const [state, setState] = useImmer<IState>(getInitialState());

    const locationName = useSelector((state: RootState) => state.app.schedule.locations?.[locationIndex].name);
    const locationActivityDescription = useSelector((state: RootState) => state.app.schedule.locations?.[locationIndex].activityDescription);

    useEffect(() => {
        if (state.location == null || state.activityDescription == null) return;

        setState(draft => {
            draft.location = locationName as string;
            draft.activityDescription = locationActivityDescription as string;
        })
    }, [locationName, locationActivityDescription, state, setState])

    const dispatch = useDispatch();

    const onActivityDescChange = (value: string) => {
        setState(draft => {
            draft.activityDescription = value;
        })
        dispatch(locationInfoChange({ locationIndex, activityDescription: value }));
    }

    const onLocationDescChange = (value: string) => {
        setState(draft => {
            draft.location = value;
        })
        dispatch(locationInfoChange({ locationIndex, location: value, }));
    }

    return (
        <div className="Static_table">
            <div className="Table_head">
                <div className="Inner_border">
                    <div className="Location">
                        <div className="Row_Usage">Location</div>
                        <textarea
                            cols={40}
                            className="active"
                            value={state.location}
                            onChange={(e) => onLocationDescChange(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="Static_holder">
                <div className="Row">
                    <div className="Row_Usage">Job Number:</div><input className="inactive noFocus" readOnly value={props.jobNumber}></input>
                </div>
                <div className="Row">
                    <div className="Row_Usage">Job Name:</div><input className="inactive noFocus" readOnly value={props.jobName === " " || props.jobName == null ? " " : props.jobName}></input>
                </div>
                <div className="Row">
                    <div className="Row_Usage">PM/PE:</div><input className="inactive noFocus" readOnly value={props.PMPE === " " || props.PMPE == null ? "Missing" : props.PMPE}></input>
                </div>
                <div className="Big_row">
                    <div className="Row_Usage">Activity Description</div>
                    <textarea
                        cols={40}
                        className="active"
                        value={state.activityDescription}
                        onChange={(e) => onActivityDescChange(e.target.value)}
                    />
                </div>
            </div>
            <div className="Scroll_Static_Holder">
                <div className="Description">
                    Description
                </div>
                <div className="Labor_req">
                    Labor Requests
                </div>
                <div className="Equipment_req">
                    Equipment Requests
                </div>
                <div className="Additional_res">
                    Additional Resources/ Comments
                </div>
            </div>
        </div>
    );
}

export default memo(LeftHeader);