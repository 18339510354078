import React, { useRef, ReactElement, useEffect } from "react";
import { useDrag, DragSourceMonitor } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { ItemTypes } from "./ItemTypes";


export interface ChangeEvent {
  dragSource: DraggableEntry;
  dropTarget?: DraggableEntry;
}

export interface DraggableItemProps {
  id: any;
  children(ref: React.RefObject<any>, isDragging: boolean): ReactElement | null;
  onChange(event: ChangeEvent): void;
  unixDate: number;
  isDraggable: boolean;
  jobNumber: number;
  locationIndex: number;
  pavingForeman: string;
  nrOfItemsWithSameJobNumber: number;
  shift: string;
}

interface DraggableEntry {
  id: any;
  type: ItemTypes;
  unixDate: number;
  jobNumber: number;
  locationIndex: number;
  pavingForeman: string;
  nrOfItemsWithSameJobNumber: number;
  shift: string;
}

export const DraggableItem: React.FC<DraggableItemProps> = ({ id, children, onChange, unixDate, isDraggable, jobNumber, locationIndex, pavingForeman, nrOfItemsWithSameJobNumber, shift }) => {
  const item: DraggableEntry = { id, unixDate, type: ItemTypes.CALENDAR_ITEM, jobNumber, locationIndex, pavingForeman, nrOfItemsWithSameJobNumber, shift };

  const ref = useRef<any>(null);

  const [{ isDragging }, drag, preview] = useDrag({
    item,

    canDrag(monitor: DragSourceMonitor) {
      return isDraggable;
    },

    end(dropResult: DraggableEntry | undefined, monitor: DragSourceMonitor) {
      if (!monitor.didDrop()) {
        onChange?.({ dragSource: item });
      }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag()
    })
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  drag(ref);

  return typeof children === "function"
    ? children(ref, isDragging)
    : null;
};