import React, { FC, useCallback, useContext, useEffect } from "react";

import useContextMenu from "../hooks/useContextMenu";
import { useDispatch, useSelector } from "react-redux";
import { clearDay, pasteDay, undoDay, copyDay, copyTrucking, copyPaving, changeDoubledShift, copyResourcesWithoutPav } from "../redux/appSlice";
import { RootState } from "../redux/store";
import { GeneralWarningModal } from "./Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import { ModalContext } from "./Modal/ModalContext/ModalContext";
import { useQuery } from "@apollo/react-hooks";
import { Maybe, Query, SuperIntendentPavingCalendar } from "../graphql/schema-types";
import { SUPER_INTENDENT_PAVING_CALENDAR_QUERY } from "../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_QUERY";

interface IMenuInterface {
  outerRef: React.MutableRefObject<any>,
  locationIndex: number;
  unix: number;
}

const Menu: FC<IMenuInterface> = (props) => {
  const { outerRef, locationIndex, unix } = props

  const day = useSelector((state: RootState) => state.app.schedule?.locations?.[locationIndex]?.daysByUnix?.[unix]);

  const stateCopyDay = useSelector((state: RootState) => state.app.copyDay);
  const stateCopyPav = useSelector((state: RootState) => state.app.copyPaving);
  const stateCopyTrk = useSelector((state: RootState) => state.app.copyTrucking);
  const lastDay = useSelector((state: RootState) => state.app.lastDay);

  const stateCopyTrucking = useSelector((state: RootState) => state.app.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.trucking?.truckingResources);
  const stateCopyPaving = useSelector((state: RootState) => state.app.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.paving?.pavingResources);
  const stateCopyTruckingLongRange = stateCopyTrucking.filter(tr => tr?.updated === false);
  const stateCopyPavingLongRange = stateCopyPaving.filter(p => p?.updated === false);

  const laborResources = useSelector((state: RootState) => state.app.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.labor?.jobInfos);
  const equipResources = useSelector((state: RootState) => state.app.schedule.locations?.[locationIndex]?.daysByUnix?.[unix]?.equip?.jobInfos);

  const pavingResourcesLoc1 = useSelector((state: RootState) => state.app.schedule.locations?.[1]?.daysByUnix?.[unix]?.paving?.pavingResources);
  const pavingResourcesLoc2 = useSelector((state: RootState) => state.app.schedule.locations?.[2]?.daysByUnix?.[unix]?.paving?.pavingResources);
  const pavingResourcesLoc3 = useSelector((state: RootState) => state.app.schedule.locations?.[3]?.daysByUnix?.[unix]?.paving?.pavingResources);
  const pavingResourcesLoc4 = useSelector((state: RootState) => state.app.schedule.locations?.[4]?.daysByUnix?.[unix]?.paving?.pavingResources);

  const jobNumber = useSelector((state: RootState) => state.app.start.jobNumber);

  const _shift = stateCopyDay != null
    ? stateCopyDay?.paving.pavingResources?.map(pav => pav?.shift)[0]
    : stateCopyPav?.map(pav => pav?.shift)[0];

  const { data: lockedDaysData, refetch: refetchLockedDays } = useQuery<Pick<Query, "superIntendentPavingCalendar">>(SUPER_INTENDENT_PAVING_CALENDAR_QUERY, {
    variables: {
      where: {
        startDate: unix,
        endDate: unix,
      }
    },
    fetchPolicy: "no-cache"
  });

  const { xPos, yPos, menu } = useContextMenu(outerRef);
  const modal = useContext(ModalContext);

  const dispatch = useDispatch();

  const onClear = () => {
    const currentDayPavingResources = day.paving.pavingResources?.length
    if (checkForLockedDays().includes(unix) && currentDayPavingResources > 0) {
      modal?.openModal?.({
        element: <GeneralWarningModal
          message="The day is locked. You can not change the paving resources!"
          title="DAY LOCKED"
          yesNoButtons={false}
          onCancel={() => { }}
        />
      });
    }
    else {
      dispatch(clearDay({ locationIndex, unix, lockedDays: checkForLockedDays() }));
    }
  }
  const onPaste = () => {
    if (checkForLockedDays().includes(unix)) {
      modal?.openModal?.({
        element: <GeneralWarningModal
          message="The day is locked. You can not change the paving resources!"
          title="DAY LOCKED"
          yesNoButtons={false}
          onCancel={() => { }}
        />
      });
    }

    if (stateCopyTrk != null && stateCopyTrk.length > 0) {
      dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
    }
    if (locationIndex <= 2) {
      if (stateCopyDay != null && stateCopyDay?.paving?.pavingResources?.length > 0 && (_shift === "D 2" || _shift === "N 2")) {
        modal?.openModal?.({
          element: <GeneralWarningModal
            message="You can not add paving or trucking resources for paving on a location number higher than 2 if the crew is special"
            title="Incorrect location"
            yesNoButtons={false}
            onCancel={() => { }}
          />
        });
        return;
      }
      // else {
      if (locationIndex === 1) {
        if (pavingResourcesLoc2 != null && pavingResourcesLoc2?.length > 0) {
          if (_shift as string === "D" || _shift as string === "N") {
            if (stateCopyDay != null) {
              if (stateCopyDay?.paving?.pavingResources?.[0]?.shift === pavingResourcesLoc2?.[0]?.shift) {
                modal?.openModal?.({
                  element: <GeneralWarningModal
                    message="You already have a Paving Crew with the same shift on this day. Do you want to automatically change the shift for this Paving Crew?"
                    title="SAME SHIFT"
                    yesNoButtons={true}
                    onCancel={() => { return }}
                    onConfirm={() => {
                      dispatch(changeDoubledShift({ stateName: "stateCopyDay", day: unix, locationIndex: locationIndex }));
                      dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }));
                    }}
                  />
                });
              } else {
                dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
              }
            }
            if (stateCopyPav != null) {
              if (stateCopyPav?.[0]?.shift === pavingResourcesLoc2?.[0]?.shift) {
                modal?.openModal?.({
                  element: <GeneralWarningModal
                    message="You already have a Paving Crew with the same shift on this day. Do you want to automatically change the shift for this Paving Crew?"
                    title="SAME SHIFT"
                    yesNoButtons={true}
                    onCancel={() => { return }}
                    onConfirm={() => {
                      dispatch(changeDoubledShift({ stateName: "stateCopyPaving", day: unix, locationIndex: locationIndex }));
                      dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }));
                    }}
                  />
                });
              }
              else {
                dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
              }
            }
          }
        } else {
          if (stateCopyDay != null || stateCopyPav != null) {
            if (_shift as string === "D" || _shift as string === "N") {
              dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
            }
          }
          if ((stateCopyDay != null && stateCopyDay?.paving.pavingResources.length === 0) || (stateCopyPav != null && stateCopyPav?.length === 0)) {
            dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
          }
        }
      }

      if (locationIndex === 2) {
        if (pavingResourcesLoc1 != null && pavingResourcesLoc1?.length > 0) {
          if (_shift as string === "D" || _shift as string === "N") {
            if (stateCopyDay != null) {
              if (stateCopyDay?.paving?.pavingResources?.[0]?.shift === pavingResourcesLoc1?.[0]?.shift) {
                modal?.openModal?.({
                  element: <GeneralWarningModal
                    message="You already have a Paving Crew with the same shift on this day. Do you want to automatically change the shift for this Paving Crew?"
                    title="SAME SHIFT"
                    yesNoButtons={true}
                    onCancel={() => { return }}
                    onConfirm={() => {
                      dispatch(changeDoubledShift({ stateName: "stateCopyDay", day: unix, locationIndex: locationIndex }));
                      dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }));
                    }}
                  />
                });
              }
              else {
                dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
              }
            }
            if (stateCopyPav != null) {
              if (stateCopyPav?.[0]?.shift === pavingResourcesLoc1?.[0]?.shift) {
                modal?.openModal?.({
                  element: <GeneralWarningModal
                    message="You already have a Paving Crew with the same shift on this day. Do you want to automatically change the shift for this Paving Crew?"
                    title="SAME SHIFT"
                    yesNoButtons={true}
                    onCancel={() => { return }}
                    onConfirm={() => {
                      dispatch(changeDoubledShift({ stateName: "stateCopyPaving", day: unix, locationIndex: locationIndex }));
                      dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }));
                    }}
                  />
                });
              }
              else {
                dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
              }
            }
          }
        } else {
          if (stateCopyDay != null || stateCopyPav != null) {
            if (_shift as string === "D" || _shift as string === "N") {
              dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
            }
          }
          if ((stateCopyDay != null && stateCopyDay?.paving.pavingResources.length === 0) || (stateCopyPav != null && stateCopyPav?.length === 0)) {
            dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
          }
        }
      }
      // }
    }

    if (locationIndex > 2 && locationIndex <= 4) {
      if (stateCopyDay != null && stateCopyDay?.paving?.pavingResources?.length > 0 && (_shift === "D" || _shift === "N")) {
        modal?.openModal?.({
          element: <GeneralWarningModal
            message="You can not add paving or trucking resources for paving on a location number higher than 2 if the crew is special"
            title="Incorrect location"
            yesNoButtons={false}
            onCancel={() => { }}
          />
        });
        return;
      }
      // else {
      if (locationIndex === 3) {
        if (pavingResourcesLoc4 != null && pavingResourcesLoc4.length > 0) {
          if (_shift as string === "D 2" || _shift as string === "N 2") {
            if (stateCopyDay != null) {
              if (stateCopyDay?.paving?.pavingResources?.[0]?.shift === pavingResourcesLoc4?.[0]?.shift) {
                modal?.openModal?.({
                  element: <GeneralWarningModal
                    message="You already have a Paving Crew with the same shift on this day. Do you want to automatically change the shift for this Paving Crew?"
                    title="SAME SHIFT"
                    yesNoButtons={true}
                    onCancel={() => { return }}
                    onConfirm={() => {
                      dispatch(changeDoubledShift({ stateName: "stateCopyDay", day: unix, locationIndex: locationIndex }));
                      dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }));
                    }}
                  />
                });
              }
              else {
                dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
              }
            }
            if (stateCopyPav != null) {
              if (stateCopyPav?.[0]?.shift === pavingResourcesLoc4?.[0]?.shift) {
                modal?.openModal?.({
                  element: <GeneralWarningModal
                    message="You already have a Paving Crew with the same shift on this day. Do you want to automatically change the shift for this Paving Crew?"
                    title="SAME SHIFT"
                    yesNoButtons={true}
                    onCancel={() => { return }}
                    onConfirm={() => {
                      dispatch(changeDoubledShift({ stateName: "stateCopyPaving", day: unix, locationIndex: locationIndex }));
                      dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }));
                    }}
                  />
                });
              }
              else {
                dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
              }
            }
          }
        } else {
          if (stateCopyDay != null || stateCopyPav != null) {
            // for location 3 & 4 the shift should be D special or N special
            if (_shift as string === "D 2" || _shift as string === "N 2") {
              dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
            }
          }
          if ((stateCopyDay != null && stateCopyDay?.paving.pavingResources.length === 0) || (stateCopyPav != null && stateCopyPav?.length === 0)) {
            dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
          }
        }
      }

      if (locationIndex === 4) {
        if (pavingResourcesLoc3 != null && pavingResourcesLoc3.length > 0) {
          if (stateCopyDay != null) {
            if (stateCopyDay?.paving?.pavingResources?.[0]?.shift === pavingResourcesLoc3?.[0]?.shift) {
              modal?.openModal?.({
                element: <GeneralWarningModal
                  message="You already have a Paving Crew with the same shift on this day. Do you want to automatically change the shift for this Paving Crew?"
                  title="SAME SHIFT"
                  yesNoButtons={true}
                  onCancel={() => { return }}
                  onConfirm={() => {
                    dispatch(changeDoubledShift({ stateName: "stateCopyDay", day: unix, locationIndex: locationIndex }));
                    dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }));
                  }}
                />
              });
            }
            else {
              dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
            }
          }
          if (stateCopyPav != null) {
            if (stateCopyPav?.[0]?.shift === pavingResourcesLoc3?.[0]?.shift) {
              modal?.openModal?.({
                element: <GeneralWarningModal
                  message="You already have a Paving Crew with the same shift on this day. Do you want to automatically change the shift for this Paving Crew?"
                  title="SAME SHIFT"
                  yesNoButtons={true}
                  onCancel={() => { return }}
                  onConfirm={() => {
                    dispatch(changeDoubledShift({ stateName: "stateCopyPaving", day: unix, locationIndex: locationIndex }));
                    dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }));
                  }}
                />
              });
            }
            else {
              dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
            }
          }
        } else {
          if (stateCopyDay != null || stateCopyPav != null) {
            // for location 3 & 4 the shift should be D special or N special
            if (_shift as string === "D 2" || _shift as string === "N 2") {
              dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
            }
            if ((stateCopyDay != null && stateCopyDay?.paving.pavingResources.length === 0) || (stateCopyPav != null && stateCopyPav?.length === 0)) {
              dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
            }
          }
        }
      }
      // }
    }

    if (locationIndex > 4) {
      if ((stateCopyDay != null && stateCopyDay?.paving?.pavingResources?.length > 0) || (stateCopyPav != null && stateCopyPav?.length > 0)) {
        modal?.openModal?.({
          element: <GeneralWarningModal
            message="You can not add paving or trucking resources for paving on a location number higher than 4"
            title="Incorrect location"
            yesNoButtons={false}
            onCancel={() => { }}
          />
        });
        return;
      }
      // else {
      dispatch(pasteDay({ locationIndex, unix, lockedDays: checkForLockedDays() }))
      // }
    }
  }
  const onUndo = () => {
    dispatch(undoDay({ locationIndex, unix }));
  }
  const onCopy = () => {
    if (stateCopyTruckingLongRange.length > 0 || stateCopyPavingLongRange.length > 0) {
      modal?.openModal?.({
        element: <GeneralWarningModal
          message="You cannot copy paving or trucking resources from long range. Only other resources will be copied.."
          title="Paving/Trucking from long range"
          yesNoButtons={false}
          onCancel={() => { }}
        />
      });
    }
    dispatch(copyDay({ locationIndex, unix }));
  }
  const onCopyTrucking = () => {
    dispatch(copyTrucking({ locationIndex, unix }));
  }
  const onCopyPaving = () => {
    if (stateCopyTruckingLongRange.length > 0 || stateCopyPavingLongRange.length > 0) {
      modal?.openModal?.({
        element: <GeneralWarningModal
          message="You cannot copy paving or trucking resources from long range. Only other resources will be copied."
          title="Paving/Trucking from long range"
          yesNoButtons={false}
          onCancel={() => { }}
        />
      });
    } else {
      dispatch(copyPaving({ locationIndex, unix }));
    }
  }

  const onCopyWhithoutPav = () => {
    dispatch(copyResourcesWithoutPav({ locationIndex, unix }))
  }

  const checkForLockedDays = useCallback(() => {
    //refetchLockedDays();
    let _lockedDays: Maybe<number>[] = [];
    lockedDaysData?.superIntendentPavingCalendar.forEach((item: Maybe<SuperIntendentPavingCalendar>) => {
      if (item?.jobNumbersBlocked?.includes(jobNumber as number) || item?.status === true) _lockedDays.push(item.date)
    });
    return _lockedDays;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockedDaysData])

  useEffect(() => {
    refetchLockedDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCopyPaving, onCopy, onUndo, onPaste, onClear, menu])

  const hasOnlyPavingResources = () => {
    const laborResourcesWithoutPav = laborResources.filter(ji => ji?.resourceID !== "PAV").length;
    const _equipResources = equipResources.length;

    if (laborResourcesWithoutPav as number > 0 && _equipResources as number > 0) {
      return false
    }
    else {
      return true
    }
  }

  if (menu) {
    return (
      <ul className="menu" style={{ top: yPos, left: xPos }}>
        {
          (laborResources?.length > 0 || equipResources?.length > 0)
            ? <><div className="menu-icons"><li onClick={onCopy}><i className="fas fa-copy" ></i>Copy All Resources</li></div></>
            : null
        }
        {
          (hasOnlyPavingResources() === false)
            ? <><div className="menu-icons"><li onClick={onCopyWhithoutPav}><i className="fas fa-copy" ></i>Copy Resources Without Pav</li></div></>
            : null
        }
        {
          stateCopyTrucking.filter(tr => tr?.operationType !== "paving").length > 0
            ? <> <li style={{ color: "grey" }}>|---------------|</li> <div className="menu-icons"><li onClick={onCopyTrucking} ><i className="fas fa-truck-pickup"></i> Copy Trucking Only</li></div></>
            : null
        }
        {
          stateCopyPaving.length > 0 && ((stateCopyPaving[0]?.addedFromPaving === false || stateCopyPaving[0]?.addedFromPaving === null) || (stateCopyPaving[0]?.updated === true || stateCopyPaving[0]?.updated === null))
            ? <> <li style={{ color: "grey" }}>|---------------|</li> <div className="menu-icons"><li onClick={onCopyPaving} ><i className="fas fa-truck-pickup"></i> Copy Paving Only</li></div></>
            : null
        }
        {
          // (stateCopyDay?.locationIndex === locationIndex && stateCopyDay.unix === unix) === false
          //    ? 
          <><div className="menu-icons"><li onClick={onPaste}><i className="fas fa-clone"></i> Paste Resource</li></div></>
          //    : null
        }
        <><div className="menu-icons"><li onClick={onClear}><i className="fas fa-eraser"></i> Clear Day</li></div></>
        {
          lastDay != null && lastDay.unix === unix && day !== null
            ? <><div className="menu-icons"><li onClick={onUndo}><i className="fas fa-undo"></i> Undo Copy/Paste</li></div></>
            : null
        }
      </ul>
    );
  }
  return <></>;
};

export default Menu;
