import gql from "graphql-tag";

export const CREWS_RESOURCES_BY_JOB_NUMBER_QUERY = gql`
  query crewResourcesByJobNumber($JobNumber: Int!){
    crewResourcesByJobNumber(JobNumber: $JobNumber) {
		id
		crewCode
		JobNumber
		QTYNeeded
		dateAdded
		resourceID
		resourceDescription
		resourceType
	}
  }
`;