import React, { FC, useState, useContext, useRef, useEffect, cloneElement } from "react";
import { SuperIntendentPavingCalendar, Maybe, SuperIntendentPavingCalendarDatas, Query, SuperIntendentPavingCalendarItemInput, UpdateSuperIntendentPavingCalendarInput } from "../../../graphql/schema-types";
import "./DayFromCalendar.css";
import { ItemFromCalendar } from "../ItemFromCalendar/ItemFromCalendar";
import moment from "moment";
import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";
import { ADD_SUPER_INTENDENT_PAVING_CALENDAR } from "../../../graphql/mutations/ADD_SUPER_INTENDENT_PAVING_CALENDAR";
import to from "await-to-js";
import { GeneralWarningModal } from "../../Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import { ModalContext } from "../../Modal/ModalContext/ModalContext";
import { SAVE_ITEM_FROM_PAVING_MODULE } from "../../../graphql/mutations/SAVE_ITEM_FROM_PAVING_MODULE";
import { DroppableItem } from "../DraggableItem/DroppableItem";
import { useDispatch, useSelector } from "react-redux";
import { movePavingItemToAnotherDate, UpdateBlockedForemanFromCurrentDay, updateBlockedForemanFromDay, updateDayStatusForPavingModule, updateEquipmentResourcesList } from "../../../redux/appSlice";
import { PAVING_FOREMANS_QUERY } from "../../../graphql/queries/PAVING_FOREMANS_QUERY";
import { toMultiMap } from "../../../utils/toMultiMap";
import { RootState } from "../../../redux/store";
import { SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY } from "../../../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY";
import { useTouchableMenu } from "../../../hooks/useTouchableMenu";
import { DayMenu } from "../DayMenu/DayMenu";
import { UPDATE_SUPER_INTENDENT_PAVING_CALENDAR } from "../../../graphql/mutations/UPDATE_SUPER_INTENDENT_PAVING_CALENDAR";

interface IDayFromCalendarProps {
	calendarDay: Maybe<SuperIntendentPavingCalendar>;
	items: Maybe<SuperIntendentPavingCalendarDatas>[];
	lastDay: string;
}

interface State {
	isScheduleLocked: boolean;
	pavingForemansNameWithMoreThan2Resources: Maybe<string>[];
	jobNumbersToLock: number[];
}

export interface DayItem {
	jobNumber: number;
	locationIndex: number;
	pavingForeman: string;
}

export const DayFromCalendar: FC<IDayFromCalendarProps> = (props) => {
	const [state, setState] = useState<State>({
		isScheduleLocked: props.calendarDay?.status as boolean,
		pavingForemansNameWithMoreThan2Resources: [],
		jobNumbersToLock: [],
	});

	const isGeneral = useSelector((state: RootState) => state.app.pavingModuleUser.isInGeneralPavingSupers);

	const { data } = useQuery<Pick<Query, "pavingForemans">>(PAVING_FOREMANS_QUERY);

	const client = useApolloClient();

	const [saveItemFromPavingModule] = useMutation(SAVE_ITEM_FROM_PAVING_MODULE);
	const [updateSuperIntendentPavingCalendar] = useMutation(UPDATE_SUPER_INTENDENT_PAVING_CALENDAR);

	const [addSuperIntendentPavingCalendar] = useMutation(
		ADD_SUPER_INTENDENT_PAVING_CALENDAR
	);

	const [TouchableMenu, { pageX, pageY, visible }] = useTouchableMenu();

	const modal = useContext(ModalContext);
	const dispatch = useDispatch();
	const refStatusDay = useRef<HTMLButtonElement>(null);
	const jobsLocked = useSelector((state: RootState) => state.app.pavingModule[props.calendarDay?.date].jobNumbersBlocked);
	const isDayDisabled = useSelector((state: RootState) => state.app.pavingModule[props.calendarDay?.date]?.isDayBlocked);
	const equipmentSubcategoriesInput = useSelector((state: RootState) => state.app.equipmentSubcategories?.equipmentsForInput);
	const blockedForemans = useSelector((state: RootState) => state.app.pavingModule[props.calendarDay?.date].noWorkForForeman);

	useEffect(() => {
		if (props.items == null) return;

		const groupedItems = toMultiMap(props.items ?? [], item => item?.pavingForeman! as string);

		const groupedItemsByJobNumber = toMultiMap(props.items ?? [], item => item?.jobNumber! as number);

		const filteredItemsByForeman = Object.keys(groupedItems)
			.map(foreman => groupedItems[foreman].length > 1 ? foreman : null);

		const pavingForemansNameWithMoreThan2Resources = filteredItemsByForeman.filter(f => f != null);

		const jobs: any[] = [];

		pavingForemansNameWithMoreThan2Resources.forEach(pavingForeman => {
			Object.values(groupedItemsByJobNumber).forEach((job) => {
				job.forEach(
					(item, i) => {
						if (job[i]?.pavingForeman === pavingForeman) jobs.push([job[i]?.jobNumber])
					}
				)
			})
		})

		setState({ ...state, pavingForemansNameWithMoreThan2Resources: filteredItemsByForeman.filter(f => f != null), jobNumbersToLock: jobs?.length !== 0 ? jobs[0] : [] });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.items])

	useEffect(() => {
		state.pavingForemansNameWithMoreThan2Resources.forEach(pavingForeman => {

			props.items.forEach(item => {
				return item?.pavingForeman === pavingForeman ? item.jobNumber : null
			})
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.pavingForemansNameWithMoreThan2Resources])

	const firstDayAllowedToModify = moment().utc();
	//You can modify if the day is in the future
	const allowToModify =
		moment(parseInt(props.calendarDay?.date)).utc().format("YYYY.MM.DD") <
			firstDayAllowedToModify.format("YYYY.MM.DD")
			? false
			: true;

	const totalTonnage = props.items
		?.map((item) => item?.tonnage)
		?.reduce(
			(previousValue, currentValue) =>
				parseInt(previousValue?.toString() as string) +
				parseInt(currentValue?.toString() as string),
			0
		);

	const itemFromDay: DayItem[] = props.items?.map(
		item => ({
			jobNumber: item?.jobNumber as number,
			locationIndex: item?.locationIndex as number,
			pavingForeman: item?.pavingForeman as string
		} as DayItem)
	)

	const onSubmit = async () => {
		const isScheduleLocked = !state.isScheduleLocked;

		setState({ ...state, isScheduleLocked });

		dispatch(updateDayStatusForPavingModule({ unix: props.calendarDay?.date, isDayBlocked: !state.isScheduleLocked }))

		const [, response] = await to(
			addSuperIntendentPavingCalendar({
				variables: {
					where: {
						date: parseInt(props.calendarDay?.date),
						status: isScheduleLocked,
						jobNumbersBlocked: jobsLocked
					},
				},
			})
		);

		if (response) {
			modal?.openModal?.({
				element: (
					<GeneralWarningModal
						message={
							isScheduleLocked === true
								? `You changed the status in locked for day ${moment(
									parseInt(props.calendarDay?.date)
								).utc().format("MM/DD/YYYY")}`
								: `You changed the status in unlocked for day  ${moment(
									parseInt(props.calendarDay?.date)
								).utc().format("MM/DD/YYYY")}`
						}
						title="Day status changed"
						yesNoButtons={false}
					/>
				),
			});
		}
	};

	const onDropEvent = async (actualDate: number, newDate: number, jobNumber: number, locationIndex: number, nrOfItemsWithSameJobNumber: number, shift: string, foreman: string) => {
		if (actualDate === newDate) return;
		let _locationIndex: number | null = null;
		let _itemToBeOverwrite: SuperIntendentPavingCalendarItemInput | null = {};
		let itemNewDay = props.items?.filter(item => item?.jobNumber === jobNumber && item.shift === shift)[0];
		const numberOfItemsNewDaySameJN = props.items?.filter(item => item?.jobNumber === jobNumber).length;
		let wasAddedAnItem = false;

		const [, response] = await to(
			client.query({
				query: SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY, variables: { where: { startDate: newDate, endDate: newDate, pavingSuperName: "" } }
			})
		);

		let itemsFromNewDayAdded: SuperIntendentPavingCalendarDatas[] = [];
		if (response != null) {
			response?.data?.superIntendentPavingCalendarDatas?.correctItems?.forEach((item: SuperIntendentPavingCalendarDatas) => {
				itemsFromNewDayAdded.push(item);
			})
		}

		const itemsLocationIndexes = props.items
			?.filter(item => item?.jobNumber as number === jobNumber)
			?.map(item => item?.locationIndex as number);

		if (itemNewDay == null) {
			if (itemsFromNewDayAdded != null && itemsFromNewDayAdded.length > 0) {
				itemNewDay = itemsFromNewDayAdded?.filter(item => item?.jobNumber === jobNumber && item.shift === shift)[0];
				if (itemNewDay != null) wasAddedAnItem = true;
			}
		}

		if (itemNewDay != null) {
			_itemToBeOverwrite = {
				bookTruckVendor: itemNewDay?.bookTruckVendor,
				broker: itemNewDay?.broker,
				crewMakeLab: itemNewDay?.crewMakeLab,
				crewMakeOp: itemNewDay?.crewMakeOp,
				date: itemNewDay?.date,
				extraWork: itemNewDay?.extraWork,
				grinder12ft: itemNewDay?.grinder12ft,
				grinder4ft: itemNewDay?.grinder4ft,
				grinder6ft: itemNewDay?.grinder6ft,
				grinder7ft: itemNewDay?.grinder7ft,
				jobName: itemNewDay?.jobName,
				jobNumber: itemNewDay?.jobNumber,
				locationIndex: itemNewDay?.locationIndex,
				material: itemNewDay?.material,
				mixDesignApproval: itemNewDay?.mixDesignApproval,
				mixSubmital: itemNewDay?.mixSubmital,
				nrOfTrucks: itemNewDay?.nrOfTrucks,
				pavingForeman: itemNewDay?.pavingForeman,
				pavingSuperIntendent: itemNewDay?.pavingSuperIntendent,
				plant: itemNewDay?.plant,
				pmpe: itemNewDay?.pmpe,
				rtsSupport: itemNewDay?.rtsSupport,
				shift: itemNewDay?.shift,
				superIntendent: itemNewDay?.superIntendent,
				tonnage: itemNewDay?.tonnage,
				tph: itemNewDay?.tph,
				typeOfTrucks: itemNewDay?.typeOfTrucks,
				uts: itemNewDay?.uts
			}
		}
		//_itemstooverwrite.length > 0 -> ma intereseaza strict locatia de pe new date de la acelasi shift
		// else (diff shift, doar un item si o locatie e goala) (cand doar fac push simplu) prima locatie goala dintre 1 si 2
		if (_itemToBeOverwrite?.locationIndex != null) {
			_locationIndex = _itemToBeOverwrite?.locationIndex as number;
		}
		else {
			if (shift === "D" || shift === "N") {
				// if the itemsLocationIndexes includes 1 or 2, than the item it keeps his location
				if (!itemsLocationIndexes.includes(1)) _locationIndex = locationIndex;
				if (!itemsLocationIndexes.includes(2)) _locationIndex = locationIndex;

				if (itemsLocationIndexes.includes(1)) _locationIndex = 2;
				if (itemsLocationIndexes.includes(2)) _locationIndex = 1;
			}
			if (shift === "D 2" || shift === "N 2") {
				// if the itemsLocationIndexes includes 1 or 2, than the item it keeps his location
				if (!itemsLocationIndexes.includes(3)) _locationIndex = locationIndex;
				if (!itemsLocationIndexes.includes(4)) _locationIndex = locationIndex;

				if (itemsLocationIndexes.includes(3)) _locationIndex = 4;
				if (itemsLocationIndexes.includes(4)) _locationIndex = 3;
			}
		}

		if (_itemToBeOverwrite.locationIndex != null && blockedForemans.includes(foreman)) {
			modal?.openModal?.({
				element: (
					<GeneralWarningModal
						message={wasAddedAnItem === false ? `Are you sure you want to overwrite the schedule from new date and remove ${foreman} from blocked list?` : `A resource already exists for this day! Are you sure you want to overwrite it and remove ${foreman} from blocked list?`}
						title="Day item changed and night transition"
						yesNoButtons={true}
						onConfirm={() => {
							saveItemToAnotherDate(actualDate, newDate, jobNumber, locationIndex, nrOfItemsWithSameJobNumber, numberOfItemsNewDaySameJN, shift, _locationIndex, _itemToBeOverwrite);
							updateForemans(foreman);
						}}
						onClose={() => { }}
						onCancel={() => { }}
					/>
				),
			});
		}
		else if (_itemToBeOverwrite.locationIndex != null && !blockedForemans.includes(foreman)) {
			modal?.openModal?.({
				element: (
					<GeneralWarningModal
						message={wasAddedAnItem === false ? "Are you sure you want to overwrite the schedule from new date?" : "A resource already exists for this day! Are you sure you want to overwrite it?"}
						title="Day item changed"
						yesNoButtons={true}
						onConfirm={() => saveItemToAnotherDate(actualDate, newDate, jobNumber, locationIndex, nrOfItemsWithSameJobNumber, numberOfItemsNewDaySameJN, shift, _locationIndex, _itemToBeOverwrite)}
						onClose={() => { }}
						onCancel={() => { }}
					/>
				),
			});
		}
		else {
			if (blockedForemans.includes(foreman)) {
				modal?.openModal?.({
					element: (
						<GeneralWarningModal
							message={`Because of the night transition, the foreman ${foreman} is blocked. Are you sure you want to add the item on this day?`}
							title="Night transition"
							yesNoButtons={true}
							onConfirm={() => {
								saveItemToAnotherDate(actualDate, newDate, jobNumber, locationIndex, nrOfItemsWithSameJobNumber, numberOfItemsNewDaySameJN, shift, _locationIndex, _itemToBeOverwrite);
								updateForemans(foreman);
							}}
							onClose={() => { }}
							onCancel={() => { }}
						/>
					),
				});
			}
			else {
				saveItemToAnotherDate(actualDate, newDate, jobNumber, locationIndex, nrOfItemsWithSameJobNumber, numberOfItemsNewDaySameJN, shift, _locationIndex, _itemToBeOverwrite);
			}
		}

	};

	const saveItemToAnotherDate = async (actualDate: number, newDate: number, jobNumber: number, locationIndex: number, nrOfItemsWithSameJobNumber: number, numberOfItemsNewDaySameJN: number, shift: string, _locationIndex: number | null, _itemToOverWrite: SuperIntendentPavingCalendarItemInput | null) => {

		dispatch(
			updateEquipmentResourcesList({
				date: actualDate,
				jobNumber,
				newDate,
				nrOfItemsWithSameJobNumber: nrOfItemsWithSameJobNumber as number,
				shift
			}),
		);

		dispatch(
			movePavingItemToAnotherDate({
				date: actualDate,
				jobNumber,
				newDate,
				locationIndex: _locationIndex == null ? locationIndex : _locationIndex as number,
				shift
			})
		);

		await to(saveItemFromPavingModule({
			variables: {
				where: {
					date: actualDate,
					jobNumber,
					shift,
					newDate: newDate,
					locationIndex,
					newLocationIndex: _locationIndex == null ? locationIndex : _locationIndex as number,
					nrOfItemsOnSameJobNumber: nrOfItemsWithSameJobNumber as number,
					numberOfItemsNewDaySameJN: numberOfItemsNewDaySameJN as number,
					itemsToRemove: _itemToOverWrite?.locationIndex != null ? _itemToOverWrite : null,
					equipmentSubcategoriesList: equipmentSubcategoriesInput as string
				},
			},
		})
		);
	}

	const updateForemans = async (foremanNameToBeRemoved: string) => {
		const _foremanNames = blockedForemans.filter(f => f !== foremanNameToBeRemoved);

		const where: UpdateSuperIntendentPavingCalendarInput = {
			date: parseInt(props.calendarDay?.date),
			jobNumbersBlocked: jobsLocked,
			noWorkForForemans: _foremanNames
		}

		const [error,] = await to(updateSuperIntendentPavingCalendar({ variables: { where } }));
		if (error == null) {
			const foremans: UpdateBlockedForemanFromCurrentDay[] = [];
			_foremanNames.forEach(f => {
				foremans.push({
					unix: parseInt(props.calendarDay?.date),
					lock: true,
					foremanName: f
				})
			});
			dispatch(updateBlockedForemanFromDay({ unix: parseInt(props.calendarDay?.date), foremans }));
		}
	}

	const canDropElement = () => {
		if (allowToModify === true) {
			if (isDayDisabled === false) {
				if (isGeneral === true) return true;
			}
		}
		return false;
	}

	return (
		<>
			{cloneElement(
				TouchableMenu,
				undefined,
				<DroppableItem
					id={props.calendarDay?.date}
					onChange={(event) => {
						onDropEvent(
							event.dragSource.unixDate,
							event.dropTarget?.unixDate as number,
							event.dragSource.jobNumber as number,
							event.dragSource.locationIndex as number,
							event.dragSource.nrOfItemsWithSameJobNumber as number,
							event.dragSource.shift as string,
							event.dragSource.pavingForeman as string,
						);
					}}
					unixDate={parseInt(props.calendarDay?.date)}
					itemsFromDay={itemFromDay}
					canDropElement={canDropElement()}
				>
					{(ref, isOver, canDrop) => {
						const opacity =
							canDrop != null ? 1 : canDrop !== true ? 0.3 : 1;
						const border = isOver ? "1px solid #00A0DF" : "";
						const cursor = isOver && !canDrop ? "no-drop" : "pointer";

						return (
							<div
								className={
									data != null && data?.pavingForemans?.length < props.items.length ?
										allowToModify === true
											? state.isScheduleLocked === true ? "Day Orange_Border Schedule_Locked" : "Day Orange_Border"
											: state.isScheduleLocked === true ? "Day Orange_Border Locked Schedule_Locked" : "Day Orange_Border Locked"
										:
										allowToModify === true
											? state.isScheduleLocked === true ? "Day Schedule_Locked" : "Day"
											: state.isScheduleLocked === true ? "Day Locked Schedule_Locked" : "Day Locked"
								}
								style={{ border, opacity, cursor }}
								ref={ref}
							>
								<DayMenu pageX={pageX} pageY={pageY} unix={props.calendarDay?.date} visible={visible} pavingForemans={data != null ? data.pavingForemans : []} />
								<div
									style={{
										textAlign: "center",
										fontSize: "10px",
									}}
								>
									{moment(
										parseInt(props.calendarDay?.date)
									).utc().format("MMMM")}
								</div>
								<div className="Day_Header">
									<div className="Day_Number">
										{
											moment(
												parseInt(props.calendarDay?.date)
											).utc().format("D")
										}
									</div>
									<button
										className="Day_Status"
										onClick={() => {
											onSubmit();
										}}
										disabled={
											allowToModify === true && isGeneral === true ? false : true
										}
										title={
											state.isScheduleLocked === true
												? "Click on the button to unlock the day"
												: "Click on the button to lock the day"
										}
										ref={refStatusDay}
									>
										{state.isScheduleLocked === true
											? "SCHEDULE LOCKED"
											: "LOCK SCHEDULE"}
									</button>
								</div>
								<div className="Day_Content">
									<div className="Day_Items">
										{props.items?.map((item, index) => (
											<ItemFromCalendar
												key={`${item?.date} ${index}`}
												item={item}
												pavingForemans={data != null ? data.pavingForemans : []}
												isBorderRed={state.pavingForemansNameWithMoreThan2Resources.includes(item?.pavingForeman!) ? true : false}
												jobsToLock={state.jobNumbersToLock}
											/>
										))}
									</div>
									{props.items && props.items?.length > 0 ? (
										<div className="Day_Total">
											Tonnage: {totalTonnage}
										</div>
									) : null}
								</div>
							</div>
						);
					}}
				</DroppableItem>
			)}
		</>
	);
};
