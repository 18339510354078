import React, { useEffect, useRef, useState } from "react";
import "./FullDayPreviewHistory.css";
import { SelectRangeOfDates } from "../SelectRangeOfDates/SelectRangeOfDates";
import moment from "moment";
import { useQuery } from "@apollo/react-hooks";
import { SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY } from "../../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY";
import { Query } from "../../graphql/schema-types";
import { filteredItems, IState } from "../../utils/filteredItems";
import { createPavingModule } from "../../redux/appSlice";
import { PAVING_FOREMANS_QUERY } from "../../graphql/queries/PAVING_FOREMANS_QUERY";
import { ScrollSync, ScrollSyncNode } from "scroll-sync-react";
import { SheetHeader } from "../SuperIntendentPavingModule/FullDayPreview/SheetHeader/SheetHeader";
import { ExpandableSheet } from "../SuperIntendentPavingModule/FullDayPreview/ExpandableSheet/ExpandableSheet";
import { Row } from "./Row/Row";
import { FullScreenLoadingIndicator } from "../Modal/LoadingIndicator/FullScreenLoadingIndicator";


export const FullDayPreviewHistory = () => {
  const [state, setState] = useState<IState>(filteredItems([], [], []));
  const [dates, setDates] = useState<string[]>([]);
  const [nrOfDays, setNrOfDays] = useState<number>(0);
  const [unixDate, setUnixDate] = useState<number[]>([]);

  const { data: pavingForemansList } = useQuery<Pick<Query, "pavingForemans">>(PAVING_FOREMANS_QUERY);
  const { data, loading } = useQuery<Pick<Query, "superIntendentPavingCalendarDatas">>(SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY, {
    variables: { where: { startDate: unixDate[0], endDate: unixDate[1], pavingSuperName: "" } },
    skip: unixDate[0] == null || unixDate[1] == null,
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  const ref = useRef<any>(null);

  const onPickerDatesChange = (dates: string[]) => {
    setDates(dates);

    const _dates: number[] = [];
    dates?.forEach(date => {
      _dates.push((moment(date).utc().unix()) * 1000)
    });

    setUnixDate(_dates);

    const _firstDay = moment(dates?.[0]);
    const _lastDay = moment(dates?.[1]);

    const daysLength = Math.abs(moment.duration(_firstDay.diff(_lastDay)).asDays()) + 1;
    setNrOfDays(daysLength);
  }

  // generate days from the range of dates selected
  const generatedDays = (dates.length === 2 && nrOfDays !== 0) ? createPavingModule(nrOfDays as number, dates[0]) : null;

  // filter paving foreman array & remove duplicates from array
  const _pavingForemans: string[] | undefined = pavingForemansList?.pavingForemans
    ?.map(foreman => foreman?.username as string);
  const filteredPavForemans: string[] | undefined = _pavingForemans?.filter((foreman, index) => _pavingForemans?.indexOf(foreman) === index);
  filteredPavForemans != null && filteredPavForemans.push("Unassgined");

  useEffect(() => {
    if (data == null || data.superIntendentPavingCalendarDatas == null) return;

    if (generatedDays != null && Object.keys(generatedDays).length > 0) {
      setState(
        {
          ...state,
          filteredItems: filteredItems(Object.keys(generatedDays), filteredPavForemans as string[], data?.superIntendentPavingCalendarDatas.correctItems).filteredItems,
          dayItemsLength: filteredItems(Object.keys(generatedDays), filteredPavForemans as string[], data?.superIntendentPavingCalendarDatas.correctItems).dayItemsLength
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.superIntendentPavingCalendarDatas]);

  const sideScroll = (element: any, direction: string, speed: number, distance: number, step: number) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === 'left') {
        element.scrollLeft -= step;
      } else if (direction === 'right') {
        element.scrollLeft += step;
      } else if (direction === 'up') {
        element.scrollTop -= step;
      } else if (direction === 'down') {
        element.scrollTop += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  const onScrollLeft = () => {
    const container = document.getElementById('rightSide');
    sideScroll(container, 'left', 25, 100, 10);
  };

  const onScrollRight = () => {
    const container = document.getElementById('rightSide');
    sideScroll(container, 'right', 25, 100, 10);

  };

  const onScrollUp = () => {
    const container = document.getElementById('secondaryScroll');
    sideScroll(container, 'up', 25, 100, 10);
  };

  const onScrollDown = () => {
    const container = document.getElementById('secondaryScroll');
    sideScroll(container, 'down', 25, 100, 10);

  };

  return (
    <>
      {loading && <FullScreenLoadingIndicator />}
      <div className="Full_Day_Preview_History">
        <div className="Select_Days">
          <div>
            <SelectRangeOfDates
              currentDates={dates}
              onPickerChange={dates => onPickerDatesChange(dates)}
            />
          </div>

          <div>
            <div>Selected days: {dates[0]} - {dates[1]}</div>
          </div>
        </div>

        <div className="Full_Day_Preview">
          <div className="Modal_Bar">
            <div className="Scroll_Horizontally_Buttons">
              <button onClick={() => onScrollLeft()} onMouseEnter={() => onScrollLeft()} title="Scroll to left" id="slideBack">
                <i className="fa fa-chevron-left"></i>
              </button>
              <button onClick={() => onScrollRight()} onMouseEnter={() => onScrollRight()} title="Scroll to right" id="slide">
                <i className="fa fa-chevron-right"></i>
              </button>
            </div>
          </div>
          <ScrollSync>
            <div className="Modal_Body">
              <ScrollSyncNode scroll="syncer-only">
                <div className="Days_Left_Header" ref={ref} id="secondaryScroll">
                  {
                    generatedDays && Object.keys(generatedDays)?.map((day, index) => (
                      <div className="date" key={index}
                        style={{
                          height: state?.dayItemsLength?.[day]?.itemsLength == null || state.dayItemsLength?.[day]?.itemsLength === 0 ? "30px" : state?.dayItemsLength?.[day]?.itemsLength * 30,
                          backgroundColor: moment(parseInt(day)).utc().days() === 6 || moment(parseInt(day)).utc().days() === 0 ? "rgba(245, 123, 32, 0.2)" : ""
                        }}
                      >{moment(parseInt(day)).utc().format("MM-DD-YY")}</div>
                    ))
                  }
                </div>
              </ScrollSyncNode>
              <div className="Wrap_Right_Side" id="rightSide">
                {
                  filteredPavForemans && filteredPavForemans?.map((pavForemanName, i) => (
                    <ExpandableSheet key={i}>

                      <div className={"SuperIntendent_Sheet"} key={i + pavForemanName}>
                        <div className="Wrap_Header">
                          <div className="SuperIndendent_Name">{(pavForemanName == null || pavForemanName === " ") ? "Unassigned" : pavForemanName + " "}
                          </div>
                          <div className="Wrap_SheetHeader">
                            <SheetHeader />
                          </div>
                        </div>
                        <ScrollSyncNode scroll="synced-only" >
                          <div className="FullBody" ref={ref} >
                            <div className="Body">
                              {
                                generatedDays && Object.keys(generatedDays)?.map(day => (
                                  <div className="Body_Row"
                                    key={day}
                                    data-day={moment(parseInt(day)).utc().format("DD-MMMM")}
                                    style={{
                                      height: state?.dayItemsLength?.[day]?.itemsLength == null || state.dayItemsLength?.[day]?.itemsLength === 0 ? "30px" : state?.dayItemsLength?.[day]?.itemsLength * 30,
                                      backgroundColor: moment(parseInt(day)).utc().days() === 6 || moment(parseInt(day)).utc().days() === 0 ? "rgba(245, 123, 32, 0.2)" : ""
                                    }}
                                  >
                                    {
                                      state?.filteredItems?.[day]?.[pavForemanName]?.map((superIntendentPavingCalendarData, index) => (
                                        <div className="Sheet_Row" key={index}>
                                          <Row item={superIntendentPavingCalendarData} />
                                        </div>
                                      ))
                                    }
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </ScrollSyncNode>
                      </div>
                    </ExpandableSheet>
                  ))
                }
              </div>
              <div className="Scroll_Vertically_Buttons">
                <button onClick={() => onScrollUp()} onMouseEnter={() => onScrollUp()} title="Scroll up" id="slideUp">
                  <i className="fa fa-chevron-up"></i>
                </button>
                <button onClick={() => onScrollDown()} onMouseEnter={() => onScrollDown()} title="Scroll down" id="slideDown">
                  <i className="fa fa-chevron-down"></i>
                </button>
              </div>
            </div>
          </ScrollSync>
        </div>
      </div>
    </>
  );
}