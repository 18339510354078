import React, { cloneElement, FC, useEffect, useState } from "react";
import "./ItemFromCalendar.css";
import { SuperIntendentPavingCalendarDatas, Maybe, PavingForeman } from "../../../graphql/schema-types";
import { DraggableItem } from "../DraggableItem/DraggableItem";
import { useMutation } from "@apollo/react-hooks";
import { SAVE_PAVING_FOREMAN } from "../../../graphql/mutations/SAVE_PAVING_FOREMAN";
import { useDispatch, useSelector } from "react-redux";
import { changePavingItemForeman } from "../../../redux/appSlice";
import ItemMenu from "../ItemMenu/ItemMenu";
import { RootState } from "../../../redux/store";
import { useTouchableMenu } from "../../../hooks/useTouchableMenu";

interface IItemFromCalendarProps {
  item?: Maybe<SuperIntendentPavingCalendarDatas>;
  pavingForemans: Maybe<PavingForeman>[];
  isBorderRed: boolean;
  jobsToLock: number[];
}
interface Foreman extends PavingForeman {
  usernameInitials: String
}

export const ItemFromCalendar: FC<IItemFromCalendarProps> = (props) => {

  const [pavingForeman, setPavingForeman] = useState<string>("");
  const [savePavForeman] = useMutation(SAVE_PAVING_FOREMAN);

  const dispatch = useDispatch();

  const isDayDisabled = useSelector((state: RootState) => state.app.pavingModule[props.item?.date]?.isDayBlocked);

  const isGeneral = useSelector((state: RootState) => state.app.pavingModuleUser.isInGeneralPavingSupers);

  const isJobLocked = useSelector((state: RootState) => state.app.pavingModule[props.item?.date]?.jobNumbersBlocked?.includes(props.item?.jobNumber as number));

  const itemsFromDayRedux = useSelector((state: RootState) => state.app.pavingModule[props.item?.date]?.items);

  let foremansBlocked = useSelector((state: RootState) => state.app.pavingModule[props.item?.date]?.noWorkForForeman);

  useEffect(() => {
    setPavingForeman(props.item?.pavingForeman as string);
  }, [props.item])

  const [TouchableMenu, { pageX, pageY, visible }] = useTouchableMenu();

  const onPavingForemanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPavingForeman(e.target.value);
    dispatch(changePavingItemForeman({ date: props.item?.date, jobNumber: props.item?.jobNumber as number, locationIndex: props.item?.locationIndex as number, pavingForeman: e.target.value, shift: props.item?.shift as string }))

    // mutation update in jobInfo
    // it saves for the paving resources from JobInfo table the foreman
    savePavForeman({
      variables: {
        where: {
          jobNumber: props.item?.jobNumber,
          date: props.item?.date,
          pavingForeman: e.target.value,
          locationIndex: props.item?.locationIndex
        }
      }
    });
  }

  const isItemDraggable = () => {
    if (isDayDisabled === false) {
      if (isJobLocked === false) {
        if (isGeneral === true)
          return true;
      }
    }
    return false;
  }

  const _pavingForemans = props?.pavingForemans?.sort((a, b) => a?.username! < b?.username! ? -1 : 1);

  let pavForemans: Foreman[] = [];
  _pavingForemans.forEach((pav) => {
    const usernameSubstrings = pav?.username?.split(" ");

    let _usernameInitials = "";
    usernameSubstrings?.forEach((u, index) => {
      _usernameInitials = _usernameInitials + u.substring(0, 1) + ".";
    });

    pavForemans.push({
      ...pav,
      usernameInitials: _usernameInitials as string
    })
  });

  return (
    <>
      {cloneElement(
        TouchableMenu,
        undefined,
        <DraggableItem
          id={props.item?.date}
          onChange={(event) => { }}
          unixDate={props.item?.date}
          isDraggable={isItemDraggable()}
          jobNumber={props.item?.jobNumber as number}
          locationIndex={props.item?.locationIndex as number}
          pavingForeman={props.item?.pavingForeman as string}
          nrOfItemsWithSameJobNumber={itemsFromDayRedux.filter(item => item?.jobNumber === props.item?.jobNumber as number).length}
          shift={props.item?.shift as string}
        >
          {(ref, isDragging) => {
            const opacity = isDragging ? 0.3 : isDragging ? 0.3 : 1;

            return (
              <div className={props.item?.shift as string === "N"
                ? "Item NightShift"
                : props.item?.shift as string === "N 2"
                  ? "Item NightShift SpecialShift"
                  : props.item?.shift as string === "D" ? "Item DayShift" : props.item?.shift as string === "D 2" ? "Item DayShift SpecialShift"
                    : "Item"
              } style={{ opacity }} ref={ref}>
                <div className={isJobLocked
                  ? "Green_Border"
                  : props.isBorderRed === true
                    ? "Blink_Border"
                    : (props.item?.shift as string === "D 2" || props.item?.shift as string === "N 2") ? "Border SpecialShift"
                      : "Border"}>
                  <ItemMenu jobNumber={props.item?.jobNumber as number} isBorderRed={props.isBorderRed} jobsToLock={props.jobsToLock} item={props.item} pageX={pageX} pageY={pageY} visible={visible} />
                  <div className="SubItem">{props.item?.jobNumber}</div>
                  <div className="SubItem">
                    {props.item!.jobName!.length! >= 10 ? props.item?.jobName?.substring(0, 10) + ".." : props.item?.jobName}
                  </div>
                  <div className="SubItem">{props.item?.tonnage}</div>
                  <select className="SubItem Select" value={pavingForeman === " " || pavingForeman == null ? "" : pavingForeman} onChange={onPavingForemanChange} style={{ outline: 0 }} disabled={(isDayDisabled || isJobLocked) || !isGeneral as boolean}>
                    <option value="" disabled>
                      Foreman:
                    </option>
                    {
                      pavForemans.length > 0 && pavForemans.map((foreman, index) => (
                        <option value={foreman?.username as string} key={index} disabled={foremansBlocked?.includes(foreman?.username as string) ? true : false}>
                          {foreman?.usernameInitials}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>
            )
          }}
        </DraggableItem>
      )}
    </>
  )
}