import React, { FC, useRef, memo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import JobSelect from "./JobSelect";
import Menu from "./Menu";
import moment from 'moment';
import DayDescription from "./DayDescription";
import { DayAdditionalResourceComments } from "./DayAdditionalResourceComments";
import { useQuery } from "@apollo/react-hooks";
import { Maybe, Query, SuperIntendentPavingCalendar, WeatherContentData, WeatherData } from "../graphql/schema-types";
import { SUPER_INTENDENT_PAVING_CALENDAR_QUERY } from "../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_QUERY";
interface IDayProps {
  locationIndex: number;
  unix: number;
  weatherContent: WeatherContentData;
  weatherValues: WeatherData[];
}

const getURLDay = (content: WeatherContentData, data: WeatherData[], shortForecast: string) => {
  let url;
  data?.forEach(d => {
    d?.shortForecast?.forEach(sf => {
      if (shortForecast.includes(sf as string)) {
        content?.weatherContentDataDay?.forEach(con => {
          if (con?.Key === d.photo) {
            url = con?.URL;
          }
        })
      }
    })
  })

  return `data:image/png;charset=utf-8;base64,${url}`;
}

const getURLNight = (content: WeatherContentData, data: WeatherData[], shortForecast: string) => {
  let url;
  data?.forEach(d => {
    d?.shortForecast?.forEach(sf => {
      if (shortForecast.includes(sf as string)) {
        content?.weatherContentDataNight?.forEach(con => {
          if (con?.Key === d.photo) {
            url = con?.URL;
          }
        })
      }
    })
  })
  return `data:image/png;charset=utf-8;base64,${url}`;
}

const Day: FC<IDayProps> = props => {
  const outerRef = useRef(null);

  const date = useSelector((state: RootState) => state.app.schedule?.locations?.[props.locationIndex]?.daysByUnix?.[props.unix].date);
  const day = useSelector((state: RootState) => state.app.schedule?.locations?.[props.locationIndex]?.daysByUnix?.[props.unix].day);
  const weekDay = useSelector((state: RootState) => state.app.schedule?.locations?.[props.locationIndex]?.daysByUnix?.[props.unix].weekDay);
  const temperatures = useSelector((state: RootState) => state.app.schedule?.locations?.[props.locationIndex]?.daysByUnix?.[props.unix]?.forecast);
  const resourceCodesByResourceType = useSelector((state: RootState) => state.app.resourceCodesByResourceType);
  const resourceIdIndexesByResourceType = useSelector((state: RootState) => state.app.resourceIdIndexesByResourceType);
  const customCrewsByJobNumber = useSelector((state: RootState) => state.app.customCrews);
  const isDayLocked = useSelector((state: RootState) => state.app.lockedDays.includes(props.unix));
  const jobNumber = useSelector((state: RootState) => state.app.start.jobNumber);

  const [lockedDaysFromRefetch, setLockedDaysFromRefetch] = useState<Maybe<number>[]>([]);

  const { data: lockedDaysData, refetch: refetchLockedDays } = useQuery<Pick<Query, "superIntendentPavingCalendar">>(SUPER_INTENDENT_PAVING_CALENDAR_QUERY, {
    variables: {
      where: {
        startDate: (moment.utc(moment().startOf('month').format("YYYY-MM-DD")).unix()) * 1000,
        endDate: (moment.utc(moment().startOf('month').add(1, 'months').format("YYYY-MM-DD")).unix()) * 1000,
      }
    },
    fetchPolicy: "no-cache"
  });

  const checkForLockedDays = () => {
    let _lockedDays: Maybe<number>[] = [];
    lockedDaysData?.superIntendentPavingCalendar.forEach((item: Maybe<SuperIntendentPavingCalendar>) => {
      if (item?.jobNumbersBlocked?.includes(jobNumber as number) || item?.status === true) _lockedDays.push(item.date)
    });
    return _lockedDays;
  } 
  
  const reloadLockedDays = () => {
    refetchLockedDays();
    setLockedDaysFromRefetch(checkForLockedDays());
  }

  return (
    <div ref={outerRef} className="app">
      <Menu outerRef={outerRef} locationIndex={props.locationIndex} unix={props.unix} />
      <div className="Tabble_day_column">
        <div className="Table_head" style={{ backgroundColor: (day === 6 || day === 0) ? 'rgba(245, 123, 32, 0.6)' : '#a5c8c1' }} >
          <div className="Inner_border">
            <div className="Day_Date">{date}</div>
            <div className="Forecast">
              <div className="Forecast_Day">
                {temperatures[temperatures.length < 3 ? 0 : 1] != null ?
                  < img className={"Forecast_Icon"}
                    src={getURLDay(props?.weatherContent as WeatherContentData, props?.weatherValues as WeatherData[], temperatures[temperatures.length < 3 ? 0 : 1]?.shortForecast)}
                    alt={temperatures[temperatures.length < 3 ? 0 : 1]?.shortForecast} /> : null}
                <div>
                  {
                    temperatures[temperatures.length < 3 ? 0 : 1]?.temperature != null
                      ? temperatures[temperatures.length < 3 ? 0 : 1]?.temperature + "°F"
                      : null
                  }
                </div>
              </div>
              <div className="Forecast_Night">
                {temperatures[temperatures.length < 3 ? 1 : 2] != null ?
                  <img className={"Forecast_Icon"}
                    src={getURLNight(props?.weatherContent as WeatherContentData, props?.weatherValues as WeatherData[], temperatures[temperatures.length < 3 ? 1 : 2]?.shortForecast)}
                    alt={temperatures[temperatures.length < 3 ? 1 : 2]?.shortForecast} /> : null}
                <div>
                  {
                    temperatures[temperatures.length < 3 ? 1 : 2]?.temperature != null
                      ? temperatures[temperatures.length < 3 ? 1 : 2]?.temperature + "°F"
                      : null
                  }
                </div>
              </div>
            </div>
            <div className="Day_Day">{weekDay} {(isDayLocked === true || lockedDaysFromRefetch.includes(props.unix)) ? <i className="fa fa-lock" aria-hidden="true"></i> : null}</div>
            <div className="Bottom_Footer">
              <div className="Resource">Resource</div>
              <div className="QTY">QTY</div>
            </div>
          </div>
        </div>

      </div>

      <div style={{ height: "665px", display: "flex", flexDirection: "column" }}>

        <div>
          <div className="Column_title">
            <DayDescription
              locationIndex={props.locationIndex}
              unix={props.unix}
            />
          </div>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, index) => {
            return (
              <div key={index} className="Column_row">
                <JobSelect
                  onReloadLockedDays={() => reloadLockedDays()}
                  resourceCodes={resourceCodesByResourceType["Labor"]}
                  resourceIdIndexes={resourceIdIndexesByResourceType["Labor"]}
                  indexPos={index}
                  locationIndex={props.locationIndex}
                  unix={props.unix}
                  crews={customCrewsByJobNumber}
                  resourceType={"labor"}
                />
              </div>
            )
          })}
        </div>

        <div className="Eq_req" style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, index) => {
            return (
              <div key={index} className="Column_row">
                <JobSelect
                  onReloadLockedDays={() => reloadLockedDays()}
                  resourceCodes={resourceCodesByResourceType["Equipment"]}
                  resourceIdIndexes={resourceIdIndexesByResourceType["Equipment"]}
                  indexPos={index}
                  locationIndex={props.locationIndex}
                  unix={props.unix}
                  resourceType={"equipment"}
                />
              </div>
            )
          })}
          <DayAdditionalResourceComments
            locationIndex={props.locationIndex}
            unix={props.unix}
          />
        </div>
      </div>

    </div >
  )
};

export default memo(Day);