import gql from "graphql-tag";

export const SUPER_INTENDENT_PAVING_CALENDAR_QUERY = gql`
  query superIntendentPavingCalendar($where: SuperIntendentPavingCalendarQueryInput!){
    superIntendentPavingCalendar(where: $where) {
			pk
			date
			status
			jobNumbersBlocked
			noWorkForForemans
		}
  }
`;
