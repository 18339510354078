import React, { FC } from "react";
import css from "./LoadingIndicator.module.css";

export const LoadingIndicator: FC = props => {
  return (
    <i
      className={`fas fa-sync-alt ${css.performance}`}
      style={{ fontSize: "4em", color: "whitesmoke" }}
    />
  );
}
