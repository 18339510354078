import React, { FC, CSSProperties, useEffect, useRef, useReducer, ReactNode, useState } from "react";
import flatpickr from "flatpickr";
import { Instance } from "flatpickr/dist/types/instance";
import moment from "moment";
import "flatpickr/dist/themes/material_green.css";
import { Maybe } from "../../../../graphql/schema-types";

export interface ISelectMultipleDatesProps {
  currentDates: string[] | null | undefined;
  disabled?: boolean;
  /**
   * Fires only when the picker changes the date
   * @param date number
   */
  onPickerChange?(dates: string[]): void;
  containerStyle?: CSSProperties;
  children?(state: IState): ReactNode;
  lockedDays?: string[] | undefined;
  dates?: { [date: string]: boolean };
  redDates?: Maybe<string>[];
  lockedItems3WeekRange?: string[] | undefined;
}

interface IState {
  currentDate: string | null;
  dates: string[] | null;
  visible: boolean;
  previousAction?: IAction;
}

enum ActionTypes {
  SET_PICKER_DATES = "SET_PICKER_DATE",
  SET_PROPS_DATES = "SET_PROPS_DATE",
  SET_VISIBILITY = "SET_VISIBILITY"
}

interface IAction<T = any> {
  type: ActionTypes;
  payload?: T;
}

const getInitialState = (): IState => ({
  dates: null,
  currentDate: null,
  visible: false,
});

const getDateFromSelectedDates = (date: Date) => {
  let _date: Date;
  if (date == null) {
    _date = moment().toDate();
  } else {
    _date = date;
  }
  return _date
}

const mainReducer = (state: IState, action: IAction): IState => {
  switch (action.type) {

    case ActionTypes.SET_PICKER_DATES: {
      const dates = action.payload as Date[];
      let _dates: string[] = [];

      dates.forEach((date) => {
        const d = getDateFromSelectedDates(date);
        const _date = moment(d).format("YYYY.MM.DD")
        _dates.push(_date);
      })

      return ({
        ...state,
        dates: _dates,
      });
    }

    case ActionTypes.SET_PROPS_DATES: {
      let dates: string[] = action.payload;

      return ({
        ...state,
        dates,
      });
    }

    case ActionTypes.SET_VISIBILITY: {
      const visible = action.payload as boolean;
      return ({
        ...state,
        visible,
      });
    }

    default:
      break;
  }

  return state;
}

const reducer = (state: IState, action: IAction): IState => {
  return ({
    ...mainReducer(state, action),
    previousAction: action,
  });
}

export const SelectMultipleDates: FC<ISelectMultipleDatesProps> = props => {
  const [state, dispatch] = useReducer(reducer, getInitialState())
  const [lockedDays, setLockedDays] = useState<string[]>([]);

  const fp = useRef<Instance>(null);
  const elem = useRef<HTMLInputElement>(null);

  // on props change, the instance should reflect that
  useEffect(() => {
    if (props.currentDates == null) return;
    dispatch({ type: ActionTypes.SET_PROPS_DATES, payload: props.currentDates });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    fp.current != null
      ? fp.current.setDate(
        props.currentDates.map(date => moment(date, "YYYY.MM.DD").toDate())
      )
      : null;
  }, [props.currentDates])

  // destroy picker on state.visible === false
  useEffect(() => {
    if (state.visible) return;
    if (fp.current == null) return;
    destroy();
  }, [state.visible]);

  useEffect(() => {
    let _state: string[] = [];
    if (props.lockedDays != null && props.lockedDays.length > 0) {
      props.lockedDays.forEach(day => {
        const _day = moment(day).format("DD MM YYYY");
        _state.push(_day);
      })
    }
    if (props.lockedItems3WeekRange != null && props.lockedItems3WeekRange.length > 0) {
      props.lockedItems3WeekRange.forEach(day => {
        _state.push(day);
      })
    }
    setLockedDays(_state);
  }, [props.lockedDays, props.lockedItems3WeekRange])

  const onWheel = useRef(() => {
    dispatch({ type: ActionTypes.SET_VISIBILITY, payload: false });
  });

  const destroy = () => {
    document.removeEventListener("wheel", onWheel.current);
    if (fp.current == null) return;
    fp.current.close();
    fp.current.destroy();
    (fp.current as any) = null;
  };

  useEffect(() => {
    return () => {
      destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDayCreate = (dObj: any, dStr: any, fp: any, dayElem: HTMLElement & any) => {
    if (props?.lockedDays?.includes(moment(dayElem.dateObj).format("YYYY.MM.DD"))) {
      dayElem.innerHTML = `<div class="blocked">${dayElem.innerHTML}</div>`
    }

    if (props?.redDates?.includes(moment(dayElem.dateObj).format("YYYY.MM.DD"))) {
      dayElem.innerHTML = `<div class="red">${dayElem.innerHTML}</div>`
    }
    if (props?.dates?.[moment(dayElem.dateObj).format("YYYY.MM.DD")] !== true) return;
    dayElem.innerHTML = `<b>${dayElem.innerHTML}</b>`
  }

  useEffect(() => {
    if (fp.current == null) return;
    fp.current.set("onDayCreate", onDayCreate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentDates])

  const create = () => {
    if (elem.current == null) return;
    if (fp.current != null) return;
    (fp.current as any) = flatpickr(elem.current, {
      onChange(selectedDates, dateStr, instance) {
        dispatch({ type: ActionTypes.SET_PICKER_DATES, payload: selectedDates });

        props.onPickerChange?.(selectedDates.map(date => moment(date).format("YYYY.MM.DD")));
      },
      onReady(selectedDates, dateStr, instance) {
        instance.open();
      },
      onOpen(selectedDates, dateStr, instance) {
        dispatch({ type: ActionTypes.SET_VISIBILITY, payload: true });
      },
      onClose(selectedDates, dateStr, instance) {
        dispatch({ type: ActionTypes.SET_VISIBILITY, payload: false });
      },
      disableMobile: true,
      showMonths: 1,
      onDayCreate,
      dateFormat: "d m Y",
      mode: "multiple",
      minDate: (moment.utc(moment().startOf('month').format("YYYY-MM-DD")).unix()) * 1000,
      maxDate: ((moment.utc(moment().startOf('month').add(6, 'months').format("YYYY-MM-DD")).unix()) * 1000),
      disable: lockedDays,
    });
    document.addEventListener("wheel", onWheel.current);
    if (props.currentDates != null) {
      fp.current!.setDate(
        props.currentDates.map(date => moment(date, "YYYY.MM.DD").toDate())
      );
    }
  };

  return (
    <div ref={elem} onClick={create} className="Select_Multiple_Dates">Select Days</div>
    //<div ref={elem} onClick={create} className="Select_Multiple_Dates">Selected Days: {state.dates?.map(s => moment(s as string, "YYYY.MM.DD").format('MM/DD/YYYY') + " ")} </div>
  );
}