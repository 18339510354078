import React, { } from "react";
import { EmitterEvents } from "../emitter";
import { Backdrop } from "../Backdrop/Backdrop";
import { ModalContext, Modal, isElementModal, isComponentModal } from "../ModalContext/ModalContext";
import css from "./ModalHub.module.css"
import Portal from "../Portal";

type ModalCb<P = any> = (modal: Modal<P>) => void;

type CloseModalCb = (modal: { id: string }) => void;

type State = {
  modals: Modal[];
  topModals: Modal[];
}

export default class ModalHub extends React.PureComponent {
  static contextType = ModalContext;
  context: React.ContextType<typeof ModalContext> | null | undefined;

  _state: State = {
    modals: [],
    topModals: [],
  };

  onModal: ModalCb = (modal) => {
    if (!isElementModal(modal) && !isComponentModal(modal)) {
      console.log("invalid modal object:", modal);
      return;
    }
    this._state.modals.push(modal);
    this.forceUpdate();
  };

  onTopModal: ModalCb = (modal) => {
    if (!isElementModal(modal) && !isComponentModal(modal)) {
      console.log("invalid modal object:", modal);
      return;
    }
    this._state.topModals.push(modal);
    this.forceUpdate();
  };

  onCloseModal: CloseModalCb = (modal) => {
    if (typeof modal?.id !== "string") {
      console.log("invalid modal object:", modal);
      return;
    }
    this._state.modals = this._state.modals.filter(_modal => _modal.id !== modal.id);
    this.forceUpdate();
  };

  onCloseTopModal: CloseModalCb = (modal) => {
    if (typeof modal?.id !== "string") {
      console.log("invalid modal object:", modal);
      return;
    }
    this._state.topModals = this._state.topModals.filter(_modal => _modal.id !== modal.id);
    this.forceUpdate();
  };

  onCloseAllModals = () => {
    this._state.modals = [];
    this._state.topModals = [];
    this.forceUpdate();
  };

  componentDidMount() {
    this.context?.emitter?.on?.(EmitterEvents.MODAL, this.onModal);
    this.context?.emitter?.on?.(EmitterEvents.TOP_MODAL, this.onTopModal);
    this.context?.emitter?.on?.(EmitterEvents.CLOSE_MODAL, this.onCloseModal);
    this.context?.emitter?.on?.(EmitterEvents.CLOSE_TOP_MODAL, this.onCloseTopModal);
    this.context?.emitter?.on?.(EmitterEvents.CLOSE_ALL_MODALS, this.onCloseAllModals);
  }

  componentWillUnmount() {
    this.context?.emitter?.off?.(EmitterEvents.MODAL, this.onModal);
    this.context?.emitter?.off?.(EmitterEvents.TOP_MODAL, this.onTopModal);
    this.context?.emitter?.off?.(EmitterEvents.CLOSE_MODAL, this.onCloseModal);
    this.context?.emitter?.off?.(EmitterEvents.CLOSE_TOP_MODAL, this.onCloseTopModal);
    this.context?.emitter?.off?.(EmitterEvents.CLOSE_ALL_MODALS, this.onCloseAllModals);
  }

  _onCloseModal = (id?: string, elementProps?: any) => {
    if (id == null) { return; }
    this._state.modals = this._state.modals.filter(modal => modal.id !== id);
    elementProps?.onClose?.();
    this.forceUpdate();
  }

  _onCloseTopModal = (id?: string, elementProps?: any) => {
    if (id == null) { return; }
    this._state.topModals = this._state.topModals.filter(modal => modal.id !== id);
    elementProps?.onClose?.();
    this.forceUpdate();
  }

  render() {

    return (
      <Portal selector="#modal-root">

        {this._state.modals.map((modal, i) => {
          return (
            <Backdrop key={`${i}-${modal.id}`} className={`${css.center} ${css.backdrop}`}>
              {isElementModal(modal)
                ? React.cloneElement(modal.element, {
                  onClose: () => this._onCloseModal(modal.id, modal.element.props),
                })
                : null}
              {isComponentModal(modal)
                ? React.createElement(modal.component, {
                  ...modal.props,
                  onClose: () => this._onCloseModal(modal.id, modal.props),
                })
                : null}
            </Backdrop>
          )
        })}

        {this._state.topModals.map((modal, i) => {
          return (
            <Backdrop key={`${i}-${modal.id}`} className={`${css.center} ${css.backdrop}`}>
              {isElementModal(modal)
                ? React.cloneElement(modal.element, {
                  onClose: () => this._onCloseTopModal(modal.id, modal.element.props),
                })
                : null}
              {isComponentModal(modal)
                ? React.createElement(modal.component, {
                  ...modal.props,
                  onClose: () => this._onCloseTopModal(modal.id, modal.props),
                })
                : null}
            </Backdrop>
          )
        })}

      </Portal>
    )
  }
}