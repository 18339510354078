import { useMutation } from "@apollo/react-hooks";
import to from "await-to-js";
import React, { FC, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { EDIT_RESOURCES_ON_MATERIAL_DATAS } from "../../../graphql/mutations/EDIT_RESOURCES_ON_MATERIAL_DATAS";
import { EDIT_RESOURCES_ON_TRUCKING_DATAS } from "../../../graphql/mutations/EDIT_RESOURCES_ON_TRUCKING_DATAS";
import { CheckBox } from "../../Form/CheckBox";
import { CloseButton } from "../../Form/CloseButton";
import { Resource } from "../AddResources";
import "./EditResource.css";

interface IEditResource {
  onClose?(): void;
  resource: Resource;
  onEditResource(res: Resource, oldResource: Resource): void;
  usedResources: string[];
}

export const EditResource: FC<IEditResource> = props => {
  const [state, setState] = useImmer<Resource>({
    uID: "",
    key: "",
    operationType: null,
    type: "",
    value: ""
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const [editResourceOnMaterialDatas] = useMutation(EDIT_RESOURCES_ON_MATERIAL_DATAS);
  const [editResourceOnTruckingDatas] = useMutation(EDIT_RESOURCES_ON_TRUCKING_DATAS);

  useEffect(() => {
    if (props.resource == null) return;

    setState(draft => {
      draft.uID = props.resource.uID as string;
      draft.key = props.resource.key as string;
      draft.type = props.resource.type as string;
      draft.value = props.resource.value as string;
      draft.operationType = props.resource.type === "Material" ? props.resource.operationType : null
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.type == null ||
      state.type.trim() === "" ||
      state.value == null ||
      state.value.trim() === "" ||
      state.key == null ||
      state.key.trim() === ""
    ) {
      setIsButtonDisabled(true);
    }
    else {
      setIsButtonDisabled(false);
    }

  }, [state, setState]);

  const onKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _eValue = e.target.value;
    setState(draft => {
      draft.key = _eValue;
    })
  }

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _eValue = e.target.value;
    setState(draft => {
      draft.value = _eValue;
    })
  }

  const onOperationType = (checked: boolean) => {
    setState(draft => {
      checked === true
        ? draft.operationType = "paving"
        : draft.operationType = null
    })
  }

  const onSaveEdit = async () => {
    if (state.type === "Material") {
      await to(editResourceOnMaterialDatas({
        variables: {
          where: {
            key: state.key,
            operationType: state.operationType,
            value: state.value,
            type: state.type
          }
        }
      }));
    }

    if (state.type !== "Material") {
      await to(editResourceOnTruckingDatas({
        variables: {
          where: {
            key: state.key,
            value: state.value,
            type: state.type,
            uID: state.uID
          }
        }
      }));
    }
    // send to parent component the edited object
    props.onEditResource(state, props.resource);
    props.onClose?.();
  }

  const onDisableButton = () => {
    if (!props.usedResources.includes(state.value)) {
      if (isButtonDisabled === false) {
        return false;
      }
    }
    return true;
  }

  return (
    <div className="Edit_Resource">
      <div className="Edit_Resource_Header">
        <div className="Wrap">
          <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
          <div className="Title">Edit Resource</div>
        </div>
        <CloseButton onClick={props.onClose} />
      </div>
      <div className="Middle_Content">
        <div className="Header">
          <div className="header_item">Type</div>
          <div className="header_item">ID</div>
          <div className="header_item">Name</div>
          <div className="header_item">Operation Type Paving (for material only)</div>
        </div>
        <div className="Content">
          <div>{state.type}</div>
          <input
            onChange={(e) => onKeyChange(e)}
            value={state.key as string}
            type="text"
            placeholder="ID"
            readOnly={props.usedResources.includes(state.value) ? true : false}
          />
          <input
            onChange={(e) => onValueChange(e)}
            value={state.value as string}
            type="text"
            placeholder="Name"
            readOnly={props.usedResources.includes(state.value) ? true : false}
          />
          <CheckBox
            className="Operation_Type"
            onChange={(e) => { onOperationType(e.target.checked) }}
            checked={state.operationType == null ? false : true}
            disabled={state.type === "Material" ? false : true}
          />
        </div>
      </div>
      <div className="Button_Wrap">
        <button onClick={() => onSaveEdit()} disabled={onDisableButton()}>Save Resource</button>
        {
          props.usedResources.includes(state.value)
            ? <div className="Disabled_Button_Message">The resource is used in a paving or trucking item, please change the {state.type} resource and then edit the value</div>
            : null
        }
      </div>
    </div>
  )

}