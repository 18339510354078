import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOAD_OUT_TIME_QUERY } from "../../../../graphql/queries/LOAD_OUT_TIME_QUERY";
import { PAVING_SUPERS_QUERY } from "../../../../graphql/queries/PAVING_SUPERS_QUERY";
import { Maybe, Query, Resource, SuperIntendentPavingCalendarDatas, TruckingData } from "../../../../graphql/schema-types";
import { saveChangesToItemFromFullDayPreview } from "../../../../redux/appSlice";
import { RootState } from "../../../../redux/store";
import { FullScreenErrorIndicator } from "../../../Modal/ErrorIndicator/FullScreenErrorIndicator";
import { FullScreenLoadingIndicator } from "../../../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { ModalContext } from "../../../Modal/ModalContext/ModalContext";
import { TextInput } from "../../../TextInput";
import CrewComposition from "../CrewComposition/CrewComposition";
import { booleanType, IState } from "./Interfaces";
import "./SheetRow.css"

interface ISheetRow {
  item?: Maybe<SuperIntendentPavingCalendarDatas>;
  // if the email it's included in the paving supers users list
  isSupersSelectDisabled: boolean;
  truckingDatas?: Maybe<TruckingData>[] | undefined;
  pavingForemans?: string[] | undefined
  isEditable?: boolean
}


export const SheetRow: FC<ISheetRow> = (props) => {
  const { item } = props;

  const [state, setState] = useState<IState>({
    nrOfTrucks: 0,
    pavingSuper: "",
    pavingForeman: "",
    tonnage: 0,
    typeOfTrucks: "",
    truckCo: "",
    loadOutTime: "",
    tph: 0,
    material: "",
    plant: "",
    mixSubmital: "",
    mixDesignApproval: "",
    rtsSupport: "",
    bookTruckVendor: "",
    uts: "",
    extrawork: "",
    oilTruk: "",
    grinder12ft: 0,
    grinder4ft: 0,
    grinder6ft: 0,
    grinder7ft: 0,
    updateJobInfoTable: false,
    updateMasterCrewSchedulePavingTable: false,
    updateMasterCrewScheduleTable: false,
  });

  const dispatch = useDispatch();

  const { loading, error, data } = useQuery<Pick<Query, "pavingSupers">>(PAVING_SUPERS_QUERY);

  const { data: loadOutTimeData } = useQuery<Pick<Query, "loadOutTimeValues">>(LOAD_OUT_TIME_QUERY, { fetchPolicy: "no-cache" });

  const modal = useContext(ModalContext);

  const materials = useSelector((state: RootState) => state.app.materialDatas);
  const isDayBlocked = useSelector((state: RootState) => state.app.pavingModule[props.item?.date]?.isDayBlocked);
  const lockedJobs = useSelector((state: RootState) => state.app.pavingModule[props.item?.date]?.jobNumbersBlocked);
  const fullDayPreviewItem = useSelector((state: RootState) => state.app.fullDayPreview).filter(f => f.date === props?.item?.date)[0];

  const currentDay = moment().utc().format("MM-DD-YY");

  useEffect(() => {

    setState({
      ...state,
      nrOfTrucks: props.item?.nrOfTrucks as number,
      tonnage: props.item?.tonnage as number,
      typeOfTrucks: props.item?.typeOfTrucks as string,
      pavingSuper: props.item?.pavingSuperIntendent as string,
      pavingForeman: props.item?.pavingForeman as string,
      truckCo: props.item?.broker as string,
      loadOutTime: props.item?.loadOutTime as string,
      bookTruckVendor: props.item?.bookTruckVendor as string,
      grinder12ft: props.item?.grinder12ft as number,
      grinder4ft: props.item?.grinder4ft == null ? 0 : props.item.grinder4ft,
      grinder6ft: props.item?.grinder6ft as number,
      grinder7ft: props.item?.grinder7ft as number,
      material: props.item?.material as string,
      mixDesignApproval: props.item?.mixDesignApproval as string,
      mixSubmital: props.item?.mixSubmital as string,
      plant: props.item?.plant as string,
      rtsSupport: props.item?.rtsSupport as string,
      tph: props.item?.tph as number,
      uts: props.item?.uts as string,
      extrawork: props.item?.extraWork as string,
      oilTruk: props.item?.oilTruk as string,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item])

  const brokersData = props?.truckingDatas
    ?.filter(td => td?.type === "Broker")
    ?.sort((a, b) => a?.value! < b?.value! ? -1 : 1);


  if (brokersData !== null && brokersData !== undefined) {
    brokersData.push({
      key: "Other",
      type: "Broker",
      value: "Other (log in notes)"
    })
  }

  const _trukData = props?.truckingDatas
    ?.filter(td => td?.type === "TruckType")
    ?.sort((a, b) => a?.value! < b?.value! ? -1 : 1);

  if (_trukData !== null && _trukData !== undefined) {
    _trukData.push({
      key: "Other",
      type: "TruckType",
      value: "Other (log in notes)"
    })
  }

  const plantData = props?.truckingDatas
    ?.filter(td => td?.type === "Plant")
    ?.sort((a, b) => a?.value! < b?.value! ? -1 : 1);

  const _pavingSupers = data?.pavingSupers?.sort((a, b) => a?.name! < b?.name! ? -1 : 1);

  const onPavingSuperChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== state.pavingSuper) {
      setState({ ...state, pavingSuper: e.target.value, updateJobInfoTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: e.target.value,
        shift: props.item?.shift as string,
        updateJobInfoTable: true,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem?.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem?.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
      }));
    }
  }

  const onPavingForemanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== state.pavingForeman) {
      setState({ ...state, pavingForeman: e.target.value, updateJobInfoTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        shift: props.item?.shift as string,
        updateJobInfoTable: true,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: e.target.value,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem?.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem?.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
      }));
    }
  }

  const onTonnageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let tonnage: Maybe<number> = parseInt(e.target.value);
    tonnage = isNaN(tonnage) ? null : tonnage;
    if (tonnage != null && (tonnage < 0 || tonnage > 49999)) { return }

    if (tonnage !== state.tonnage) {
      setState({ ...state, tonnage: tonnage as number, updateMasterCrewSchedulePavingTable: true })
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        tonnage: tonnage,
        shift: props.item?.shift as string,
        updateMasterCrewSchedulePavingTable: true,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
      }));
    }
  }

  const onNrOfTrucksChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let nrOfTrucks: Maybe<number> = parseInt(e.target.value);
    nrOfTrucks = isNaN(nrOfTrucks) ? null : nrOfTrucks;
    if (nrOfTrucks != null && (nrOfTrucks < 0 || nrOfTrucks > 999)) { return }

    if (nrOfTrucks !== state.nrOfTrucks) {
      setState({ ...state, nrOfTrucks: nrOfTrucks as number, updateMasterCrewScheduleTable: true })
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        nrOfTrucks: nrOfTrucks as number,
        superIntendent: state.pavingSuper,
        shift: props.item?.shift as string,
        updateMasterCrewScheduleTable: true,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
      }));
    }
  }

  const onTypeOfTrucksChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== state.typeOfTrucks) {
      setState({ ...state, typeOfTrucks: e.target.value, updateMasterCrewScheduleTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        typeOfTrucks: e.target.value,
        shift: props.item?.shift as string,
        updateMasterCrewScheduleTable: true,
        loadOutTime: state?.loadOutTime as string,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
      }));
    }
  }

  const onTruckCoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== state.truckCo) {
      setState({ ...state, truckCo: e.target.value, updateMasterCrewScheduleTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: e.target.value,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        shift: props.item?.shift as string,
        updateMasterCrewScheduleTable: true,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
      }));
    }
  }

  const onLoadOutTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== state.loadOutTime) {
      setState({ ...state, loadOutTime: e.target.value, updateMasterCrewSchedulePavingTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        shift: props.item?.shift as string,
        updateMasterCrewSchedulePavingTable: true,
        loadOutTime: e.target.value as string,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
      }));
    }
  }

  const onTPHChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let tph: Maybe<number> = parseInt(e.target.value);
    tph = isNaN(tph) ? null : tph;
    if (tph != null && (tph < 0 || tph > 999)) { return }

    if (tph !== state.tph) {
      setState({ ...state, tph: tph as number, updateMasterCrewSchedulePavingTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        shift: props.item?.shift as string,
        updateMasterCrewSchedulePavingTable: true,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
        tph: tph as number,
      }));
    }
  }

  const onMixDesignApproval = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== state.mixDesignApproval) {
      setState({ ...state, mixDesignApproval: e.target.value, updateMasterCrewSchedulePavingTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        shift: props.item?.shift as string,
        updateMasterCrewSchedulePavingTable: true,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
        mixDesignApproval: e.target.value
      }));
    }
  }

  const onRtsSupport = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== state.rtsSupport) {
      setState({ ...state, rtsSupport: e.target.value, updateMasterCrewSchedulePavingTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        shift: props.item?.shift as string,
        updateMasterCrewSchedulePavingTable: true,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
        rtsSupport: e.target.value,
      }));
    }
  }

  const onBookTruckVendor = (e: React.ChangeEvent<HTMLInputElement>) => {
    const oilTruck = e.target.value != null && e.target.value !== "" ? "true" : "false";

    if (e.target.value !== state.bookTruckVendor) {
      setState({ ...state, bookTruckVendor: e.target.value, updateMasterCrewSchedulePavingTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        typeOfTrucks: state.typeOfTrucks,
        shift: props.item?.shift as string,
        updateMasterCrewSchedulePavingTable: true,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
        oilTruk: oilTruck,
        bookTruckVendor: e.target.value,
      }));
    }
  }

  const onUtsChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== state.uts) {
      setState({ ...state, uts: e.target.value, updateMasterCrewSchedulePavingTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        shift: props.item?.shift as string,
        updateMasterCrewSchedulePavingTable: true,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
        uts: e.target.value,
      }));
    }
  }

  const onMixSubmital = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== state.mixSubmital) {
      setState({ ...state, mixSubmital: e.target.value, updateMasterCrewSchedulePavingTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        typeOfTrucks: state.typeOfTrucks,
        shift: props.item?.shift as string,
        updateMasterCrewSchedulePavingTable: true,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
        mixSubmital: e.target.value,
      }));
    }
  }

  const onExtraWorkChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== state.extrawork) {
      setState({ ...state, extrawork: e.target.value, updateMasterCrewSchedulePavingTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        shift: props.item?.shift as string,
        updateMasterCrewSchedulePavingTable: true,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
        extrawork: e.target.value
      }));
    }
  }

  const onMaterialChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== state.material) {
      setState({ ...state, material: e.target.value, updateMasterCrewSchedulePavingTable: true, updateMasterCrewScheduleTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        shift: props.item?.shift as string,
        updateMasterCrewSchedulePavingTable: true,
        updateMasterCrewScheduleTable: true,
        material: e.target.value,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
      }));
    }
  }

  const onPlantChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== state.plant) {
      setState({ ...state, plant: e.target.value, updateMasterCrewSchedulePavingTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        shift: props.item?.shift as string,
        updateMasterCrewSchedulePavingTable: true,
        plant: e.target.value,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
      }));
    }
  }

  const onGrinder4ftChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let grinder4ft: Maybe<number> = parseInt(e.target.value);
    grinder4ft = isNaN(grinder4ft) ? null : grinder4ft;
    if (grinder4ft != null && (grinder4ft < 0 || grinder4ft > 999)) { return }

    if (grinder4ft !== state.grinder4ft) {
      setState({ ...state, grinder4ft: grinder4ft as number, updateMasterCrewSchedulePavingTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        shift: props.item?.shift as string,
        updateMasterCrewSchedulePavingTable: true,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
        grinder4ft: grinder4ft as number,
      }));
    }
  }

  const onGrinder6ftChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let grinder6ft: Maybe<number> = parseInt(e.target.value);
    grinder6ft = isNaN(grinder6ft) ? null : grinder6ft;
    if (grinder6ft != null && (grinder6ft < 0 || grinder6ft > 999)) { return }

    if (grinder6ft !== state.grinder6ft) {
      setState({ ...state, grinder6ft: grinder6ft as number, updateMasterCrewSchedulePavingTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        shift: props.item?.shift as string,
        updateMasterCrewSchedulePavingTable: true,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
        grinder6ft: grinder6ft as number,
      }));
    }
  }

  const onGrinder7ftChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let grinder7ft: Maybe<number> = parseInt(e.target.value);
    grinder7ft = isNaN(grinder7ft) ? null : grinder7ft;
    if (grinder7ft != null && (grinder7ft < 0 || grinder7ft > 999)) { return }

    if (grinder7ft !== state.grinder7ft) {
      setState({ ...state, grinder7ft: grinder7ft as number, updateMasterCrewSchedulePavingTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        shift: props.item?.shift as string,
        updateMasterCrewSchedulePavingTable: true,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
        grinder7ft: grinder7ft as number,
      }));
    }
  }

  const onGrinder12ftChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let grinder12ft: Maybe<number> = parseInt(e.target.value);
    grinder12ft = isNaN(grinder12ft) ? null : grinder12ft;
    if (grinder12ft != null && (grinder12ft < 0 || grinder12ft > 999)) { return }

    if (grinder12ft !== state.grinder12ft) {
      setState({ ...state, grinder12ft: grinder12ft as number, updateMasterCrewSchedulePavingTable: true });
      dispatch(saveChangesToItemFromFullDayPreview({
        ...state,
        broker: state.truckCo,
        date: props.item?.date,
        jobNumber: props.item?.jobNumber as number,
        locatinIndex: props.item?.locationIndex as number,
        superIntendent: state.pavingSuper,
        shift: props.item?.shift as string,
        updateMasterCrewSchedulePavingTable: true,
        loadOutTime: state.loadOutTime as string,
        areaManager: item?.areaManager as string,
        jobMap: item?.jobMap as string,
        jobName: item?.jobName as string,
        pavingForeman: item?.pavingForeman as string,
        pavingSuper: item?.pavingSuperIntendent as string,
        pavingSuperIntendent: item?.superIntendent as string,
        pmpe: item?.pmpe as string,
        crewMakeLab: item?.crewMakeLab as number,
        crewMakeOp: item?.crewMakeOp as number,
        resourcesLab: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesLab != null) ? fullDayPreviewItem.resourcesLab : item?.resourcesLab as [Resource],
        resourcesOp: (fullDayPreviewItem != null && fullDayPreviewItem.resourcesOp != null) ? fullDayPreviewItem.resourcesOp : item?.resourcesOp as [Resource],
        grinder12ft: grinder12ft as number,
      }));
    }
  }

  const isDayDisabled = () => {
    return isDayBlocked || lockedJobs?.includes(props.item?.jobNumber as number) || (props.item?.shift === "" || props.item?.shift === " " || props.item?.shift == null);
  }

  const isDayInThePast = () => {
    const day = moment(parseInt(props.item?.date)).utc().format("MM-DD-YY");
    return day < currentDay ? true : false;
  }

  const setCrewCompositionLabAssigment = () => {
    if (props.isSupersSelectDisabled || isDayDisabled()) return;
    modal?.openModal?.({
      element: <CrewComposition
        resources={(fullDayPreviewItem?.resourcesLab != null && fullDayPreviewItem?.resourcesLab?.[0].resourceType != null) ? fullDayPreviewItem?.resourcesLab : props.item?.resourcesLab as Maybe<Resource>[]}
        otherResources={props.item?.resourcesOp as Maybe<Resource>[]}
        type={"Labor"}
        item={props.item}
        itemState={state}
        updateJobInfoTable={state.updateJobInfoTable}
        updateMasterCrewSchedulePavingTable={state.updateMasterCrewSchedulePavingTable}
        updateMasterCrewScheduleTable={state.updateMasterCrewScheduleTable}
      />
    })
  }

  const setCrewCompositionOpAssigment = () => {
    if (props.isSupersSelectDisabled || isDayDisabled()) return;
    modal?.openModal?.({
      element: <CrewComposition
        resources={(fullDayPreviewItem?.resourcesOp != null && fullDayPreviewItem?.resourcesOp?.[0].resourceType != null) ? fullDayPreviewItem?.resourcesOp : props.item?.resourcesOp as Maybe<Resource>[]}
        otherResources={props.item?.resourcesLab as Maybe<Resource>[]}
        type={"Operator"}
        item={props.item}
        itemState={state}
        updateJobInfoTable={state.updateJobInfoTable}
        updateMasterCrewSchedulePavingTable={state.updateMasterCrewSchedulePavingTable}
        updateMasterCrewScheduleTable={state.updateMasterCrewScheduleTable}
      />
    })

  }

  return (
    <>
      {loading && <FullScreenLoadingIndicator />}
      {error != null && <FullScreenErrorIndicator />}
      <div
        style={{ display: "flex", alignItems: "center", borderTop: (isDayBlocked || lockedJobs?.includes(props.item?.jobNumber as number)) ? "2px solid #107418" : "none", borderBottom: (isDayBlocked || lockedJobs?.includes(props.item?.jobNumber as number)) ? "2px solid #107418" : "none" }} className="Row_Sheet"
      >
        <div className="Row_Item Small">{item?.jobNumber as number}</div>
        <div className="Row_Item Large NoWrap">{item?.jobName as string}</div>
        <div className="Row_Item Medium NoWrap">{item?.pmpe}</div>
        <div className="Row_Item Small">{item?.shift as string}</div>
        <div className="Row_Item Large">
          <select
            className={(state.pavingSuper === " " || state.pavingSuper == null) && !isDayDisabled() ? "Row_Item Large Select_Red" : "Row_Item Large Select"}
            value={state.pavingSuper === " " || state.pavingSuper == null ? "" : state.pavingSuper}
            onChange={onPavingSuperChange}
            style={{ outline: 0 }}
            disabled={props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast()}>
            <option value="" disabled>
              Super:
            </option>
            {
              _pavingSupers != null && _pavingSupers?.map((pavSuper, index) => (
                <option value={pavSuper?.name as string} key={index}>
                  {pavSuper?.name}
                </option>
              ))
            }
          </select>
        </div>
        <div className="Row_Item Medium">
          <select
            className={(state.pavingForeman === " " || state.pavingForeman == null) && !isDayDisabled() ? "Row_Item Medium Select_Red" : "Row_Item Medium Select"}
            value={state.pavingForeman === " " || state.pavingForeman == null ? "" : state.pavingForeman}
            onChange={onPavingForemanChange}
            style={{ outline: 0 }}
            disabled={props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast()}>
            <option value="" disabled>
              Foreman:
            </option>
            {
              props.pavingForemans != null && props.pavingForemans?.map((pavForeman, index) => (
                <option value={pavForeman as string} key={index}>
                  {pavForeman}
                </option>
              ))
            }
          </select>
        </div>
        <div className="Row_Item Small">
          <TextInput
            className={(state.tonnage?.toString?.() === "" || state.tonnage?.toString?.() == null) && !isDayDisabled() ? "Row_Item Small input Blink_Border" : "Row_Item Small input"}
            value={state.tonnage?.toString?.() ?? ""}
            disabled={props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast()}
            onChange={(e) => onTonnageChange(e)}
            type="text"
            maxLength={5}
          />
        </div>
        <div className="Row_Item Small">
          <TextInput
            className={"Row_Item Small input"}
            value={state.tph?.toString?.() ?? ""}
            disabled={props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast()}
            onChange={(e) => onTPHChange(e)}
            type="text"
            maxLength={5}
          />
        </div>
        <div className="Row_Item Large NoWrap">
          <select
            className={(state.material === " " || state.material == null) && !isDayDisabled() ? "Row_Item Large Select_Red" : "Row_Item Large Select"}
            value={state.material === " " || state.material == null ? "" : state.material}
            onChange={onMaterialChange}
            style={{ outline: 0 }}
            disabled={props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast()}>
            <option value="" disabled>
              MAT'L:
            </option>
            {
              materials && materials.filter(m => m?.operationType === "paving").sort().map((material, index) => (
                <option value={material?.value as string} key={index}>
                  {material?.value}
                </option>
              ))
            }
          </select>
        </div>
        <div className="Row_Item Large NoWrap">
          <TextInput
            className={(state.mixSubmital === " " || state.mixSubmital == null) && !isDayDisabled() ? "Row_Item Large Select_Red" : "Row_Item Large Select"}
            value={state.mixSubmital ?? ""}
            disabled={props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast()}
            onChange={(e) => onMixSubmital(e)}
            type="text"
            maxLength={20}
          />
        </div>
        <div className="Row_Item Medium NoWrap">
          <select
            className={(state.plant === " " || state.plant == null) && !isDayDisabled() ? "Row_Item Medium Select_Red" : "Row_Item Medium Select"}
            value={state.plant === " " || state.plant == null ? "" : state.plant}
            onChange={onPlantChange}
            style={{ outline: 0 }}
            disabled={props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast()}>
            <option value="" disabled>
              Plant:
            </option>
            {
              plantData != null && plantData?.map((plant, index) => (
                <option value={plant?.value as string} key={index}>
                  {plant?.value}
                </option>
              ))
            }
          </select>
        </div>
        <div className="Row_Item Medium">
          <select className="Row_Item Medium Select"
            value={state.loadOutTime === " " || state.loadOutTime == null ? "" : state.loadOutTime}
            onChange={onLoadOutTimeChange}
            style={{ outline: 0 }}
            disabled={(props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast())}>
            <option value="" disabled>
              Load Out Time:
            </option>
            {
              item?.shift === "D" ?
                loadOutTimeData != null && loadOutTimeData?.loadOutTimeValues?.filter(lt => lt?.value?.includes("AM")).map((time, index) => (
                  <option value={time?.value?.toString()} key={index}>
                    {time?.value?.toString()}
                  </option>
                ))
                : loadOutTimeData != null && loadOutTimeData?.loadOutTimeValues?.filter(lt => lt?.value?.includes("PM")).map((time, index) => (
                  <option value={time?.value?.toString()} key={index}>
                    {time?.value?.toString()}
                  </option>
                ))
            }
          </select>
        </div>
        <div className="Row_Item Small">
          <select className="Row_Item Small Select"
            value={state.mixDesignApproval === " " || state.mixDesignApproval == null ? "" : state.mixDesignApproval}
            onChange={onMixDesignApproval} style={{ outline: 0 }}
            disabled={(props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast())}>
            <option value="" disabled>
              Mix Dsgn:
            </option>
            {
              booleanType != null && booleanType?.map((option, index) => (
                <option value={option?.value as string} key={index}>
                  {option?.key}
                </option>
              ))
            }
          </select>
        </div>
        <div className="Row_Item Medium NoWrap">
          <TextInput
            className="Row_Item Medium input"
            value={state.rtsSupport ?? ""}
            disabled={props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast()}
            onChange={(e) => onRtsSupport(e)}
            type="text"
            maxLength={20}
          />
        </div>
        <div className="Row_Item Small">
          <TextInput
            className="Row_Item Small input"
            value={state.bookTruckVendor ?? ""}
            disabled={props.isSupersSelectDisabled || isDayDisabled()}
            onChange={(e) => onBookTruckVendor(e)}
            type="text"
            maxLength={20}
          />
        </div>
        <div className="Row_Item Large">
          <select className="Row_Item Large Select"
            value={state.truckCo === " " || state.truckCo == null ? "" : state.truckCo}
            onChange={onTruckCoChange} style={{ outline: 0 }}
            disabled={(props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast())}>
            <option value="" disabled>
              Truck Co:
            </option>
            {
              brokersData != null && brokersData?.map((broker, index) => (
                <option value={broker?.value as string} key={index} >
                  {broker?.value}
                </option>
              ))
            }
          </select>
        </div>
        <div className="Row_Item Medium">
          <select className="Row_Item Medium Select"
            value={state.typeOfTrucks === " " || state.typeOfTrucks == null ? "" : state.typeOfTrucks}
            onChange={onTypeOfTrucksChange} style={{ outline: 0 }}
            disabled={(props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast())}>
            <option value="" disabled>
              Type of trucks:
            </option>
            {
              _trukData != null && _trukData?.map((truck, index) => (
                <option value={truck?.value as string} key={index}>
                  {truck?.value}
                </option>
              ))
            }
          </select>
        </div>
        <div className="Row_Item Small">
          <TextInput
            className={(state.nrOfTrucks?.toString?.() === "" || state.nrOfTrucks?.toString?.() == null) && !isDayDisabled() ? "Row_Item Small input Blink_Border" : "Row_Item Small input"}
            value={state.nrOfTrucks?.toString?.() ?? ""}
            onChange={(e) => onNrOfTrucksChange(e)}
            type="text"
            disabled={props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast()}
            onBlur={() => { }}
            maxLength={3}
          />
        </div>
        <div className="Row_Item Small">
          <select className="Row_Item Small Select"
            value={state.uts === " " || state.uts == null ? "" : state.uts}
            onChange={onUtsChange} style={{ outline: 0 }}
            disabled={(props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast())}>
            <option value="" disabled>
              UTS:
            </option>
            {
              booleanType != null && booleanType?.map((option, index) => (
                <option value={option?.value as string} key={index}>
                  {option?.key}
                </option>
              ))
            }
          </select>
        </div>
        <div className="Row_Item Small">
          <select className="Row_Item Small Select"
            value={state.extrawork === " " || state.extrawork == null ? "" : state.extrawork}
            onChange={onExtraWorkChange} style={{ outline: 0 }}
            disabled={(props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast())}>
            <option value="" disabled>
              EW:
            </option>
            {
              booleanType != null && booleanType?.map((option, index) => (
                <option value={option?.value as string} key={index}>
                  {option?.key}
                </option>
              ))
            }
          </select>
        </div>
        <div className="Row_Item Small" onClick={setCrewCompositionOpAssigment} style={{ cursor: "pointer" }}>{fullDayPreviewItem?.crewMakeOp != null ? fullDayPreviewItem?.crewMakeOp : item?.crewMakeOp as number}</div>
        <div className="Row_Item Small" onClick={setCrewCompositionLabAssigment} style={{ cursor: "pointer" }}>{fullDayPreviewItem?.crewMakeLab != null ? fullDayPreviewItem?.crewMakeLab : item?.crewMakeLab as number}</div>
        <div className="Row_Item Medium grinder">
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <TextInput
              style={{ padding: "3px", width: "25px" }}
              value={state.grinder4ft?.toString?.() ?? ""}
              onChange={(e) => onGrinder4ftChange(e)}
              type="text"
              disabled={props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast()}
              onBlur={() => { }}
              maxLength={3}
            />
            <TextInput
              style={{ padding: "3px", width: "25px" }}
              value={state.grinder6ft?.toString?.() ?? ""}
              onChange={(e) => onGrinder6ftChange(e)}
              type="text"
              disabled={props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast()}
              onBlur={() => { }}
              maxLength={3}
            />
            <TextInput
              style={{ padding: "3px", width: "25px" }}
              value={state.grinder7ft?.toString?.() ?? ""}
              onChange={(e) => onGrinder7ftChange(e)}
              type="text"
              disabled={props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast()}
              onBlur={() => { }}
              maxLength={3}
            />
            <TextInput
              style={{ padding: "3px", width: "25px" }}
              value={state.grinder12ft?.toString?.() ?? ""}
              onChange={(e) => onGrinder12ftChange(e)}
              type="text"
              disabled={props.isSupersSelectDisabled || isDayDisabled() || isDayInThePast()}
              onBlur={() => { }}
              maxLength={3}
            />
          </div>
        </div>
      </div>
    </>
  )
}