import "./EquipmentDetailsWindow.css";
import { useQuery } from "@apollo/react-hooks";
import React, { FC, useEffect, useRef, useState } from "react";
import { ScrollSync } from 'scroll-sync-react';
import { ScrollSyncNode } from 'scroll-sync-react';
import { useSelector } from "react-redux";
import { EQUIPMENT_MANAGEMENT_DATAS_QUERY } from "../../../graphql/queries/EQUIPMENT_MANAGEMENT_DATAS_QUERY";
import { EquipmentManagementData, EquipmentResource, Maybe, Query } from "../../../graphql/schema-types";
import { RootState } from "../../../redux/store";
import { CloseButton } from "../../Form/CloseButton";
import { FullScreenErrorIndicator } from "../../Modal/ErrorIndicator/FullScreenErrorIndicator";
import { FullScreenLoadingIndicator } from "../../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import moment from "moment";
import { toMultiMap } from "../../../utils/toMultiMap";

interface IEquipmentDetailsWindowProps {
  onClose?(): void
}
interface IState {
  [eqName: string]: {
    [date: string]: number[];
  }
}

const filteredItems = (days: string[], equipmentNameList: string[], equipmentItems: Maybe<EquipmentManagementData>[], equipmentSubcategoryName?: string[]): IState => {
  const mappedItems: IState = {};

  const init = (day: number, eqName: string) => {
    if (mappedItems[eqName] == null) {
      mappedItems[eqName] = {};
    }

    if (mappedItems[eqName][day] == null) {
      mappedItems[eqName][day] = [];
    }
  }

  if (equipmentNameList.length > 0 && days.length > 0) {
    equipmentNameList.forEach(eqName => {
      days.forEach(day => {

        const filteredItemsByDate = equipmentItems
          .filter(item => item?.date === parseInt(day));

        const resources = filteredItemsByDate
          .map(item => item?.equipmentResources)
          .flat();

        resources?.forEach(r => {
          if (r?.eqName === eqName) {
            init(parseInt(day), eqName);

            mappedItems[eqName][day].push(r?.jobNumber as number);
          }
        });
      });
    });
  }

  if (equipmentSubcategoryName != null && equipmentSubcategoryName.length > 0 && days.length > 0) {
    equipmentSubcategoryName.forEach(e => {
      days.forEach(day => {
        const filteredItemsByDate = equipmentItems
          .filter(item => item?.date === parseInt(day));

        const resources = filteredItemsByDate
          .map(item => item?.equipmentSubcategoryData)
          .flat();

        resources?.forEach(r => {
          if (r?.eqSubcategoryDescription === e) {
            init(parseInt(day), e);
            mappedItems[e][day].push(r?.qty as number);
          }
        });
      })
    })
  }

  return mappedItems
}

export const EquipmentDetailsWindow: FC<IEquipmentDetailsWindowProps> = (props) => {
  const ref = useRef<any>(null);

  const [state, setState] = useState<IState>(filteredItems([], [], []));
  const [equipmentNameList, setEquipmentNameList] = useState<string[]>([]);
  const [equipmentSubcategoriesList, setSubcategoriesList] = useState<string[]>([]);
  // const [equipmentNameListByCategory, setEquipmentNameListByCategory] = useState<{ [key: string]: [Maybe<EquipmentResource>]; }>({});

  // const [scroll, setScroll] = useState(false);

  const equipmentResources = useSelector((state: RootState) => state.app?.equipmentResources);
  const equipmentSubcategories = useSelector((state: RootState) => state.app?.equipmentSubcategories);
  const days = useSelector((state: RootState) => state.app?.pavingModule);

  const { data, loading, error } = useQuery<Pick<Query, "equipmentManagementDatas">>(EQUIPMENT_MANAGEMENT_DATAS_QUERY, {
    variables: {
      where: {
        startDate: parseInt(Object.keys(days)[0]),
        endDate: parseInt(Object.keys(days)[Object.keys(days).length - 1])
      }
    },
    skip: Object.keys(days).length === 0,
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.removeAttribute('style')
    }
  }, [])

  useEffect(() => {
    window.onpopstate = (e: any) => {
      props?.onClose?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let _subcategories: string[] = [];
    let _equipmentNameList: Maybe<EquipmentResource>[] = [];
    let _equipmentSubcategoryList: Maybe<EquipmentResource>[] = [];
    let _equipmentNameListByCategoryId = toMultiMap(equipmentResources ?? [], resource => resource?.eqSubcategoryID ?? "");
    // let _equipmentNameListByCategory = toMultiMap(equipmentResources ?? [], resource => resource?.eqSubcategoryDescription ?? "");

    Object.keys(_equipmentNameListByCategoryId).forEach(key => {
      _equipmentNameListByCategoryId[key].sort((a, b) => a?.eqName! < b?.eqName! ? -1 : 1)
    });

    if (Object.keys(_equipmentNameListByCategoryId).length > 0) {

      // get equip subcategories from query; split them by "," and filter the resources ony by these subcategories
      let equipments = equipmentSubcategories?.equipmentsForCheckbox?.split(",");
      let _equipmentResourcesFiltered: string[] = [];

      for (let i = 0; i < equipments?.length!; i++) {
        let currentSubcategory = equipments?.[i]!;
        for (let j = 0; j < Object.keys(_equipmentNameListByCategoryId).length; j++) {
          let currentItem = Object.keys(_equipmentNameListByCategoryId)[j];
          if (currentItem === currentSubcategory) {
            _equipmentResourcesFiltered.push(currentItem);
          }
        }
      }

      _equipmentResourcesFiltered.forEach((sc: string) => {
        _equipmentNameList.push(
          ..._equipmentNameListByCategoryId?.[sc]
        )
      })

      let _equipmentSubcategories = equipmentSubcategories?.equipmentsForInput?.split(",");
      let _equipmentSubcategoriesFiltered: string[] = [];

      for (const subcategory of _equipmentSubcategories!) {
        for (const currentItem of Object.keys(_equipmentNameListByCategoryId)) {
          if (currentItem === subcategory) {
            _equipmentSubcategoriesFiltered.push(currentItem);
          }
        }
      }
      _equipmentSubcategoriesFiltered.forEach((sc: string) => {
        _equipmentSubcategoryList.push(
          ..._equipmentNameListByCategoryId?.[sc]
        )
      })

      _subcategories = _equipmentSubcategoryList
        ?.map(e => e?.eqSubcategoryDescription?.trim() as string)
    }

    if (_equipmentNameList.length > 0) {
      setEquipmentNameList(_equipmentNameList.map(eq => eq?.eqName as string));
    }

    if (_subcategories.length > 0) {
      setSubcategoriesList(_subcategories.filter((sc, i) => _subcategories.indexOf(sc) === i))
    }

    // setEquipmentNameListByCategory(_equipmentNameListByCategory);

  }, [equipmentResources, equipmentSubcategories])

  useEffect(() => {
    if (data == null || data.equipmentManagementDatas == null || state === {}) return;

    setState(filteredItems(Object.keys(days), equipmentNameList, data.equipmentManagementDatas, equipmentSubcategoriesList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.equipmentManagementDatas, equipmentNameList, equipmentSubcategoriesList])

  return (
    <div className="EquipmentDetailsWindow">
      {loading && <FullScreenLoadingIndicator />}
      {error != null && <FullScreenErrorIndicator />}
      <div className="Modal_Bar">
        <div className="Date_Wrap">
          <img src={process.env.PUBLIC_URL + '/logo.png'} alt="graniterock" />
          <div className="Title">Equipment Details Preview</div>
        </div>
        <CloseButton onClick={props.onClose} />
      </div>
      <ScrollSync>
        <div className="Table">
          <ScrollSyncNode scroll="synced-only" key={1}>
            <div className="Equipment_Table">
              {
                equipmentNameList.map((eqName, index) => (
                  <div className="Equip_Name" key={eqName}>{eqName}</div>
                ))
              }
              {
                equipmentSubcategoriesList.map((eqSubName, i) => (
                  <div className="Equip_Name" key={eqSubName}>{eqSubName}</div>
                ))
              }
            </div>
          </ScrollSyncNode>
          <ScrollSyncNode scroll="synced-only" key={2}>
            <div className="Dates" id="dates">
              {
                Object.keys(days).map((day, index) => (
                  <div className="Date" key={day + index}
                    style={{
                      backgroundColor: moment(parseInt(day)).utc().days() === 6 || moment(parseInt(day)).utc().days() === 0
                        ? "rgba(240,220,200,1)"
                        : "",


                    }}
                  >{moment(parseInt(day)).utc().format("MM/DD")}</div>
                ))
              }
            </div>
          </ScrollSyncNode>
          <ScrollSyncNode scroll="syncer-only" key={3}>
            <div className="Scroll_Table"
              ref={ref}
            // onScrollCapture={() => setScroll(true)}
            >
              {
                Object.keys(days).map((day, index) => (
                  <div key={index + day} className="Table_Column"
                    style={{
                      backgroundColor: moment(parseInt(day)).utc().days() === 6 || moment(parseInt(day)).utc().days() === 0
                        ? "rgba(240,220,200,1)"
                        : "",
                      height: (equipmentNameList.length + equipmentSubcategoriesList.length + 1) * 30
                    }}>
                    <div className="Date" key={day}>
                      {moment(parseInt(day)).utc().format("MM/DD")}
                    </div>
                    <div key={day + 1}>
                      {equipmentNameList.map((eqName, i) => (
                        <>
                          <div
                            key={i + eqName as string}
                            className={state?.[eqName]?.[day]?.length > 1 ? "Job_Number_Bold" : "Job_Number"}
                            style={{
                              backgroundColor: moment(parseInt(day)).utc().days() === 6 || moment(parseInt(day)).utc().days() === 0
                                ? "rgba(240,220,200,1)"
                                : "",
                            }}
                            data-name={eqName + i}
                          >
                            {state?.[eqName]?.[day]?.[0]}
                          </div>
                        </>
                      ))}
                      {
                        equipmentSubcategoriesList?.map((subcategoryName, i) => (
                          <>
                            <div
                              key={i + subcategoryName as string}
                              className="Job_Number"
                              style={{
                                backgroundColor: moment(parseInt(day)).utc().days() === 6 || moment(parseInt(day)).utc().days() === 0
                                  ? "rgba(240,220,200,1)"
                                  : "",
                              }}
                              data-name={subcategoryName + i}
                            >
                              {state?.[subcategoryName]?.[day]
                                ?.reduce((previousValue, currentValue) => previousValue! as number + currentValue! as number, 0) as number}
                            </div>
                          </>
                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </ScrollSyncNode>
        </div>
      </ScrollSync>
    </div>
  )
}