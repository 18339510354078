import React, {useState, useEffect} from "react";
import { useHistory } from 'react-router-dom';
import "./SelectAmPm.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import jwt_decode from "jwt-decode";
import { addPavingModuleUser } from "../../redux/appSlice";

export const SelectAmPm = () => {
  const history = useHistory();

  const token = useSelector((state: RootState) => state.app.auth.id_token);
	const dispatch = useDispatch();

	const [state, setState] = useState({
		cognito_groups: [] as string[],
		email: "",
		name: "",
		isDisabled: null as boolean | null,
		isPavingSuper: null as boolean | null,
		isGeneral: null as boolean | null
	});

	useEffect(() => {
		if (token == null) return;
		try {
			const decoded_token: any = jwt_decode(token as string);
			const email = decoded_token?.["identities"][0].userId;
			const cognito_groups = decoded_token?.["cognito:groups"];
			const isDisabled = cognito_groups.includes("DisabledUsers");
			const isPavingSuper = cognito_groups.includes("PavingSupers");
			const isGeneral = cognito_groups.includes("GeneralPavingSupers");
			const _name = email.split("@")[0].toUpperCase();
			const name = _name.slice(0, 1) + " " + _name.slice(1);

			setState((state) => ({
				cognito_groups,
				email,
				name,
				isDisabled,
				isPavingSuper,
				isGeneral
			}))
		}
		catch (err) { }
	}, [token, state.isDisabled])

	useEffect(() => {
		if (token == null) return;
		if (state.isDisabled === true || state.isPavingSuper === false) return;

		dispatch(addPavingModuleUser({
			name: state.name,
			email: state.email,
			groups: state.cognito_groups,
			isInPavingSupers: state.isPavingSuper as boolean,
			isInGeneraPavingSupers: state.isGeneral as boolean,
			isDisabled: state.isDisabled as boolean,
		}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, state.isDisabled])

  function handleClickAM(){
    history.push('/pavingplanner');
    window.location.reload()
  }
  function handleClickPM(){
    history.push('/start');
    window.location.reload()
  }

  return (
        <div className="Button_Container">
          <div className="top-ampm">
              <button disabled={state.isGeneral === false || state.isPavingSuper === false} className={state.isGeneral === false || state.isPavingSuper === false ? "disable-button" : ""} onClick={handleClickAM}>Paving View</button>
              <button onClick={handleClickPM}>Engineer View</button>
          </div>
        </div>
  )
}

