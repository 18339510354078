import { configureStore, ThunkAction, Action, Middleware } from '@reduxjs/toolkit';
import { app } from "./appSlice";
import { loginMiddleware, tokenMiddleware, refreshTokenMiddleware } from './middleware/loginMiddleware';
import { logoutMiddleware } from './middleware/logoutMiddleware';
import { logger } from './middleware/loggerMiddleware';
import thunk from 'redux-thunk'

export const store = configureStore({
  reducer: {
    app,
  },
  middleware: [thunk, loginMiddleware, tokenMiddleware, refreshTokenMiddleware, logoutMiddleware, logger] as Middleware[],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
