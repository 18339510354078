import React, { FC } from "react";

interface ICheckBoxProps {
	children?: any;
	checked: boolean;
	onChange(e: React.ChangeEvent<HTMLInputElement>): void;
	name?: string;
	disabled?: boolean;
	className?: string;
}

export const CheckBox: FC<ICheckBoxProps> = ({ name, onChange, checked, children, disabled, className }) => {
	return (
		<div className={className}>
			<input
				type="checkbox"
				name={name}
				checked={checked}
				disabled={disabled}
				onChange={(e) => onChange(e)} />
			<div>{children}</div>
		</div>
	)
}
