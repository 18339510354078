import React, { FC, useEffect, useState } from "react";
import { Switch, Route, Router as Root, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Start } from "./Start";
import Schedule from "./Schedule";
import { Nowhere } from "./Nowhere";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import ModalHub from "./Modal/ModalHub/ModalHub";
import { LoadingHub } from "./Modal/LoadingHub/LoadingHub";
import { Login2 } from "./Login/Login2";
import { RefreshToken } from "./RefreshToken";
import { LogOut } from "./Login/LogOut";
import { SuperIntendentPavingModule } from "./SuperIntendentPavingModule/SuperIntendentPavingModule";
import jwt_decode from "jwt-decode";
import { addPavingModuleUser } from "../redux/appSlice";
import { DisabledUser } from "./DisabledUser";
import { FullScreenLoadingIndicator } from "./Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { PavingPlanner } from "./PavingPlanner/PavingPlanner";
import { FullDayPreviewPage } from "./FullDayPreviewPage/FullDayPreviewPage";
import { SideBar } from "./SuperIntendentPavingModule/SideBar/SideBar";
import { InitPavingModule } from "./InitPavingModule/InitPavingModule";
import { FullDayPreviewHistory } from "./FullDayPreviewHistory/FullDayPreviewHistory";
import { useLocation } from 'react-router-dom';

const browserHistory = createBrowserHistory();

export const Router: FC = () => {
	const token = useSelector((state: RootState) => state.app.auth.id_token);
	const dispatch = useDispatch();

	const [state, setState] = useState({
		cognito_groups: [] as string[],
		email: "",
		name: "",
		isDisabled: null as boolean | null,
		isPavingSuper: null as boolean | null,
		isGeneral: null as boolean | null
	});

	const location = window.location;
	const {pathname} = location

	useEffect(() => {
		if (token == null) return;
		try {
			const decoded_token: any = jwt_decode(token as string);
			const email = decoded_token?.["identities"][0].userId;
			const cognito_groups = decoded_token?.["cognito:groups"];
			const isDisabled = cognito_groups.includes("DisabledUsers");
			const isPavingSuper = cognito_groups.includes("PavingSupers");
			const isGeneral = cognito_groups.includes("GeneralPavingSupers");
			const _name = email.split("@")[0].toUpperCase();
			const name = _name.slice(0, 1) + " " + _name.slice(1);

			setState((state) => ({
				cognito_groups,
				email,
				name,
				isDisabled,
				isPavingSuper,
				isGeneral
			}))
		}
		catch (err) { }
	}, [token, state.isDisabled])

	useEffect(() => {
		if (token == null) return;
		if (state.isDisabled === true || state.isPavingSuper === false) return;

		dispatch(addPavingModuleUser({
			name: state.name,
			email: state.email,
			groups: state.cognito_groups,
			isInPavingSupers: state.isPavingSuper as boolean,
			isInGeneraPavingSupers: state.isGeneral as boolean,
			isDisabled: state.isDisabled as boolean,
		}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, state.isDisabled])


	if (token == null) return (
		<>
			<RefreshToken />
			<Root history={browserHistory}>
				<Route exact component={Login2} />
				<ModalHub />
				<LoadingHub />
			</Root>
		</>
	);

	if (state.isDisabled == null && token != null) return <FullScreenLoadingIndicator />;

	if (state.isDisabled != null && state.isDisabled === true) return (
		<>
			<RefreshToken />
			<Root history={browserHistory}>
				<Route exact component={DisabledUser} />
				<ModalHub />
				<LoadingHub />
			</Root>
		</>
	);
	return (
		<>
			<RefreshToken />
			<Root history={browserHistory}>
				<Switch>
					{(state.isPavingSuper === true || state.isGeneral === true)
						? <Redirect exact from="/" to="/pavingplanner" />
						: <Redirect exact from="/" to="/start" />
					}

					{(pathname === "/pavingplanner" || pathname === "/calendar") && (state.isPavingSuper === true || state.isGeneral === true)
						? (
							<>
								<Route component={SideBar} />
								<Route component={InitPavingModule} />
								<Route
									exact
									path="/pavingplanner"
									component={PavingPlanner}
								/>
								<Route
									exact
									path="/daypreview"
									component={FullDayPreviewPage}
								/>
								<Route
									exact
									path="/calendar"
									component={SuperIntendentPavingModule}
								/>
								<Route
									exact
									path="/history"
									component={FullDayPreviewHistory}
								/>
								<Route exact path="/logout" component={LogOut} />
							</>
						)
						: (
							<>
								<Route exact path="/start" component={Start} />
								<Route
									exact
									path="/schedule"
									component={Schedule}
								/>
								<Route exact path="/logout" component={LogOut} />
							</>
						)}
					<Route component={Nowhere} />
				</Switch>
				<ModalHub />
				<LoadingHub />
			</Root>
		</>
	);
};
