import { useMutation, useQuery } from "@apollo/react-hooks";
import to from "await-to-js";
import React, { FC, useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { ADD_RESOURCES_ON_MATERIAL_DATAS } from "../../graphql/mutations/ADD_RESOURCES_ON_MATERIAL_DATAS";
import { ADD_RESOURCES_ON_TRUCKING_DATAS } from "../../graphql/mutations/ADD_RESOURCES_ON_TRUCKING_DATAS";
import { MATERIAL_DATAS_QUERY } from "../../graphql/queries/MATERIAL_DATAS_QUERY";
import { TRUCKING_DATAS_QUERY } from "../../graphql/queries/TRUCKING_DATAS_QUERY";
import { MaterialData, Maybe, Query, TruckingData } from "../../graphql/schema-types";
import { CheckBox } from "../Form/CheckBox";
import { CloseButton } from "../Form/CloseButton";
import { FullScreenLoadingIndicator } from "../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { ModalContext } from "../Modal/ModalContext/ModalContext";
import { AddResource } from "./AddResource/AddResource";
import "./AddResources.css"
import { EditResource } from "./EditResource/EditResource";
import { toMap } from "../../utils/toMap";
import { DELETE_RESOURCES_ON_MATERIAL_DATAS } from "../../graphql/mutations/DELETE_RESOURCES_ON_MATERIAL_DATAS";
import { DELETE_RESOURCES_ON_TRUCKING_DATAS } from "../../graphql/mutations/DELETE_RESOURCES_ON_TRUCKING_DATAS";
import { GeneralWarningModal } from "../Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { updateTruckingAndMaterialDatas } from "../../redux/appSlice";
import { MASTER_CREW_SCHEDULE_AND_PAVING_QUERY } from "../../graphql/queries/MASTER_CREW_SCHEDULE_AND_PAVING_QUERY";
import moment from "moment";
interface IAddResources {
  onClose?(): void;
}
export type Resource = {
  checked?: boolean
  uID: string
  type: string
  key: string
  value: string
  operationType: string | null | undefined
}

type State = {
  resources: { [key: string]: Resource },
  currentMenuOption: string | number | null | undefined,
  usedResources: {
    usedMaterials: string[],
    usedPlants: string[],
    usedTruckTypes: string[],
    usedBrokers: string[],
    allUsedResources: string[]
  }
};

export const AddResources: FC<IAddResources> = (props) => {

  const firstDay = (moment.utc(moment().format("YYYY-MM-DD")).unix()) * 1000;
  const lastDay = (moment.utc(moment(firstDay).add(6, 'months').format("YYYY-MM-DD")).unix()) * 1000;
  const isGeneralPavingSuper = useSelector((state: RootState) => state.app.pavingModuleUser.isInGeneralPavingSupers);

  const [state, setState] = useImmer<State>({
    resources: {},
    currentMenuOption: null,
    usedResources: {
      usedBrokers: [],
      usedMaterials: [],
      usedPlants: [],
      usedTruckTypes: [],
      allUsedResources: []
    }
  })

  const { data: data, loading: loading, refetch: refetch } = useQuery<Pick<Query, "truckingDatas">>(TRUCKING_DATAS_QUERY);
  const { data: material, loading: loadingMaterial, refetch: refetchMaterial } = useQuery<Pick<Query, "materialDatas">>(MATERIAL_DATAS_QUERY);

  const { data: objects } = useQuery<Pick<Query, "masterCrewScheduleAndPaving">>(MASTER_CREW_SCHEDULE_AND_PAVING_QUERY,
    {
      variables: {
        where: {
          startDate: firstDay,
          endDate: lastDay
        }
      },
      skip: isGeneralPavingSuper === false || isGeneralPavingSuper == null,
      fetchPolicy: "no-cache"
    });

  const [addResourcesOnMaterialDatas, { loading: loadingAddResourcesOnMaterial }] = useMutation(ADD_RESOURCES_ON_MATERIAL_DATAS);
  const [addResourcesOnTruckingDatas, { loading: loadingAddResourcesOnTrucking }] = useMutation(ADD_RESOURCES_ON_TRUCKING_DATAS);

  const [deleteResourcesOnMaterialDatas, { loading: loadingDeleteResourcesOnMaterial }] = useMutation(DELETE_RESOURCES_ON_MATERIAL_DATAS);
  const [deleteResourcesOnTruckingDatas, { loading: loadingDeleteResourcesOnTrucking }] = useMutation(DELETE_RESOURCES_ON_TRUCKING_DATAS);

  const modal = useContext(ModalContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (objects?.masterCrewScheduleAndPaving == null) return;

    let usedMaterials: string[] = [];
    let usedPlants: string[] = [];
    let usedTruckTypes: string[] = [];
    let usedBrokers: string[] = [];

    objects?.masterCrewScheduleAndPaving.paving?.forEach(r => {
      if (!usedMaterials.includes(r?.material as string)) {
        usedMaterials.push(r?.material as string);
      }

      if (!usedPlants.includes(r?.plant as string)) {
        usedPlants.push(r?.plant as string);
      }
    });

    objects?.masterCrewScheduleAndPaving.trucking?.forEach(r => {
      if (!usedMaterials.includes(r?.material as string)) {
        usedMaterials.push(r?.material as string);
      }

      if (!usedPlants.includes(r?.loadSite as string)) {
        usedPlants.push(r?.loadSite as string);
      }

      if (!usedTruckTypes.includes(r?.type as string)) {
        usedTruckTypes.push(r?.type as string);
      }

      if (!usedBrokers.includes(r?.broker as string)) {
        usedBrokers.push(r?.broker as string);
      }
    })

    setState(draft => {
      draft.usedResources.usedBrokers = usedBrokers;
      draft.usedResources.usedMaterials = usedMaterials;
      draft.usedResources.usedPlants = usedPlants;
      draft.usedResources.usedTruckTypes = usedTruckTypes;
      draft.usedResources.allUsedResources = [...usedBrokers, ...usedMaterials, ...usedPlants, ...usedTruckTypes].flat()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objects]);

  useEffect(() => {
    // because MaterialData & TruckingData are different types, we combine them into a general type called State, and save them to component state
    if (material?.materialDatas == null || data?.truckingDatas == null) return;

    const _res: Resource[] = [];
    material.materialDatas?.forEach(m => {
      _res.push({
        uID: m?.uID as string,
        key: m?.key as string,
        type: m?.type as string,
        value: m?.value as string,
        operationType: m?.operationType as string
      });
    });

    // the only key that make those 2 to be different is the "operationType" and we put value null to those who came from truckingDatas
    data?.truckingDatas?.forEach(t => {
      _res.push({
        uID: t?.uID as string,
        key: t?.key as string,
        type: t?.type as string,
        value: t?.value as string,
        operationType: null
      })
    });

    // sort the array before convert into an key-value pair
    _res.sort((a, b) => a.key.toLowerCase() > b.key.toLocaleLowerCase() ? 1 : -1);
    const r = toMap(_res, r => r.key ?? "");

    setState(draft => {
      draft.resources = r;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, material, refetch, refetchMaterial]);

  const _menuOptions = Object.values(state.resources)
    .map(r => r?.type!)
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort((a, b) => a > b ? 1 : -1);

  const onCheckboxChange = (res: Resource, isChecked: boolean) => {
    setState(draft => {
      draft.resources[res.key].checked = isChecked;
    });
  }

  const onAddRes = async (resource: Resource) => {
    if (resource == null) { return }

    if (resource.type === "Material") {
      const [error, response] = await to(addResourcesOnMaterialDatas({
        variables: {
          where: {
            key: resource.key,
            operationType: resource.operationType,
            value: resource.value,
            type: resource.type
          }
        }
      }));
      if (error == null && response != null) {
        refetchMaterial();

        let _materialDatas = Object.values(state.resources).filter(r => r.type === "Material");
        _materialDatas.push({
          uID: "",
          key: resource.key,
          operationType: resource.operationType,
          value: resource.value,
          type: resource.type
        });

        const materialDatas: Maybe<MaterialData>[] | undefined = _materialDatas.map(r => ({
          key: r.key,
          operationType: r.operationType,
          type: r.type,
          uID: r.uID,
          value: r.value
        }))

        dispatch(updateTruckingAndMaterialDatas({ materialDatas }))
      }
    }
    else {
      const [error, response] = await to(addResourcesOnTruckingDatas({
        variables: {
          where: {
            key: resource.key,
            value: resource.value,
            type: resource.type
          }
        }
      }));
      if (error == null && response != null) {
        refetch();

        let _truckingDatas = Object.values(state.resources).filter(r => r.type !== "Material");
        _truckingDatas.push({
          uID: "",
          operationType: null,
          key: resource.key,
          value: resource.value,
          type: resource.type
        });

        const truckingDatas: Maybe<TruckingData>[] | undefined = _truckingDatas.map(t => ({
          key: t.key,
          type: t.type,
          uID: t.uID,
          value: t.value
        }));

        dispatch(updateTruckingAndMaterialDatas({ truckingDatas }))
      }
    }

    setState((draft) => {
      draft.resources[resource.key] = resource;
    });
  }

  const onEditRes = (res: Resource, oldResource: Resource) => {
    if (Object.values(state.resources) == null) { return }
    setState(draft => {
      draft.resources[res.key] = res;

      if((draft.resources?.[oldResource.value] !== draft.resources?.[res.value]) && (res.uID === oldResource.uID)){
        delete draft.resources?.[oldResource.key];
      } else if ((draft.resources?.[oldResource.key] !== draft.resources?.[res.key]) && (res.uID === oldResource.uID)){
        delete draft.resources?.[oldResource.key];
      }
      });

    // prepare the arrays for changing the modifications into the redux state
    if (res.type === "Material") {
      const materialDatas: Maybe<MaterialData>[] | undefined = [];
      const _materialDatas = Object.values(state.resources).filter(r => r.type === "Material");
      _materialDatas.push({
        uID: "",
        key: res.key,
        operationType: res.operationType,
        value: res.value,
        type: res.type
      });
      _materialDatas.map(r => (
        materialDatas.push({
          key: r.key,
          operationType: r.operationType,
          type: r.type,
          uID: r.uID,
          value: r.value
        })
      ));
      dispatch(updateTruckingAndMaterialDatas({ materialDatas }))
    }
    if (res.type !== "Material") {
      const truckingDatas: Maybe<TruckingData>[] | undefined = [];
      const _truckingDatas = Object.values(state.resources).filter(r => r.type !== "Material");
      _truckingDatas.push({
        uID: "",
        key: res.key,
        operationType: res.operationType,
        value: res.value,
        type: res.type
      });
      _truckingDatas.map(r => (
        truckingDatas.push({
          key: r.key,
          type: r.type,
          uID: r.uID,
          value: r.value
        })
      ));
      dispatch(updateTruckingAndMaterialDatas({ truckingDatas }))
    }
  }

  const onAddResource = () => {
    modal?.openModal?.({
      element: <AddResource types={_menuOptions} onAddingResource={onAddRes} />
    });
  }

  const onEditValue = (r: any, usedResources: string[]) => {
    modal?.openModal?.({
      element: <EditResource resource={r} onEditResource={onEditRes} usedResources={usedResources} />
    });
  }

  const onDeleteResourceConfirmation = () => {
    modal?.openModal?.({
      element: <GeneralWarningModal
        message="Are you sure you want to delete the selected resources?"
        title="Delete Resources Confirmation"
        yesNoButtons={true}
        onConfirm={() => onDeleteResource()}
        onCancel={() => { }}
      />
    });
  }

  const onDeleteResource = async () => {
    const checkedForDelete = Object.values(state.resources).filter(r => r.checked === true);

    // check if one of the checked for delete resources is selected for future items

    const _materialDataResources = checkedForDelete
      ?.filter(r => r.type === "Material");

    const _truckingDataResources = checkedForDelete
      ?.filter(r => r.type !== "Material")

    if (_materialDataResources != null && _materialDataResources.length > 0) {
      const [error, response] = await to(deleteResourcesOnMaterialDatas({
        variables: { where: _materialDataResources?.map(r => r.uID) }
      }));
      // delete from state the resources you checked for delete
      if (error == null && response != null) {
        setState(draft => {
          _materialDataResources.forEach(m => {
            delete draft.resources[m.key]
          })
        })
        // prepare objects for updating the redux
        let _materialDataAfterDelete = state.resources;
        
        const _matDatas = Object.values(_materialDataAfterDelete).filter(r => r.type === "Material");
        const materialDatas: Maybe<MaterialData>[] | undefined = [];

        _matDatas.map(r => (
          r != null
            ? materialDatas.push({
              key: r.key,
              operationType: r.operationType,
              type: r.type,
              uID: r.uID,
              value: r.value
            })
            : null
        ));
        
        dispatch(updateTruckingAndMaterialDatas({ materialDatas, _materialDataResources})); 
        refetchMaterial()
      }
    }

    if (_truckingDataResources != null && _truckingDataResources.length > 0) {
      const [error, response] = await to(deleteResourcesOnTruckingDatas({
        variables: { where: _truckingDataResources?.map(r => r.uID) }
      }));

      // delete from state the resources you checked for delete
      if (error == null && response != null) {
        setState(draft => {
          _truckingDataResources.forEach(t => {
            delete draft.resources[t.key]
          })
        })

        // prepare objects for updating the redux
        let _truckingDataAfterDelete = state.resources;

        const _trkDatas = Object.values(_truckingDataAfterDelete).filter(r => r.type === "Material");
        const truckingDatas: Maybe<TruckingData>[] | undefined = [];

        _trkDatas.map(r => (
          truckingDatas.push({
            key: r.key,
            type: r.type,
            uID: r.uID,
            value: r.value
          })
        ));

        dispatch(updateTruckingAndMaterialDatas({ truckingDatas,  _truckingDataAfterDelete}));
        refetch()
      }
    }
  }

  const disableCheckbox = (r: Resource) => {
      if (!state.usedResources.allUsedResources.includes(r.value)) {
        return false
      }
    return true;
  }

  return (
    <>
      {(loading || loadingMaterial || loadingAddResourcesOnMaterial || loadingAddResourcesOnTrucking || loadingDeleteResourcesOnMaterial || loadingDeleteResourcesOnTrucking) && <FullScreenLoadingIndicator />}
      <div className="Add_Resources">
        <div className="Add_Resources_Header">
          <div className="Wrap">
            <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
            <div className="Title">Resources Management</div>
          </div>
          <CloseButton onClick={props.onClose} />
        </div>
        <div className="Wrap_Content">
          <div className="Menu_Options">
            {
              _menuOptions?.map((mo: string, i: number) => (
                <div
                  key={i}
                  className={state.currentMenuOption === mo ? "Table_Line Selected_Line" : "Table_Line"}
                  onClick={() => { setState((draft) => { draft.currentMenuOption = mo }) }}>
                  {mo}
                </div>
              ))
            }
          </div>
          <div className="Resources">
            <div className="Header">
              <div className="item"></div>
              <div className="item">ID</div>
              <div className="item">Name</div>
              <div className="item">Edit Resource</div>
            </div>
            <div className="Resources_List">
              {
                Object.values(state.resources).filter(r => r?.type === state.currentMenuOption).map((r, index) =>
                  r != null ? <div className={state.usedResources.allUsedResources.includes(r.value) ? 'Resource Disabled' : 'Resource'} key={r.type + index}>
                    <CheckBox
                      onChange={(e) => { onCheckboxChange(r, e.target.checked) }}
                      checked={r.checked || false}
                      disabled={disableCheckbox(r)}
                    />
                    <div className="Resource_Key">{r?.key}</div>
                    <div className="Resource_Value">{r?.value}</div>
                    <button className="Edit_Values"
                      onClick={() => onEditValue(r, state.usedResources.allUsedResources)}
                      >
                      Edit
                    </button>
                  </div>
                    : null
                )
              }
            </div>
          </div>
        </div>
        <div className="Button_Menu">
          <button onClick={() => onAddResource()} className="Button Add_Resource">Add Resource</button>
          <button
            className="Button Delete_Resource"
            onClick={() => onDeleteResourceConfirmation()}
            >
            Delete Resource
          </button>
        </div>
      </div>
    </>
  )
}