import React, { FC, CSSProperties, memo } from 'react';
import { useDragLayer, XYCoord, } from "react-dnd";

const layerStyles: CSSProperties = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%"
};

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  offset: XYCoord | null
) {
  if (!initialOffset || !currentOffset || !offset) {
    return {
      display: "none"
    };
  }

  let { x, y } = {
    x: offset.x + 5,
    y: offset.y + 5
  };

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
}

const Preview: FC = () => {
  const {
    item,
    initialOffset,
    currentOffset,
    clientOffset
  } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    clientOffset: monitor.getClientOffset()
  }));

  if (item?.noPreview === true) return null;

  return (
    <div style={layerStyles}>
      <div style={{
        display: "inline-block",
        ...getItemStyles(initialOffset, currentOffset, clientOffset),
        backgroundColor: "white",
        border: "1px solid #E6E6E6",
        padding: "10px",
        borderRadius: "8px",
      }}>{item && `${item.jobNumber}`}</div>
    </div>
  );
}

export default memo(Preview);