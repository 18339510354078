import React, { FC, useEffect, useState } from "react"
import { useImmer } from "use-immer"
import { CheckBox } from "../../Form/CheckBox";
import { CloseButton } from "../../Form/CloseButton";
import { Resource } from "../AddResources";
import "./AddResource.css";
interface IAddResource {
  types: string[];
  onClose?(): void;
  onAddingResource(resource: Resource): void;
}

export const AddResource: FC<IAddResource> = (props) => {
  const [state, setState] = useImmer<Resource>({
    uID: "",
    key: "",
    value: "",
    operationType: null,
    type: ""
  })
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (state.type == null ||
      state.type.trim() === "" ||
      state.value == null ||
      state.value.trim() === "" ||
      state.key == null ||
      state.key.trim() === ""
    ) {
      setIsButtonDisabled(true);
    }
    else {
      setIsButtonDisabled(false);
    }

  }, [state, setState]);

  const onTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const _eValue = e.target.value;
    setState(draft => {
      draft.type = _eValue;
    })
  }

  const onKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _eValue = e.target.value;
    setState(draft => {
      draft.key = _eValue;
    })
  }

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _eValue = e.target.value;
    setState(draft => {
      draft.value = _eValue;
    })
  }

  const onOperationType = (checked: boolean) => {
    setState(draft => {
      checked === true
        ? draft.operationType = "paving"
        : draft.operationType = null
    })
  }

  const onSave = () => {
    props.onAddingResource(state);
    props.onClose?.();
  }

  return (
    <div className="Add_Resource">
      <div className="Add_Resource_Header">
        <div className="Wrap">
          <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
          <div className="Title">Add Resource</div>
        </div>
        <CloseButton onClick={props.onClose} />
      </div>
      <div className="Middle_Content">
        <div className="Header">
          <div className="header_item">Type</div>
          <div className="header_item">ID</div>
          <div className="header_item">Name</div>
          <div className="header_item">Operation Type Paving (for material only)</div>
        </div>
        <div className="Content">
          <select
            value={state.type as string === " " || state.type == null ? "" : state.type as string}
            onChange={onTypeChange}
          >
            <option value="" disabled>
              Type:
            </option>
            {
              props.types.length > 0 && props.types.map((s, index) => (
                <option value={s} key={index}>
                  {s}
                </option>
              ))
            }
          </select>
          <input
            onChange={(e) => onKeyChange(e)}
            value={state.key as string}
            type="text"
            placeholder="ID"
          />
          <input
            onChange={(e) => onValueChange(e)}
            value={state.value as string}
            type="text"
            placeholder="Name"
          />
          <CheckBox
            className="Operation_Type"
            onChange={(e) => { onOperationType(e.target.checked) }}
            checked={state.operationType == null ? false : true}
            disabled={state.type === "Material" ? false : true}
          />
        </div>
      </div>
      <div className="Button_Wrap">
        <button onClick={() => onSave()} disabled={isButtonDisabled}>Save Resource</button>
      </div>
    </div>
  )
}