import React from "react"
import { loadingContext, EmittersType } from "../LoadingContext/LoadingContext";

interface IFullScreenLoadingIndicatorProps {
  origin?: string | undefined;
}

export class FullScreenLoadingIndicator extends React.PureComponent<IFullScreenLoadingIndicatorProps> {
  static contextType = loadingContext;
  context: React.ContextType<typeof loadingContext> | null | undefined;

  emitters: EmittersType;

  componentDidMount() {
    if (this.context == null) return;
    this.emitters = this.context.getEmitters(this.props.origin || "FullScreenLoadingIndicator");

    this.emitters.emitLoadingEvent();
  }

  componentWillUnmount() {
    if (this.context == null) return;
    if (this.emitters == null) return;

    this.emitters.emitCompletedEvent();
  }

  render() {
    return (
      <></>
    )
  }

}
