import React, { memo, FC, useContext, useEffect, useCallback } from "react";
import moment from "moment";
import "flatpickr/dist/themes/material_green.css";
import { BookDatePavingComponent } from "./BookDate/BookDatePaving";
import { DateInput } from "./DateInput";
import { ModalContext } from "../../Modal/ModalContext/ModalContext";
import "./PavingCalendar.css"
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Maybe, Query, SuperIntendentPavingCalendar } from "../../../graphql/schema-types";
import { DATES_PAVING_CALENDAR_QUERY_JN } from "../../../graphql/queries/DATES_PAVING_CALENDAR_JN";
import { useImmer } from "use-immer";
import { toMap } from "../../../utils/toMap";
import { FullScreenLoadingIndicator } from "../../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { FullScreenErrorIndicator } from "../../Modal/ErrorIndicator/FullScreenErrorIndicator";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { DATES_PAVING_CALENDAR_QUERY } from "../../../graphql/queries/DATES_PAVING_CALENDAR";
import { toMultiMap } from "../../../utils/toMultiMap";
import { CloseButton } from "../../Form/CloseButton";
import { PAVING_FOREMANS_QUERY } from "../../../graphql/queries/PAVING_FOREMANS_QUERY";
import { SUPER_INTENDENT_PAVING_CALENDAR_QUERY } from "../../../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_QUERY";

interface ILongRangeCalendarProps {
  onClose?(): void;
}

interface State {
  startDate: string;
  endDate: string;
  currentMonth: string;
  lastMonth: string;
}

const LongRangeCalendar: FC<ILongRangeCalendarProps> = props => {
  const dateFormat = "YYYY.MM.DD";

  const [state, setState] = useImmer<State>({
    currentMonth: moment().startOf('month').format("M"),
    lastMonth: moment().startOf('month').add(3, 'months').format("M"),
    startDate: moment().startOf('month').format(dateFormat),
    endDate: moment().startOf('month').add(3, 'months').format(dateFormat)
  });

  const [getDatesInPavingCalendar, { loading, error, data: datesInPavingCalendarData, refetch }] = useLazyQuery<Pick<Query, "datesJNInPavingCalendar">>(DATES_PAVING_CALENDAR_QUERY_JN, {
    fetchPolicy: "no-cache",
  });

  const { data: dataForeman, loading: loadingForeman, error: errorForeman } = useQuery<Pick<Query, "pavingForemans">>(PAVING_FOREMANS_QUERY, {
    fetchPolicy: "no-cache"
  });

  const { data: datesInPavingCalendar, loading: loadingDatesInPavingCalendar } = useQuery<Pick<Query, "datesInPavingCalendar">>(DATES_PAVING_CALENDAR_QUERY, {
    variables: {
      data: {
        startDate: (moment.utc(state.startDate, "YYYY-MM-DD").unix()) * 1000,
        endDate: (moment.utc(state.endDate, "YYYY-MM-DD").unix()) * 1000
      }
    },
    skip: state.startDate == null || state.startDate === "" || state.endDate == null || state.endDate === "",
    fetchPolicy: "no-cache"
  });

  const jobNumber = useSelector((state: RootState) => state.app.start.jobNumber);
  const firstDayPM = useSelector((state: RootState) => state.app.schedule.locations?.[1]?.days?.[0]);
  const lastDayPM = useSelector((state: RootState) => state.app.schedule.locations?.[1]?.days?.[state?.app?.schedule?.locations?.[1]?.days?.length - 1]);

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.removeAttribute('style')
    }
  }, [])

  useEffect(() => {
    window.onpopstate = (e: any) => {
      props?.onClose?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const _startDate = (moment.utc(state.startDate, "YYYY-MM-DD").unix()) * 1000;
    const _endDate = (moment.utc(state.endDate, "YYYY-MM-DD").unix()) * 1000;

    getDatesInPavingCalendar({
      variables: { data: { jobNumber, startDate: _startDate, endDate: _endDate } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, getDatesInPavingCalendar])

  const modal = useContext(ModalContext);

  const onNext = () => {
    const _startDate = moment(state.startDate, dateFormat).add(3, "months").format(dateFormat);
    const _endDate = moment(state.endDate, dateFormat).add(3, "months").format(dateFormat);
    const _startMonth = moment(state.currentMonth).add(3, "months").format("M");
    const _endMonth = moment(state.lastMonth).add(3, "months").format("M");

    setState(draft => {
      draft.startDate = moment.utc(_startDate).format(dateFormat);
      draft.endDate = moment.utc(_endDate).format(dateFormat);
      draft.currentMonth = moment.utc(_startMonth).format("M");
      draft.lastMonth = moment.utc(_endMonth).format("M");
    })
  }

  const onPrev = () => {
    const _startDate = moment(state.startDate, dateFormat).add(-3, "months").format(dateFormat);
    const _endDate = moment(state.endDate, dateFormat).add(-3, "months").format(dateFormat);
    const _startMonth = moment(state.currentMonth).add(-3, "months").format("M");
    const _endMonth = moment(state.lastMonth).add(-3, "months").format("M");

    setState(draft => {
      draft.startDate = moment.utc(_startDate).format(dateFormat);
      draft.endDate = moment.utc(_endDate).format(dateFormat);
      draft.currentMonth = moment.utc(_startMonth).format("M");
      draft.lastMonth = moment.utc(_endMonth).format("M");
    })
  }

  const getDates = useCallback(() => {
    return toMap(datesInPavingCalendarData?.datesJNInPavingCalendar ?? [], pavCalendar => pavCalendar?.date!, () => true);
  }, [datesInPavingCalendarData])

  const getDatesWithMoreThanThreeJN = useCallback(() => {
    const limitItemsNumber = dataForeman != null && dataForeman?.pavingForemans?.length > 0 ? dataForeman?.pavingForemans.length : 3;
    const groupedDates = toMultiMap(datesInPavingCalendar?.datesInPavingCalendar ?? [], date => date?.date!);
    const dates = Object.keys(groupedDates)
      ?.map(date => groupedDates[date].map(d => d?.jobNumber))
      ?.map((gd, i) => gd?.length >= limitItemsNumber
        ? Object.keys(groupedDates)[i]
        : null
    )

    return dates.filter(d => d != null)
  }, [datesInPavingCalendar, dataForeman])

  const { data: lockedDaysData, refetch: refetchLockedDays } = useQuery<Pick<Query, "superIntendentPavingCalendar">>(SUPER_INTENDENT_PAVING_CALENDAR_QUERY, {
    variables: {
      where: {
        startDate: (moment.utc(moment().startOf('month').format("YYYY-MM-DD")).unix()) * 1000,
        endDate: (moment.utc(moment().startOf('month').add(3, 'months').format("YYYY-MM-DD")).unix()) * 1000,
      }
    },
    fetchPolicy: "no-cache"
  });

  const getDatesFrom3WeeksLookWithPaving = useCallback(() => {
    const _firstDatePM = moment(firstDayPM).format(dateFormat);
    const _lastDatePM = moment(lastDayPM).format(dateFormat);

    return datesInPavingCalendarData?.datesJNInPavingCalendar
      ?.filter(item => item!.date! >= _firstDatePM && item!.date! <= _lastDatePM)
      ?.map(item => item?.date as string);

  }, [datesInPavingCalendarData, firstDayPM, lastDayPM]);



  useEffect(() => {
    if (lockedDaysData == null) return;
    let lockedDays: string[] = checkForLockedDays();
    _lockedDays.forEach(ld => {
      if (!lockedDays.includes(ld)) lockedDays?.push(ld)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockedDaysData])

  const disableArrows = useCallback((pressedArrow: string) => {
    const currentMonth = parseInt(moment().utc().format('M'));
    const firstMonthFromState = parseInt(moment.utc(state.currentMonth).format("M"));
    const lastMonthFromState = parseInt(moment.utc(state.lastMonth).format("M"));

    if (pressedArrow === "right") {
      if (lastMonthFromState === currentMonth) {
        return "true";
      }
    }

    if (pressedArrow === "left") {
      if (currentMonth === firstMonthFromState) {
        return "true";
      }
    }

    return "false";
  }, [state])

  const setShowBookDateComponent = useCallback((date: string) => {
    modal?.openModal({
      element: <BookDatePavingComponent
        date={date}
        dateFormat={dateFormat}
        refetchItemInCalendar={refetch}
        dates={datesInPavingCalendarData?.datesJNInPavingCalendar}
        redDates={datesInPavingCalendar?.datesInPavingCalendar}
        nrOfForemans={dataForeman?.pavingForemans.length as number}
        lockedItems3WeekRange={getDatesFrom3WeeksLookWithPaving()}
      />
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datesInPavingCalendarData, datesInPavingCalendar, dataForeman, getDatesFrom3WeeksLookWithPaving])

  const checkForLockedDays = () => {
    let _lockedDays: Maybe<number>[] = [];
    lockedDaysData?.superIntendentPavingCalendar.forEach((item: Maybe<SuperIntendentPavingCalendar>) => {
      if (item?.jobNumbersBlocked?.includes(jobNumber as number) || item?.status === true) _lockedDays.push(item.date)
    })
    let lockedDays = _lockedDays?.map(day => moment.utc(day!).format("YYYY.MM.DD"));
    return lockedDays;
  }

  let _lockedDays = checkForLockedDays()?.map(day => moment.utc(day!).format("YYYY.MM.DD"));

  useEffect(() => {
    refetchLockedDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, setState, setShowBookDateComponent, getDates, getDatesWithMoreThanThreeJN, getDatesFrom3WeeksLookWithPaving, onPrev, onNext])

  return (
    <>
      {(loading || loadingForeman || loadingDatesInPavingCalendar) && <FullScreenLoadingIndicator />}
      {(error != null || errorForeman != null) && <FullScreenErrorIndicator />}
      <div className="Paving_Calendar" onClick={() => { refetchLockedDays() }}>
        <div className="PavingCalendar_Header" onClick={() => { refetchLockedDays() }}>
          <div className="Title">Calendar</div>
          <CloseButton className="Close" onClick={() => props.onClose?.()} />
        </div>
        <div className="button-wrap">
          <button className={`arrow left ${disableArrows("left")}`} onClick={() => onPrev()} ><i className="fa fa-chevron-left"></i></button>
          <button className={`arrow right ${disableArrows("right")}`} onClick={() => onNext()} ><i className="fa fa-chevron-right"></i></button>
        </div>
        <div className="Calendar" onClick={() => { refetchLockedDays() }}>
          <DateInput
            // key={JSON.stringify(datesInPavingCalendarData?.datesJNInPavingCalendar)}
            date={moment.utc(state.startDate).format(dateFormat)}
            onPickerChange={setShowBookDateComponent}
            dates={getDates()}
            redDates={getDatesWithMoreThanThreeJN()}
            lockedDays={checkForLockedDays()}
            lockedDays3WeekRange={getDatesFrom3WeeksLookWithPaving()}
          />
        </div>
      </div>
    </>
  );
}

export default memo(LongRangeCalendar);