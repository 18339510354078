import gql from "graphql-tag";

export const PAVING_SUPERS_QUERY = gql`
  query pavingSupers {
    pavingSupers {
      name
      email
      disabled
	  }
  }
`;