import React, { FC, CSSProperties, useEffect, useRef, useReducer, ReactNode } from "react";
import flatpickr from "flatpickr";
import { Instance } from "flatpickr/dist/types/instance";
import moment from "moment";
import { Maybe } from "../../../graphql/schema-types";

export interface IDateInputProps {
  date: string | null | undefined;
  disabled?: boolean;
  /**
   * Fires only when the picker changes the date
   * @param date number
   */
  onPickerChange?(date: string): void;
  containerStyle?: CSSProperties;
  children?(state: IState): ReactNode;
  dates?: { [date: string]: boolean };
  redDates?: Maybe<string>[];
  lockedDays?: string[];
  lockedDays3WeekRange?: string[];
}

interface IState {
  date: string | null;
  visible: boolean;
  previousAction?: IAction;
}

enum ActionTypes {
  /** SET_ISO_DATE */
  SET_PICKER_DATE = "SET_PICKER_DATE",
  /** SET_UNIX_DATE */
  SET_PROPS_DATE = "SET_PROPS_DATE",
  SET_VISIBILITY = "SET_VISIBILITY"
}

interface IAction<T = any> {
  type: ActionTypes;
  payload?: T;
}

const getInitialState = (): IState => ({
  date: null,
  visible: false,
});

const getDateFromSelectedDates = (dates: Date[]) => {
  let date: Date;
  if (dates == null || dates[0] == null) {
    date = moment().toDate();
  } else {
    date = dates[0];
  }
  return date
}

const mainReducer = (state: IState, action: IAction): IState => {
  switch (action.type) {

    case ActionTypes.SET_PICKER_DATE: {
      const dates = action.payload as Date[];
      const date = getDateFromSelectedDates(dates);
      const _date = moment(date).format("YYYY.MM.DD")
      return ({
        ...state,
        date: _date,
      });
    }

    case ActionTypes.SET_PROPS_DATE: {
      const date = action.payload as string;
      return ({
        ...state,
        date,
      });
    }

    case ActionTypes.SET_VISIBILITY: {
      const visible = action.payload as boolean;
      return ({
        ...state,
        visible,
      });
    }

    default:
      break;
  }

  return state;
}

const reducer = (state: IState, action: IAction): IState => {
  return ({
    ...mainReducer(state, action),
    previousAction: action,
  });
}

export const DateInput: FC<IDateInputProps> = props => {
  const [state, dispatch] = useReducer(reducer, getInitialState())

  const fp = useRef<Instance>(null);
  const elem = useRef<HTMLDivElement>(null);

  // on props change, the instance should reflect that
  useEffect(() => {
    if (props.date == null) return;
    dispatch({ type: ActionTypes.SET_PROPS_DATE, payload: props.date });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    fp.current != null
      ? fp.current.setDate(moment(props.date, "YYYY.MM.DD").toDate())
      : null;
  }, [props.date])

  // destroy picker on state.visible === false
  useEffect(() => {
    if (state.visible) return;
    if (fp.current == null) return;
    destroy();
  }, [state.visible])

  const onWheel = useRef(() => {
    dispatch({ type: ActionTypes.SET_VISIBILITY, payload: false });
  });

  const destroy = () => {
    document.removeEventListener("wheel", onWheel.current);
    if (fp.current == null) return;
    fp.current.close();
    fp.current.destroy();
    (fp.current as any) = null;
  };

  useEffect(() => {
    create();
    return () => {
      destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dates, props.redDates, props.lockedDays])

  const onDayCreate = (dObj: any, dStr: any, fp: any, dayElem: HTMLElement & any) => {
    if (props?.lockedDays?.includes(moment(dayElem.dateObj).format("YYYY.MM.DD"))) {
      dayElem.innerHTML = `<div class="blocked">${dayElem.innerHTML}</div>`
    }

    if (props?.lockedDays3WeekRange?.includes(moment(dayElem.dateObj).format("YYYY.MM.DD"))) {
      dayElem.innerHTML = `<div class="blocked grey">${dayElem.innerHTML}</div>`
    }

    if (props?.redDates?.includes(moment(dayElem.dateObj).format("YYYY.MM.DD"))) {
      dayElem.innerHTML = `<div class="red">${dayElem.innerHTML}</div>`
    }
    if (props?.dates?.[moment(dayElem.dateObj).format("YYYY.MM.DD")] !== true) return;
    dayElem.innerHTML = `<b>${dayElem.innerHTML}</b>`
  }

  useEffect(() => {
    if (fp.current == null) return;
    fp.current.set("onDayCreate", onDayCreate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dates])

  const create = () => {
    if (elem.current == null) return;
    if (fp.current != null) return;
    (fp.current as any) = flatpickr(elem.current, {
      onChange(selectedDates, dateStr, instance) {
        dispatch({ type: ActionTypes.SET_PICKER_DATE, payload: selectedDates });
        props.onPickerChange?.(moment(getDateFromSelectedDates(selectedDates)).format("YYYY.MM.DD"))
      },
      onReady(selectedDates, dateStr, instance) {
        instance.open();
      },
      onOpen(selectedDates, dateStr, instance) {
        dispatch({ type: ActionTypes.SET_VISIBILITY, payload: true });
      },
      onClose(selectedDates, dateStr, instance) {
        dispatch({ type: ActionTypes.SET_VISIBILITY, payload: false });
      },
      disableMobile: true,
      showMonths: 3,
      inline: true,
      prevArrow: '<div />',
      nextArrow: '<div />',
      onDayCreate,
      dateFormat: "d m Y",
      minDate: (moment.utc(moment().startOf('month').format("YYYY-MM-DD")).unix()) * 1000,
      maxDate: ((moment.utc(moment().startOf('month').add(12, 'months').format("YYYY-MM-DD")).unix()) * 1000),
    });
    document.addEventListener("wheel", onWheel.current);
    if (props.date != null) {
      fp.current!.setDate(moment(props.date, "YYYY.MM.DD").toDate());
    }
  };

  return (
    <div ref={elem}></div>
  );
}