import React, { FC } from "react";
import css from "./CloseButton.module.css";

type CloseButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const CloseButton: FC<CloseButtonProps> = props => {
  return (
    <div {...props} className={`${props.className} ${css.button}`}>
      <i className="fas fa-times" />
    </div>
  );
}
