import React, { FC } from "react";
import { Button } from "../../Form/Button";
import { CloseButton } from "../../Form/CloseButton";
import "./SleepMode.css";

interface ISleepModeProps {
  onConfirm?(): void;
  onClose?(): void;
  onCancel?(): void;
  onConfirmButtonText?: string;
}

export const SleepMode: FC<ISleepModeProps> = (props) => {
  return (
    <div>
      <div className="main">
        <div className="main_title">
          <div className="wrap">
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="graniterock" />
            <div className="title">Sleeping</div>
          </div>
          <CloseButton onClick={() => props.onClose?.()} />
        </div>
        <div className="body">
          <div className="message">
            <div className="text" style={{ whiteSpace: "pre-wrap" }}>
              The session timed out. Press OK to resume.
            </div>
          </div>
          <div className="WrapButton">
            <Button
              onClick={() => { props.onClose?.() }}
              title="OK"
              className="Button"
            />
          </div>
        </div>
      </div>
    </div>
  );
}