import React, { FC, useEffect, useContext, useState } from "react";
import { MasterCrewSchedule, TruckingData, Maybe, MaterialData, Query, SuperIntendentPavingCalendar } from "../../../graphql/schema-types";
import { CloseButton } from "../../Form/CloseButton";
import { useImmer } from "use-immer";
import { TextInput } from "../../TextInput";
import "./EditTruckingResource.css";
import { TruckingResourcesNotAllocated } from "../../Warnings & errors/ApplyTruckingResources/TruckingResourcesNotAllocated";
import { ModalContext } from "../../Modal/ModalContext/ModalContext";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { GeneralWarningModal } from "../../Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import { useQuery } from "@apollo/react-hooks";
import { SUPER_INTENDENT_PAVING_CALENDAR_QUERY } from "../../../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_QUERY";
import moment from "moment";

interface IEditTruckingResourceProps {
  onClose?(): void;
  truckingResource: Maybe<MasterCrewSchedule>;
  truckingResourceIndex: number;
  brokerData: Maybe<TruckingData>[] | undefined;
  materialData: Maybe<MaterialData>[] | undefined;
  truckData: Maybe<TruckingData>[] | undefined;
  loadSiteData: Maybe<TruckingData>[] | undefined;
  onEditResource(resource: MasterCrewSchedule, resourceIndex: number): void;
  fromPavingManagement: boolean;
  unix: number;
}

const getInitialTruckingState = (): MasterCrewSchedule => {
  return ({
    broker: "",
    loadSite: "",
    material: "",
    notes: "",
    shift: "",
    type: "",
    qty: null,
    operationType: ""
  })
}

export const EditTruckingResource: FC<IEditTruckingResourceProps> = (props) => {
  const [state, setState] = useImmer<MasterCrewSchedule>(getInitialTruckingState());
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [hasSameShift, setSameShift] = useState<boolean>(false);

  const pavingResurcesLocation1ForPaving = useSelector((state: RootState) => state.app.schedule.locations?.[1]?.daysByUnix?.[props.unix]?.paving?.pavingResources);

  const pavingResurcesLocation2ForPaving = useSelector((state: RootState) => state.app.schedule.locations?.[2]?.daysByUnix?.[props.unix]?.paving?.pavingResources);

  const jobNumber = useSelector((state: RootState) => state.app.start.jobNumber);

  const { data: lockedDaysData, refetch: refetchLockedDays } = useQuery<Pick<Query, "superIntendentPavingCalendar">>(SUPER_INTENDENT_PAVING_CALENDAR_QUERY, {
    variables: {
      where: {
        startDate: (moment.utc(moment().startOf('month').format("YYYY-MM-DD")).unix()) * 1000,
        endDate: (moment.utc(moment().startOf('month').add(1, 'months').format("YYYY-MM-DD")).unix()) * 1000,
      }
    },
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (props.truckingResource == null) return;

    setState(draft => {
      draft.broker = props.truckingResource?.broker;
      draft.loadSite = props.truckingResource?.loadSite;
      draft.material = props.truckingResource?.material;
      draft.notes = props.truckingResource?.notes;
      draft.qty = props.truckingResource?.qty;
      draft.shift = props.truckingResource?.shift;
      draft.type = props.truckingResource?.type;
      draft.operationType = props.truckingResource?.operationType;
    })

  }, [props.truckingResource, setState])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.removeAttribute('style')
    }
  }, [])

  useEffect(() => {
    if (state.material === "") return;
    const operationTypeForSelectedMaterial = props?.materialData?.filter(material => material?.value === state.material)

    setState(draft => {
      draft.operationType = operationTypeForSelectedMaterial?.[0]?.operationType as string;
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.material])

  useEffect(() => {
    if (state.broker === "" ||
      state.loadSite === "" ||
      state.material === "" ||
      state.qty == null ||
      ((state.material === "No Material" && state.loadSite !== "None – Job Prep") && state.qty === 0) ||
      ((state.material !== "No Material" && state.loadSite === "None – Job Prep") && state.qty === 0) ||
      state.qty.toString() === "" ||
      state.shift === "" ||
      state.type === "" ||
      checkForLockedDays().includes(props.unix)) {
      setDisableButton(true)
    }
    else {
      setDisableButton(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, setState, disableButton, setDisableButton])

  const modal = useContext(ModalContext);

  const shift = [
    {
      key: 1,
      value: "N"
    },
    {
      key: 2,
      value: "D"
    }
  ];

  const shiftSpecial = [
    {
      key: 1,
      value: "N 2"
    },
    {
      key: 2,
      value: "D 2"
    }
  ];

  const onBrokerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const _eValue = e.target.value;

    setState(draft => {
      draft.broker = _eValue;
    })
  }

  const onTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const _eValue = e.target.value;

    setState(draft => {
      draft.type = _eValue;
    })
  }

  const onMaterialChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const _eValue = e.target.value;

    setState(draft => {
      draft.material = _eValue;
    })
  }

  const onLoadSiteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const _eValue = e.target.value;

    setState(draft => {
      draft.loadSite = _eValue;
    })
  }

  const onQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let qty: number | "" = parseInt(e.target.value);
    qty = isNaN(qty) ? "" : qty;

    if (state.material != null && (state.material !== "No Material" && state.loadSite !== "None – Job Prep")) {
      if (qty < 0) { return }
    }

    if (qty.toString().length > 5) {
      return;
    }

    setState(draft => {
      draft.qty = qty as number;
    })
  }

  const onNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const _eValue = e.target.value.length > 0 ? e.target.value : " ";;

    if (_eValue.length > 250) { return }

    setState(draft => {
      draft.notes = _eValue
    })
  }

  const onShiftChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const _eValue = e.target.value;

    const shiftPavLocation1 = pavingResurcesLocation1ForPaving?.map(e => e?.shift)?.[0];
    const shiftPavLocation2 = pavingResurcesLocation2ForPaving?.map(e => e?.shift)?.[0];

    setSameShift(false);

    if (state.operationType === "paving") {
      if ((props.truckingResource?.locationIndex === 1 && _eValue === shiftPavLocation2) || ((props.truckingResource?.locationIndex === 2 && _eValue === shiftPavLocation1))) {
        setSameShift(true);
        modal?.openModal?.({
          element: <GeneralWarningModal
            message="You have the same shift on the other location. Please change, otherwise you will cannot save"
            title="Paving Management Warning"
            yesNoButtons={false}
          />
        })
      }
    }

    setState(draft => {
      draft.shift = _eValue;
    })
  }

  const openTruckingResources = () => {
    modal?.openModal({
      element: <TruckingResourcesNotAllocated
        onConfirm={() => props.onClose?.()}
        onCancel={() => { }}
        message="Exit without changes?"
        title="Trucking resources not saved"
        yesNoButtons={true}
      />
    });
  }

  const checkIfResourcesWasEdited = () => {
    if (props.truckingResource?.broker === state.broker &&
      props.truckingResource?.loadSite === state.loadSite &&
      props.truckingResource?.material === state.material &&
      props.truckingResource?.notes === state.notes &&
      props.truckingResource?.qty === state.qty &&
      props.truckingResource?.shift === state.shift &&
      props.truckingResource?.type === state.type) {
      return true
    }
    return false
  }

  const checkForLockedDays = () => {
    let _lockedDays: Maybe<number>[] = [];
    lockedDaysData?.superIntendentPavingCalendar.forEach((item: Maybe<SuperIntendentPavingCalendar>) => {
      if (item?.jobNumbersBlocked?.includes(jobNumber as number) || item?.status === true) _lockedDays.push(item.date)
    })
    return _lockedDays;
  }

  useEffect(() => {
    refetchLockedDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, setState, onBrokerChange, onTypeChange, onMaterialChange, onLoadSiteChange,
    onQtyChange, onNotesChange, onShiftChange])

  return (
    <div className="Edit_Trucking_Resource">
      <div className="Trucking_Header">
        <div className="Date_Wrap">
          <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
          <div className="Title">Edit Trucking Resource</div>
        </div>
        <CloseButton onClick={checkIfResourcesWasEdited() === true ? props.onClose : openTruckingResources} />
      </div>
      <div className="middle-content">
        <div className="Wrap_Inputs_Side">
          <div className="Wrap_Row">
            <div className="left_side">
              <div className="Row">
                <div className="Wrap_Select">
                  <select className="dropdown" value={state?.broker as string} onChange={onBrokerChange} style={{ outline: 0 }}>
                    <option value="" disabled data-default>
                      Broker
                    </option>
                    {
                      props.brokerData?.map((bd, index) => (
                        <option value={bd?.value as string} key={index}>
                          {bd?.value}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="Row">
                <div className="Combined">
                  <div className="Wrap_Select">
                    <select className="dropdown" value={state?.type as string} onChange={onTypeChange} style={{ outline: 0 }}>
                      <option value="" disabled>
                        Type
                      </option>
                      {
                        props.truckData?.map((td, index) => (
                          <option value={td?.value as string} key={index}>
                            {td?.value}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                  <TextInput
                    type="text"
                    value={state?.qty?.toString?.() ?? ""}
                    onChange={(e) => onQtyChange(e)}
                    className="input-container"
                    placeholder="Qty"
                  />
                </div>
              </div>
              <div className="Row">
                <div className="Wrap_Select">
                  <select className="dropdown" value={state?.material as string} onChange={onMaterialChange} style={{ outline: 0 }}>
                    <option value="" disabled>
                      Material:
                    </option>
                    {
                      props.fromPavingManagement != null && props.fromPavingManagement === false
                        ? props.materialData?.map((md, index) => (
                          <option value={md?.value as string} key={index}
                            disabled={md?.operationType === "paving"
                              || (state.operationType === "paving" && md?.operationType !== "paving")
                              ? true
                              : false
                            }>
                            {md?.key !== md?.value ? md?.key as string + " - " + md?.value as string : md?.value as string}
                          </option>
                        ))
                        : props.materialData?.map((md, index) => (
                          <option value={md?.value as string} key={index}
                            disabled={(state.operationType !== "paving" && md?.operationType === "paving")
                              || (state.operationType === "paving" && md?.operationType !== "paving")
                              ? true
                              : false
                            }>
                            {md?.key !== md?.value ? md?.key as string + " - " + md?.value as string : md?.value as string}
                          </option>
                        ))
                    }
                  </select>
                </div>
              </div>
              <div className="Row">
                <div className="Wrap_Select">
                  <select className="dropdown" value={state?.loadSite as string} onChange={onLoadSiteChange} style={{ outline: 0 }}>
                    <option value="" disabled>
                      Load Site:
                    </option>
                    {
                      props.loadSiteData?.map((lsd, index) => (
                        <option value={lsd?.value as string} key={index}>
                          {lsd?.value}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="Row">
                <div className="Wrap_Select">
                  <select className="dropdown" value={state?.shift as string} onChange={(e) => onShiftChange(e)} style={{ outline: 0 }}>
                    <option value="" disabled>
                      Shift:
                    </option>
                    {
                      props.truckingResource?.locationIndex != null && props.truckingResource?.locationIndex > 2
                        ? shiftSpecial.map((s, index) => (
                          <option value={s.value} key={index}>
                            {s.value}
                          </option>
                        ))
                        : shift.map((s, index) => (
                          <option value={s.value} key={index}>
                            {s.value}
                          </option>
                        ))
                    }
                  </select>
                </div>
              </div>
            </div>
            <div className="right_side">
              <div className="Row">
                <textarea
                  value={state?.notes as string}
                  onChange={(e) => onNotesChange(e)}
                  placeholder="Notes"
                  cols={30}
                  rows={30}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="Edit_Resource_Button"
        onClick={() => { props.onEditResource(state, props.truckingResourceIndex); props.onClose?.() }}
        disabled={disableButton || hasSameShift}>
        Apply
      </button>
    </div>
  )
}