import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EQUIPMENT_MANAGEMENT_DATAS_QUERY } from "../../graphql/queries/EQUIPMENT_MANAGEMENT_DATAS_QUERY";
import { EQUIPMENT_RESOURCES_QUERY } from "../../graphql/queries/EQUIPMENT_RESOURCES_QUERY";
import { EQUIPMENT_SUBCATEGORIES_QUERY } from "../../graphql/queries/EQUIPMENT_SUBCATEGORIES";
import { GET_REMOTE_EQUIPMENT } from "../../graphql/queries/GET_REMOTE_EQUIPMENT";
import { MATERIAL_DATAS_QUERY } from "../../graphql/queries/MATERIAL_DATAS_QUERY";
import { TRUCKING_DATAS_QUERY } from "../../graphql/queries/TRUCKING_DATAS_QUERY";
import { Query } from "../../graphql/schema-types";
import { addEquipmentResources, addEquipmentSubcategories, addEquipmentToPavingModule, addRemoteEquipmentList, addTruckingAndMaterialDatas, initPavingModule } from "../../redux/appSlice";
import { RootState } from "../../redux/store";
import { FullScreenErrorIndicator } from "../Modal/ErrorIndicator/FullScreenErrorIndicator";

export const InitPavingModule = () => {
  const dispatch = useDispatch();

  const items = useSelector((state: RootState) => state.app?.pavingModule);
  const daysFromState: string[] = Object.keys(items);
  const pavingModuleUser = useSelector((state: RootState) => state.app?.pavingModuleUser);

  const { data: dataEquip, loading: loadingEquip, error: errorEquip } = useQuery<Pick<Query, "equipmentResources">>(EQUIPMENT_RESOURCES_QUERY);

  const { data: equipmentManagement, loading: loadingEquipmentManagement, error: errorEquipmentManagement } = useQuery<Pick<Query, "equipmentManagementDatas">>(EQUIPMENT_MANAGEMENT_DATAS_QUERY, {
    variables: {
      where: {
        startDate: parseInt(daysFromState[0]),
        endDate: parseInt(daysFromState[daysFromState.length - 1]),
      }
    },
    skip: Object.keys(items).length === 0
  });

  const { data: trucking } = useQuery<Pick<Query, "truckingDatas">>(TRUCKING_DATAS_QUERY);
  const { data: materials } = useQuery<Pick<Query, "materialDatas">>(MATERIAL_DATAS_QUERY, { fetchPolicy: "no-cache" });

  const { data: equipmentSubcategories, loading: loadingSubcategories, error: errorSubcategories } = useQuery<Pick<Query, "equipmentSubcategories">>(EQUIPMENT_SUBCATEGORIES_QUERY, {
    fetchPolicy: "no-cache"
  });

  const { data: remoteEquipment, loading: remoteEquipmentLoading, error: remoteEquipmentError } = useQuery<Pick<Query, "getRemoteEquipment">>(GET_REMOTE_EQUIPMENT);

  useEffect(() => {
    dispatch(initPavingModule({}))
  }, [dispatch])

  useEffect(() => {
    if (loadingEquip || dataEquip == null || dataEquip.equipmentResources == null || loadingEquipmentManagement || equipmentManagement == null || loadingSubcategories || equipmentSubcategories == null || equipmentSubcategories.equipmentSubcategories == null || remoteEquipment == null || remoteEquipmentLoading == null) { return }

    dispatch(addEquipmentResources({ equipmentResources: dataEquip.equipmentResources }));
    dispatch(addEquipmentSubcategories({
      equipmentsForCheckbox: equipmentSubcategories?.equipmentSubcategories.equipmentsForCheckbox,
      equipmentsForInput: equipmentSubcategories?.equipmentSubcategories.equipmentsForInput
    }))
    dispatch(addEquipmentToPavingModule({ equipmentManagementResources: equipmentManagement.equipmentManagementDatas }))
    dispatch(addTruckingAndMaterialDatas({ truckingDatas: trucking?.truckingDatas, materialDatas: materials?.materialDatas }))

    dispatch(addRemoteEquipmentList({ list: remoteEquipment.getRemoteEquipment }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pavingModuleUser, loadingEquip, equipmentManagement, equipmentSubcategories, remoteEquipment])

  return (
    <>
      {(errorEquip != null || errorEquipmentManagement != null || errorSubcategories != null || remoteEquipmentError != null) && <FullScreenErrorIndicator />}
    </>
  );
}