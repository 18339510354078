import React, { FC } from "react";

export const DisabledUser: FC = props => {

  return (
    <div className="First_select" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/gr-com_construction-dispatch_background.png'})` }}>

      <div className="wrap_holder">
        <div className="Select_holder">
          Call your superintendent, your account has been disabled.
        </div>
      </div>
    </div>

  );
}