import React, { FC } from "react";
import { Button } from "../../Form/Button";
import "./LockedDaysWhileWorking.css";
import { CloseButton } from "../../Form/CloseButton";
import moment from "moment";

interface LockedDaysWhileWorking {
  onClose?(): void;
  days: number[];
}

export const LockedDaysWhileWorking: FC<LockedDaysWhileWorking> = props => {

  return (
    <div>
      <div className="LockedDaysWhileWorking">
        <div className="Title">
          <img src={process.env.PUBLIC_URL + '/logo.png'} alt="graniterock" style={{ width: "50px", height: "50px" }} />
          <div>Locked days while working</div>
          <CloseButton onClick={() => props.onClose?.()} />
        </div>
        <div className="ErrorMessage">
          <div className="ErrorText">
            {`These days were locked while you were working: ${props.days.map(d => moment(d).utc().format("MM/DD"))}.
            Paving Crews can't be saved for them.`}
          </div>
        </div>
        <div className="ErrorActionHolder">
          <Button
            onClick={() => props.onClose?.()}
            title="OK"
            type="button"
            className="Button"
          />
        </div>
      </div>
    </div>
  );
}