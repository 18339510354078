import gql from "graphql-tag";

export const MASTER_CREW_SCHEDULES_QUERY = gql`
  query masterCrewSchedules($where: MasterCrewSchedulesQueryInput!) {
    masterCrewSchedules(where: $where) {
			uID 
			jobNumber  
			locationIndex  
			searchGSI_JobNumber  
			tableauGSI_Tableau
			date   
			broker
    	type
    	qty
			material
    	loadSite
    	shift
    	notes
			operationType
			addedFromPaving
			updated
		}
	}
`;