import gql from "graphql-tag";

export const JOB_INFO_QUERY = gql`
  query JOB_INFO_QUERY($where: JobInfoInput!){
    jobInfo(where: $where) {
      uID
      jobNumber_Date
      searchGSI_JobNumber
      tableauGSI_Tableau
      date  
      areaManager
      jobName
      pmpe
      superIntendent
      foreman
      resourceName
      resourceID
      resourceType
      resourceQTY  
      description
      additionalResourcesComments
      locationIndex  
      pavingForeman
      pavingSuperIntendent
      jobMap
    }
  }
`;
