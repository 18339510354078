import gql from "graphql-tag";

export const CREW_RESOURCES_QUERY = gql`
  query crewResources($crewCode: String!){
    crewResources(crewCode: $crewCode) {
		id
		crewCode
		JobNumber
		QTYNeeded
		dateAdded
		resourceID
		resourceDescription
		resourceType
	}
  }
`;