import React, { FC, useState, memo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { isNumber } from "util";

interface Props {

}

interface Location {
  name: string | undefined;
  value: number;
}
const equalityFn = (leftArray: Location[], rightArray: Location[]) => {
  if (leftArray.length === rightArray.length) { return true }
  const rv = leftArray?.filter((left, i) => left.name === rightArray[i].name && left.value === rightArray[i].value).length === leftArray.length
  return rv
}

const LocationSelect: FC<Props> = props => {
  const [state, setState] = useState(1)
  const locations = useSelector((state: RootState) => Object.keys(state.app.schedule.locations).map((k) => {
    return ({

      name: state.app.schedule.locations?.[parseInt(k)].name,
      value: parseInt(k)
    })
  }), equalityFn);


  const onLocationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const v = parseInt(event.target.value)
    if (!isNumber(v)) { return }
    setState(v)
    //history.push(`#${v}`);
    // eslint-disable-next-line no-restricted-globals
    // location.href = `${location.origin}${location.pathname}#${v}`
    document.getElementById(`${v}`)?.scrollIntoView();
  }

  return (
    <select className="select-box" value={state} onChange={onLocationChange} style={{ outline: 0 }}>
      {locations?.map((loc, index) => (
        <option key={loc.value} value={loc.value}>
          {loc.name === "" ? `Location ${loc.value}` : loc.name}
        </option>
      ))}
    </select>
  );
}

export default memo(LocationSelect);