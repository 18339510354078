import gql from "graphql-tag";

export const JOB_INFO_LOCATIONS_QUERY = gql`
    query JOB_INFO_LOCATIONS_QUERY($where: jobInfoLocationsInput!) {
        jobInfoLocations(where: $where) {
        jobNumber
        locationIndex
        activityDescription
        name
        }
    }
`;