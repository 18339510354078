import gql from "graphql-tag";

export const WEATHER_CONTENT_QUERY = gql`
  query weatherContent {
    weatherContent {
      weatherContentDataDay{
      ETag
      Key
      URL
      }
      weatherContentDataNight{
      ETag
      Key
      URL
      }
	  }
  }
`;