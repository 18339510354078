import React, { FC, useState, useContext, useEffect } from "react";
import "./AddCrew.css";
import { TextInput } from "../../TextInput";
import { Button } from "../../Form/Button";
import { CREATE_CREW_MUTATION } from "../../../graphql/mutations/CREATE_CREW_MUTATION";
import { useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import to from 'await-to-js';
import { ModalContext } from "../../Modal/ModalContext/ModalContext";
import { Maybe, CustomCrew } from "../../../graphql/schema-types";
import { CloseButton } from "../../Form/CloseButton";
import { GeneralWarningModal } from "../../Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import { v4 } from "uuid";
import { addCustomCrews } from "../../../redux/appSlice";

interface AddCrewInterface {
  onClose?(): void;
  refetch?(): void;
  crews: Maybe<CustomCrew>[] | undefined;
}

export const AddCrew: FC<AddCrewInterface> = props => {

  const [crewCode, setCrewCode] = useState<string>("");
  const [crewDescription, setCrewDescription] = useState<string>("");
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const modal = useContext(ModalContext);

  const dispatch = useDispatch();

  const [createCrew] = useMutation(CREATE_CREW_MUTATION, { onCompleted: props.refetch });

  const jobNumber = useSelector((state: RootState) => state.app.start.jobNumber);
  const customCrewsByJobNumber = useSelector((state: RootState) => state.app.customCrews);

  const isCrewDescriptionAlreadySavedInDB = props.crews?.filter(crew => crew?.crewDescription === crewDescription);

  useEffect(() => {
    if (crewCode.trim() === "" || crewDescription.trim() === "") {
      setButtonDisabled(true);
    }
    else {
      setButtonDisabled(false);
    }
  }, [crewDescription, crewCode])

  useEffect(() => {
    window.onpopstate = (e: any) => {
      props?.onClose?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCancel = () => {
    props.onClose?.();
    setCrewCode("");
    setCrewDescription("");
  }

  const onSubmit = async () => {
    if (isCrewDescriptionAlreadySavedInDB != null && isCrewDescriptionAlreadySavedInDB?.length > 0) {
      modal?.openModal?.({
        element: <GeneralWarningModal
          message="You entered a crew code or crew name that already exists...Please check!!"
          title="Error on adding a new crew"
          yesNoButtons={false}
          onClose={() => { }}
        />
      });
    }
    else {
      const where = {
        jobNumber: jobNumber as number,
        code: crewCode,
        description: crewDescription
      }
      const [, response] = await to(createCrew({ variables: where }));
      if (response) {
        let crews: Maybe<CustomCrew>[] = [];
        customCrewsByJobNumber.forEach(c => {
          if (c?.crewCode !== crewCode) {
            crews.push(c);
          }
        })
        crews.push({
          crewCode: crewCode,
          crewDescription: crewDescription,
          jobNumber: jobNumber as number,
          id: v4()
        })
        dispatch(addCustomCrews({ customCrews: crews }))
        props.onClose?.();
      }
      else {
        modal?.openModal?.({
          element: <GeneralWarningModal
            message="You entered a crew code or crew name that already exists...Please check!!"
            title="Error on adding a new crew"
            yesNoButtons={false}
            onClose={() => { }}
          />
        });
      }
    }
  }

  return (
    <>
      <div className="Add_Crew">
        <div className="New_Crew_Title">
          <div className="Wrap">
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="graniterock" />
            <div className="Title">Crew Operations</div>
          </div>
          <CloseButton onClick={props.onClose} />
        </div>
        <div className="Add_Crew_Body">
          <div className="Add_Crew_Heading">Add New Crew</div>
          <div className="New_Crew_Line">
            <div className="Code">Code</div>
            <div className="Code_Input">
              <TextInput
                type="text"
                onChange={(e) => { setCrewCode(e.target.value) }}
                value={crewCode}
              />
            </div>
          </div>
          <div className="New_Crew_Line">
            <div className="New_Crew_Name">Crew Name</div>
            <div className="Crew_Name_Input">
              <TextInput
                type="text"
                onChange={(e) => { setCrewDescription(e.target.value) }}
                value={crewDescription}
              />
            </div>
          </div>
          <div className="New_Crew_Action_Holder">
            <Button
              onClick={() => onCancel()}
              title="Cancel"
              type="button"
              className="Button_Cancel"
            />

            <Button
              onClick={() => onSubmit()}
              title="Add"
              type="submit"
              className="Button_Add"
              disabled={buttonDisabled}
            />
          </div>
        </div>
      </div>
    </>
  );
}