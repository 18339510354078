import React, { FC, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/store";
import { addAdditionalResourceCommentsToDay } from "../redux/appSlice";

interface IDayAdditionalResourceCommentsProps {
  locationIndex: number;
  unix: number;
}

export const DayAdditionalResourceComments: FC<IDayAdditionalResourceCommentsProps> = (props) => {
  const [additionalRC, setAdditionalRC] = useState<string>("");

  const additionalResourceComments = useSelector((state: RootState) => state.app.schedule?.locations?.[props.locationIndex]?.daysByUnix?.[props.unix].additionalResourceComments);

  useEffect(() => {
    if (additionalRC === additionalResourceComments) return;
    setAdditionalRC(additionalResourceComments);
  }, [additionalRC, additionalResourceComments])

  const dispatch = useDispatch();

  const onAdditionalResourceComments = (value: string) => {
    setAdditionalRC(value);

    dispatch(addAdditionalResourceCommentsToDay({
      locationIndex: props.locationIndex,
      unix: props.unix,
      additionalResourceComments: value,
    }))
  }

  return (
    <div className="Ad_res" style={{ flex: 1 }}>
      <textarea
        cols={30}
        value={additionalRC}
        onChange={(e) => onAdditionalResourceComments(e.target.value)}
      />
    </div>
  );
}