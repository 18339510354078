import React, { FC } from "react";
import { Button } from "../../Form/Button";
import "./CancelAssignment.css";

interface CancelAssignmentInterface {
  onConfirm?(): void;
  onCancel?(): void;
  onClose?(): void;
}

export const CancelAssignment: FC<CancelAssignmentInterface> = (props) => {
  return (
    <div>
      <div className="CancelAssignment Add_Crew">
        <div className="New_Crew_Title">
          <div>Cancel Assignment</div>
          <div onClick={() => { props.onCancel?.(); props.onClose?.() }} style={{ padding: "0 1em" }}>X</div>
        </div>
        <div className="ErrorMessage">
          <div className="ErrorText">
            All edits here will be lost. <br />
            Are you sure you want to cancel?
          </div>
        </div>
        <div className="ErrorActionHolder">
          <Button
            onClick={() => { props.onConfirm?.(); props.onClose?.() }}
            title="Yes"
            type="button"
            className="Button"
          />
          <Button
            onClick={() => { props.onCancel?.(); props.onClose?.() }}
            title="No"
            type="button"
            className="Button"
          />
        </div>
      </div>
    </div>
  );
}