import { useMutation } from '@apollo/react-hooks';
import to from 'await-to-js';
import moment from 'moment';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_SUPER_INTENDENT_PAVING_CALENDAR } from '../../../graphql/mutations/UPDATE_SUPER_INTENDENT_PAVING_CALENDAR';
import { Maybe, PavingForeman, UpdateSuperIntendentPavingCalendarInput } from '../../../graphql/schema-types';
import { UpdateBlockedForemanFromCurrentDay, updateBlockedForemanFromDay } from '../../../redux/appSlice';
import { RootState } from '../../../redux/store';
import { CheckBox } from "../../Form/CheckBox";
import { CloseButton } from '../../Form/CloseButton';
import { FullScreenLoadingIndicator } from '../../Modal/LoadingIndicator/FullScreenLoadingIndicator';
import { ModalContext } from '../../Modal/ModalContext/ModalContext';
import { GeneralWarningModal } from '../../Warnings & errors/GeneralWarningModal/GeneralWarningModal';
import './NoWorkForForeman.css';

interface INoWorkForForeman {
  unix: any,
  pavingForemans: Maybe<PavingForeman>[],
  onClose?(): void;
}

// this component is opened when you right click on a day from Paving Planner and select "No work Foreman"
// this blocks a foreman for the current day
// the foreman will not be able to work that day/you will cannot select on that day as a team manager for any resource

export const NoWorkForForeman: FC<INoWorkForForeman> = (props) => {
  const { unix, pavingForemans } = props;

  const [checkBoxes, setCheckBoxes] = useState<{ [name: string]: boolean }>({});
  // foremans that are already setted for an item on current day
  const [usedForemans, setUsedForemans] = useState<string[]>([]);

  const [updateSuperIntendentPavingCalendar, { loading }] = useMutation(UPDATE_SUPER_INTENDENT_PAVING_CALENDAR);

  const dispatch = useDispatch();
  const modal = useContext(ModalContext);

  const isDayDisabled = useSelector((state: RootState) => state.app.pavingModule[unix]?.isDayBlocked);
  const jobNumbersBlocked: number[] = useSelector((state: RootState) => state.app.pavingModule[unix]?.jobNumbersBlocked);
  const _blockedForemans = useSelector((state: RootState) => state.app.pavingModule[unix]?.noWorkForForeman);
  const itemsFromCurrentDay = useSelector((state: RootState) => state.app.pavingModule[unix]?.items);

  const sortedPavingForemans = pavingForemans?.sort((a, b) => a! > b! ? -1 : 1);

  useEffect(() => {
    if (_blockedForemans == null || _blockedForemans.length === 0) return;

    let obj = { ...checkBoxes };
    _blockedForemans.forEach(f => {
      Object.assign(obj, { [f]: true });
    })

    setCheckBoxes(obj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (itemsFromCurrentDay?.length === 0) return;

    const foremansUsed: string[] = itemsFromCurrentDay.map(i => i?.pavingForeman as string);

    setUsedForemans(foremansUsed);
  }, [itemsFromCurrentDay])

  const onCheckForeman = (e: React.ChangeEvent<HTMLInputElement>, checkBoxValue: string) => {
    const checked = e.target.checked;
    const _checkboxes = { ...checkBoxes };
    if (checked === false) {
      delete _checkboxes[checkBoxValue];
      setCheckBoxes(_checkboxes);
      return;
    }
    setCheckBoxes({ ...checkBoxes, [checkBoxValue]: checked });
  }

  const onSave = async () => {
    const _foremanNames: string[] = Object.keys(checkBoxes);

    const where: UpdateSuperIntendentPavingCalendarInput = {
      date: parseInt(unix),
      jobNumbersBlocked: jobNumbersBlocked,
      noWorkForForemans: _foremanNames
    }

    const [error,] = await to(
      updateSuperIntendentPavingCalendar({ variables: { where } }));

    if (error != null) {
      modal?.openModal?.({
        element:
          <GeneralWarningModal
            message='The modifications could not be saved! Please try again'
            title='Error on saving'
            yesNoButtons={false}
            onClose={() => { }}
          />
      });
    }
    else {
      const foremans: UpdateBlockedForemanFromCurrentDay[] = [];
      _foremanNames.forEach(f => {
        foremans.push({
          unix: parseInt(unix),
          lock: true,
          foremanName: f
        })
      })
      dispatch(updateBlockedForemanFromDay({ unix: parseInt(unix), foremans }));
      props.onClose?.();
    }
  }

  const isSaveButtonDisabled = () => {
    let isButtonDisabled = false;

    if (isDayDisabled === true) {
      isButtonDisabled = true;
    }

    if (Object.keys(checkBoxes).length === 0) {
      isButtonDisabled = true;
    }

    return isButtonDisabled;
  }

  return (
    <>
      {loading && <FullScreenLoadingIndicator />}
      <div className="NoWorkForForeman">
        <div className="NoWorkForForeman_Header">
          <div className="Date_Wrap">
            <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
            <div className="Title">Foremans that do not work on day {moment(parseInt(unix)).utc().format("DD MMMM")} </div>
          </div>
          <CloseButton onClick={props.onClose} />
        </div>
        <div className='NoWorkForForeman_Content'>
          <div className='Foremans_List'>
            {
              sortedPavingForemans?.map((pf, index) => (
                <CheckBox
                  key={index}
                  checked={checkBoxes[pf?.username as string] || false}
                  onChange={(e) => onCheckForeman(e, pf?.username as string)}
                  className='Checkbox_Item'
                  disabled={usedForemans.includes(pf?.username as string) ? true : false}
                >
                  {pf?.username as string}
                </CheckBox>
              ))
            }
          </div>
          <div className="Buttons_Wrap">
            <button className="Save" onClick={() => onSave()} disabled={isSaveButtonDisabled()}>Save</button>
            <button className="Cancel" onClick={() => props.onClose?.()} >Cancel</button>
          </div>
        </div>
      </div>
    </>
  )
}