import React, { FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeItemFromPavingModule, updateJobNumberStatusFromDay } from "../../../redux/appSlice";
import { RootState } from "../../../redux/store";
import "./ItemMenu.css";
import { useMutation } from "@apollo/react-hooks";
import to from "await-to-js";
import { UPDATE_SUPER_INTENDENT_PAVING_CALENDAR } from "../../../graphql/mutations/UPDATE_SUPER_INTENDENT_PAVING_CALENDAR";
import { DELETE_ITEM_FROM_CALENDAR } from "../../../graphql/mutations/DELETE_ITEM_FROM_CALENDAR";
import { ModalContext } from "../../Modal/ModalContext/ModalContext";
import { EquipmentResources } from "../EquipmentResources/EquipmentResources";
import { Maybe, SuperIntendentPavingCalendarDatas } from "../../../graphql/schema-types";
import { JobMap } from "../JobMap/JobMap";
import { GeneralWarningModal } from "../../Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import moment from "moment";
import Portal from "../../Modal/Portal";

interface IItemMenuInterface {
  jobNumber: number;
  isBorderRed: boolean;
  jobsToLock: number[];
  item?: Maybe<SuperIntendentPavingCalendarDatas>;
  pageX: number,
  pageY: number,
  visible: boolean,
}

const ItemMenu: FC<IItemMenuInterface> = (props) => {
  const { jobNumber, item, pageX, pageY, visible } = props;

  let jobNumbersBlocked = useSelector((state: RootState) => state.app.pavingModule[item?.date]?.jobNumbersBlocked);
  let foremansBlocked = useSelector((state: RootState) => state.app.pavingModule[item?.date]?.noWorkForForeman);

  const [deleteItemFromCalendar] = useMutation(DELETE_ITEM_FROM_CALENDAR);

  const isGeneral = useSelector((state: RootState) => state.app?.pavingModuleUser.isInGeneralPavingSupers);
  const isDayDisabled = useSelector((state: RootState) => state.app.pavingModule[item?.date]?.isDayBlocked);
  const isItemLocked = jobNumbersBlocked != null && jobNumbersBlocked?.includes(jobNumber);
  const equipmentResources = useSelector((state: RootState) => state.app.equipmentResources);

  const [stateItemLock, setStateItemLock] = useState<boolean>(isItemLocked);

  const [updateSuperIntendentPavingCalendar] = useMutation(UPDATE_SUPER_INTENDENT_PAVING_CALENDAR);

  const dispatch = useDispatch();
  const modal = useContext(ModalContext);

  let updatedJobNumbersBlocked: number[] = [];

  useEffect(() => {
    setStateItemLock(isItemLocked);
  }, [updatedJobNumbersBlocked, isItemLocked])

  const onLock = async () => {
    dispatch(updateJobNumberStatusFromDay({ jobNumber, unix: item?.date, lock: true }));
    updatedJobNumbersBlocked.push(...jobNumbersBlocked, jobNumber);
    setStateItemLock(true);
    await to(
      updateSuperIntendentPavingCalendar({
        variables: {
          where: {
            date: item?.date,
            jobNumbersBlocked: updatedJobNumbersBlocked,
            noWorkForForemans: foremansBlocked
          },
        },
      })
    );
  }

  const onUnlock = async () => {
    dispatch(updateJobNumberStatusFromDay({ jobNumber, unix: item?.date, lock: false }));
    updatedJobNumbersBlocked.push(...jobNumbersBlocked);
    setStateItemLock(false);
    if (updatedJobNumbersBlocked.includes(jobNumber)) {
      let jobNumberIndex = updatedJobNumbersBlocked.indexOf(jobNumber);
      updatedJobNumbersBlocked.splice(jobNumberIndex, 1);
    }

    await to(
      updateSuperIntendentPavingCalendar({
        variables: {
          where: {
            date: item?.date,
            jobNumbersBlocked: updatedJobNumbersBlocked,
            noWorkForForemans: foremansBlocked
          },
        },
      })
    );
  }

  const onClickEquipList = () => {
    modal?.openModal?.({
      element:
        <EquipmentResources
          equipmentResources={equipmentResources}
          item={item as SuperIntendentPavingCalendarDatas}
        />
    })
  }

  const onClickJobMap = () => {
    modal?.openModal?.({
      element:
        <JobMap
          item={item as SuperIntendentPavingCalendarDatas}
        />
    })
  }

  const onClickDeleteSchedule = () => {
    modal?.openModal?.({
      element: <GeneralWarningModal
        message={`You are about to delete job ${item?.jobNumber} paving schedule for ${moment(item?.date).utc().format("MM-DD-YY")}. This operation can not be undone.`}
        title={`Delete Schedule`}
        yesNoButtons={true}
        onConfirm={() => openDeleteModal()}
      />
    });
  }

  const openDeleteModal = () => {
    modal?.openModal?.({
      element: <GeneralWarningModal
        message={`Are you sure?`}
        title={`Delete Schedule`}
        yesNoButtons={true}
        onConfirm={() => { deleteSchedule() }}
      />
    });
  }

  const deleteSchedule = async () => {
    if (item != null) {
      //Update redux (remove item from day) and refetch query
      dispatch(removeItemFromPavingModule(item))
      //Delete mutation
      await to(deleteItemFromCalendar({
        variables: {
          where: {
            jobNumber: item?.jobNumber,
            date: item?.date,
            locationIndex: item?.locationIndex
          },
        },
      })
      );
    }
  }

  const hasShift = () => {
    if (props.item?.shift as string !== "D") {
      if (props.item?.shift as string !== "N") {
        if (props.item?.shift as string !== "D 2") {
          if (props.item?.shift as string !== "N 2") {
            return false;
          }
        }
        return true;
      }
      return true;
    }
    return true;
  }

  if (visible) {
    return (
      <Portal selector="#menu-root">
        <ul className="Menu_Calendar" id="Am_Menu"
          style={{ top: `${pageY}px`, left: `${pageX}px` }}
        >
          {
            stateItemLock === false
              ? <> <div className="Menu_Icons"><i className="fas fa-lock"></i><button id="Am_Menu_ButtonLK" disabled={isDayDisabled || props.isBorderRed || !hasShift()} onClick={onLock} >Lock Job</button></div></>
              : <> <div className="Menu_Icons"><i className="fas fa-unlock"></i><button id="Am_Menu_ButtonULK" disabled={isDayDisabled || props.isBorderRed || !hasShift()} onClick={onUnlock} >Unlock Job</button></div></>
          }
          <> <div className="Menu_Icons"><i className="fa fa-cubes"></i><button id="Am_Menu_ButtonEQ" disabled={!hasShift() && !isGeneral} onClick={() => onClickEquipList()} >Equipment List</button></div></>
          {
            props.item?.jobMap === " " || props.item?.jobMap === "" || props.item?.jobMap == null
              ? <> <div className="Menu_Icons"><i className="fa fa-map"></i><button id="Am_Menu_ButtonAJ" disabled={!hasShift() && !isGeneral} onClick={() => onClickJobMap()} >Add Job Map</button></div></>
              : <> <div className="Menu_Icons"><i className="fa fa-map"></i><button id="Am_Menu_ButtonVJ" disabled={!hasShift() && !isGeneral} onClick={() => onClickJobMap()} >View Job Map</button></div></>
          }
          <> <div className="Menu_Icons"><i className="fa fa-calendar-times"></i><button disabled={isDayDisabled || !hasShift() || stateItemLock !== false} onClick={() => onClickDeleteSchedule()}>Delete Schedule</button></div></>
        </ul>
      </Portal>
    );
  }
  return <></>;
};

export default ItemMenu;