import gql from "graphql-tag";

export const DATES_PAVING_CALENDAR_QUERY = gql`
  query datesInPavingCalendar($data: PavingCalendarQInput!) {
    datesInPavingCalendar(data: $data) {
      uID
      date
      jobNumber
      jobName
      manager
      tonnage
      plant
      nrOfTrucks
      typeOfTrucks
      broker
      material
      operationType
      constant
      shift
      locationIndex
    }
  }
`;