
import React, { FC } from "react";
import { IncompleteSuperIntendentPavingCalendarDatas, Maybe } from "../../../graphql/schema-types";
import moment from "moment";
import "./NotifyIncompleteItems.css";
import { CloseButton } from "../../Form/CloseButton";

interface INotifyIncompleteItems {
  incompleteItems: Maybe<IncompleteSuperIntendentPavingCalendarDatas>[];
  onClose(): void;
}

export const NotifyIncompleteItems: FC<INotifyIncompleteItems> = (props) => {

  return (
    <div className="Incomplete_Items_List">
      <CloseButton onClick={props.onClose} className="CloseButton" />
      {
        props.incompleteItems?.map((item, index) => (
          <div className="item" key={index}>On {moment(item?.date).utc().format("MM-DD-YYYY")} Job {item?.jobNumber} has invalid data.</div>
        ))
      }
    </div>
  )
}