import { useMutation } from "@apollo/react-hooks";
import to from "await-to-js";
import React, { FC, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { POST_TO_SNS } from "../../../graphql/mutations/POST_TO_SNS";
import { RootState } from "../../../redux/store";
import { AddResources } from "../../AddResources/AddResources";
import { FullScreenLoadingIndicator } from "../../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { ModalContext } from "../../Modal/ModalContext/ModalContext";
import { GeneralWarningModal } from "../../Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import { AuditTrail } from "../AuditTrail/AuditTrail";
import { EquipmentDetailsWindow } from "../EquipmentDetailsWindow/EquipmentDetailsWindow";
import { EquipmentListManagement } from "../EquipmentListManagement/EquipmentListManagement";
import { NotifyIncompleteItems } from "../NotifyIncompleteItems/NotifyIncompleteItems";
import { TeamManagement } from "../TeamManagement/TeamManagement";
import { UsersManagement } from "../UsersManagement/UsersManagement";
import "./SideBar.css";
import { SelectAmPm } from "../../SelectAmPm/SelectAmPm";

interface ISideBarProps {
}

export const SideBar: FC<ISideBarProps> = (props) => {

  const [xPosition, setXPosition] = useState<number>(-200);
  const [listVisibillity, setListVisibillity] = useState<boolean>(true);

  const history = useHistory();
  const modal = useContext(ModalContext);

  const isCurrentUserGeneral = useSelector((state: RootState) => state.app?.pavingModuleUser.isInGeneralPavingSupers);
  const incompleteItems = useSelector((state: RootState) => state.app?.pavingModuleIncompleteItems);

  const [postToSns, { loading }] = useMutation(POST_TO_SNS);

  useEffect(() => {
    setXPosition(0);
  }, [])

  const toggleSideBar = () => {
    setXPosition(-200);

    if (xPosition < 0) {
      setXPosition(0);
    }
  }

  const onLogout = () => {
    history.push({
      pathname: '/logout',
      state: {
        from: "/logout"
      }
    })
  }

  const onUserManagementClick = () => {
    modal?.openModal?.({
      element: (
        <UsersManagement />
      ),
    });
  }

  const onEquipmentDetailsClick = () => {
    modal?.openModal?.({
      element: (
        <EquipmentDetailsWindow />
      ),
    });
  }

  const onTeamManagementClick = () => {
    modal?.openModal?.({
      element: (
        <TeamManagement />
      ),
    });
  }

  const onAuditTrailClick = () => {
    modal?.openModal?.({
      element: (
        <AuditTrail />
      ),
    });
  }

  const onNotificationsBubbleClick = () => {
    if (listVisibillity === false) {
      setListVisibillity(true)
    }
    else {
      setListVisibillity(false)
    }
  }

  const onEquipmentListManagementClick = () => {
    modal?.openModal?.({
      element: (
        <EquipmentListManagement />
      ),
    });
  }

  const onPushPavingScheduleClick = async () => {
    const [error, respose] = await to(postToSns());

    if (error?.message != null) {
      modal?.openModal?.({
        element: (
          <GeneralWarningModal
            message={error.message}
            title="Push Paving Schedule"
            yesNoButtons={false}
          />
        ),
      });
    }

    if (respose != null) {
      modal?.openModal?.({
        element: (
          <GeneralWarningModal
            message={"Successfully completed."}
            title="Push Paving Schedule"
            yesNoButtons={false}
          />
        ),
      });
    }
  }

  const onCalendarModule = () => {
    history.push({
      pathname: '/calendar',
      state: {
        from: "/calendar"
      }
    })
  }

  const onDayPreviewModule = () => {
    history.push({
      pathname: '/daypreview',
      state: {
        from: "/daypreview"
      }
    })
  }

  const onHistoryPreview = () => {
    history.push({
      pathname: '/history',
      state: {
        from: "/history"
      }
    })
  }

  const openManageResources = () => {
    modal?.openModal?.({
      element: (
        <AddResources />
      ),
    });
  }

  return (
    <>
      {loading && <FullScreenLoadingIndicator />}
      <div
        className="SideBar"
        style={{
          transform: `translatex(${xPosition}px)`,
          width: 200,
        }}
      >
        <button
          className="SideBar_Button"
          onClick={() => toggleSideBar()}
          style={{
            transform: `translate(${200}px, 20vh)`
          }}>
          {
            xPosition === 0
              ? <i className="fa fa-angle-double-left"></i>
              : <i className="fa fa-angle-double-right"></i>
          }

        </button>
        <div className="SideBar_Logo">
          <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
        </div>
        <div className="SideBar_Wrapper">
          <div className="SideBar_Nav">
            <div className="wrap">
              <button
                className="Equipment_Details_Management"
                onClick={() => onEquipmentListManagementClick()}
                disabled={!isCurrentUserGeneral as boolean}>
                <i className="fa fa-cogs" style={{ fontSize: "1.5rem" }} aria-hidden="true"></i>
              </button>
              <button
                className="Bubble_Notifications_Button"
                onClick={onNotificationsBubbleClick} disabled={!isCurrentUserGeneral as boolean}>
                <i className="fas fa-comment" style={{ fontSize: "1.5rem" }}></i>
                <div className="label">{incompleteItems.length}</div>
              </button>
            </div>
            {
              (listVisibillity === true && incompleteItems.length > 0)
                ? <NotifyIncompleteItems incompleteItems={incompleteItems} onClose={() => setListVisibillity(false)} />
                : null
            }
            <button
              onClick={onUserManagementClick}
              disabled={!isCurrentUserGeneral as boolean}
              className="Users_Management_Button"
            >
              Users Management
            </button>
            <button onClick={onEquipmentDetailsClick}>Equipment Details</button>
            <button onClick={onTeamManagementClick}>Crew Management</button>
            <button onClick={onAuditTrailClick}>Audit trail</button>
            <button onClick={() => openManageResources()}>Manage Resources</button>
            <button
              onClick={onPushPavingScheduleClick}
              className="Push_Paving_Schedule_Button"
              disabled={!isCurrentUserGeneral as boolean}
            >
              Push Paving Schedule
            </button>
          </div>
          <div className="Middle_Buttons">
            {
              history.location.pathname === "/calendar"
                ? <button onClick={onDayPreviewModule}>Go to Day Preview</button>
                : history.location.pathname === "/daypreview" ? <button onClick={onCalendarModule}>Go to Calendar</button> : null
            }
            {
              history.location.pathname === "/history"
                ? <>
                  <button onClick={onDayPreviewModule}>Go to Day Preview</button>
                  <button onClick={onCalendarModule}>Go to Calendar</button> </>
                : null
            }
            {
              history.location.pathname === "/calendar" || history.location.pathname === "/daypreview"
                ? <button onClick={onHistoryPreview}>Go to History Preview</button>
                : null
            }
             {
              history.location.pathname === "/calendar" || history.location.pathname === "/daypreview"
                ? <SelectAmPm/>
                : null
            }
          </div>
          <button onClick={onLogout}>Logout</button>
        </div>
      </div>
    </>
  )
}