import React, { FC } from "react";
import { Button } from "../../Form/Button";
import "./GeneralWarningModal.css";
import { CloseButton } from "../../Form/CloseButton";

interface IGeneralWarningModalProps {
  onConfirm?(): void;
  onClose?(): void;
  onCancel?(): void;
  message: string;
  title: string;
  onConfirmButtonText?: string;
  yesNoButtons: boolean;
}

export const GeneralWarningModal: FC<IGeneralWarningModalProps> = (props) => {
  return (
    <div>
      <div className="GeneralWarningModal Add_Crew">
        <div className="New_Crew_Title">
          <div className="Wrap">
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="graniterock" />
            <div className="Title">{props.title}</div>
          </div>
          <CloseButton onClick={() => props.onClose?.()} />
        </div>
        <div className="GeneralWarningModal_Body">
          <div className="ErrorMessage">
            <div className="ErrorText">
              {props.message}
            </div>
          </div>
          {
            props.yesNoButtons === true
              ? <div className="ErrorActionHolder">
                <Button
                  onClick={() => { props.onConfirm?.(); props.onClose?.() }}
                  title="Yes"
                  type="button"
                  className="Button"
                />
                <Button
                  onClick={() => { props.onCancel?.(); props.onClose?.() }}
                  title="No"
                  type="button"
                  className="Button"
                />
              </div>
              : <div className="WrapButton">
                <Button
                  onClick={() => { props.onClose?.() }}
                  title="Ok"
                  type="button"
                  className="Button"
                />
              </div>
          }
        </div>
      </div>
    </div>
  );
}