import gql from "graphql-tag";

export const JOB_INFOS_BY_DELETED_FOREMAN = gql`
  query jobInfosByDeletedForeman($where: JobInfosByDeletedForeman!){
    jobInfosByDeletedForeman(where: $where) {
      uID
      jobNumber_Date
      searchGSI_JobNumber
      tableauGSI_Tableau
      date  
      areaManager
      jobName
      pmpe
      superIntendent
      foreman
      resourceName
      resourceID
      resourceType
      resourceQTY  
      description
      additionalResourcesComments
      locationIndex  
      pavingForeman
      pavingSuperIntendent
      jobMap
    }
  }
`;
