import gql from "graphql-tag";

export const CANNED_QUERY = gql`
  query canned {
    canned {
		cannedID
		cannedCode
		cannedResources {
			resourceID,
			resourceQty
		}
	}
  }
`;