import React, { FC, useState, useEffect, useContext } from "react";
import "./CrewManagement.css";
import { AddCrew } from "../AddCrew/AddCrew";
import { EditCrew } from "../EditCrew/EditCrew";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Query, CustomCrewResourceComposition, Maybe, CustomCrew } from "../../../graphql/schema-types";
import { CREWS_QUERY } from "../../../graphql/queries/CREWS_QUERY";
import { CREWS_RESOURCES_BY_JOB_NUMBER_QUERY } from "../../../graphql/queries/CREWS_RESOURCES_BY_JOB_NUMBER_QUERY";
import { CheckBox } from "../../Form/CheckBox";
import { DELETE_CREW_RESOURCES_MUTATION } from "../../../graphql/mutations/DELETE_CREW_RESOURCES";
import { Button } from "../../Form/Button";
import { DELETE_CREW_MUTATION } from "../../../graphql/mutations/DELETE_CREW";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ModalContext } from "../../Modal/ModalContext/ModalContext";
import { CloseButton } from "../../Form/CloseButton";
import { FullScreenLoadingIndicator } from "../../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { FullScreenErrorIndicator } from "../../Modal/ErrorIndicator/FullScreenErrorIndicator";
import { addCustomCrews } from "../../../redux/appSlice";

interface CrewManagementInterface {
  onClose?(): void;
}

enum ButtonTypes {
  DELETE_RESOURCES = "DELETE_RESOURCES",
  DELETE_CREW = "DELETE_CREW"
}

export const CrewManagement: FC<CrewManagementInterface> = props => {

  const [currentCrew, setCurrentCrew] = useState<string | number | null | undefined>();
  const [currentCrewName, setCurrentCrewName] = useState<string | null | undefined>();
  const [checkBoxes, setCheckBoxes] = useState<{ [id: string]: boolean }>({});
  const [buttonType, setButtonType] = useState<ButtonTypes | null>(null);

  const jobNumber = useSelector((state: RootState) => state.app.start.jobNumber);

  const customCrewsByJobNumber = useSelector((state: RootState) => state.app.customCrews);

  const dispatch = useDispatch();

  const { loading, error, data, refetch } = useQuery<Pick<Query, "crews">>(CREWS_QUERY, {
    variables: { jobNumber },
    skip: jobNumber == null || jobNumber === "",
    fetchPolicy: "no-cache"
  });

  const { data: crewR, refetch: refCrewR } = useQuery<Pick<Query, "crewResourcesByJobNumber">>(CREWS_RESOURCES_BY_JOB_NUMBER_QUERY, {
    variables: { JobNumber: jobNumber },
    skip: jobNumber == null || jobNumber === "",
    fetchPolicy: "no-cache"
  });

  const [deleteCrewResources] = useMutation(DELETE_CREW_RESOURCES_MUTATION, { onCompleted: () => refCrewR(), fetchPolicy: "no-cache" });
  const [deleteCrew, { loading: loadingDeletingCrew }] = useMutation(DELETE_CREW_MUTATION, { onCompleted: () => refetch(), fetchPolicy: "no-cache" });

  const modal = useContext(ModalContext);

  const showAddCrewModal = () => {
    modal?.openModal?.({
      element: <AddCrew
        refetch={() => refetch()}
        crews={data?.crews}
      />
    })
  }

  useEffect(() => {
    if (data == null) return;
    setCurrentCrew(data.crews?.[0]?.crewCode);
    setCurrentCrewName(data.crews?.[0]?.crewDescription);
  }, [data])

  useEffect(() => {
    window.onpopstate = (e: any) => {
      props?.onClose?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.removeAttribute('style')
    }
  }, [])

  let currentCrewResources: CustomCrewResourceComposition[] = crewR?.crewResourcesByJobNumber
    .filter(cr => cr?.crewCode === currentCrew) as CustomCrewResourceComposition[];

  let sortedCurrentCrewResources = [...(currentCrewResources ?? [])]
    .sort((a, b) => a.resourceType! < b.resourceType! ? 1 : -1)
    .sort((n, p) => n.resourceID! > p.resourceID! ? 1 : -1);

  useEffect(() => {
    if (sortedCurrentCrewResources == null) return;
    if (sortedCurrentCrewResources?.length === 0) {
      setButtonType(ButtonTypes.DELETE_CREW);
    }
    else {
      setButtonType(ButtonTypes.DELETE_RESOURCES)
    }
  }, [crewR, sortedCurrentCrewResources])

  const onCheckBoxesChange = (e: React.ChangeEvent<HTMLInputElement>, checkBoxValue: string) => {
    const checked = e.target.checked;
    const _checkboxes = { ...checkBoxes };
    if (checked === false) {
      delete _checkboxes[checkBoxValue];
      setCheckBoxes(_checkboxes);
      return;
    }
    setCheckBoxes({ ...checkBoxes, [checkBoxValue]: checked });
  }

  const onSubmit = () => {
    deleteCrewResources({ variables: { resourceIDs: Object.keys(checkBoxes) } })
  }

  const onDeleteCrew = () => {
    deleteCrew({ variables: { jobNumber, crewCode: currentCrew } });
    let crews: Maybe<CustomCrew>[] = [];
    customCrewsByJobNumber.forEach(c => {
      if (c?.crewCode !== currentCrew) {
        crews.push(c);
      }
    })
    dispatch(addCustomCrews({ customCrews: crews }))
  }

  const showEditCrewModal = () => {
    modal?.openModal?.({
      element:
        <EditCrew
          key={Date.now()}
          crewCode={currentCrew as string}
          crewName={currentCrewName as string}
          crewResoures={sortedCurrentCrewResources!}
          jobNumber={jobNumber}
          refetch={() => refCrewR()}
        />
    })
  }

  return (
    <>
      {loading && <FullScreenLoadingIndicator />}
      {loadingDeletingCrew && <FullScreenLoadingIndicator />}
      {error != null && <FullScreenErrorIndicator />}
      <div className="Manage_Crew_Main_Screen">
        <div className="Crew_Header">
          <div className="Wrap">
            <img src={process.env.PUBLIC_URL + '/gr_logo_rgb.png'} alt="graniterock" />
            <div className="Title">Crew Management</div>
          </div>
          <CloseButton onClick={props.onClose} />
        </div>
        <div className="Crew_Select">
          <div className="Crews_Table">
            <div className="Table_Header">
              Crew Name
            </div>
            {
              data && data?.crews?.map(c => (
                <div
                  className={currentCrew === c?.crewCode ? "Table_Line Selected_Line" : "Table_Line"}
                  key={c?.id as string}
                  onClick={() => { setCurrentCrew(c?.crewCode); setCurrentCrewName(c?.crewDescription); }}
                >
                  {c?.crewDescription}
                </div>
              ))
            }
          </div>
        </div>
        <div className="Crew_Resources">
          <div className="Crew_Name">
            {currentCrewName != null ? currentCrewName : ""}
          </div>

          <div className="Resources_Table">
            {
              sortedCurrentCrewResources != null && sortedCurrentCrewResources
                .map((cr, index: number) => (
                  <div className="Resource_Line" key={index}>
                    <CheckBox
                      checked={checkBoxes[cr?.id as string] || false}
                      onChange={(e) => onCheckBoxesChange(e, cr?.id as string)}
                    />
                    <div className="Resource_ID">
                      {cr?.resourceID}
                    </div>
                    <div className="Resource_Name">
                      {cr?.resourceDescription}
                    </div>
                    <div className="Resource_Qu">
                      {cr?.QTYNeeded}
                    </div>
                  </div>
                ))
            }
          </div>
        </div>
        <div className="Actions_Holder">
          <div className="Add_Crew_Holder">
            <button className="Button Add_Crew_Button" onClick={showAddCrewModal}>
              Add Crew
            </button>
          </div>
          <div className="Add_Delete_Resources_Holder">
            <div className="Flex_Corect">
              <button className="Add_Edit_Resources Button" onClick={showEditCrewModal} disabled={currentCrew != null ? false : true}>
                Add/Edit Resources
              </button>
              {
                buttonType === ButtonTypes.DELETE_RESOURCES ?
                  (
                    <Button
                      onClick={() => onSubmit()}
                      title="Delete Selected Resource"
                      className="Delete_Resources Button"
                      disabled={true ? Object.keys(checkBoxes).length === 0 : false}
                    />
                  )
                  : (
                    <Button
                      onClick={() => onDeleteCrew()}
                      title="Delete Selected Crew"
                      className="Delete_Resources Button"
                      disabled={currentCrew != null ? false : true}
                    />
                  )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}