import React, { FC } from "react";
import { Button } from "../../Form/Button";
import "./ApplyCrew.css";
import { CloseButton } from "../../Form/CloseButton";

interface ApplyCrewInterface {
  onConfirm(): void;
  onClose?(): void;
  crewName: string;
  crewCode: string;
  assignDate?: string;
}

export const ApplyCrew: FC<ApplyCrewInterface> = (props) => {
  return (
    <div>
      <div className="ApplyCrew Add_Crew">
        <div className="New_Crew_Title">
          <div className="Wrap">
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="graniterock" />
            <div className="Title">Apply Crew</div>
          </div>
          <CloseButton onClick={() => props.onClose?.()} />
        </div>
        <div className="ApplyCrew_Body">
          <div className="ErrorMessage">
            <div className="ErrorText">
              Are you sure you want to apply <br />
              crew: {props.crewCode} / {props.crewName}  <br />
              to: {props.assignDate} ?
            </div>
          </div>
          <div className="ErrorActionHolder">
            <Button
              onClick={() => { props.onConfirm(); props.onClose?.() }}
              title="Yes"
              type="button"
              className="Button"
            />
            <Button
              onClick={() => props.onClose?.()}
              title="No"
              type="button"
              className="Button"
            />
          </div>
        </div>
      </div>
    </div>
  );
}