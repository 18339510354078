import React, { FC, useState } from "react";
import "./AddLocation.css";
import { CloseButton } from "../../../Form/CloseButton";
import { useDispatch } from "react-redux";
import { addLocation } from "../../../../redux/appSlice";

interface LocationNotificationInterface {
  onClose?(): void;
}
export const AddLocation: FC<LocationNotificationInterface> = props => {

  const [name, setName] = useState<string>("");

  const dispatch = useDispatch();

  const onSave = () => {
    dispatch(addLocation(name))
    props.onClose?.();
  }

  return (
    <div>
      <div className="Add_Location">
        <div className="New_Crew_Title">
          <div className="Wrap">
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="graniterock" />
            <div className="Title">New Location</div>
          </div>
          <CloseButton onClick={props.onClose} />
        </div>
        <div className="LocationCreated_Body">
          <input placeholder="Location name" value={name} onChange={(e) => setName(e.target.value)} />
          <button className="button button-location" onClick={() => onSave()} disabled={name.trim() === "" ? true : false}><i className="fas fa-plus"></i> Save location</button>
        </div>
      </div>
    </div>
  );
}
