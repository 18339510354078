import { useMutation } from "@apollo/react-hooks";
import to from "await-to-js";
import React, { FC, useContext, useState } from "react";
import { CHANGE_USER_MUTATION } from "../../../../graphql/mutations/CHANGE_USER_MUTATION";
import { JobInfo, Maybe, PavingSupers } from "../../../../graphql/schema-types";
import { CloseButton } from "../../../Form/CloseButton";
import { ModalContext } from "../../../Modal/ModalContext/ModalContext";
import { GeneralWarningModal } from "../../../Warnings & errors/GeneralWarningModal/GeneralWarningModal";
import "./DisableUser.css";

interface IDisableUserProps {
  onClose?(): void;
  activeUsers: Maybe<PavingSupers>[] | undefined
  userNameWeWantToDisable: string;
  userEmailWeWantToDisable: string
  refetch(): void;
  jobInfosByDisabledUser: JobInfo[]
}


export const DisableUser: FC<IDisableUserProps> = (props) => {

  const modal = useContext(ModalContext);

  const [selectedUser, setUser] = useState<string>("");

  const [changeUser] = useMutation(CHANGE_USER_MUTATION, { onCompleted: props.refetch });

  const onUserSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUser(e.target.value);
  }

  const onChangeUserApply = () => {
    modal?.openModal?.({
      element: <GeneralWarningModal
        message={
          `Are you sure you want to replace ${props.userNameWeWantToDisable} with ${selectedUser} ?`
        }
        title="Disable user"
        onConfirm={() => onChangeUser()}
        onCancel={() => { }}
        yesNoButtons={true}
      />
    });
  }

  const onChangeUser = async () => {
    if (selectedUser !== "") {
      await to(changeUser({ variables: { where: { disabledUser: props.userNameWeWantToDisable, disabledUserEmail: props.userEmailWeWantToDisable, updateUser: selectedUser, disabled: true, jobInfosByDisabledUser: props.jobInfosByDisabledUser } } }));
      props.onClose?.();
    }
    else {
      modal?.openModal?.({
        element: (
          <GeneralWarningModal
            message={
              `You have to select a super.`
            }
            title="No super selected"
            yesNoButtons={false}
          />
        ),
      });
    }
  }

  return (
    <div className="Disable_User">
      <div className="Disable_User_Header">
        <div className="Date_Wrap">
          <img src={process.env.PUBLIC_URL + '/logo.png'} alt="graniterock" />
          <div className="Title">Disable User {props.userNameWeWantToDisable}</div>
        </div>
        <CloseButton onClick={props.onClose} />
      </div>
      <div className="Disable_User_Content">
        <div className="Wrap_Select">
          <select className="dropdown" value={selectedUser} onChange={onUserSelect} style={{ outline: 0 }}>
            <option value="" disabled>
              Supers:
            </option>
            {
              props.activeUsers != null && props.activeUsers.map((au, index) => (
                <option value={au?.name as string} key={index} disabled={au?.name === props.userNameWeWantToDisable}>
                  {au?.name}
                </option>
              ))
            }
          </select>
        </div>
        <button onClick={() => { onChangeUserApply() }} className="Change_User_Button">Change User</button>
      </div>
    </div>
  )
}