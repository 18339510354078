import cookie from 'js-cookie';

export const addTokenToCookie = (token: string) => {
  cookie.set('token', token);
};

export const addIdTokenToCookie = (token: string) => {
  cookie.set('id_token', token);
};

export const addRefreshTokenToCookie = (token: string) => {
  cookie.set('refresh_token', token);
};
