import React, { FC, useContext, useEffect, useState } from "react";
import "./SuperIntendentPavingModule.css";
import { Calendar } from "./Calendar";
import { useDispatch, useSelector } from "react-redux";
import { addDataToPavingModule, addIncompleteItemsToPavingModule } from "../../redux/appSlice";
import { Query } from "../../graphql/schema-types";
import { useQuery } from "@apollo/react-hooks";
import { SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY } from "../../graphql/queries/SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY";
import { RootState } from "../../redux/store";
import { FullScreenLoadingIndicator } from "../Modal/LoadingIndicator/FullScreenLoadingIndicator";
import { SuperIndendentPavingModuleHeader } from "./SuperIndendentPavingModuleHeader/SuperIndendentPavingModuleHeader";
import { SleepMode } from "../Warnings & errors/SleepMode/SleepMode";
import { ModalContext } from "../Modal/ModalContext/ModalContext";

interface ISuperIntendentPavingModuleProps { }

export interface PavingModuleDayState {
  unix: number;
}

export const SuperIntendentPavingModule: FC<ISuperIntendentPavingModuleProps> = (props) => {
  const dispatch = useDispatch();

  const [call, setCall] = useState(false);
  const [queryCounter, setQueryCounter] = useState(0);
  const [sleepMode, setSleepMode] = useState(false);

  const modal = useContext(ModalContext);

  const items = useSelector((state: RootState) => state.app?.pavingModule);

  const pavingModuleUser = useSelector((state: RootState) => state.app?.pavingModuleUser);

  const remoteEquipmentList = useSelector((state: RootState) => state.app?.remoteEquipmentList);

  const daysFromState: string[] = Object.keys(items);

  const { data, loading, refetch } = useQuery<Pick<Query, "superIntendentPavingCalendarDatas">>(SUPER_INTENDENT_PAVING_CALENDAR_DATAS_QUERY, {
    variables: { where: { startDate: parseInt(daysFromState[0]), endDate: parseInt(daysFromState[daysFromState.length - 1]), pavingSuperName: pavingModuleUser.name } },
    skip: Object.keys(items).length === 0,
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  useEffect(() => {
    if (loading || data == null) { return }
    dispatch(addDataToPavingModule({ superIntendentDatas: data.superIntendentPavingCalendarDatas.correctItems }))
    dispatch(addIncompleteItemsToPavingModule({ incompleteItems: data.superIntendentPavingCalendarDatas.incompleteItems }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.superIntendentPavingCalendarDatas, loading]);

  const MINUTE_MS = 5000; //5 seconds

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('Verify if new items were added in the last 5 seconds');
      if (sleepMode === false)
        setCall(true);
    }, MINUTE_MS);

    return () => {
      clearInterval(interval);
    } // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call]);

  useEffect(() => {
    if (call === false) return;
    if (queryCounter === 120) // after 10 minutes
    {
      setSleepMode(true);
      setCall(false);
      modal?.openModal({
        element:
          <SleepMode
            onClose={() => { setSleepMode(false); setQueryCounter(0); }}
            onCancel={() => { setSleepMode(false); setQueryCounter(0); }} />
      })
      return;
    }
    else {
      refetch();
      setCall(false);
      setQueryCounter(queryCounter + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call]);

  return (
    <>
      {(loading) && <FullScreenLoadingIndicator />}
      {remoteEquipmentList.length > 0 ?  <div className="Paving_Module">
        <SuperIndendentPavingModuleHeader />
        <div className="Paving_Module_Calendar">
          <Calendar generatedDays={daysFromState} />
        </div>
      </div>
      :<FullScreenLoadingIndicator />}
    </>
  )
}