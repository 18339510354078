import React, { FC } from "react";
import "./SheetHeader.css";

interface ISheetHeaderProps {

}

export const SheetHeader: FC<ISheetHeaderProps> = () => {
  return (
    <div className="Sheet_Header">
      <div className="item small">Job#</div>
      <div className="item large">Job Name</div>
      <div className="item medium">PM/PE</div>
      <div className="item small">D/N</div>
      <div className="item large">Super</div>
      <div className="item medium">Foreman</div>
      <div className="item small">Qty</div>
      <div className="item small">TPH</div>
      <div className="item large">MAT'L</div>
      <div className="item large">Prod Type</div>
      <div className="item medium">Plant</div>
      <div className="item medium">Load out time</div>
      <div className="item small">Mix Dsgn</div>
      <div className="item medium">RTS Spprt</div>
      <div className="item small">Boot Trk Vndr</div>
      <div className="item large">TRK CO.</div>
      <div className="item medium">Type of Trucks</div>
      <div className="item small">#Trks</div>
      <div className="item small">UTS</div>
      <div className="item small">EW</div>
      <div className="item small">Crew Make OP</div>
      <div className="item small">Crew Make Lab</div>
      <div className="Item_Grinder">
        <div>Grinder</div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ padding: "2px" }}>4</div>
          <div style={{ padding: "2px" }}>6</div>
          <div style={{ padding: "2px" }}>7</div>
          <div style={{ padding: "2px" }}>12</div>
        </div>
      </div>
    </div>
  )
}