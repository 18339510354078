import React, { FC, useContext } from "react";
import { Maybe, PavingForeman } from "../../../graphql/schema-types";
import { ModalContext } from "../../Modal/ModalContext/ModalContext";
import Portal from "../../Modal/Portal";
import { NoWorkForForeman } from "../NoWorkForForeman/NoWorkForForeman";
import "./DayMenu.css";

interface IDayMenuInterface {
  unix: any,
  visible: boolean,
  pageX: number,
  pageY: number,
  pavingForemans: Maybe<PavingForeman>[]
}

export const DayMenu: FC<IDayMenuInterface> = (props) => {
  const { unix, pageX, pageY, visible, pavingForemans } = props;

  const modal = useContext(ModalContext);

  const onNoWorkForemanForDay = () => {
    modal?.openModal?.({
      element:
        <NoWorkForForeman unix={unix} pavingForemans={pavingForemans} onClose={() => { }} />
    });
  }

  if (visible) {
    return (
      <Portal selector="#menu-root">
        <ul className="Menu_Calendar" id="Day_Menu"
          style={{ top: `${pageY}px`, left: `${pageX}px` }}
        >
          <li className="Menu_Icons">
            <button onClick={() => onNoWorkForemanForDay()}>
              <i className="fa fa-user-edit"></i>
              No Work Foreman
            </button>
          </li>
        </ul>
      </Portal>
    );
  }
  return <></>;
};