import gql from "graphql-tag";

export const CREWS_QUERY = gql`
  query crews($jobNumber: Int!){
    crews(jobNumber: $jobNumber) {
		id
		crewCode
		crewDescription
		jobNumber
	}
  }
`;
