import { FC, useEffect, useState } from "react";
import { useIdToken } from "../../hooks/useIdToken";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { settings } from "../../config/settings";
import { useHistory } from "react-router-dom";
import React from "react";
import { LogOut } from "./LogOut";
import { logout } from "../../redux/appSlice";

export const Login2: FC = props => {

  const [isLogOut, setIsLogOut] = useState(false);

  const hasId = useIdToken();

  const token = useSelector((state: RootState) => state.app.auth.id_token);

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!hasId) { return }
    if (token != null) { return }
    // @ts-ignore
    if (history?.location?.state?.from === "/logout") {
      setIsLogOut(true);
      return
    }

    dispatch(logout());

    // eslint-disable-next-line no-restricted-globals
    location.href = settings.redirectUri;
  }, [token, hasId, history, dispatch])

  if (isLogOut) { return <LogOut /> }

  return (null);
}