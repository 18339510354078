import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import * as serviceWorker from './serviceWorker';
import { App } from './components/App';

import moment from "moment";
import { DragDropContextProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
// @ts-ignore
window.moment = moment;

ReactDOM.render(

  <DragDropContextProvider backend={HTML5Backend}>
    <App />
  </DragDropContextProvider>
  ,
  document.getElementById('app-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
